import React, { useContext } from 'react'
import { GlobalContext } from './../../contexts/GlobalContext';
const MendatoryFieldMsg = () => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const mendatoryFieldMsg = getFrontendMessage('Forms', 'Mandatory Form Field', 'Message', 'Text')
    return (
        <div className="mendatoryFieldMessage">
            <span className="mendatoryMark">*</span>{mendatoryFieldMsg}
        </div>
    )
}
export default MendatoryFieldMsg