import axios from 'axios'

export const fetchProductsAPI = async (queryString, header) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products${queryString}`, header)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchProductDetailsAPI = async (slug) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/by-slug/${slug}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}




export const fetchProductGroupsAPI = async (productid) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/grouped-products/${productid}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const fetchProductGalleryAPI = async (productid) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/gallery/${productid}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const notifyMeWhenProductInStockAPI = async (data, headers) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/notify-me`, data, headers)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchProductReviewsAPI = async (queryString) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/product-reviews?${queryString}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchProductQuestionsAnswersAPI = async (queryString) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/product-questions?${queryString}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const submitProductQuestionAPI = async (data, headers) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/product-questions/create`, data, headers)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchBoughtTogetherProductsAPI = async (id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/bought-together/${id}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}