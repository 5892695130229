import React, { useReducer, useState, useContext } from 'react'
import { Form, Button, Col, Container } from 'react-bootstrap'
import { Formik, Field, ErrorMessage } from 'formik'
import FormTextArea500 from '../../components/FormPartials/FormTextArea500'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'
import { orderReviewValidation } from '../../components/FormPartials/Validation'
import Rating from 'react-rating';
import { addProdutReviewAPI } from '../../apis/buyerOrdersAPIs'
import { AuthContext } from "../../contexts/AuthContext"
import ratingStar from '../../assets/images/rating_star.svg';
import ratedStar from '../../assets/images/rated_star.svg';

const AddOrderReview = ({ productID, itemID, orderID, setShowAddReviewPopup, reviewSubmittedForItem, props }) => {
    const { getBearerTokenConfig } = useContext(AuthContext);
    const [count, setCount] = useState(0);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);

    return (
        <Container className="orderReview">
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle="Thank you for your review"
                                msgBody="We will use your feedback to enhance your shopping experience on JNooNz"
                            />
                        ) : (
                            <>
                                <div className="customHeadingForForm">
                                    <p>
                                        Review Your Purchase
                                    </p>
                                </div>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        rating: "",
                                        comments: "",
                                        product_id: productID,
                                        item_id: itemID,
                                        order_id: orderID
                                    }}
                                    validationSchema={orderReviewValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        console.log(values);
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const submissionResult = await addProdutReviewAPI(values, getBearerTokenConfig());
                                        console.log(submissionResult);
                                        if (submissionResult.status) {
                                            formDispatch({
                                                type: 'FORM_SUBMISSION_SUCCESS',
                                            });
                                            reviewSubmittedForItem(itemID);
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                setShowAddReviewPopup(false);
                                            }, 5000);
                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }

                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {formState.hasSubmissionError && (
                                                <FormSubmissionFailed />
                                            )}
                                            <Col className="mb-4 p-0 mendatory productRating xl">
                                                <Col className="p-0 mb-1">
                                                    <label>Rate this item</label>
                                                </Col>
                                                <Field name="rating" id="rating" type="number">
                                                    {({ field: { value }, form: { setFieldValue } }) => (
                                                        <div>
                                                            <Rating
                                                                initialRating={values.rating}
                                                                emptySymbol={<img src={ratingStar} className="icon" alt="" />}
                                                                fullSymbol={<img src={ratedStar} className="icon" alt="" />}
                                                                onChange={(rating) => {
                                                                    setFieldValue('rating', rating)
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="rating" component="small" className="errorMessage" />

                                            </Col>
                                            <Col className={`p-0 mendatory ${errors.comments && touched.comments ? "errorParent" : ""}`}>
                                                <FormTextArea500
                                                    controlId="comments"
                                                    label="Write Your Review"
                                                    name="comments"
                                                    type="text"
                                                    as="textarea"
                                                    rows={6}
                                                    value={values.comments}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setCount(e.target.value.length);
                                                    }}
                                                    onBlur={handleBlur}
                                                    count={count}
                                                />
                                            </Col>

                                            <Col className="p-0 mt-40 buttonContainer">
                                                <Button variant="PurpleButton"
                                                    className="hasPadding uppercase hasShadow bold"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Submit your Review
                                                </Button>
                                            </Col>

                                            <Col className="p-0 mt-3 textRight">
                                                <MendatoryFieldMsg />
                                            </Col>

                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </Container>
    )
}

export default AddOrderReview