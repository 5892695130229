import axios from 'axios'
import { isValidEmail } from "../helpers/CommonHelper"


export const loginAPI = async (data, headers) => {
    if (!data || !data.password || !data.email || !isValidEmail(data.email))
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/login`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const logoutAPI = async (headers) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/logout`,
            {},
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const isloggedInAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/is-logged-in`,
            headers
        )
        console.log(res);
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}



export const checkEmailExist = async (email) => {
    if (!email || !isValidEmail(email))
        return false;

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/check-email-exist`,
            { email: email }
        )
        return res.data.emailExist;
    } catch (error) {
        return false;
    }
};

export const changePasswordAPI = async (data, headers) => {
    if (!data || !data.old_password || !data.new_password || data.new_password !== data.confirm_password)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/change-password`,
            data,
            headers
        )
        console.log(res);
        return res.data;
    } catch (error) {
        return { "status": false, error: 'Action failed, try again later.' };
    }
}

export const registerGuest = async (data) => {
    if (!data || !data.first_name || !data.email || !isValidEmail(data.email))
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/register-guest`,
            data
        )
        return { "status": true, "user": res.data.user };
    } catch (error) {
        return { "status": false };
    }
};


export const registerMember = async (data) => {
    if (!data || !data.email || !isValidEmail(data.email) || !data.first_name || !data.last_name || !data.mobile || !data.country_id)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/register`,
            data
        )
        return { "status": true, "user": res.data.user };
    } catch (error) {
        return { "status": false };
    }
};


export const registerWishlist = async (data) => {
    if (!data || !isValidEmail(data.email))
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/register-wishlist`,
            data,
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false, 'message': error.message };
    }
};

export const buyerStatsAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/dashboard-stats`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const buyerLogAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers-log`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getProfileStatsAPI = async (headers) => {
    if (!headers)
        return { "status": false };
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/profile-stats`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const updateProfileAPI = async (data, headers) => {
    if (!data || !data.id || !data.first_name || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/update/${data.id}`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const updateAlternateEmailAPI = async (data, headers) => {
    if (!data || !data.alternate_email || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/update-alternate-email`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getInterestOptionsAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/get-interest-options`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const updateInterestOptionsAPI = async (data, headers) => {
    if (!data || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/update-interests`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getHearAboutUsOptionsAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/get-hear-about-us-options`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const updateHearAboutUsOptionsAPI = async (data, headers) => {
    if (!data || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyers/update-hear-about-us`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}