import React, { useContext } from "react";
import { Link } from "react-router-dom";
import CustomizationButton from "./CustomizationButton/CustomizationButton";
import PackingButton from "./PackingItem/PackingButton";

import { isCustomizationApplied } from "../../helpers/CartHelper";
import Price from "./../ProductItem/Price";
import {
  getSelectedCurrencySymbol,
  getPriceInSelectedCurrency,
} from "../../helpers/MoneyHelper";
import { GlobalContext } from "./../../contexts/GlobalContext";

const CartItemForCheckout = ({ cart_item, ...props }) => {
  const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
  const { currencies } = useContext(GlobalContext).globalData.data;

  return (
    <>
      <div className="details d-flex">
        <div className="itemImageHolder">
          <Link to={`/product/${cart_item.product.slug}`}>
            <img
              className="itemImage"
              src={`${process.env.REACT_APP_BASE_URL}${cart_item.product.image}`}
              alt={cart_item.product.title}
              title={cart_item.product.title}
            />
          </Link>
        </div>
        <div className="itemDetails">
          <ul>
            <li className="itemCategory">
              {cart_item.product.category.name === "" ? (
                <p>{cart_item.product.type.name}</p>
              ) : (
                <>
                  {cart_item.product.subCategory.name === "" ? (
                    <p>{cart_item.product.category.name}</p>
                  ) : (
                    <p>{cart_item.product.subCategory.name}</p>
                  )}
                </>
              )}
              <></>
            </li>
            <li className="itemTitle">{cart_item.product.title}</li>
            <li>
              <div className="cartItemBottom">
                <ul>
                  <li className="priceContainer">
                    <Price
                      price={cart_item.product.price}
                      oldPrice={cart_item.product.old_price}
                    />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        </div>
        <div className="addonContainer">
          <ul className="d-flex mt-2 addOnul">
            <li>
              {isCustomizationApplied(cart_item) && (
                <div className="additionalCostinCartItem ">
                  Customization Cost:{" "}
                  <span className="additionalCost">
                    {getSelectedCurrencySymbol(
                      defaultSelectedCurrency,
                      currencies
                    ) +
                      getPriceInSelectedCurrency(
                        cart_item.customization_price,
                        defaultSelectedCurrency,
                        currencies,
                        true
                      )}
                  </span>
                </div>
              )}
            </li>

            <li>
              {cart_item.packaging_id && (
                <div className="additionalCostinCartItem ">
                  Packaging Cost:{" "}
                  <span className="additionalCost">
                    {getSelectedCurrencySymbol(
                      defaultSelectedCurrency,
                      currencies
                    ) +
                      getPriceInSelectedCurrency(
                        cart_item.packaging_price,
                        defaultSelectedCurrency,
                        currencies,
                        true
                      )}
                  </span>
                </div>
              )}
            </li>
            </ul>

            {cart_item.product.is_customizable ? (
              <>
                <div className="customization_pacaking_container pb-2">
                  <ul className="d-flex align-items-start flex-column">
                    <CustomizationButton cart_item={cart_item} />
                    <PackingButton cart_item={cart_item} />
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
          
        </div>
  
    </>
  );
};

export default CartItemForCheckout;
