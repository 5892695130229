import React, {
  useState,
  useReducer,
  useContext,
  useRef,
  useEffect,
} from "react";
import "./Search.scss";
import { Form, FormControl, Overlay, Popover, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { itemReducer, itemInitialState } from "../../reducers/Reducers";
import axios from "axios";
import { OverlayContext } from "../../contexts/OverlayContext";
import { AuthContext } from "../../contexts/AuthContext";
import { BackdropContext } from "../../contexts/BackdropContext";
import { fixedBody, relativeBody } from "../../helpers/DOMHelpers";
// import queryString from 'query-string'
import { CloseButton } from "../CloseOverlayButton/CloseOverlay";
import { ItemNotFoundDynamic } from "../Alert/Alert";
import * as DOMHelpers from "../../helpers/DOMHelpers"
import TopSearchedProducts from "./TopSearchedProducts";
import { getProductSuggestionsAPI } from "../../apis/generalAPIs";
import Menu from '../Menu/Menu'

const Search = (props) => {
   
    const { overlayState, overlayDispatch } = useContext(OverlayContext);
    const hideOverlay = () => {
        overlayDispatch({
          type: "OVERLAY_CLOSE",
        });
        backdropDispatch({
          type: "FETCH_BACKDROP_CLOSE",
        });
        DOMHelpers.relativeBody();
      }
  let history = useHistory();
  const { getBearerTokenConfig } = useContext(AuthContext);
  const currentURL = window.location.pathname;
  // console.log("Current URL", currentURL);
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
  const [searchQuery, setSearchQuery] = useState();
  const onSubmit = (e) => {
    if (currentURL === "/products/" || currentURL === "/products") {
      if (searchQuery) {
        history.push(`?q=${searchQuery}`);
      } else {
        console.log("reload needed");
        history.push(currentURL);
      }
    } else {
      if (searchQuery) {
        history.push(`/products/?q=${searchQuery}`);
      }
    }

    //history.push(`${currentURL === "/products/" ? `?q=${searchQuery}` : `/products/?q=${searchQuery}`}`)
    handleCloseButton();
  };

  const { searchOverlayState, searchOverlayDispatch } =
    useContext(OverlayContext);
  const { backdropState, backdropDispatch } = useContext(BackdropContext);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const showSearchOverlay = (event) => {
    searchOverlayDispatch({
      type: "OVERLAY_OPEN",
    });
    backdropDispatch({
      type: "FETCH_BACKDROPSOLID_OPEN",
    });
    fixedBody();
  };

  const closeSearchOverlay = (event) => {
    searchOverlayDispatch({
      type: "OVERLAY_CLOSE",
    });
    backdropDispatch({
      type: "FETCH_BACKDROPSOLID_CLOSE",
    });
    relativeBody();
  };

  const handleCloseButton = (event) => {
    searchOverlayDispatch({
      type: "OVERLAY_CLOSE",
    });
    backdropDispatch({
      type: "FETCH_BACKDROPSOLID_CLOSE",
    });
    relativeBody();
  };

  const getSuggestions = async (searchQuery) => {
    itemDispatch({
      type: "FETCH_REQUEST",
    });

    if (searchQuery && searchQuery.length > 2) {
      showSearchOverlay();
      const submissionResult = await getProductSuggestionsAPI(
        searchQuery,
        getBearerTokenConfig()
      );
      if (submissionResult.status) {
        itemDispatch({
          type: "FETCH_SUCCESS",
          payload: submissionResult.data,
        });
      } else {
        itemDispatch({
          type: "FETCH_FAILURE",
        });
      }
    } else {
      closeSearchOverlay();
      itemDispatch({
        type: "FETCH_SUCCESS",
        payload: [],
      });
    }
  };

  useEffect(() => {
    getSuggestions(searchQuery);
  }, [searchQuery]);

  //   const refOutside = useRef();
  //   function useOnClickOutside(refOutside, handler) {
  //       useEffect(
  //         () => {
  //           const listener = (event) => {
  //             if (!refOutside.current || refOutside.current.contains(event.target)) {
  //               return;
  //             }
  //             handler(event);
  //           };
  //           document.addEventListener("mousedown", listener);
  //           document.addEventListener("touchstart", listener);
  //           return () => {
  //             document.removeEventListener("mousedown", listener);
  //             document.removeEventListener("touchstart", listener);
  //           };
  //         },
  //         [refOutside, handler]
  //       );
  //     }

  // useOnClickOutside(refOutside, () =>
  // {
  // handleCloseButton()
  // })

  return (
    <>
      <div ref={ref}>
     
        <Form
          className="searchContainer"
          action="/"
          method="get"
          autoComplete="off"
        >
        <div className="menuholder"> <Menu
                  hideOverlay={hideOverlay}
                /></div>
          <FormControl
            size="lg"
            className="searchInputCustom"
            placeholder="Start typing here to search"
            type="text"
            // name="searchQuery"
            value={searchQuery || ""}
            // onKeyDown={(e) => { if (e.key === 'Backspace') { getData() }}}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onSubmit();
              }
            }}
            onChange={(event) => {
              setSearchQuery(event.target.value);
              if (event.target.value && event.target.value.length > 2) {
                showSearchOverlay();
                setTarget(event.target);
              }
            }}
            // onClick={event => {
            //     showSearchOverlay()
            // }}
          />
        
        </Form>

        <React.Fragment>
          <Overlay
            show={searchOverlayState.isShowing}
            target={target}
            placement="bottom"
            container={ref.current}
            containerPadding={20}
            rootClose={false}
            onHide={() => {
              handleCloseButton();
            }}
            transition={false}
          >
            <Popover className="customPopoverTheme searchResultOverlay disabledElementChild">
              <div className="closeButtonContainer floatButton">
                <CloseButton onClick={handleCloseButton} />
              </div>
              <Popover.Content className="p-0">
                <div className="searchResultPopupContent">
                  <Row>
                    <Col
                      xl={
                        (itemState.item.types &&
                          itemState.item.types.length > 0) ||
                        (itemState.item.categories &&
                          itemState.item.categories.length > 0) ||
                        (itemState.item.sub_categories &&
                          itemState.item.sub_categories.length > 0)
                          ? 4
                          : 8
                      }
                      lg={
                        (itemState.item.types &&
                          itemState.item.types.length > 0) ||
                        (itemState.item.categories &&
                          itemState.item.categories.length > 0) ||
                        (itemState.item.sub_categories &&
                          itemState.item.sub_categories.length > 0)
                          ? 4
                          : 8
                      }
                      md={
                        (itemState.item.types &&
                          itemState.item.types.length > 0) ||
                        (itemState.item.categories &&
                          itemState.item.categories.length > 0) ||
                        (itemState.item.sub_categories &&
                          itemState.item.sub_categories.length > 0)
                          ? 4
                          : 8
                      }
                      sm={12}
                      xs={12}
                    >
                      <ul>
                        <li>
                          <div className="searchResultInHeading mb-2">
                            Suggestions
                          </div>
                        </li>
                        {searchQuery !== "" && (
                          <>
                            {itemState.item.products &&
                            itemState.item.products.length ? (
                              <>
                                {itemState.item.products
                                  .slice(0, 4)
                                  .map((product, index) => (
                                    <React.Fragment key={product.id}>
                                      <li>
                                        <div
                                          className="hasCursor"
                                          onClick={(event) => {
                                            handleCloseButton();
                                            // history.push(`/products/?type=${product.type.slug}&category=${product.category.slug}&subCategory=${product.subCategory.slug}`)
                                            history.push(
                                              `/product/${product.slug}`
                                            );
                                          }}
                                        >
                                          {product.title}
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  ))}
                              </>
                            ) : (
                              <ItemNotFoundDynamic msgText="No matching found!" />
                            )}
                          </>
                        )}
                      </ul>
                    </Col>

                    {searchQuery !== "" &&
                    ((itemState.item.types &&
                      itemState.item.types.length > 0) ||
                      (itemState.item.categories &&
                        itemState.item.categories.length > 0) ||
                      (itemState.item.sub_categories &&
                        itemState.item.sub_categories.length > 0)) ? (
                      <React.Fragment>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                          <ul>
                            <li>
                              <div className="searchResultInHeading mb-2">
                                Matching Results In
                              </div>
                            </li>
                            {itemState.item.types &&
                            itemState.item.types.length ? (
                              <React.Fragment>
                                {itemState.item.types
                                  .slice(0, 4)
                                  .map((type, index) => (
                                    <React.Fragment key={type.id}>
                                      <li>
                                        <div
                                          className="hasCursor"
                                          onClick={(event) => {
                                            handleCloseButton();
                                            history.push(
                                              `/products/?type=${type.slug}`
                                            );
                                          }}
                                        >
                                          {type.name}
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  ))}
                              </React.Fragment>
                            ) : // types closed
                            null}

                            {itemState.item.categories &&
                            itemState.item.categories.length ? (
                              <React.Fragment>
                                {itemState.item.categories
                                  .slice(0, 4)
                                  .map((category, index) => (
                                    <React.Fragment key={category.id}>
                                      <li className="catogoryName tertiaryPink">
                                        <div
                                          className="hasCursor"
                                          onClick={(event) => {
                                            handleCloseButton();
                                            history.push(
                                              `/products/?type=${category.type.slug}&category=${category.slug}`
                                            );
                                          }}
                                        >
                                          {category.name}
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  ))}
                              </React.Fragment>
                            ) : // Category closed
                            null}

                            {itemState.item.sub_categories &&
                            itemState.item.sub_categories.length ? (
                              <React.Fragment>
                                {itemState.item.sub_categories
                                  .slice(0, 4)
                                  .map((subCategory, index) => (
                                    <React.Fragment key={subCategory.id}>
                                      <li className="subcatogoryName secondaryGreen">
                                        <div
                                          className="hasCursor"
                                          onClick={(event) => {
                                            handleCloseButton();
                                            history.push(
                                              `/products/?type=${subCategory.type.slug}&category=${subCategory.category.slug}&subCategory=${subCategory.slug}`
                                            );
                                          }}
                                        >
                                          {subCategory.name}
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  ))}
                              </React.Fragment>
                            ) : // Sub Category closed
                            null}
                          </ul>
                        </Col>
                      </React.Fragment>
                    ) : null}

                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <TopSearchedProducts
                        handleCloseButton={handleCloseButton}
                      />
                    </Col>
                  </Row>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </React.Fragment>
      </div>
    </>
  );
};

export default Search;
