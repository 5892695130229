
export const isTrue = (value) => {
    if (value === 1 || value === '1' || value === true || value === "true") return true;
    return false;
}

export const isEmpty = (data) => {
    if (data === undefined || data === 'undefined' || data === null || data === 'null' || data === '') return true;
    return false;
}


export const isValidEmail = (email) => {

    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (EMAIL_REGEX.test(email)) {
        return true;
    }
    return false;
}


export function getGuestSessionToken() {
    return JSON.parse(localStorage.getItem('guest_session_token'));
}


export function upperCaseFirst(str) {
    if (!str)
        return '';

    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    return arr.join(" ");
}


export function removeItemFromArray(item, itemArray) {
    if (!item)
        return itemArray;

    const index = itemArray.indexOf(item);
    if (index > -1) {
        itemArray.splice(index, 1);
    }

    return itemArray;
}

export function getImageValidationError(image) {
    //console.log('imageValidation called',image);
    
    if(image && image.name){
        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
        let allowedSize = 1;

        if(allowedExtension.indexOf(image.type) === -1){
            // console.log('not in allowed extensions');
            return 'Allowed extensions are : *.' + allowedExtension.join(', *.');
        }else if((image.size / 1024 / 1024) > allowedSize){
            //  console.log('not in allowed size');
            return 'Maximum allowed size is ' + allowedSize+'MB.';
        }
    }

    return null;
}