import React, { useReducer } from 'react'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent } from '../../components/Alert/Alert'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import axios from 'axios'
import FormInput from '../../components/FormPartials/FormInput'
import { resetPasswordValidation } from '../../components/FormPartials/Validation'
import { Link } from 'react-router-dom'
const ResetPasswordForm = ({ hash }) => {
    console.log('hash', hash);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    return (
        <>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted && (
                            <div>
                                <SuccessMsg
                                    msgTitle="Your password has been updated!"
                                    msgBody=""
                                />
                                <Form.Row className="mt-4 buttonContainer center">
                                    <Link
                                        to="/login"
                                        className="btn btn-PurpleButton hasPadding uppercase hasShadow bold"
                                    >
                                        Login
                                    </Link>
                                    <Link
                                        to="/"
                                        className="btn btn-PinkButton hasPadding uppercase hasShadow bold"
                                    >
                                        Continue Shopping
                                    </Link>
                                </Form.Row>
                            </div>
                        )}

                        {formState.inFormInitial && (
                            <Formik
                                initialValues={{
                                    hash: hash,
                                    password: "",
                                    confirm_password: "",
                                }}
                                validationSchema={resetPasswordValidation}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    formDispatch({
                                        type: "FORM_SUBMISSION_START",
                                    });
                                    setTimeout(() => {
                                        formDispatch({
                                            type: "FORM_SUBMISSION_SUCCESS",
                                        });
                                        const sendData = async (e) => {
                                            await axios.post(`${process.env.REACT_APP_BASE_URL}api/buyers/reset-password`,
                                                values
                                            )
                                                .then(res => {
                                                    console.log(res.data);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                        sendData();
                                    }, 500);
                                    console.log(values);
                                }}

                            >
                                {({ values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} className="mx-auto">
                                        <div className="pageHeading mb-2">Reset Password</div>
                                        <div className="customHeadingForForm">
                                            <p>
                                                Please enter a new password to use for your JNooNz account
                                            </p>
                                        </div>
                                        <input type="hidden" name="hash" value={values.hash} />
                                        <Col className={`form-group-p-1 mb-4 ${errors.password && touched.password ? "errorParent" : ""}`}>
                                            <FormInput
                                                controlId="password"
                                                label="New Password"
                                                name="password"
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="mb-2"
                                            />
                                            <div className="caption">
                                                Use 8-16 characters with one upper letters and at least one number.
                                            </div>
                                        </Col>

                                        <Col className={`${errors.confirm_password && touched.confirm_password ? "errorParent" : ""}`}>
                                            <FormInput
                                                controlId="confirm_password"
                                                label="Confirm Password"
                                                name="confirm_password"
                                                type="password"
                                                value={values.confirm_password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="mb-2"
                                            />
                                        </Col>


                                        <Button variant="PurpleButton mt-4"
                                            className="hasPadding uppercase hasShadow bold"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </>
    )
}

export default ResetPasswordForm
