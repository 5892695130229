import React, { useState, useEffect, useContext } from 'react'
import { Form } from "react-bootstrap"
import "./Quantity.scss"
import { CartContext } from "./../../contexts/CartContext"

const Quantity = ({ itemID, productQuantity }) => {

    const { updateCartQuantity } = useContext(CartContext);
    const [quantity, setQuantity] = useState(productQuantity);

    const handleQuantity = (event) => {
        if (isNaN(event.target.value)) {
            return;
        }
        setQuantity(event.target.value);
        updateCartQuantity(itemID, event.target.value)
    }
    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1)
        updateCartQuantity(itemID, quantity + 1)
    }
    const decrementQuantity = () => {
        if (quantity === 1) {
            return;
        }
        setQuantity(prevQuantity => prevQuantity - 1)
        updateCartQuantity(itemID, quantity - 1)
    }
    return (
        <>
            <div className="manageQuantityContainer">
                <Form>
                    <div className="manageQuantityHolder justify-content-end">
                        <div>
                            {
                                quantity > 1 &&
                                <div className="decrementButton"
                                    title="Remove Quantity"
                                    onClick={decrementQuantity}></div>
                            }
                        </div>
                        <div className="inputHolder">
                            <Form.Control className="quantityInput"
                                type="number" value={quantity}
                                onChange={handleQuantity}
                                readOnly
                            />
                        </div>
                        <div>
                            <div className="incrementButton"
                                title="Add Quantity"
                                onClick={incrementQuantity}
                            ></div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Quantity
