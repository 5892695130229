import React, { useReducer, useState, useContext } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { AuthContext } from '../../contexts/AuthContext'
import GoBack from '../../components/GoBack/GoBack'
import { updateAlternateEmailAPI } from '../../apis/buyerAPIs'
const validationSchema = Yup.object().shape({
    alternate_email: Yup.string()
        .email("Please enter a valid email address."),
});

const EditEmailID = (props) => {
    const { history } = props;
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { userAuthState, getBearerTokenConfig, userAuthDispatch } = useContext(AuthContext)
    const [initialValues, setInitialValues] = useState({
        email: userAuthState.user.email,
        alternate_email: userAuthState.user.alternate_email,
    });

    return (
        <div>
            <GoBack history={history} />
            <div className="pageHeading">Edit Email Address</div>
            <div className="caption2">You can add/edit your alternate email address only. Since the primary email address which is used for registration process is not allowed to edit.</div>
            <div className="formContainer mt-4">
                {formState && formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle="Alternative email is updated successfully"
                                msgBody=""
                            />
                        ) : (
                            <>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const apiResult = await updateAlternateEmailAPI(values, getBearerTokenConfig());
                                        console.log(apiResult);
                                        if (apiResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                            userAuthDispatch({
                                                type: "LOGIN",
                                                payload: {
                                                    user: { ...userAuthState.user, alternate_email: values.alternate_email }
                                                }
                                            })
                                            setInitialValues({
                                                email: values.email,
                                                alternate_email: values.alternate_email,
                                            })

                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED"
                                            });
                                        }

                                        setTimeout(() => {
                                            formDispatch({
                                                type: "FORM_INITIAL_STAGE",
                                            });
                                        }, 5000);
                                        setSubmitting(false); //// Important
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {formState.hasSubmissionError && <FormSubmissionFailed />}
                                            <Form.Row>
                                                <Col xl={6} lg={12} md={12} sm={12} xs={12} className={`${errors.email ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="email"
                                                        label="Email Address"
                                                        name="email"
                                                        type="email"
                                                        value={values.email}
                                                        disabled
                                                        readOnly
                                                    />
                                                </Col>

                                                <Col xl={6} lg={12} md={12} sm={12} xs={12} className={`${errors.alternate_email ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="alternate_email"
                                                        label="Alternate Email Address (optional)"
                                                        name="alternate_email"
                                                        type="email"
                                                        value={values.alternate_email}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Form.Row>

                                            <Button variant="PurpleButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default EditEmailID
