import React, { useContext } from 'react'
import CartItemForCheckout from "../../components/Cart/CartItemForCheckout"
import { SkeletonHorizontalItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent } from '../../components/Alert/Alert'
import NoItemFoundInCart from './NoItemFoundInCart'
import { CartContext } from "../../contexts/CartContext"


const CartCheckout = (props) => {
    const { cart } = useContext(CartContext);

    return (
        <>
            {(cart.isFetching || cart.isUpdating) ? (
                <SkeletonHorizontalItem />
            ) : cart.hasError ? (
                <ErrorComponent />
            ) : (
                <>
                    {cart.cartItems && cart.cartItems.items ? (
                        <>
                            <ul >
                                {cart.cartItems.items.map((cart_item, index) =>
                                    <li key={cart_item.id} className="itemHolder horizontalItemHolder checkoutsection">
                                        {cart.isRemoving ? (
                                            <SkeletonHorizontalItem />
                                        ) : (
                                            <CartItemForCheckout
                                                key={cart_item.id}
                                                cart_item={cart_item}
                                            />
                                        )}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <NoItemFoundInCart />
                    )}
                </>
            )}
        </>
    )
}

export default CartCheckout;