import React, { useReducer, useContext } from 'react'
import { Nav } from 'react-bootstrap'
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { logoutAPI } from '../../apis/buyerAPIs'
import { AuthContext } from '../../contexts/AuthContext'
import { CartContext } from "../../contexts/CartContext"
import { useHistory } from 'react-router-dom'
const Logout = ({ hideCartOverlay, props }) => {
    const { setCartItems } = useContext(CartContext);
    const { userAuthState, getBearerTokenConfig, userAuthDispatch } = useContext(AuthContext);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    let history = useHistory();

    return (
        <Nav.Link
            className="p-0"
            onClick={async (e) => {

                if (userAuthState && userAuthState.user && userAuthState.user.token) {
                    const apiResult = await logoutAPI(getBearerTokenConfig());
                    if (apiResult.status) {
                        userAuthDispatch({ type: "LOGOUT" })
                        hideCartOverlay()
                        itemDispatch({
                            type: "FETCH_SUCCESS",
                            payload: apiResult.data
                        });
                        setCartItems([]);
                        history.push('/')
                    } else {
                        itemDispatch({
                            type: "FETCH_FAILURE"
                        });
                    }
                } else {
                    userAuthDispatch({ type: "LOGOUT" })
                    hideCartOverlay()
                    itemDispatch({
                        type: "FETCH_SUCCESS",
                        payload: []
                    });
                    setCartItems([]);
                }
            }
            }
        >Logout
        </Nav.Link>
    )
}

export default Logout