import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AppliedPackaging = ({ currencySymbol, packaging, props }) => {



    return (
        <Container className="orderReview">
            <div className="formContainer">
                <div className="packingDetailsOnItem">

                    <>
                        <div className="pageHeading mb-4">
                            Your Selected Packaging
                        </div>
                        <div className="packingContainer">
                            <div className="packingDetails">
                                <ul>
                                    <li>
                                        {/* ID: {cart_item.package_id}
                                            {cart_item.title} */}

                                        <Row >

                                            <Col xl={4} lg={4} md={4} sm={4} xs={12} className="pr-0">
                                                <div className="packingImage">
                                                    <img
                                                        className="itemImage"
                                                        src={`${process.env.REACT_APP_BASE_URL}${packaging.image}`}
                                                        alt={packaging.title}
                                                        title={packaging.title}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                                <div className="selectedOption">{packaging.title}</div>
                                            </Col>

                                        </Row>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>

                    <div className="packingTotalCost hasBorderTop hasMediumTopPadding">
                        Cost: <span className="totalPrice">

                            <span>{currencySymbol}{packaging.price}</span>

                        </span>

                    </div>

                </div>
            </div>
        </Container>
    )
}

export default AppliedPackaging