import React from 'react';
import BreadcrumbsProducts from '../Breadcrumbs/BreadcrumbsProducts';
import "./InnerPageHeader.scss";
const InnerPageHeader = ({ productTypes, productCategories, productSubCategories }) => {
    return (
        <div className="innerPageHeader">
            <div className="headerPattern top"></div>
            <BreadcrumbsProducts
                productTypes={productTypes}
                productCategories={productCategories}
                productSubCategories={productSubCategories}
            />
        </div>
    )
}

export default InnerPageHeader
