export const CustomizationReducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_CUSTOMIZATION_POPUP':
            return {
                ...state,
                showModal: true,
                isLoading: true,
                hasError: false
            };

        case 'CLOSE_CUSTOMIZATION_POPUP':
            return {
                ...state,
                showModal: false,
                isLoading: false,
                hasError: false
            };

        case 'FETCH_CUSTOMIZATION_REQUEST':
            return {
                ...state,
                showModal: true,
                isLoading: true,
                hasError: false
            };
        case "FETCH_CUSTOMIZATION_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                options: action.payload,
            };
        case "FETCH_CUSTOMIZATION_ERROR":
            return {
                ...state,
                isLoading: false,
                hasError: true,
                options: {}
            };


        default:
            return state;
    }
}