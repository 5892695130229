import React, { useReducer, useEffect, useContext } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import axios from 'axios'
import { SkeletonSinglelineItem } from '../../components/Skeleton/Skeleton'
import { ItemNotFound } from '../../components/Alert/Alert'
import { Time } from '../../components/TimeFormat/TimeFormat'
import { Link, useRouteMatch } from 'react-router-dom'
import ContinueShoppingButton from '../../components/ContinueShoppingButton/ContinueShoppingButton'
import { ErrorComponent } from './../../components/Alert/Alert';
import { GlobalContext } from './../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext'
import { orderLogAPI } from '../../apis/orderAPIs'
const OrderLog = ({ statsLoaded }) => {
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const { getFrontendMessage } = useContext(GlobalContext);
    let { url } = useRouteMatch()


    const loadContent = async () => {
        if (userAuthState.user && userAuthState.user.token) {
            itemDispatch({
                type: "FETCH_REQUEST"
            });

            const apiResult = await orderLogAPI(getBearerTokenConfig());
            if (apiResult.status) {
                itemDispatch({
                    type: "FETCH_SUCCESS",
                    payload: apiResult.data
                });
            } else {
                itemDispatch({
                    type: "FETCH_FAILURE"
                });
            }
        }
    }


    useEffect(() => {
        if (statsLoaded) {
            console.log('stats laoded true');
            loadContent();
        }
    }, [statsLoaded]);



    // useEffect(() => {
    //     if (userAuthState.user && userAuthState.user.token) {
    //         itemDispatch({
    //             type: "FETCH_PRODUCTS_REQUEST"
    //         });
    //         const getData = async () => {
    //             await axios.get(`${process.env.REACT_APP_BASE_URL}api/buyer-orders/log`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${userAuthState.user.token}`,
    //                     }
    //                 }
    //             )
    //                 .then(res => {
    //                     itemDispatch({
    //                         type: "FETCH_SUCCESS",
    //                         payload: res.data
    //                     });
    //                     console.log(res.data);
    //                 })
    //                 .catch(error => {
    //                     itemDispatch({
    //                         type: "FETCH_FAILURE"
    //                     });
    //                 });
    //         }
    //         getData()
    //     }
    // }, []);
    return (
        <>
            {(itemState.item && itemState.item.length > 0) &&

                <div className="buyersLogContainer">
                    <>
                        <Card border="secondaryGray">
                            <Card.Header>{getFrontendMessage('Dashboard', 'Order Dashboard', 'Recent Order', 'Text')}</Card.Header>
                            <Card.Body className="cardBody">

                                <ListGroup variant="flush" as="ul">
                                    {itemState.item.map((logItem, index) => (
                                        <ListGroup.Item as="li" key={index} variant="light">
                                            <div className="secondaryPink activityDate"><Time time={logItem.created_at} /></div>
                                            <div className="mr-2 ml-2 activitytext">
                                                <div className="text">
                                                    {/* You successfully placed your order #{logItem.order_id}  */}
                                                    {logItem.action}.
                                                </div>
                                            </div>
                                            {/* <Card.Link as={Link} to={`${url}/orders/${logItem.id}`}
                            className="link pink viewActivityLink"
                        >View</Card.Link> */}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>

                            </Card.Body>
                        </Card>

                    </>
                </div>


            }

        </>
    )
}

export default OrderLog
