import React, { useContext } from 'react'
import { Col, Form } from 'react-bootstrap'
import { GlobalContext } from './../../contexts/GlobalContext';
const AllowToMakeProfile = ({ values, handleBlur, handleChange, msg, ...props }) => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const miniProfileMessage = getFrontendMessage('Forms', 'Create Mini Profile', 'Message', 'Text')
    const miniProfileCheckboxTitle = getFrontendMessage('Forms', 'Create Mini Profile', 'Checkbox Label', 'Text')
    return (
        <Col className="p-0 mb-3 allowForMiniProfileContainer">
            <div className="caption mb-3">
                {miniProfileMessage}
            </div>
            <Form.Group controlId="create_my_profile">
                <Form.Check
                    inline
                    label={miniProfileCheckboxTitle}
                    name="create_my_profile"
                    type="checkbox"
                    id="create_my_profile"
                    value={values.create_my_profile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Form.Group>
        </Col>
    )
}

export default AllowToMakeProfile