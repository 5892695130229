import React, { useContext } from 'react'
import { ImCart } from "react-icons/im"
import { Button } from 'react-bootstrap'
import { CartContext } from "../../../contexts/CartContext"

const AddToCart = ({ item, isCartButtonStyle }) => {
    const { addCartItem } = useContext(CartContext);

    return (
        <>
            {isCartButtonStyle === false &&
                <div className="addToCartButton hasWhatsAppOrderIcon"
                    title="Add to Cart"
                    onClick={() => {
                        addCartItem(item.id);
                    }
                    }
                >
                    <ImCart
                        className="icon"
                    // className={`${itemID === 32 ? "disabled": ""}`}
                    />
                </div>
            }

            {isCartButtonStyle === true &&
                <div className="d-flex">
                    <Button variant="PinkButton" size="lg" className="hasShadow hasPadding uppercase"
                        title="Add to Cart"
                        onClick={() => {
                            addCartItem(item.id);
                        }
                        }
                    >
                        Add to Cart
                        <span className="addToCartButtonDetailsPage hasWhatsAppOrderIcon">
                            <ImCart style={{
                                fontSize: 26, marginLeft: 12
                            }} />
                        </span>
                    </Button>
                </div>
            }
        </>
    )
}
export default AddToCart;