import React from 'react'


const TypeCategoryFilters = ({ availableFilters, appliedFilters, applyNewFilter }) => {
    const { productTypes, productCategories, productSubCategories } = availableFilters
    const { type, category, subCategory } = appliedFilters
    //console.log(availableFilters, appliedFilters);

    return (
        <>
            {(productTypes && productTypes.length > 0) &&
                <div className="linkFilterContainer" >
                    <div className="filterSectionHeading">
                        Category
                    </div>
                    <ul className="typesContainer">

                        <>
                            {productTypes.map((availableProductType, index) => (
                                <li key={index}>
                                    <div
                                        className="hasCursor link gray"
                                        onClick={() => { applyNewFilter('type', availableProductType.slug) }}
                                    >
                                        <span
                                            className={`title  ${type === availableProductType.slug ? "active" : ""}`}
                                        >
                                            {availableProductType.name}
                                        </span>
                                        {<span className="productCount">({availableProductType.products_count})</span>}
                                    </div>

                                    {(type && type === availableProductType.slug && productCategories && productCategories.length > 0) &&

                                        <div className="categoryContainer">
                                            <ul>
                                                {productCategories.map((availableProductCategory, index) => (
                                                    <li key={index}>
                                                        <div
                                                            className="hasCursor link dark"
                                                            onClick={() => { applyNewFilter('category', availableProductCategory.slug) }}
                                                        >
                                                            <span
                                                                className={`title  ${category === availableProductCategory.slug ? "active" : ""}`}
                                                            >
                                                                {availableProductCategory.name}
                                                            </span>
                                                            <span className="productCount">({availableProductCategory.products_count})</span>
                                                        </div>

                                                        {(category && category === availableProductCategory.slug && productSubCategories && productSubCategories.length > 0) &&
                                                            <div className="subCategoryContainer">
                                                                <ul>
                                                                    {productSubCategories.map((availableProductSubCategory, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {availableProductSubCategory.products_count > 0 &&
                                                                                <li>
                                                                                    <div
                                                                                        className="hasCursor link semidark"
                                                                                        onClick={() => { applyNewFilter('subCategory', availableProductSubCategory.slug) }}
                                                                                    >
                                                                                        <span
                                                                                            className={`title  ${subCategory === availableProductSubCategory.slug ? "active" : ""}`}
                                                                                        >
                                                                                            {availableProductSubCategory.name}
                                                                                        </span>
                                                                                        <span className="productCount">({availableProductSubCategory.products_count})</span>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}

                                                                </ul>
                                                            </div>
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </li >
                            ))}
                        </>

                    </ul >
                </div>
            }

        </>
    )
}

export default TypeCategoryFilters
