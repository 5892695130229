import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap'
import { Field, ErrorMessage} from 'formik';

const FormTextarea500 = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  ...props
}) => {
  const max_length = 500;
  return (
    <React.Fragment>

<Field name={name} validate={validate}>
  {({
  field,
  form: { touched, errors },
  meta,
  }) => (
    <Form.Group md={md} controlId={controlId}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      name={name}
      as={as}
      type={type}
      label={label}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      errors={errors}
      required={required}
      rows={rows}
  />
  <ErrorMessage component= "div" name={name}
    className="errorMessage" 
  />
  <span
    className="charCount"
    >
        {props.count} / {max_length} Characters only
    </span>
 </Form.Group>
  )}

</Field>
    </React.Fragment>
  )
}

FormTextarea500.defaultProps = {
  type: "text",
  className: ""
}

FormTextarea500.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default FormTextarea500;