import React, { useEffect, useReducer } from 'react';
import './About.scss'
import { Container, Col } from 'react-bootstrap'
import JnoonzExclusiveSlider from '../../components/JnoonzExclusiveSlider/JnoonzExclusiveSlider'
import Spotlights from "../../components/Spotlights/Spotlights";
import { PagesReducer, pageInitialState } from "../../reducers/PagesReducer"
import { fetchPageContentAPI } from '../../apis/generalAPIs'
import { ContainerLoading } from './../../components/Loading/Loading';
import { ErrorComponent } from './../../components/Alert/Alert';

const About = () => {
    const [pageState, pageStateDispatch] = useReducer(PagesReducer, pageInitialState);

    const loadContent = async () => {
        const apiResult = await fetchPageContentAPI('about');
        if (apiResult.status) {
            pageStateDispatch({
                type: "FETCH_PAGE_CONTENT_SUCCESS",
                payload: apiResult.data
            });
        } else {
            pageStateDispatch({
                type: "FETCH_PAGE_CONTENT_ERROR"
            });
        }
    }

    useEffect(() => {
        loadContent();
    }, []);

    return (
        <>
            <div className="aboutus">
                <div className="aboutusPattern top"></div>
                <Container>
                    <Col className="p-0 aboutusTextContent">
                        {pageState.isLoading && <ContainerLoading />}
                        {pageState.hasError
                            ? <ErrorComponent />
                            : <>
                                <h2 className="uppercase heading">{(pageState.content && pageState.content.heading) ? pageState.content.heading : 'About Jnoonz'}</h2>
                                <div className="textContent richtext" dangerouslySetInnerHTML={{ __html: ((pageState.content && pageState.content.content) ? pageState.content.content : null) }}></div>
                            </>
                        }
                    </Col>
                </Container>
                <div className="aboutusPattern bottom"></div>
            </div>
            <div className="hasNegativeMarginTop50">
                <JnoonzExclusiveSlider />
            </div>
            <Container className="p-0">
                <Spotlights />
            </Container>
        </>
    )
}

export default About;