import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { GlobalContext } from './../../contexts/GlobalContext';
import { CartContext } from "../../contexts/CartContext"


const CurrencySelecter = () => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { setSelectedCurrency } = useContext(GlobalContext)
    const { changeCurrency } = useContext(CartContext);


    const { currencies } = useContext(GlobalContext).globalData.data;

    //console.log(currencies)
    const currencykeys = Object.keys(currencies);
    //console.log(currencykeys)



    return (
        <>
            {
                (currencies && currencykeys && currencykeys.length > 1) &&
                <Form.Control
                    as="select" custom
                    className="appCurrency"
                    value={defaultSelectedCurrency}
                    onChange={(e) => {

                        changeCurrency(e.target.value)
                        //console.log('currency change called with ', e.target.value);
                        //localStorage.setItem('defaultCurrency', e.target.value)
                        //setSelectedCurrency(e.target.value)
                    }}
                >
                    {currencykeys.map((currencyCode, index) => (
                        <option key={index} value={currencyCode} className="textColor">{currencyCode}</option>
                    ))}
                </Form.Control>
            }
        </>
    )
}

export default CurrencySelecter
