import React, { useReducer, useEffect, useContext, useState } from 'react'
import '../Messages.scss'
import { ListGroup, Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { AuthContext } from "../../../contexts/AuthContext"
import axios from 'axios'
import { itemReducer, itemInitialState } from "../../../reducers/Reducers"
import { SkeletonEmailItem } from '../../../components/Skeleton/Skeleton';
import { ErrorComponent, ItemNotFoundDynamic } from '../../../components/Alert/Alert'
import MessageThreadItem from '../MessageThreadItem'
import GoBack from '../../../components/GoBack/GoBack'
import { useHistory } from 'react-router-dom';
import { ClosePopup } from '../../../components/CloseOverlayButton/CloseOverlay'
import { ContainerLoading } from '../../../components/Loading/Loading'
import ReplyMessage from './ReplyMessage'
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getMessageThreadAPI } from '../../../apis/buyerMessagesAPIs'

const MessagesThread = (props) => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const { getBearerTokenConfig } = useContext(AuthContext);

    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const [showPopup, setShowPopup] = useState(false)
    const [messageSubject, setMessageSubject] = useState()

    let history = useHistory();
    const { match } = props;
    const messageid = match.params.id;
    console.log(messageid);


    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [messages, setMessages] = useState([])


    const loadMessages = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const apiResult = await getMessageThreadAPI(messageid, `page=${currentPage}`, getBearerTokenConfig());
        console.log(apiResult);
        if (apiResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: []
            });
            setMessages([...messages, ...apiResult.data.items]);
            setTotalPages(apiResult.data._meta.pageCount)
            setMessageSubject(apiResult.data.items[0].subject)
        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            });
        }
    }
    useEffect(() => {
        if (currentPage > 0) {
            loadMessages();
        }

        if (currentPage === 0) {
            setMessages([]);
        }
    }, [currentPage]);

    const loadMore = () => {
        setLoadMoreLoading(true)
        setTimeout(function () {
            setCurrentPage(currentPage + 1);
            setLoadMoreLoading(false)
        }, 1500)
    }




    return (
        <>
            <Container className="dashboardMessages messageThread">
                <GoBack history={history} />
                <Row className="align-items-center">
                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                        <div className="pageHeading">{getFrontendMessage('Dashboard', 'Buyer Messages', 'Thread Heading', 'Text')}</div>
                        <div className="caption2">{getFrontendMessage('Dashboard', 'Buyer Messages', 'Thread Sub Heading', 'Text')}</div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                        <div className="buttonContainer mb-4 textRight textAlignInheritMobile">
                            <Button size="sm"
                                variant="PinkButton"
                                className="lessCorners uppercase"
                                onClick={() => {
                                    setShowPopup(true)

                                }
                                }>
                                {getFrontendMessage('Dashboard', 'Buyer Messages', 'Thread Reply Button', 'Text')}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className="dashboardMessagesContainer mt-4">
                    {(itemState.isFetching && messages.length <= 0) ? (
                        <SkeletonEmailItem />
                    ) : itemState.hasError ? (
                        <ErrorComponent />
                    ) : (
                        <>
                            {(messages && messages.length > 0) ? (
                                <>
                                    <ListGroup variant="flush" as="ul">
                                        {messages.map((message, index) => (
                                            <React.Fragment key={index}>
                                                <MessageThreadItem key={message.id} message={message} />
                                            </React.Fragment>
                                        ))}
                                    </ListGroup>
                                    {loadMoreLoading ? <SkeletonEmailItem /> : ''}
                                    {totalPages !== currentPage &&
                                        <div className="loadMoreButton">
                                            <Button
                                                className="hasShadow"
                                                variant="whiteButton"
                                                size="sm"
                                                onClick={() => {
                                                    loadMore()
                                                }}
                                            >
                                                load more
                                            </Button>
                                        </div>
                                    }
                                </>
                            ) : (
                                <ItemNotFoundDynamic
                                    msgTitle={getFrontendMessage('Dashboard', 'Buyer Messages', 'No Records', 'Text')}
                                />
                            )}
                        </>
                    )}
                </div>
            </Container>
            <Modal
                show={showPopup}
                centered
                onHide={() =>
                    setShowPopup(false)
                }
                animation={false}
                className="customModal"
                size="md"
            >
                <Modal.Body>
                    <div className="closeButtonContainer floatButton">
                        <ClosePopup
                            setShowPopup={setShowPopup}
                        />
                    </div>

                    <div>
                        <ReplyMessage

                            messageSubject={messageSubject}
                            messageid={messageid}
                            setCurrentPage={setCurrentPage}
                            setShowPopup={setShowPopup}

                        />
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}
export default MessagesThread