import React, { useReducer, useEffect, useContext, useState } from "react";
import "./Orders.scss";
import { Link, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import {
  Container,
  Badge,
  Form,
  ListGroup,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsEyeFill, BsFillEnvelopeFill } from "react-icons/bs";
import { MdCloudDownload } from "react-icons/md";
import GoBack from "../../components/GoBack/GoBack";
import { itemReducer, itemInitialState } from "../../reducers/Reducers";
import axios from "axios";
import queryString from "query-string";
import { AuthContext } from "../../contexts/AuthContext";
import { Time } from "../../components/TimeFormat/TimeFormat";
import OrderSearch from "./OrderSearch";
import {
  ErrorComponent,
  FormSubmissionFailed,
  ItemNotFound,
} from "./../../components/Alert/Alert";
import { SkeletonEmailItem } from "../../components/Skeleton/Skeleton";
import PaginationItem from "../../components/Pagination/Pagination";
import { getOrdersAPI } from "../../apis/buyerOrdersAPIs";

const Orders = (props) => {
  let history = useHistory();
  let { url } = useRouteMatch();
  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  console.log(search);
  const { getBearerTokenConfig } = useContext(AuthContext);
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
  const [stateByDays, setStateByDays] = useState();
  const [stateByOrderStatus, setStateByOrderStatus] = useState();
  const [stateByPaymentStatus, setStateByPaymentStatus] = useState();
  const [searchQuery, setSearchQuery] = useState();
  // useEffect(() => {
  //   if (userAuthState.user && userAuthState.user.token) {
  //     itemDispatch({
  //       type: "FETCH_REQUEST"
  //     });
  //     const getData = async () => {
  //       await axios.get(`${process.env.REACT_APP_BASE_URL}api/buyer-orders${search}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${userAuthState.user.token}`,
  //           }
  //         }
  //       )
  //         .then(res => {
  //           itemDispatch({
  //             type: "FETCH_SUCCESS",
  //             payload: res.data
  //           });
  //           console.log("Order List", res.data);
  //         })
  //         .catch(error => {
  //           itemDispatch({
  //             type: "FETCH_FAILURE"
  //           });
  //         });
  //     }
  //     getData()
  //   }
  // }, [search]);

  const applyFilters = (name, value) => {
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history.push({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const setPagination = (value) => {
    console.log("setPagination called", value);

    //console.log(productState.data._meta);

    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));
    newAppliedFilters["page"] = value;
    let newURL = queryString.stringify(newAppliedFilters);
    history.push({ search: newURL });
  };

  const loadOrders = async () => {
    itemDispatch({
      type: "FETCH_REQUEST",
    });
    const apiResult = await getOrdersAPI(search, getBearerTokenConfig());
    console.log(apiResult);
    if (apiResult.status) {
      itemDispatch({
        type: "FETCH_SUCCESS",
        payload: apiResult.data,
      });
    } else {
      itemDispatch({
        type: "FETCH_FAILURE",
      });
    }
  };

  useEffect(() => {
    loadOrders();
  }, [search]);

  const daysFilterList = [
    { value: "last_7_days", key: "Last 7 days" },
    { value: "last_30_days", key: "Last 30 days" },
    { value: "last_3_months", key: "Past 3 months" },
  ];
  const filterByDaysOptions = daysFilterList.map((item) => {
    return (
      <option
        key={item.key}
        value={item.value}
        selected={appliedFilters && appliedFilters["duration"] === item.value}
      >
        {item.key}
      </option>
    );
  });

  const orderStatusFilterList = [
    { value: "Pending", key: "Pending" },
    { value: "Dispatch", key: "Dispatch" },
    { value: "Delivered", key: "Delivered" },
    { value: "Cancel", key: "Cancel" },
  ];
  const filterByOrderStatusOptions = orderStatusFilterList.map((statusItem) => {
    return (
      <option
        key={statusItem.key}
        value={statusItem.value}
        selected={
          appliedFilters && appliedFilters["order_status"] === statusItem.value
        }
      >
        {statusItem.key}
      </option>
    );
  });

  const paymentStatusFilterList = [
    { value: "Pending", key: "Pending" },
    { value: "Received", key: "Done" },
  ];
  const filterByPaymentStatusOptions = paymentStatusFilterList.map((item) => {
    return (
      <option
        key={item.key}
        value={item.value}
        selected={
          appliedFilters && appliedFilters["payment_status"] === item.value
        }
      >
        {item.key}
      </option>
    );
  });

  return (
    <Container className="orders">
      <GoBack history={history} />
      <div className="pageHeading">Order(s)</div>

      {itemState.isFetching ? (
        <SkeletonEmailItem />
      ) : itemState.hasError ? (
        <ErrorComponent />
      ) : (
        <>
          <div className="caption2">
            See how your order(s) are managed and check the latest status on
            your order(s).
          </div>
          <div className="ordersRecordContainer">
            <div className="searchFilterArea mt-4 mb-4 hasBorderTop hasBorderBottom hasMediumTopPadding hasMediumBottomPadding">
              <Row className="mb-3 align-items-center">
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="totalOrdersHeading"
                >
                  Your total order(s):{" "}
                  <span className="secondaryPink fontsize-lg">
                    {itemState.item &&
                    itemState.item._meta &&
                    itemState.item._meta.totalCount
                      ? itemState.item._meta.totalCount
                      : 0}
                  </span>
                </Col>
                {/* <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <OrderSearch
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setStateByDays={setStateByDays}
                  />
                </Col> */}
              </Row>

              <Row className="align-items-center">
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  sx={12}
                  className="form-group-p-0"
                >
                  <Form.Group controlId="filterByDays">
                    <Form.Control
                      as="select"
                      name="filterByDays"
                      onChange={(e) => {
                        applyFilters("duration", e.target.value);
                      }}
                    >
                      <option value="">Filter by Days</option>
                      {filterByDaysOptions}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  sx={12}
                  className="form-group-p-0"
                >
                  <Form.Group controlId="filterByOrderStatus">
                    <Form.Control
                      as="select"
                      name="filterByOrderStatus"
                      onChange={(e) => {
                        applyFilters("order_status", e.target.value);
                      }}
                    >
                      <option value="">Filter by Order Status</option>
                      {filterByOrderStatusOptions}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  sx={12}
                  className="form-group-p-0"
                >
                  <Form.Group controlId="filterByPaymentStatus">
                    <Form.Control
                      as="select"
                      name="filterByPaymentStatus"
                      onChange={(e) => {
                        applyFilters("payment_status", e.target.value);
                      }}
                    >
                      <option value="">Filter by Payment Status</option>
                      {filterByPaymentStatusOptions}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <ListGroup variant="flush" as="ul">
              <>
                {itemState.item &&
                itemState.item.items &&
                itemState.item.items.length ? (
                  <>
                    {itemState.item.items.map((order, index) => (
                      <ListGroup.Item as="li" key={index} variant="transparent">
                        <Row>
                          <Col xl={9} md={9}>
                            <Row>
                              <Col xl={8} md={12}>
                                {" "}
                                <ul className="orderGeneralDetails">
                                  <Link
                                    to={`/member/orders/${order.id}`}
                                    style={{ textDecoration: "none" }}
                                    title="View Order Details"
                                  >
                                    <li className="orderNumber">
                                      Order Number: {order.order_id}
                                    </li>
                                  </Link>

                                  <li className="orderDate mt-2">
                                    Order Date: <Time time={order.created_at} />
                                  </li>
                                  <li>
                                    <div className="">
                                      Order Status:{" "}
                                      <Badge
                                        pill
                                        variant={`${
                                          order.order_status === "Pending"
                                            ? "warning"
                                            : [
                                                order.order_status ===
                                                "Payment Pending"
                                                  ? "warning"
                                                  : [
                                                      order.order_status ===
                                                      "Delivered"
                                                        ? "success"
                                                        : [
                                                            order.order_status ===
                                                            "Dispatch"
                                                              ? "secondary"
                                                              : [
                                                                  order.order_status ===
                                                                  "Cancel"
                                                                    ? "danger"
                                                                    : "",
                                                                ],
                                                          ],
                                                    ],
                                              ]
                                        }`}
                                      >
                                        {" "}
                                        {order.order_status}
                                      </Badge>
                                    </div>
                                  </li>
                                  <li></li>
                                </ul>
                              </Col>
                              <Col xl={4} md={12}>
                                {" "}
                                <div className="mb-1">Total Amount</div>
                                <div className="orderTotal">
                                  {order.currency.symbol}
                                  {order.total_amount}
                                </div>
                                <div className="mt-2 ">
                                  Payment Status:{" "}
                                  <Badge
                                    pill
                                    variant={`${
                                      order.payment_status === "Pending"
                                        ? "warning"
                                        : [
                                            order.payment_status === "Received"
                                              ? "success"
                                              : [
                                                  order.payment_status ===
                                                  "Cancel"
                                                    ? "danger"
                                                    : "",
                                                ],
                                          ]
                                    }`}
                                  >
                                    {" "}
                                    {order.payment_status}
                                  </Badge>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={3} md={3}>
                            <ul>
                              <li className="actionButtonsContainer">
                                <ul className="actionButtons">
                                  <li>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip>View Order Details</Tooltip>
                                      }
                                    >
                                      <Link
                                        to={`/member/orders/${order.id}`}
                                        title="View Order Details"
                                      >
                                        <BsEyeFill />
                                      </Link>
                                    </OverlayTrigger>
                                  </li>

                                  <li>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Connect with JNooNz for this order
                                        </Tooltip>
                                      }
                                    >
                                      <Link
                                        to={`/member/orders/messages/${order.id}`}
                                        title=""
                                      >
                                        <BsFillEnvelopeFill />
                                      </Link>
                                    </OverlayTrigger>
                                  </li>

                                  {/* <li>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Download Receipt
                                        </Tooltip>
                                      }
                                    >
                                      <Link to="/cancle" title="Download Receipt">
                                        <MdCloudDownload />
                                      </Link>
                                    </OverlayTrigger>
                                  </li> */}
                                  {/* <li>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip>
                                          Download Invoice
                                        </Tooltip>
                                      }
                                    >
                                      <Link to="/modify" title="Cancle Order">
                                        <MdCloudDownload />
                                      </Link>
                                    </OverlayTrigger>
                                  </li> */}
                                </ul>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </>
                ) : (
                  <div className="mt-4">
                    <ItemNotFound />
                  </div>
                )}
              </>
            </ListGroup>
            <div className="mt-4">
              {itemState.item &&
              itemState.item._meta &&
              itemState.item._meta &&
              itemState.item._meta.currentPage &&
              itemState.item._meta.pageCount &&
              itemState.item._meta.currentPage <=
                itemState.item._meta.pageCount ? (
                <Row>
                  <div className="mt-4">
                    <PaginationItem
                      pages={itemState.item._meta}
                      setPagination={setPagination}
                    />
                  </div>
                </Row>
              ) : null}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Orders;
