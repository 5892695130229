import React from 'react'
import { Form } from 'react-bootstrap'

const QuickFilters = ({ appliedFilters, setAppliedFilters, applyNewFilter }) => {

    return (
        <div className="checkboxFilterContainer filterSectionTopMargin">
            <div className="filterSectionHeading">
                Quick Filter(s)
            </div>
            <ul>
                {/* <li className="filterElementsHolder hasCursor">
                    <Form.Check
                        type="checkbox"
                        id="customizable"
                        label="Customizable"
                        className="checkbox"
                        checked={(appliedFilters.customizable === true || appliedFilters.customizable === 'true')}
                        onClick={() => {
                            let newValue = ((appliedFilters.customizable === true || appliedFilters.customizable === 'true') ? false : true);
                            setAppliedFilters({ ...appliedFilters, customizable: newValue })
                            applyNewFilter('customizable', newValue)
                        }}
                    />
                </li> */}
                <li className="filterElementsHolder hasCursor">
                    <Form.Check
                        type="checkbox"
                        id="hotitem"
                        label="Hot Item"
                        className="checkbox"
                        checked={(appliedFilters.hot === true || appliedFilters.hot === 'true')}
                        onClick={() => {
                            let newValue = ((appliedFilters.hot === true || appliedFilters.hot === 'true') ? false : true);
                            setAppliedFilters({ ...appliedFilters, hot: newValue })
                            applyNewFilter('hot', newValue)
                        }}
                    />
                </li>

                <li className="filterElementsHolder hasCursor">
                    <Form.Check
                        type="checkbox"
                        id="newitem"
                        label="New Item(s)"
                        className="checkbox"
                        checked={(appliedFilters.newItem === true || appliedFilters.newItem === 'true')}
                        onClick={() => {
                            let newValue = ((appliedFilters.newItem === true || appliedFilters.newItem === 'true') ? false : true);
                            setAppliedFilters({ ...appliedFilters, newItem: newValue })
                            applyNewFilter('newItem', newValue)
                        }}
                    />
                </li>

                <li className="filterElementsHolder hasCursor">
                    <Form.Check
                        type="checkbox"
                        id="freeDelivery"
                        label="Free Delivery"
                        className="checkbox"
                        checked={(appliedFilters.free_delivery === true || appliedFilters.free_delivery === 'true')}
                        onClick={() => {
                            let newValue = ((appliedFilters.free_delivery === true || appliedFilters.free_delivery === 'true') ? false : true);
                            setAppliedFilters({ ...appliedFilters, free_delivery: newValue })
                            applyNewFilter('free_delivery', newValue)
                        }}
                    />
                </li>

                <li className="filterElementsHolder hasCursor">
                    <Form.Check
                        type="checkbox"
                        id="stock"
                        label="In Stock"
                        className="checkbox"
                        checked={(appliedFilters.stock === true || appliedFilters.stock === 'true')}
                        onClick={() => {
                            let newValue = ((appliedFilters.stock === true || appliedFilters.stock === 'true') ? false : true);
                            setAppliedFilters({ ...appliedFilters, stock: newValue })
                            applyNewFilter('stock', newValue)
                        }}
                    />
                </li>

            </ul>
        </div>
    )
}

export default QuickFilters
