import React, { useState, useEffect, useReducer } from 'react';
import { productReducer, productsInitialState } from "../../reducers/Reducers";
import { Col } from "react-bootstrap";
import { ErrorComponent } from "../../components/Alert/Alert"
import { ContainerLoading } from '../../components/Loading/Loading'
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { getSigninSliderAPI } from '../../apis/generalAPIs'


SwiperCore.use([Navigation, Autoplay]);

const SigninSlider = (props) => {
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const productSliderParams = {
    speed: 600,
    allowTouchMove:false,
    autoplay: { delay: 2000000 },
    slidesPerView: 1,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
  };

  const [productState, productDispatch] = useReducer(productReducer, productsInitialState);
  const loadContent = async () => {
    productDispatch({
      type: "FETCH_PRODUCTS_REQUEST"
    });
    const submissionResult = await getSigninSliderAPI();
    if (submissionResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: submissionResult.data
      });
    } else {
      productDispatch({
        type: "FETCH_PRODUCTS_FAILURE"
      });
    }

  }

  useEffect(() => {
    loadContent();
  }, []);

  const handleBoxClick = (item) => {
    console.log(item);
    if (item.link_type === 'Box') {
      //window.location.href = item.link
      window.open(item.link, '_blank');
    }
  }
  return (
    <>

      <div className="bannerArea">

        <Col
          className={`p-0 content${productState.hasError ? 'hasError' : ''}`}>
          {productState.isFetching ? (
            <ContainerLoading />
          ) : productState.hasError ? (
            <ErrorComponent />
          ) : (
            <>
              {(productState.products && productState.products.length > 0) ? (
                <>
                  <div style={{
                    position: "relative",
                    height: "100%",
                  }}>
                    <Swiper
                      {...productSliderParams}
                      onSwiper={(swiper) => {
                        setTimeout(() => {
                          swiper.params.navigation.prevEl = navigationPrevRef.current
                          swiper.params.navigation.nextEl = navigationNextRef.current
                          setIsFirstSlide(true);
                          swiper.navigation.destroy()
                          swiper.navigation.init()
                          swiper.navigation.update()
                        })
                      }}
                      onSlideChange={(swiper) => {
                        if (swiper.activeIndex === 0) {
                          setIsFirstSlide(true);
                        }
                        else if (swiper.isEnd) {
                          setIsLastSlide(true);
                        }
                        else {
                          setIsFirstSlide(false);
                          setIsLastSlide(false);
                        }
                      }}
                      style={{ height: "100%" }}
                    >

                      {productState.products.map((item, index) => (
                        <SwiperSlide className={`swiper-slide promotionalBanner ${item.template === 1 ? "hasPurpleBg" :
                        [item.template === 2 ? "hasGreenBg" : [
                          item.template === 3 ? "hasPinkBg" : ""
                        ]]}  ${item.link_type === 'Box' ? "hasLink" : ""} `} key={index}>
                          <div 
                            style={{ marginLeft: 0 }}
                            onClick={() => handleBoxClick(item)}
                          >
                            <div className="topContent" style={{height:'397px'}}>
                              
                              <div className="heading">
                                {item.title}
                              </div>
                              {item.sub_title &&
                                <div className="subTitle">
                                  {item.sub_title}
                                </div>
                              }
                              {(item.link_type === 'Button') &&
                                <a
                                  className={`btn hasPadding hasExtraPadding uppercase hasShadow btn-lg bold mt-4 ${item.template === 1 ? "btn-PinkButton" :
                                    [item.template === 2 ? "btn-whiteButton tertiaryPink" : [
                                      item.template === 3 ? "btn-PurpleButton" : ""
                                    ]]}`}
                                  href={item.link}
                                  target="_blank"
                                >
                                  {item.button_text}
                                </a>
                              }
                            </div>
                            <div className="imageHolder">
                              <img
                                className={`promotionalImage ${item.template === 1 ? "exclusiveImage" :
                                  [item.template === 2 ? "sendagiftImage" : [
                                    item.template === 3 ? "itemanddeals" : ""
                                  ]]}`}
                                src={`${item.background_image}`}
                                alt={item.title}
                                title={item.title}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div ref={navigationPrevRef}
                      className={`customSliderNavigation prev ${isFirstSlide ? "" : ""}`}
                    >
                      <FaCaretRight className="customSliderPrevIcon" />
                    </div>
                    <div ref={navigationNextRef}
                      className={`customSliderNavigation next ${isLastSlide ? "" : ""}`}
                    >
                      <FaCaretLeft className="customSliderNextIcon" />
                    </div>


                  </div>
                </>
              ) :
                (
                  <ContainerLoading />
                )}
            </>
          )}

        </Col>

      </div>
    </>
  )
}

export default SigninSlider;