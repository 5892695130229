import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik';

const FormInput = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  onMouseLeave,
  ...props
}) => {
  return (
    <React.Fragment>

      <Field name={name} validate={validate}>
        {({
          field,
          form: { touched, errors },
          meta,
        }) => (
          <Form.Group md={md} controlId={controlId}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
              name={name}
              ref={ref}
              as={as}
              type={type}
              label={label}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              options={options}
              errors={errors}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              onMouseLeave={onMouseLeave}
              autoComplete="off"
            />
            <ErrorMessage component="div" name={name}
              className="errorMessage"
            />
          </Form.Group>
        )}

      </Field>
    </React.Fragment>
  )
}

FormInput.defaultProps = {
  type: "text",
  className: ""
}
FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'password']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default FormInput;