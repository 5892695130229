import React, { useState, useEffect } from 'react'
import { Col, ListGroup } from "react-bootstrap"
import { Link } from 'react-router-dom'
// import image from '../../assets/images/item01.png'
const SitemapNormal = ({ props, hideOverlay, itemState }) => {
  const [typeID, setTypeID] = useState(1)
  const [categoryID, setCategoryID] = useState(1)


  const setProductType = (productTypeID) => {
    console.log("setProductType Called with  " + productTypeID);
    setTypeID(productTypeID)
    itemState.item.map((productType, index) => {
      if (productType.id === productTypeID && productType.child && productType.child.length > 0) {
        setCategoryID(productType.child[0].id)
      }
    })



  }
  useEffect(() => {
    if (itemState.item.length) {
      setProductType(itemState.item[0].id);
    }
  }, []);

  return (
    <>
      {itemState && (
        <Col lg={4} className="pr-2 pl-2">
          <ListGroup variant="flush" as="ul" >
            {itemState.item.map((type, index) => (
              <ListGroup.Item as="li" key={type.id}
                className={`colTwo ${typeID === type.id ? "active" : ""}`}
                action onClick={() => { setProductType(type.id) }}
              >
                {type.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      )}

      {typeID && (
        <Col lg={4} className="pr-2 pl-2">
          <div className="subLevel">
            <ListGroup variant="flush" as="ul">
              {itemState.item.map((type, index) => (
                <React.Fragment key={type.id}>
                  {type.id === typeID ? (
                    <>
                      {type.child.map((category, index) => (
                        <>
                        
                          <ListGroup.Item as="li" key={category.id}
                            className={`colTwo ${categoryID === category.id ? "active" : ""}`}
                            action onClick={() => {
                              setCategoryID(category.id)
                            }}
                          >
                            {category.name}
                          </ListGroup.Item>
                         
                        
                        </>
                        
                      ))}
                       {type.child.map((category, index) => (
                         <>
                         {type.child.length < 5 && categoryID === category.id &&
                          <div className="typesImage  hasShadow">
                            <img
                              className="itemImage"
                              src={`${process.env.REACT_APP_BASE_URL}${category.image}`}
                              alt=""
                            />
                          </div>
                          
                        }
                        </>
                       ))}
  
                    </>
                  ) : (null)}
                </React.Fragment>
              ))}
            </ListGroup>
            {/* {(typeID && categoryID) &&  (
    <>
        {itemState.item.map((type, index) => (
    <>
      {type.id === typeID ? (
        <>
          {type.child.map((category, index) => (
          <div className="typesImage hasShadow">
          <img
          className="itemImage"
          src={`${process.env.REACT_APP_BASE_URL}${category.image}`}
          alt={category.name}
          title={category.name}
          />
          </div>
          ))}
        </>
      ):(null)}
    </>
  ))}
    </>
  )} */}



          </div>
          
        </Col>
      )}

      {(typeID && categoryID) && (
        <Col lg={4} className="pr-2 pl-2">
          <div className="subLevel" >
            <ListGroup variant="flush" as="ul">
              {itemState.item.map((type, index) => (
                <React.Fragment key={type.id}>
                  {type.id === typeID ? (
                    <>
                      {type.child.map((category, cindex) => (
                        <React.Fragment key={category.id}>
                          {category.id === categoryID ? (

                            <>
                              {(category.child && category.child.length) ? (

                                <>
                                  {category.child.map((subCategory, scindex) => (

                                    <ListGroup.Item as="li" key={subCategory.id} className="colLast"
                                      onClick={() => {
                                        hideOverlay()
                                      }}>
                                      <Link to={`/products?type=${type.slug}&category=${category.slug}&subCategory=${subCategory.slug}`}
                                        className="colLastLinks"
                                      >{subCategory.name}</Link>
                                    </ListGroup.Item>

                                  ))}
                                </>

                              ) : (null)}
                            </>

                          ) : (null)}

                        </React.Fragment>

                      ))}
                    </>
                  ) : (null)}

                </React.Fragment>
              ))}
            </ListGroup>
          </div>
        </Col>
      )}
    </>
  )
}
export default SitemapNormal












// import React, { useState, useEffect } from 'react'
// import { Col, ListGroup } from "react-bootstrap"
// import { Link } from 'react-router-dom'
// // import image from '../../assets/images/item01.png'
// const SitemapNormal = ({ props, hideOverlay, itemState }) => {
//   const [typeID, setTypeID] = useState(1)
//   const [categoryID, setCategoryID] = useState(1)
//   useEffect(() => {
//     if (itemState.item.length) {
//       setTypeID(itemState.item[0].id)
//       setCategoryID(itemState.item[0].child[0].id)
//     }
//   }, []);

//   return (
//     <>
//       {itemState && (
//         <Col lg={4} className="pr-2 pl-2">
//           <ListGroup variant="flush" as="ul" >
//             {itemState.item.map((type, index) => (
//               <ListGroup.Item as="li" key={type.id}
//                 className={`colTwo ${typeID === type.id ? "active" : ""}`}
//                 action onClick={() => { setTypeID(type.id) }}
//               >
//                 {type.name}
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//         </Col>
//       )}

//       {typeID && (
//         <Col lg={4} className="pr-2 pl-2">
//           <div className="subLevel">
//             <ListGroup variant="flush" as="ul">
//               {itemState.item.map((type, index) => (
//                 <React.Fragment key={type.id}>
//                   {type.id === typeID ? (
//                     <>
//                       {type.child.map((category, index) => (
//                         <>
//                           <ListGroup.Item as="li" key={category.id}
//                             className={`colTwo ${categoryID === category.id ? "active" : ""}`}
//                             action onClick={() => {
//                               setCategoryID(category.id)
//                             }}
//                           >
//                             {category.name}
//                           </ListGroup.Item>

//                           {type.child.length < 5 && categoryID === category.id &&
//                             <div className="typesImage hasShadow">
//                               <img
//                                 className="itemImage"
//                                 src={`${process.env.REACT_APP_BASE_URL}${category.image}`}
//                                 alt=""
//                               />
//                             </div>
//                           }
//                         </>
//                       ))}
//                     </>
//                   ) : (null)}
//                 </React.Fragment>
//               ))}
//             </ListGroup>
//             {/* {(typeID && categoryID) &&  (
//     <>
//         {itemState.item.map((type, index) => (
//     <>
//       {type.id === typeID ? (
//         <>
//           {type.child.map((category, index) => (
//           <div className="typesImage hasShadow">
//           <img
//           className="itemImage"
//           src={`${process.env.REACT_APP_BASE_URL}${category.image}`}
//           alt={category.name}
//           title={category.name}
//           />
//           </div>
//           ))}
//         </>
//       ):(null)}
//     </>
//   ))}
//     </>
//   )} */}



//           </div>
//         </Col>
//       )}

//       {(typeID && categoryID) && (
//         <Col lg={4} className="pr-2 pl-2">
//           <div className="subLevel" >
//             <ListGroup variant="flush" as="ul">
//               {itemState.item.map((type, index) => (
//                 <React.Fragment key={type.id}>
//                   {type.id === typeID ? (
//                     <>
//                       {type.child.map((category, cindex) => (
//                         <React.Fragment key={category.id}>
//                           {category.id === categoryID ? (

//                             <>
//                               {(category.child && category.child.length) ? (

//                                 <>
//                                   {category.child.map((subCategory, scindex) => (

//                                     <ListGroup.Item as="li" key={subCategory.id} className="colLast"
//                                       onClick={() => {
//                                         hideOverlay()
//                                       }}>
//                                       <Link to={`/products?type=${type.slug}&category=${category.slug}&subCategory=${subCategory.slug}`}
//                                         className="colLastLinks"
//                                       >{subCategory.name}</Link>
//                                     </ListGroup.Item>

//                                   ))}
//                                 </>

//                               ) : (null)}
//                             </>

//                           ) : (null)}

//                         </React.Fragment>

//                       ))}
//                     </>
//                   ) : (null)}

//                 </React.Fragment>
//               ))}
//             </ListGroup>
//           </div>
//         </Col>
//       )}
//     </>
//   )
// }
// export default SitemapNormal