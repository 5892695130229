import React,{useContext} from 'react'
import {Button,OverlayTrigger,Tooltip,Nav} from 'react-bootstrap'
import {BsWhatsapp} from 'react-icons/bs'
import './floatbutton.scss'
import { GlobalContext } from './../../contexts/GlobalContext';
import chatIcon from '../../assets/images/chat.png';
const Floatbutton = () => {
  const { config } = useContext(GlobalContext).globalData.data;
  
 
  return (
    <OverlayTrigger
    key="top"
    placement="top"
    overlay={
    <Tooltip>
    Reach us through WhatsApp
    </Tooltip>
    }
    >
  
    <div className="floatContainer animate__animated shakeAnimation animate__delay-3s animate__duration-5s animate__infinite">
       <img src={chatIcon} alt="" className="chat" />
       
          <Button Button style={{textDecoration: 'none'}}  variant="outline-successColor" className="float rounded-circle bg-successColor text-white " href={`https://api.whatsapp.com/send?phone=${config.phone}&text=Hi,JNooNz%20Customer%20Care.`} target="_blank">  <BsWhatsapp className="mr-3 mt-1 my-float text-white "/> </Button>
     


    </div>
    </OverlayTrigger>
  )
}

export default Floatbutton
