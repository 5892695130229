import React, { useEffect } from 'react'
import {
  Switch,
} from "react-router-dom"
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import Home from "../pages/Home/Home"
import About from "../pages/About/About"
import Products from "../pages/Products/Products"
import ProductDetails from "../pages/Products/ProductDetails"
import PageNotFound from '../components/Errors/PageNotFound'
import ShoppingCart from "../pages/ShoppingCart/ShoppingCart"
import Checkout from '../pages/Checkout/Checkout'
import ConfirmOrder from "../pages/Checkout/ConfirmOrder"
import Dashboard from "../userdashboard/Dashboard"
import LoginScreen from '../pages/Login/LoginScreen'
import ForgotPassword from '../pages/ResetPassword/ForgotPassword'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import Contact from '../pages/Contact/Contact'
import WishlistScreen from '../pages/WishlistScreen/WishlistScreen'

import StaticContentPage from '../pages/StaticContentPage'
import PackagingScreen from './../components/Cart/PackingItem/PackagingScreen';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-182672787-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const AppRoutes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Switch>
      <PublicRoutes restricted={false} component={Home} path="/" exact />
      <PublicRoutes restricted={false} component={About} path="/about" />
      <PublicRoutes restricted={false} component={Contact} path="/contact" />
      <PublicRoutes restricted={false} component={StaticContentPage} path="/payments" />
      <PublicRoutes restricted={false} component={StaticContentPage} path="/shipping" />
      <PublicRoutes restricted={false} component={StaticContentPage} path="/cancellation-returns" />
      <PublicRoutes restricted={false} component={Checkout} path="/checkout" />
      <PublicRoutes restricted={false} component={ShoppingCart} path="/cart" />
      <PublicRoutes restricted={false} component={ForgotPassword} path="/forgotpassword" />
      <PublicRoutes restricted={false} component={ResetPassword} path="/reset-password/:hash" />
      {/* <PublicRoutes restricted={false} component={PageNotFound} path="/resetpassword" exact /> */}
      <PublicRoutes restricted={false} component={PackagingScreen} path="/packaging/:id" />
      <PublicRoutes component={WishlistScreen} path="/wishlist" />
      <PublicRoutes restricted={false} component={ConfirmOrder} path="/confirmorder" />
      <PublicRoutes restricted={false} component={LoginScreen} path="/login" exact />
      <PublicRoutes restricted={false} component={Products} path="/products" exact />
      <PublicRoutes restricted={false} component={ProductDetails} path="/product/:slug" />
      <PrivateRoutes component={Dashboard} path="/member" />
      <PublicRoutes restricted={false} component={PageNotFound} />
    </Switch>
  )
}

export default AppRoutes
