import React, { createContext, useReducer, useContext } from 'react';
import { CartReducer } from "../reducers/CartReducer";
import axios from 'axios'
import { getGuestSessionToken } from './../helpers/CommonHelper';
import { AuthContext } from './AuthContext'
import { OverlayContext } from "./../contexts/OverlayContext"
import { BackdropContext } from "./../contexts/BackdropContext"
import { toast } from "react-toastify";
import { ToastCartItemAdded } from './../components/Toasts/Toasts';
import * as DOMHelpers from "./../helpers/DOMHelpers"
import { useLocation } from 'react-router-dom';
import { GlobalContext } from './../contexts/GlobalContext';


const initialState = {
    cartItems: {},
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isRemoving: false,
    hasError: false,
};


//Create Context
export const CartContext = createContext(initialState)

//Create Provider
export const CartProvider = ({ children }) => {

    const [state, dispatch] = useReducer(CartReducer, initialState)
    const { userAuthState, getBearerTokenConfig, userAuthDispatch } = useContext(AuthContext);
    const { cartOverlayDispatch } = useContext(OverlayContext);
    const { backdropDispatch } = useContext(BackdropContext);
    const { setSelectedCurrency } = useContext(GlobalContext)
    const location = useLocation();

    function changeCurrency(currencyCode) {
        // console.log('currency change called with ', currencyCode);
        if (currencyCode) {
            dispatch({
                type: "FETCH_CARTITEM_REQUEST"
            });
            try {
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}api/cart/change-currency`,
                    {
                        selected_currency_code: currencyCode,
                        guest_session_token: getGuestSessionToken()
                    },
                    getBearerTokenConfig()
                ).then((res) => {
                    setSelectedCurrency(currencyCode)
                    dispatch({
                        type: "FETCH_CARTITEM_SUCCESS",
                        payload: res.data
                    });
                })
            } catch (error) {
                dispatch({
                    type: "FETCH_CARTITEM_ERROR",
                    payload: error.message
                });
            }
        }
    }

    function getCartItems() {
        dispatch({
            type: "FETCH_CARTITEM_REQUEST"
        });
        try {
            axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/get-items`,
                { guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            ).then((res) => {
                dispatch({
                    type: "FETCH_CARTITEM_SUCCESS",
                    payload: res.data
                });
            })

        } catch (error) {
            dispatch({
                type: "FETCH_CARTITEM_ERROR",
                payload: error.message
            });
        }
    }



    function setCartItems(data) {
        dispatch({
            type: "SET_CARTITEMS",
            payload: data
        });
        return;
    }

    // function delete
    async function deleteCartItem(id) {
        dispatch({
            type: "REMOVE_CARTITEM_REQUEST"
        });
        try {
            //await axios.delete(`/api/v1/transactions/${id}`)
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/remove`,
                { cart_item_id: id, guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            )

            dispatch({
                type: "REMOVE_CARTITEM_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "REMOVE_CARTITEM_ERROR",
                payload: error.message
            });
        }
    }

    //
    async function addCartItem(productID) {
        dispatch({
            type: "ADD_CARTITEM_REQUEST"
        });

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/add`,
                {
                    product_id: productID,
                    guest_session_token: getGuestSessionToken(),
                    quantity: 1,
                    selected_currency_code: localStorage.getItem('defaultCurrency') ? localStorage.getItem('defaultCurrency') : 'USD'
                },
                getBearerTokenConfig()
            )

            //console.log(res);

            if (res.data && res.data.guest_session_token) {
                localStorage.setItem("guest_session_token", JSON.stringify(res.data.guest_session_token));
            }

            dispatch({
                type: 'ADD_CARTITEM_SUCCESS',
                payload: res.data
            });


            if (location.pathname !== '/cart') {
                setTimeout(() => {
                    cartOverlayDispatch({
                        type: "OVERLAY_OPEN",
                    });
                    backdropDispatch({
                        type: "FETCH_BACKDROPHORIZONTAL_OPEN",
                    });
                    DOMHelpers.fixedBody();
                    toast.success(<ToastCartItemAdded />)
                }, 100);
            }
        } catch (error) {
            dispatch({
                type: "ADD_CARTITEM_ERROR",
                payload: error.message
            });
        }
    }

    //
    async function updateCartQuantity(itemID, quantity) {
        dispatch({
            type: "UPDATE_CARTITEM_QUANTITY_REQUEST"
        });

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/update-quantity`,
                {
                    item_id: itemID,
                    guest_session_token: getGuestSessionToken(),
                    quantity: 1 * quantity
                },
                getBearerTokenConfig()
            )

            //console.log(res.data);
            dispatch({
                type: 'UPDATE_CARTITEM_QUANTITY_SUCCESS',
                payload: res.data
            });


        } catch (error) {
            dispatch({
                type: "UPDATE_CARTITEM_QUANTITY_ERROR",
                payload: error.message
            });
        }
    }


    // function remove customization
    async function addCustomization(data) {

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/add-customization`,
                data,
                getBearerTokenConfig()
            )
            return { "status": true, "data": res.data };
        } catch (error) {
            return { "status": false };
        }
    }


    async function removeCustomization(id) {
        dispatch({
            type: "REMOVE_CUSTOMIZATION_REQUEST"
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/remove-customization`,
                { cart_item_id: id, guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            )

            dispatch({
                type: "REMOVE_CUSTOMIZATION_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "REMOVE_CUSTOMIZATION_ERROR",
                payload: error.message
            });
        }
    }


    async function addPackaging(data) {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/add-packaging`,
                data,
                getBearerTokenConfig()
            )
            return { "status": true, "data": res.data };
        } catch (error) {
            return { "status": false };
        }
    }


    async function removePackaging(id) {
        dispatch({
            type: "REMOVE_PACKAGING_REQUEST"
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/remove-packaging`,
                { cart_item_id: id, guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            )

            dispatch({
                type: "REMOVE_PACKAGING_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "REMOVE_PACKAGING_ERROR",
                payload: error.message
            });
        }
    }

    async function addShippingAddressToCart(addressID) {

        dispatch({
            type: "ADD_SHIPPING_ADDRESS_REQUEST"
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/add-shipping-address`,
                { address_id: addressID, guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            )
            dispatch({
                type: "ADD_SHIPPING_ADDRESS_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "ADD_SHIPPING_ADDRESS_ERROR",
                payload: error.message
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR"
                });
            }, 5000);

        }
    }


    async function addPaymentMethodToCart(paymentMethod) {

        dispatch({
            type: "ADD_PAYMENT_METHOD_REQUEST"
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/add-payment-method`,
                { payment_method: paymentMethod, guest_session_token: getGuestSessionToken() },
                getBearerTokenConfig()
            )
            dispatch({
                type: "ADD_PAYMENT_METHOD_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "ADD_PAYMENT_METHOD_ERROR",
                payload: error.message
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR"
                });
            }, 5000);

        }
    }


    async function memberCheckout() {
        dispatch({
            type: "PLACE_ORDER_REQUEST"
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/member-checkout`,
                {
                    member_checkout: true
                },
                getBearerTokenConfig()
            )
            dispatch({
                type: "PLACE_ORDER_SUCCESS",
            });


            if (userAuthState && userAuthState.user && userAuthState.user.type === 'Guest') {
                userAuthDispatch({ type: 'LOGOUT' })
            }


            return { "status": true, "data": res.data };

        } catch (error) {
            dispatch({
                type: "PLACE_ORDER_ERROR",
                payload: error.message
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR"
                });
            }, 5000);
            return { "status": false };

        }
    }

    async function whatsAppCheckout(data) {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/cart/whatsapp-checkout`,
                data,
            )

            // console.log('whatsapp checkout return data ', res);
            if (res.data.user && res.data.user.token) {
                // console.log('calling login with ', res.data.user);
                userAuthDispatch({
                    type: "LOGIN",
                    payload: res.data
                })
            }

            return { "status": true, "order_id": res.data.order_id };
        } catch (error) {
            return { "status": false };
        }
    }


    return (<CartContext.Provider value={{
        cart: state,
        changeCurrency,
        getCartItems,
        deleteCartItem,
        addCartItem,
        updateCartQuantity,
        setCartItems,
        addCustomization,
        removeCustomization,
        addPackaging,
        removePackaging,
        addShippingAddressToCart,
        addPaymentMethodToCart,
        memberCheckout,
        whatsAppCheckout
    }}>{children}</CartContext.Provider>)
}



