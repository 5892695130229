import React from "react"
import { Container, Breadcrumb } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
const BreadcrumbsProducts = ({ productTypes, productCategories, productSubCategories }) => {


  const { search } = useLocation();
  const appliedFilters = queryString.parse(search)


  return (
    <Container className="p-0">

      <Breadcrumb className="relative">

        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/" }}
        >
          Home
        </Breadcrumb.Item>

        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/products" }}
        >
          Products
        </Breadcrumb.Item>

        {appliedFilters.type &&
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/products?type=${appliedFilters.type}` }}
          >
            {
              (productTypes && productTypes.length) &&
              <>
                {
                  productTypes.map((productType, index) => (
                    (productType && productType.slug === appliedFilters.type) &&
                    <React.Fragment key={index}>
                      {productType.name}
                    </React.Fragment>
                  ))
                }
              </>
            }
          </Breadcrumb.Item>
        }


        {appliedFilters.category &&
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/products?type=${appliedFilters.type}&category=${appliedFilters.category}` }}
          >
            {
              (productCategories && productCategories.length) &&
              <>
                {
                  productCategories.map((productCategory, index) => (
                    (productCategory && productCategory.slug === appliedFilters.category) &&
                    <React.Fragment key={index}>
                      {productCategory.name}
                    </React.Fragment>
                  ))
                }
              </>
            }
          </Breadcrumb.Item>
        }


        {appliedFilters.subCategory &&
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: `/products?type=${appliedFilters.type}&category=${appliedFilters.category}&subCategory=${appliedFilters.subCategory}` }}
          >
            {
              (productSubCategories && productSubCategories.length) &&
              <>
                {
                  productSubCategories.map((productSubCategory, index) => (
                    (productSubCategory && productSubCategory.slug === appliedFilters.subCategory) &&
                    <React.Fragment key={index}>
                      {productSubCategory.name}
                    </React.Fragment>
                  ))
                }
              </>
            }
          </Breadcrumb.Item>
        }
      </Breadcrumb>
    </Container >
  )
}

// export default withBreadcrumbs(AppRoutes)(Breadcrumbs);
export default BreadcrumbsProducts;