import React, { useEffect, useReducer } from 'react'
import { Row } from "react-bootstrap";
import "./Spotlights.scss";
import SpotlightsItem from './SpotlightsItem';
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { getSpotlightAPI } from '../../apis/generalAPIs'
const Spotlights = (props) => {
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const loadContent = async () => {
        const submissionResult = await getSpotlightAPI();
        if (submissionResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: submissionResult.data
            });
        }
    }

    useEffect(() => {
        loadContent()
    }, []);
    return (
        <>
            {(itemState.item && itemState.item.items && itemState.item.items.length > 0) &&
                <div className="promotionalBannersContainer">
                    <Row>
                        {itemState.item.items.map((item, index) => (
                            <SpotlightsItem key={item.id} item={item} />
                        ))}
                    </Row>
                </div>
            }
        </>
    )
}
export default Spotlights;