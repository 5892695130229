import React, { useEffect, useContext } from 'react'
import './ExistingShippingAddress.scss'
import { Row, Col, Card, Accordion, useAccordionToggle, AccordionContext, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useRouteMatch } from 'react-router-dom'


import { SkeletonAddressItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent, FormSubmissionFailed, ItemNotFoundDynamic } from '../../components/Alert/Alert'
import { isTrue } from "../../helpers/DOMHelpers"

import { BsPlusCircle, BsDashCircle, BsTrash, BsPencilSquare } from "react-icons/bs";
import { GlobalContext } from './../../contexts/GlobalContext';
import { ShippingAddressContext } from '../../contexts/ShippingAddressContext';
import { CartContext } from "../../contexts/CartContext"
import { ContainerLoading } from '../Loading/Loading';

const ExistingShippingAddress = () => {
    const { cart, addShippingAddressToCart } = useContext(CartContext);
    const { shippingAddressData, getShippingAddressList, setPrimaryAddress, deleteAddress } = useContext(ShippingAddressContext);
    const { getFrontendMessage } = useContext(GlobalContext);
    const noAddressMsg = getFrontendMessage('Checkout', 'Shipping Address', 'Not Available', 'Text')
    let { url } = useRouteMatch();


    useEffect(() => {
        getShippingAddressList();
    }, []);

    useEffect(() => {
        if (!cart.cartItems.shippingAddress && shippingAddressData.items && shippingAddressData.items.length) {
            console.log('shippingAddressData.items changed to ', shippingAddressData.items);

            shippingAddressData.items.map((address, index) => {
                if (isTrue(address.is_primary)) {
                    addShippingAddressToCart(address.id)
                }
            })


        }

    }, [shippingAddressData.items])

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = currentEventKey === eventKey;
        return (
            <>
                <div
                    className="hasCursor"
                    onClick={decoratedOnClick}>
                    {isCurrentEventKey ?
                        <BsDashCircle title="Close" />
                        :
                        <BsPlusCircle title="View Address" />
                    }
                </div>
            </>
        );
    }

    return (
        <div className="existingShippingAddress">


            {((cart && cart.isUpdating) || (shippingAddressData && (shippingAddressData.isUpdating || shippingAddressData.isFetching))) && <ContainerLoading />}
            {(cart && cart.hasError) && <FormSubmissionFailed />}

            {shippingAddressData &&
                <>

                    {shippingAddressData.hasError
                        ? <ErrorComponent />
                        : <>
                            {shippingAddressData.hasUpdateError && <FormSubmissionFailed />}


                            {(shippingAddressData.items && shippingAddressData.items.length) > 0 ? (
                                <Row>
                                    {shippingAddressData.items.map((address, index) => (
                                        <Col xl={12} key={index} className="shippingAddressCard mb-3">
                                            <Accordion>
                                                <Card>
                                                    <Card.Header
                                                        eventKey={address.id}
                                                        className={`${(
                                                            (url === '/checkout' && cart.cartItems.shippingAddress && cart.cartItems.shippingAddress.id === address.id)
                                                            //|| (url === '/checkout' && !cart.cartItems.shippingAddress && isTrue(address.is_primary))
                                                            || (url === '/member/shipping-address' && isTrue(address.is_primary))
                                                        ) ? 'isPrimary' : ''}`}
                                                    >
                                                        <div className="flexContainer">
                                                            <div className="flex">
                                                                <div className="align-items-center">

                                                                    {url === '/checkout' &&

                                                                        <>
                                                                        <div>

                                                                            <div className="mb-2 fs-6">
                                                                                <div>
                                                                                {address.address}    
                                                                                </div>
                                                                                <div>
                                                                                    {address.city}
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex flex-wrap">
                                                                            <div className="radioHolder">
                                                                                <input type="radio" id={`${address.id}`}
                                                                                    name="shipping_address" value={`${address.id}`}
                                                                                    checked={`${(cart.cartItems.shippingAddress && cart.cartItems.shippingAddress.id === address.id) ? 'checked' : ''}`}
                                                                                    onChange={async () => {
                                                                                        if (!cart.cartItems.shippingAddress || cart.cartItems.shippingAddress.id !== address.id) {
                                                                                            await addShippingAddressToCart(address.id)
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            Deliver to this address
                                                                            </div>
                                                        
                                                                        </div>
                                                                      
                                                                            
                                                                        </>
                                                                    }


                                                                    {/*TODO: while working on buyer shipping address module in his panel*/}
                                                                    {url === '/member/shipping-address' &&

                                                                        <>

                                                                            <div className="mb-2 fs-6">
                                                                                <div>
                                                                                {address.address}    
                                                                                </div>
                                                                                <div>
                                                                                    {address.city}
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex flex-wrap">
                                                                            <div className="radioHolder">
                                                                                <input type="radio" id={`${address.id}`}
                                                                                    name="is_primary" value={`${address.id}`}
                                                                                    checked={`${isTrue(address.is_primary) ? 'checked' : ''}`}
                                                                                    onChange={async () => {
                                                                                        await setPrimaryAddress(address.id)
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                            {isTrue(address.is_primary) ? "Primary Address" : "Make Address as Primary"}
                                                                            </div>
                                                                            </div>

                                                                            
                                                            
                                                                        </>
                                                                    }


                                                                </div>
                                                            </div>
                                                            <ContextAwareToggle eventKey={address.id}></ContextAwareToggle>
                                                        </div>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={address.id}>
                                                        <Card.Body className="p-0">
                                                            <div className="shippingAddressDetails">
                                                                <ul>
                                                                    <li>
                                                                        Full Name: {address.first_name} {address.last_name}
                                                                    </li>
                                                                    <li>
                                                                        EmailAA: {address.email}
                                                                    </li>
                                                                    <li>
                                                                        Mobile: {address.mobile}
                                                                    </li>
                                                                    <li>
                                                                        City: {address.city}
                                                                    </li>
                                                                    <li>
                                                                        Country: {address.country}
                                                                    </li>
                                                                    <li>
                                                                        Address: {address.address}
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {/*TODO: while working on buyer shipping address module in his panel*/}
                                                            {url === '/member/shipping-address' &&

                                                                <Card.Footer>
                                                                    <div className="actionButtons">
                                                                        <Row noGutters>
                                                                            <div className="actionButton">
                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            Edit Address
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Link to={`/member/shipping-address/${address.id}`} title="Edit Address">
                                                                                        <BsPencilSquare />
                                                                                    </Link>
                                                                                </OverlayTrigger>
                                                                            </div>

                                                                            <div className="actionButton">
                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            Delete Address
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div title="Delete Address"
                                                                                        onClick={async () => {
                                                                                            await deleteAddress(address.id)

                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        <BsTrash />
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Card.Footer>
                                                            }


                                                        </Card.Body>
                                                    </Accordion.Collapse>

                                                </Card>
                                            </Accordion>

                                        </Col>
                                    ))}
                                </Row>




                            ) : (
                                <ItemNotFoundDynamic
                                    className="itemNotFound textCenter mt-4"
                                    msgTitle={noAddressMsg}
                                />
                            )}
                        </>
                    }




                    {/* Below - OK */}

                </>
            }




        </div>
    )
}

export default ExistingShippingAddress
