import React, { useContext, useState } from 'react'
import './Orders.scss'
import { Container } from 'react-bootstrap'
import OrderDashboardStats from './OrderDashboardStats'
import OrderLog from './OrderLog'
import { GlobalContext } from './../../contexts/GlobalContext';


const OrdersDashboard = () => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const [statsLoaded, setStatsLoaded] = useState(false)

    return (
        <Container className="dashboardItems ordersDashboard">
            <div className="pageHeading"> {getFrontendMessage('Dashboard', 'Order Dashboard', 'Heading', 'Text')}</div>
            <div className="caption2">{getFrontendMessage('Dashboard', 'Order Dashboard', 'Sub Heading', 'Text')}</div>

            <OrderDashboardStats setStatsLoaded={setStatsLoaded} />
            <OrderLog statsLoaded={statsLoaded} />
        </Container>
    )
}
export default OrdersDashboard