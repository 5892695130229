import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const AppliedCustomization = ({ currencySymbol, customization, props }) => {
  return (
    <Container className="orderReview">
      <div className="formContainer">
        <div className="packingDetailsOnItem">
          <div className="pageHeading mb-4">Your Selected Customization</div>

          <div className="customizationContainer">
            <div className="customizationDetails">
              <ul>
                {customization.image && (
                  <li>
                    <div className="heading ">Image:</div>
                    <div
                      className="customizationImage mb-2"
                      style={{ width: "100%", height: "auto" }}
                    >
                      <img
                        className="itemImage"
                        style={{ width: "100%", height: "auto" }}
                        src={`${process.env.REACT_APP_BASE_URL}${customization.image}`}
                        alt="Customization Image"
                        title="Customization Image"
                      />
                    </div>
                    {customization.logoMessage && (
                      <div className="selectedOption tertiaryPink">
                        {customization.logoMessage}
                      </div>
                    )}
                  </li>
                )}

                {customization.toWhom && (
                  <li>
                    <div className="heading ">To Whom:</div>
                    <div className="selectedOption tertiaryPink">
                      {customization.toWhom}
                    </div>
                  </li>
                )}

                {customization.from && (
                  <li>
                    <div className="heading ">From:</div>
                    <div className="selectedOption tertiaryPink">
                      {customization.from}
                    </div>
                  </li>
                )}

                {customization.note && (
                  <li>
                    <div className="heading ">Note:</div>
                    <div className="selectedOption tertiaryPink">
                      {customization.note}
                    </div>
                  </li>
                )}

                {customization.brief && (
                  <li>
                    <div className="heading ">Additional Comments:</div>
                    <div className="selectedOption tertiaryPink">
                      {customization.brief}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="packingTotalCost hasBorderTop hasMediumTopPadding">
            Cost:{" "}
            <span className="totalPrice">
              <span>
                {currencySymbol}
                {customization.price}
              </span>
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AppliedCustomization;
