export const ProductDeatilsReducer = (state, action) => {

    switch (action.type) {
        case "FETCH_PRODUCT_DETAILS_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_PRODUCT_DETAILS_ERROR":
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case "FETCH_PRODUCT_DETAILS_SUCCESS":
            return {
                ...state,
                isFetching: false,
                hasError: false,
                product: action.payload,
            };




        default:
            return state;
    }
};