import React, { useState, useEffect } from 'react'
import { Form, Col, Button } from "react-bootstrap"
import FormInput from '../../../components/FormPartials/FormInput'
import { Formik } from 'formik'


const FilterByKeyword = ({ appliedFilters, applyNewFilter }) => {

    //console.log();

    let searchedTerm = (appliedFilters['q'] && appliedFilters['q'].length > 0) ? appliedFilters['q'] : '';
    const [error, setError] = useState('');






    return (
        <>
            <div className="checkboxFilterContainer filterSectionTopMargin">
                <div className="filterSectionHeading">
                    By Keyword
                </div>
                <ul>
                    <li className="filterElementsHolder hasCursor">
                        <div className="formContainer mt-2">

                            <Formik
                                initialValues={{
                                    query: searchedTerm,
                                }}
                                enableReinitialize={true}
                                validationSchema=""
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    //console.log(values);
                                    let query = values.query;
                                    query = query.trim();
                                    //console.log(query);
                                    if (query === '') {
                                        setError("Please enter value");
                                    } else if (query.length < 3) {
                                        setError("Please enter atlest 3 letters");
                                    } else {
                                        setError("");
                                        //  console.log('now search it');
                                        applyNewFilter('q', query);
                                    }
                                }}
                            >
                                {({ values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} className="mx-auto">

                                        <Form.Row>
                                            <Col xl={8} lg={8}>
                                                <div className="inputHolder hideInputLabel">
                                                    <FormInput
                                                        controlId="query"
                                                        placeholder="Type here"
                                                        name="query"
                                                        type="text"
                                                        value={values.query}
                                                        onChange={(e) => {
                                                            setError('');
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    {(searchedTerm && searchedTerm.length > 0) && <span onClick={() => applyNewFilter('q', '')}>Clear</span>}
                                                </div>

                                            </Col>

                                            <Col xl={4} lg={4}>
                                                <Button
                                                    type="submit"

                                                    className="lessCorners" variant="PurpleButton" block>
                                                    Go
                                                </Button>
                                            </Col>
                                        </Form.Row>


                                        {error && <Form.Row>
                                            <Col xl={12} lg={12}>
                                                <div className="errorMessage" style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>
                                                    {error}
                                                </div>
                                            </Col>
                                        </Form.Row>}


                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default FilterByKeyword
