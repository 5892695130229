import React, { useReducer, useState, useContext, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import { ErrorComponent } from '../../components/Alert/Alert'
import { loginFormValidation } from '../../components/FormPartials/Validation'
import { Link } from 'react-router-dom'

import { relativeBody } from "../../helpers/DOMHelpers"
import { getGuestSessionToken } from '../../helpers/CommonHelper';
import { AuthContext } from '../../contexts/AuthContext'
import { loginAPI } from '../../apis/buyerAPIs'
import { CartContext } from "../../contexts/CartContext"
import { useRouteMatch, useHistory } from 'react-router-dom'



const LoginForm = ({ closeAfterSignIn, popupDispatch, openSignupPopup }) => {
  const { userAuthState, userAuthDispatch, getBearerTokenConfig } = useContext(AuthContext);
  const { getCartItems } = useContext(CartContext);
  const [noLoginError, setNoLoginError] = useState()
  const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
  let { url } = useRouteMatch();
  //console.log(url);
  let history = useHistory();


  useEffect(() => {
    //console.log("useEffect of Login", userAuthState);
    if (userAuthState && userAuthState.user && userAuthState.user.token) {
      getCartItems();

      if (userAuthState.user.type === 'Member') {
        if (url === '/login') {
          //  console.log('login page');
          history.push('/')
        }
      }
    }
  }, [userAuthState]);

  return (

    <div className="formContainer">
      {formState ? (
        <>
          {formState.isFormSubmitting && (
            <ContainerLoading />
          )}
          {formState.isFormSubmitted ? (
            closeAfterSignIn()
          ) : (
            <>

              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: "",
                  password: "",
                  guest_cart_session_token: getGuestSessionToken()
                }}
                validationSchema={loginFormValidation}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  const submissionResult = await loginAPI(values, getBearerTokenConfig());


                  if (submissionResult.status) {


                    userAuthDispatch({
                      type: "LOGIN",
                      payload: submissionResult.data,
                    })






                    popupDispatch({
                      type: "POPUP_SIGNIN_CLOSE",
                    })
                    relativeBody();





                    setNoLoginError(true)


                  } else {
                    setNoLoginError(false)
                    setTimeout(() => {
                      setNoLoginError(true)

                    }, 5000);
                  }
                  setSubmitting(false);



                }}
              >
                {({ values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting }) => (
                  <>
                    <Form onSubmit={handleSubmit} className="mx-auto">

                      <Col className={`p-0 mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                        <FormInput
                          controlId="email"
                          label="Your Email Address"
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>

                      <Col className={`p-0 mendatory ${errors.password && touched.password ? "errorParent" : ""}`}>
                        <FormInput
                          controlId="password"
                          label="Password"
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>

                      <Col className="p-0">
                        <Row className="align-items-center">
                          <Col xl={8} lg={8} md={7} sm={8} xs={12}>
                            <Button variant="PurpleButton"
                              className="hasPadding uppercase hasShadow bold"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          </Col>
                          <Col xl={4} lg={4} md={5} sm={4} xs={12}>
                            <Link to="/forgotpassword" className="forgotPassword mb-2"
                              onClick={() =>
                                popupDispatch({
                                  type: "POPUP_SIGNIN_CLOSE",
                                })
                              }
                            >Forgot Password?</Link>
                          </Col>
                        </Row>
                      </Col>

                      <>

                        {noLoginError === false &&
                          <div className="errorMessage mt-3">
                            Incorrect username or password. Please try again
                          </div>
                        }

                      </>

                    </Form>
                    <div className="switchToSigninSignup mt-4">
                      Not A Member Yet? &nbsp;
                      <span
                        onClick={() =>
                          openSignupPopup()
                        }
                        className="link green">Sign Up
                      </span>
                    </div>
                  </>
                )}
              </Formik>
            </>

          )}
        </>
      ) : (
        <ErrorComponent />
      )}
    </div>


  )
}

export default LoginForm