import React, { useContext, useEffect } from "react"
import './LoginScrees.scss'
import { AuthContext } from "../../contexts/AuthContext"
import { PopupContext } from "../../contexts/PopupContext"
import axios from 'axios'
import LoginForm from "./LoginForm"
import { LoginInitialValues } from "./LoginInitialValues"
import { Container, Col, Row } from 'react-bootstrap'
import image from '../../assets/images/exclusive_deals.png'
import { useHistory } from "react-router-dom"
export const LoginScreen = (props) => {
  const { popupDispatch } = useContext(PopupContext);
  const { userAuthState, userAuthDispatch } = useContext(AuthContext);
  const [data, setData] = React.useState(LoginInitialValues);
  let history = useHistory()


  useEffect(() => {
    if (userAuthState.user && userAuthState.token && userAuthState.token === userAuthState.user.token && userAuthState.user.type === 'Member') {
      console.log('logged in');
      history.push('/member');
    }
  }, [])





  return (
    <>
      <Container>
        <Col xl={10} lg={10} sm={12} xs={12} className="loginScreen">
          <div className="pageHeading mb-4 xl">Sign In</div>
          <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12} className="bannerArea">
              <div className="content">
                <div className="heading">
                  Exclusive Dealz & Treats
                </div>
                <div className="imageHolder">
                  <img src={image}
                    alt="" />
                </div>
              </div>
            </Col>
            <Col xl={7} lg={7} md={12} sm={12} xs={12} className="loginArea">
              <LoginForm
                data={data}
                userAuthState={userAuthState}
              />
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default LoginScreen;
