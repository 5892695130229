import axios from 'axios'



export const getMessagesAPI = async (queryString, headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-messages?${queryString}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const sendMessagesAPI = async (data, headers) => {
    if (!data || !data.subject || !data.message || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyer-messages/send-message`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const getMessageThreadAPI = async (id, queryString, headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-messages/get-thread/${id}?${queryString}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const sendMessagesReplyAPI = async (id, data, headers) => {
    if (!id || !data || !data.subject || !data.message || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyer-messages/send-reply/${id}`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}