import React, { useReducer, useContext, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { GlobalContext } from './../../contexts/GlobalContext';
import { notifyMeWhenProductInStockAPI } from '../../apis/productAPIs'
import { AuthContext } from './../../contexts/AuthContext'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),
});

const NotifyMe = ({ setOpenPopup, itemID, ...props }) => {
    const { userAuthState,getBearerTokenConfig } = useContext(AuthContext);

    const { getFrontendMessage } = useContext(GlobalContext);
    const outOfStockFormSuccessMessage = getFrontendMessage('Product', 'Out of Stock', 'Success Message', 'Paragraphs')


    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    return (
        <div>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}
                        {formState.isFormSubmitted ? (
                            <div>
                                <SuccessMsg
                                    msgTitle={outOfStockFormSuccessMessage && outOfStockFormSuccessMessage.length ? outOfStockFormSuccessMessage[0].text : ''}
                                    msgBody={outOfStockFormSuccessMessage && outOfStockFormSuccessMessage.length ? outOfStockFormSuccessMessage[1].text : ''}
                                />
                            </div>
                        ) :



                            <>
                                <div className="customHeadingForForm">
                                    {getFrontendMessage('Product', 'Out of Stock', 'Popup Form Heading', 'Text')}
                                </div>
                                <Formik
                                    initialValues={{
                                        email: ((userAuthState.isAuthenticated && userAuthState.user && userAuthState.user.email)?userAuthState.user.email:''),
                                        product_id: itemID
                                    }}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        console.log(values);
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const submissionResult = await notifyMeWhenProductInStockAPI(values, getBearerTokenConfig());

                                        if (submissionResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                setOpenPopup(false)
                                            }, 5000);

                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                        }
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (


                                        <Form onSubmit={handleSubmit} className="mx-auto">

                                            {
                                                formState.hasSubmissionError && (
                                                    <FormSubmissionFailed />
                                                )
                                            }


                                            <Col className={`p-0 ${errors.email ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="email"
                                                    label="Email Address"
                                                    name="email"
                                                    type="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>

                                            <Button
                                                variant="PurpleButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                title="Add To Wishlist"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>

                            </>
                        }

                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div >
    )
}

export default NotifyMe