import React, { useContext } from 'react'
import CartItemPreview from "../../components/Cart/CartItemPreview"
import { SkeletonHorizontalItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent } from '../../components/Alert/Alert'
import NoItemFoundInCart from './NoItemFoundInCart'
import { CartContext } from "../../contexts/CartContext"
import { ContainerLoading } from './../Loading/Loading';
const CartPreview = ({ item, props }) => {
    const { cart } = useContext(CartContext);
    return (
        <>
       
            {cart.isUpdating ? (
                <ContainerLoading />
            ) : cart.hasError ? (
                <ErrorComponent />
            ) : (
                <>
                    {cart.cartItems && cart.cartItems.items ? (
                        <>
                            <ul >
                                {cart.cartItems.items.map((cart_item, index) =>
                                    <li key={index} className="itemHolder horizontalItemHolder">
                                        {cart.isRemoving ? (
                                            <SkeletonHorizontalItem />
                                        ) : (
                                            <CartItemPreview
                                                key={cart_item.id}
                                                cart_item={cart_item}
                                            />
                                        )}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <NoItemFoundInCart />
                    )}
                </>
            )}
        </>
    )
}

export default CartPreview;