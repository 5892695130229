import React,{createContext, useReducer} from 'react';
import {
    overlayReducer,
    searchOverlayReducer,
    CartOverlayReducer,
    WishlistOverlayReducer,
    UserOverlayReducer,
    overlayInitialState,
    ToastReducer,
    toastInitialState
} from "../reducers/Reducers";
export const OverlayContext = createContext();
const OverlayContextProvider = (props)=>{
const [overlayState, overlayDispatch] = useReducer(overlayReducer, overlayInitialState)
const [searchOverlayState, searchOverlayDispatch] = useReducer(searchOverlayReducer, overlayInitialState)
const [cartOverlayState, cartOverlayDispatch] = useReducer(CartOverlayReducer, overlayInitialState)
const [wishlistOverlayState, wishlistOverlayDispatch] = useReducer(WishlistOverlayReducer, overlayInitialState)
const [userOverlayState, userOverlayDispatch] = useReducer(UserOverlayReducer, overlayInitialState)
const [showToastState, showToastDispatch] = useReducer(ToastReducer, toastInitialState)
    return(
        <OverlayContext.Provider value={{
            overlayState,
            overlayDispatch,
            searchOverlayState,
            searchOverlayDispatch,
            cartOverlayState,
            cartOverlayDispatch,
            wishlistOverlayState,
            wishlistOverlayDispatch,
            userOverlayState,
            userOverlayDispatch,
            showToastState,
            showToastDispatch
        }}>
              {props.children}
        </OverlayContext.Provider>
    );
}
export default OverlayContextProvider;