import React from 'react'
import { ImCart } from "react-icons/im"
import { FaCheckCircle } from "react-icons/fa"
import {Button} from 'react-bootstrap'
const AddedToCart = ({isCartButtonStyle}) => {
    return (
        <>
        {isCartButtonStyle === false &&
        <div className="addedToCart hasWhatsAppOrderIcon"
            title="Item is Added to Cart"
        >
        <FaCheckCircle className="additionalIcon"/>
        <ImCart
            className="icon"
        /> 
        </div>
        }
        {isCartButtonStyle === true && 
        <div className="d-flex">
        <Button variant="whiteButtonActive" size="lg" className="hasShadow noHover tertiaryPink"
            title="Item is Added to Cart"
        >
        Item is Added to Cart <FaCheckCircle className="additionalIcon secondaryGreen ml-1"/>
        </Button>
        </div>
        }
        </>
    )
}

export default AddedToCart