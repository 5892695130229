import React from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ComputerAccessories from "../../assets/images/ComputerAccessories.jpg";
import watch from "../../assets/images/watchcase.jpg";
import phonecase from "../../assets/images/phonecover.jpg";
import audio from "../../assets/images/audio.jpg";

const CategoryItem = ({ type }) => {
  //console.log(type);
  return (

    <Col lg={4} sm={6} md={12} xs={12}>
      <div className="categoryContent hasShadow">
        <div className="TitleContainer p-2">
          <h3 className="categoryTitle mb-3 ">{type.product_type_name} </h3>
        </div>

        <div className="container ">
          <div className="ItemContainer">
            <div className="row">

              {(type.categories && type.categories.length > 0) &&
                <>
                  {type.categories.map((category, index) => {
                    return <div key={index} className="col-6 col-md-3 col-xl-6 col-lg-6">

                      <Link
                        className="productLink"
                        to={`products?category=${category.category.slug}&type=${type.product_type_slug}`}
                      >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip>{category.category.name}</Tooltip>}
                        >
                          <img
                            className="itemImage"
                            src={`${process.env.REACT_APP_BASE_URL}${category.category.image}`}
                            alt={category.category.name}
                            title=""
                          />

                        </OverlayTrigger>
                        <p className="subtitle">{category.category.name}</p>
                      </Link>

                      {/* <Link
                        className="d-flex align-items-center justify-content-center"
                        to={`products?category=${category.category.slug}&type=${type.product_type_slug}`}
                      >
                       
                      </Link> */}

                    </div>
                  })}
                </>


              }
            </div>
          </div>

          <div className="seemoreLink">
            <OverlayTrigger
              key="top"
              placement="bottom"
              overlay={<Tooltip>View all</Tooltip>}
            >
              <Link
                to={`products?type=${type.product_type_slug}`}
                className="btn btn-PurpleButton text-white hasShadow btn-sm"
              >
                View all
              </Link>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </Col>


  );
};

export default CategoryItem;
