export const getSentance = (type) => {
    if (type === 'Register') {
       return("Registration successful.")
    } else if (type === 'Login') {
        return("You successfully logged in.")
    } else if (type === 'Logout') {
        return("You safely logged out.")
    } else if (type === 'Profile Update') {
        return("You have successfully updated the profile.")
    }
    else if (type === 'Forgot Password') {
        return("You Forgot your password ")
    }
    else if (type === 'Reset Password') {
        return("You have resetted your password.")
    } else if (type === 'Change Password') {
        return("You have changed your password.")
    }
    else if (type === 'Alternate Email Update') {
        return("You updated your alternate email.")
    }

}
