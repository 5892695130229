import React, { useState, useContext } from 'react'
import './OutOfStock.scss'
import { Button, Row, Col, Modal } from 'react-bootstrap'
import { IoBanOutline } from "react-icons/io5";
import NotifyMe from './NotifyMe';
import { GlobalContext } from './../../contexts/GlobalContext';
const OutOfStock = ({ itemID, props }) => {
    const [openPopup, setOpenPopup] = useState(false)
   
    const { getFrontendMessage } = useContext(GlobalContext);
    const outOfStockMessages = getFrontendMessage('Product', 'Out of Stock', 'Messages', 'Paragraphs')
    return (
        <>
            <div className="outOfStock">
                <Row>
                    <Col xl={1} lg={1} md={1} sm={1} xs={12}>
                        <IoBanOutline
                            className="icon"
                        />
                   
                
                    </Col>
                    <Col xl={11} lg={11} md={11} sm={11} xs={12}>
                        <div className="title">
                            {(outOfStockMessages && outOfStockMessages.length) ? outOfStockMessages[0].text : ''}
                        </div>
                        <div className="caption">{(outOfStockMessages && outOfStockMessages.length > 1) ? outOfStockMessages[1].text : ''}</div>
                        <Button
                            variant="PinkButton"
                            className="hasShadow hasPadding uppercase"
                            onClick={() => setOpenPopup(true)}
                        >
                            Notify Me
                        </Button>
                    </Col>
                </Row>
            </div>

            <Modal
                size="md"
                show={openPopup}
                centered
                onHide={() =>
                    setOpenPopup(false)
                }
                animation={false}
                className=""
            >
                <Modal.Body>
                    <div className="formContainer">
                        <NotifyMe
                            setOpenPopup={setOpenPopup}
                            itemID={itemID}
                        />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default OutOfStock
