import React, { useContext } from 'react'
import { getSelectedCurrencySymbol, getPriceInSelectedCurrency, getPriceClasses } from "../../helpers/MoneyHelper"
import { GlobalContext } from './../../contexts/GlobalContext';

const Price = ({ price, oldPrice }) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;

    return (
        <div>
            <div className={`itemPrice ${getPriceClasses((getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(price, defaultSelectedCurrency, currencies, true)), 'product_item', (getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(oldPrice, defaultSelectedCurrency, currencies, true)))}`}>
                <div className="price" >
                    {getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(price, defaultSelectedCurrency, currencies, true)}
                </div>
                {(oldPrice && oldPrice > price) && (
                    <div className="oldPrice"><del>{getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(oldPrice, defaultSelectedCurrency, currencies, true)}</del></div>
                )}
            </div>
        </div>
    )
}

export default Price
