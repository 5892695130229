import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { GlobalContextReducer } from "../reducers/GlobalContextReducer";
import { fetchStartupContentAPI } from '../apis/generalAPIs'
import { AuthContext } from './AuthContext'

const initialState = {
    data: {
        config: {},
        socialChannels: {},
        footerProductTypes: {},
        sitemap: [],
        frontendMessages: [],
        currencies: [],
    },
    defaultSelectedCurrency: (localStorage.getItem('defaultCurrency') ? localStorage.getItem('defaultCurrency') : 'USD'),
    isFetching: true,
    hasError: false,
    isContentLoaded: false,
}

//Create Context
export const GlobalContext = createContext(initialState)

//Create Provider
export const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(GlobalContextReducer, initialState)

    useEffect(() => {
        loadContent();
    }, [])

    async function loadContent() {
        const submissionResult = await fetchStartupContentAPI();
        if (submissionResult.status) {
            dispatch({
                type: "FETCH_CONTENT_SUCCESS",
                payload: submissionResult.data
            });
        } else {
            dispatch({
                type: "FETCH_CONTENT_FAILURE"
            });
        }
    }

    function getFrontendMessage(section, component, name, type) {
        //console.log(state);
        //console.log(section, component, name, type);
        let message = '';
        if (state.data && state.data.frontendMessages && state.data.frontendMessages.length) {
            const foundObject = (state.data.frontendMessages.find(x => (x.section === section && x.component === component && x.name === name && x.type === type)));
            if (foundObject !== undefined) {
                if (
                    foundObject['type'] === 'Text'
                ) {
                    message = foundObject['text']
                } else {
                    message = foundObject['texts']
                }
            }
        }
        return message;
    }

    async function setSelectedCurrency(currencyCode) {
        if (currencyCode) {
            localStorage.setItem('defaultCurrency', currencyCode)
            dispatch({
                type: "SET_SELECTED_CURRENCY",
                payload: currencyCode
            });
        }
    }


    return (<GlobalContext.Provider value={{
        globalData: state,
        getFrontendMessage,
        setSelectedCurrency
    }}>{children}</GlobalContext.Provider>)
}



