export const ProductListingReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_PRODUCTS_REQUEST":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_PRODUCTS_SUCCESS":
            // localStorage.setItem("productState", JSON.stringify(action.payload));
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        case "FETCH_PRODUCTS_FAILURE":
            return {
                ...state,
                hasError: true,
                isLoading: false
            };
        default:
            return state;
    }
};