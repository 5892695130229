import React from 'react'
import { Container, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <Container>
                <Col
                       style={{
                        paddingTop:200,
                        paddingBottom:200,
                        textAlign:"center",
                    }}
                >
                    <div
                        style={{
                            fontSize:55,
                            color:"#DA0072",
                            marginBottom: 10
                        }}
                    >
                        PAGE NOT FOUND
                    </div>
                    <h3
                        style={{
                            fontSize: 28,
                        }}
                    >Uh-oh, something went wrong here</h3>
                    <p>Just keep browsing to get back on track</p>
                    <Link to="/" className="btn btn-PinkButton hasPadding uppercase hasShadow mt-3 lg">BACK TO HOMEPAGE</Link>
                </Col>
            </Container>
        </>
    )
}

export default PageNotFound
