import {Row, Col, Card} from "react-bootstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const totalItems = 3;
const totalItemsSix = 6;
const items = new Array(totalItems).fill(null);
const totalItemsSlider = 4;
const itemsSlider = new Array(totalItemsSlider).fill(null);
const totalItemsTwo = 2;
const itemsTwo = new Array(totalItemsTwo).fill(null);
const checkboxGroup = new Array(totalItemsSix).fill(null);
export const SkeletonProductItem = () => (
    <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea">
        <Row noGutters>
        {items.map((_, index)=>(
            <Col lg={4} className="itemListingHolder" key={index}>
                    <div className="itemHolder">
                    <div className="itemImageHolder" style={{display: 'inherit'}}>
                    <Skeleton height={160}  width={"100%"}/>
                    </div>
            
                    <div className="itemDetails">
                    <ul>
                    <li className="itemCategory">
                    <Skeleton height={10} width={120} />      
                    </li>
                    <li className="itemTitle">
                    <Skeleton height={10} count={3} />
                    </li>
                    <li className="itemRating">
                    <Skeleton height={10} width={120} />
                    </li>
                    <li>
                    <Row className="align-items-center">
                    <Col className="itemPrice isDiscountedprice" lg={7} sm={7} xs={7}>
                        <div className="price" >
                            <Skeleton height={40} width={80} />
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Row className="align-items-center">
                            <Col className="addToWishlist textCenter" lg={6} sm={6} xs={6}>
                                <Skeleton circle={true} height={40} width={40} />
                            </Col>
                            <Col className="addToCart textCenter" lg={6} sm={6} xs={6}>
                                <Skeleton circle={true} height={40} width={40} />
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    </li>
                    </ul>
                    </div>
                    </div>
                </Col>
                
        ))}
        </Row>
    </SkeletonTheme>

);

export const SkeletonProductSlider = () => (
    <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea">
        <Row noGutters>
        {itemsSlider.map((_, index)=>(
            <Col lg={3} className="itemListingHolder" key={index}>
                    <div className="itemHolder">
                    <div className="itemImageHolder" style={{display: 'inherit'}}>
                    <Skeleton height={160}  width={"100%"}/>
                    </div>
            
                    <div className="itemDetails">
                    <ul>
                    <li className="itemCategory">
                    <Skeleton height={10} width={120} />      
                    </li>
                    <li className="itemTitle">
                    <Skeleton height={10} count={3} />
                    </li>
                    <li className="itemRating">
                    <Skeleton height={10} width={120} />
                    </li>
                    <li>
                    <Row className="align-items-center">
                    <Col className="itemPrice isDiscountedprice" lg={7}>
                        <div className="price" >
                            <Skeleton height={40} width={80} />
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Row noGutters className="align-items-center">
                            <Col className="addToWishlist textCenter" lg={6}>
                                <Skeleton circle={true} height={40} width={40} />
                            </Col>
                            <Col className="addToCart textCenter" lg={6}>
                                <Skeleton circle={true} height={40} width={40} />
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    </li>
                    </ul>
                    </div>
                    </div>
                </Col>
                
        ))}
        </Row>
    </SkeletonTheme>

);


export const SkeletonQuestionItem = () => (
<SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10}}>
    <div className="mb-3">
        <Skeleton height={10} count={2}/>
        <Skeleton width={120} height={10}/>
    </div>
</SkeletonTheme>
);

export const SkeletonEmailItem = () => (
    <>
        {itemsSlider.map((_, index)=>(
            <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10}} key={index}>
            <div className="mb-3">
                <Skeleton height={10} count={2}/>
                <Skeleton width={120} height={10}/>
            </div>
            </SkeletonTheme>
        ))}
    </>
    );

export const SkeletonCheckboxGroup = () => (
<>
    <Col>
    <Row>
    {checkboxGroup.map((_, index)=>(
        <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
        <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10}} >
        <Row className="mb-3">
            <Skeleton width={12} height={12} className="mr-2"/>
            <Skeleton width={120} height={12}/>
        </Row>
        </SkeletonTheme>
        </Col>
    ))}
    </Row>
    </Col>
</>
);

export const SkeletonSinglelineItem = () => (
<>
    {itemsSlider.map((_, index)=>(
    <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10}} key={index}>
        <Skeleton height={14} count={1}/>
    </SkeletonTheme>
    ))}
</>
);

export const SkeletonHorizontalItem = () => (
    <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10}}>

    <Col lg={12}>
    <Row>
        <Col lg={5} sm={5} xs={5}>
            <div className="itemImageHolder" style={{display: 'inherit'}}>
                <Skeleton height={140}  width={"100%"}/>
            </div>
            </Col>
            <Col lg={7} sm={7} xs={7}>
                
            <div>
            <ul>
            <li className="itemCategory">
            <Skeleton height={10} width={120} />      
            </li>
            <li className="itemTitle">
            <Skeleton height={10} count={3} />
            </li>
            <li className="itemRating">
            <Skeleton height={10} width={120} />
            </li>
            <li>
            <Row className="align-items-center">
            <Col className="itemPrice isDiscountedprice" lg={7} sm={7} xs={7}>
            <div className="price" >
                <Skeleton height={30} width={80} />
            </div>
            </Col>
            <Col lg={5}>
            <Row className="align-items-center">
                <Col className="addToWishlist textCenter" lg={6} sm={6} xs={6}>
                    <Skeleton circle={true} height={30} width={30} />
                </Col>
                <Col className="addToCart textCenter" lg={6} sm={6} xs={6}>
                    <Skeleton circle={true} height={30} width={30} />
                </Col>
            </Row>
            </Col>
            </Row>
            </li>
            </ul>
            </div>
        </Col>
    </Row>

    </Col>
    
    </SkeletonTheme>
    );


export const SkeletonAuthSliderItem = () => (
    <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10, height: "100%"}}>
    <div className="" style={{height:"100%"}}>
        <div className="content">
            <div className="topContent">
                
                    <div className="heading">
                        <Skeleton  height={14} width={160} />
                    </div>
                    <Skeleton height={14} width={160} />
                </div>
                <div className="imageHolder">
                <Skeleton height={150} count={1} />
                </div>
        </div>
    </div>
    
    </SkeletonTheme>
    );


    export const SkeletonDashboardItem = () => (
        <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10, height: "100%"}}>
            <Row>
            {itemsSlider.map((_, index)=>(
            <Col xl={6} lg={6} xs={12} key={index}>
            <div className="dashboardBox">
            <Card>
            <Card.Body>
            <Row className="align-items-center">
            <Col lg={3} sm={12} xs={12}>
                <Skeleton circle={true} height={80} width={80} />
            </Col>
            <Col lg={9} sm={12} xs={12} style={{height: "100%"}}>
            <Card.Text className="mb-2">
                <Skeleton height={14} width="100%" />
            </Card.Text>
            <Card.Title className="mb-0">
                <Skeleton height={14}  width="100%" />
            </Card.Title>
            <Card.Link>
            <Skeleton height={14} width={80} />
            </Card.Link>
            </Col>
            </Row>
            </Card.Body>
            </Card>
            </div>
            </Col>
            ))}
            </Row>
        </SkeletonTheme>
        );
    

        export const SkeletonAddressItem = () => (

            <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea" style={{marginBottom: 10, height: "100%"}}>
            <Skeleton height={13} count={3} width="100%" />
             </SkeletonTheme>

            );