import React, { useContext } from "react"
import { Header } from './../Header/Header';
import AppRoutes from './../../routes/AppRoutes';
import Footer from './../Footer/Footer';
import { Backdrop, BackdropHorizontal, BackdropSolid } from './../Backdrop/Backdrop';
import { FullPageLoading } from "../Loading/Loading";
import PageSetupFailed from './../Errors/PageSetupFailed';
import { GlobalContext } from './../../contexts/GlobalContext';
import Floatbutton from '../../components/Floatbutton/Floatbutton';

const WebWrapper = () => {
    const { globalData } = useContext(GlobalContext);
    return (
        <>
            {globalData.isFetching && <FullPageLoading />}
            {globalData.hasError && <PageSetupFailed />}

            {
                globalData.isContentLoaded
                && <>
                    <Header />
                    <Floatbutton />
                    <AppRoutes />
                    <Footer />
                    <Backdrop />
                    <BackdropHorizontal />
                    <BackdropSolid />
                </>
            }
        </>
    )
}

export default WebWrapper
