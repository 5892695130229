export const ShippingAddressReducer = (state, action) => {

    switch (action.type) {
        case "FETCH_ITEMS_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_ITEMS_ERROR":
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case "FETCH_ITEMS_SUCCESS":
            //console.log(action.payload);
            return {
                ...state,
                isFetching: false,
                hasError: false,
                items: action.payload,
            };



        case "ADD_ITEM_SUCCESS":
            return {
                ...state,
                items: [...state.items, action.payload.item]
            };



        case "SET_PRIMARY_REQUEST":
            return {
                ...state,
                isUpdating: true,
            };
        case "SET_PRIMARY_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                items: state.items.map((item) => {
                    if (item.id === action.payload.item.id) {
                        return action.payload.item;
                    }
                    item['is_primary'] = 0;
                    return item;
                })
            };
        case "SET_PRIMARY_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasUpdateError: true
            };


        case "REMOVE_ITEM_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false
            };
        case "REMOVE_ITEM_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                items: state.items.filter((item) => item.id !== action.payload)
            };
        case "REMOVE_ITEM_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasUpdateError: true
            };



        case "FETCH_ITEM_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_ITEM_ERROR":
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case "FETCH_ITEM_SUCCESS":
            return {
                ...state,
                isFetching: false,
                hasError: false,
                items: action.payload,
            };



        case "UPDATE_ITEM_REQUEST":
            return {
                ...state,
                isUpdating: true,
            };
        case "UPDATE_ITEM_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                isUpdated: true,
                items: action.payload,
            };
        case "UPDATE_ITEM_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasUpdateError: true
            };


        case "REMOVE_ERROR":
            return {
                ...state,
                hasUpdateError: false,
                hasError: false
            };

        case "SET_INITIAL":
            return {
                ...state,
                hasUpdateError: false,
                hasError: false,
                isUpdated: false,
            };


        default:
            return state;
    }
};