import React, { useReducer, useContext, useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { AuthContext } from "../../contexts/AuthContext"
import { changePasswordAPI } from '../../apis/buyerAPIs'
const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Please enter your old password"),
    new_password: Yup
        .string()
        .required('Please enter your new password')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
            "Please select a strong password"
        ),
    confirm_password: Yup
        .string()
        .required('Please retype your password')
        .when("new_password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("new_password")],
                "Your new password and confirmation password do not match"
            )
        }),
});



const ChangePasswordForm = (props) => {
    const { getBearerTokenConfig } = useContext(AuthContext);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [submissionError, setSubmissionError] = useState('')
    const [initialValues, setInitialValues] = useState({
        confirm_password: "",
        old_password: "",
        new_password: "",
    })
    return (
        <div>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}

                        {formState.isFormSubmitted
                            ?
                            <div>
                                <SuccessMsg
                                    msgTitle="Your password changed successfully"
                                    msgBody=""
                                />
                            </div>
                            : <>
                                {submissionError && <FormSubmissionFailed msg={submissionError} />}
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        setSubmitting(true);
                                        const submissionResult = await changePasswordAPI(values, getBearerTokenConfig());
                                        //console.log(submissionResult['error']);
                                        if (submissionResult['status']) {
                                            formDispatch({
                                                type: 'FORM_SUBMISSION_SUCCESS',
                                            });
                                            setInitialValues(initialValues);
                                        } else {

                                            setSubmissionError(submissionResult['error'])
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                setSubmissionError('')
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }

                                    }}

                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">

                                            <Col className={` ${errors.old_password ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="old_password"
                                                    label="Old Password"
                                                    name="old_password"
                                                    type="password"
                                                    value={values.old_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="mb-2"
                                                />
                                            </Col>

                                            <Col className={`form-group-p-1 mb-4 ${errors.new_password ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="new_password"
                                                    label="New Password"
                                                    name="new_password"
                                                    type="password"
                                                    value={values.new_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="mb-2"
                                                />
                                                <div className="caption">
                                                    Use 8-16 characters with one upper letters and at least one number.
                                                </div>
                                            </Col>

                                            <Col className={`${errors.confirm_password ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="confirm_password"
                                                    label="Confirm Password"
                                                    name="confirm_password"
                                                    type="password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="mb-2"
                                                />
                                            </Col>


                                            <Button variant="PurpleButton mt-4"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        }


                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default ChangePasswordForm
