import React, { useReducer, useEffect, useContext, useState } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Formik, ErrorMessage } from 'formik'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed, ItemNotFoundDynamic } from '../../components/Alert/Alert'
import { itemReducer, itemInitialState } from '../../reducers/Reducers'
import CheckboxGroup from '../../components/FormPartials/CheckboxGroup';

import { SkeletonCheckboxGroup } from '../../components/Skeleton/Skeleton';
import { AuthContext } from '../../contexts/AuthContext'
import { isTrue } from "../../helpers/DOMHelpers"
import { getInterestOptionsAPI, updateInterestOptionsAPI } from '../../apis/buyerAPIs'

const LetUsKnowYourInterest = (props) => {
  const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
  const { getBearerTokenConfig } = useContext(AuthContext);

  const loadContent = async () => {
    itemDispatch({
      type: "FETCH_REQUEST"
    });
    const apiResult = await getInterestOptionsAPI(getBearerTokenConfig());
    if (apiResult.status) {
      itemDispatch({
        type: "FETCH_SUCCESS",
        payload: apiResult.data.interests
      });

    } else {
      itemDispatch({
        type: "FETCH_FAILURE"
      });
    }
  }

  useEffect(() => {
    loadContent()
  }, []);

  return (
    <div>
      <div className="pageHeading">Your Interest</div>
      <div className="caption2">Please let us know your interest so we can send you upcoming deals on your email as per your interest.</div>
      <div className="formContainer mt-4">

        {formState ? (
          <>
            {formState.isFormSubmitting && (
              <ContainerLoading />
            )}
            {formState.isFormSubmitted ? (
              <SuccessMsg
                msgTitle="Thank you to let us know your interest"
                msgBody="It's very helpful to know the interest of our valued customers so we can update our inventory as per requirement"
              />
            ) : (
              <>
                <Formik
                  enableReinitialize={true}
                  initialValues={{}}
                  validate={(values, props) => {
                    const errors = {};
                    let filtered = itemState.item.filter(item => item.isChecked === true);
                    if (filtered.length <= 0) {
                      errors.checked = 'Select at-least one option';
                    }
                    return errors;
                  }}
                  validateOnChange={true}
                  validateOnBlur={false}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    formDispatch({
                      type: "FORM_SUBMISSION_START",
                    });


                    const apiResult = await updateInterestOptionsAPI(itemState.item, getBearerTokenConfig());
                    console.log(apiResult);
                    if (apiResult.status) {
                      formDispatch({
                        type: "FORM_SUBMISSION_SUCCESS",
                      });
                    } else {
                      formDispatch({
                        type: "FORM_SUBMISSION_FAILED"
                      });
                    }

                    setTimeout(() => {
                      formDispatch({
                        type: "FORM_INITIAL_STAGE",
                      });
                    }, 5000);
                    setSubmitting(false);
                  }}
                >
                  {({ values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting }) => (
                    <>
                      {itemState.isFetching ? (
                        <SkeletonCheckboxGroup />
                      ) : itemState.hasError ? (
                        <ErrorComponent />
                      ) : (
                        <Form onSubmit={handleSubmit} className="mx-auto">
                          {formState.hasSubmissionError && <FormSubmissionFailed />}
                          {(itemState.item && itemState.item.length) > 0 ? (
                            <>
                              <Row>

                                {itemState.item.map((option, index) => {
                                  return (
                                    <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                                      <div className="mb-3 hasCursor">
                                        <CheckboxGroup
                                          id={option.name}
                                          name="checked"
                                          type="checkbox"
                                          label={option.name}
                                          value={option.name}
                                          onChange={(e) => {

                                            itemDispatch({
                                              type: "FETCH_SUCCESS",
                                              payload: itemState.item.map((optionCheck) => {
                                                if (optionCheck.name === option.name) {
                                                  optionCheck.isChecked = !optionCheck.isChecked;
                                                }
                                                return optionCheck
                                              })
                                            });
                                            handleChange(e)
                                          }

                                          }
                                          onBlur={handleBlur}
                                          checked={isTrue(option.isChecked)}
                                        />
                                      </div>
                                    </Col>
                                  )
                                }
                                )}
                                <ErrorMessage component="div" name="checked" className="errorMessage" />
                              </Row>
                              <Button variant="PurpleButton"
                                className="hasPadding uppercase hasShadow bold mt-3"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <ItemNotFoundDynamic
                              className="itemNotFound textCenter"
                              msgTitle="No options found due to a temporary issue"
                              msgText="Please refresh your browser or try after some time"
                            />
                          )}
                        </Form>
                      )}
                    </>
                  )}
                </Formik>
              </>

            )}
          </>
        ) : (
          <ErrorComponent />
        )}
      </div>
    </div>
  )
}

export default LetUsKnowYourInterest