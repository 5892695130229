export const isCustomizationApplied = (item) => {
    if (
        item
        && (
            item.customization_logo
            || item.customization_to_whom
            || item.customization_from
            || item.customization_note
        )
    )
        return true;

    return false;
}