import React from 'react'
import { Container, Col } from 'react-bootstrap'


const PageSetupFailed = () => {
    return (
        <>
            <Container>
                <Col
                    style={{
                        paddingTop: 200,
                        paddingBottom: 200,
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            fontSize: 55,
                            color: "#DA0072",
                            marginBottom: 10
                        }}
                    >
                        PAGE SETUP FAILED
                    </div>
                    <h3
                        style={{
                            fontSize: 28,
                        }}
                    >Uh-oh, something went wrong</h3>
                    <p>Kindly reload the page after sometime</p>

                </Col>
            </Container>
        </>
    )
}

export default PageSetupFailed
