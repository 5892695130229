import React, { useEffect, useReducer, useContext } from 'react';
import './Contact.scss'
import { Container, Col, Row, Card } from 'react-bootstrap'
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import ContactForm from './ContactForm';
import { PagesReducer, pageInitialState } from "../../reducers/PagesReducer"
import { ErrorComponent } from './../../components/Alert/Alert';
import { fetchPageContentAPI } from '../../apis/generalAPIs'
import { ContainerLoading } from './../../components/Loading/Loading';
import { GlobalContext } from './../../contexts/GlobalContext';

const Contact = (props) => {
    const { config } = useContext (GlobalContext).globalData.data;
    const [pageState, pageStateDispatch] = useReducer(PagesReducer, pageInitialState);

    const loadContent = async () => {
        const apiResult = await fetchPageContentAPI('contact-us');
        if (apiResult.status) {
            pageStateDispatch({
                type: "FETCH_PAGE_CONTENT_SUCCESS",
                payload: apiResult.data
            });
        } else {
            pageStateDispatch({
                type: "FETCH_PAGE_CONTENT_ERROR"
            });
        }
    }

    useEffect(() => {
        loadContent();
    }, []);

    return (
        <>
            <div className="contact">
                <div className="contactPattern top"></div>
                <Container>
                    <Col className="p-0 contactTextContent">

                        {pageState.isLoading && <ContainerLoading />}
                        {pageState.hasError
                            ? <ErrorComponent />
                            : <>
                                <h2 className="uppercase heading">{(pageState.content && pageState.content.heading) ? pageState.content.heading : 'Contact Jnoonz'}</h2>

                                <Row className="contactDetails">
                                    {(pageState.content && pageState.content.phone) &&
                                        <Col xl={4} lg={4} md={4} sm={4} sx={12}>
                                            <Card>
                                                <Card.Body className="">
                                                    <FaPhoneAlt className="icon" />
                                                    <Card.Title className="text">by Phone</Card.Title>
                                                </Card.Body>
                                                <Card.Footer>
                                                   <a className="text-tertiaryPink" href="tel:+96171650772"> {pageState.content.phone} </a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    }

                                    {(pageState.content && pageState.content.whatsapp) &&
                                        <Col xl={4} lg={4} md={4} sm={4} sx={12}>
                                            <Card>
                                                <Card.Body className="">
                                                    <IoLogoWhatsapp className="icon" />
                                                    <Card.Title className="text">by WhatsApp</Card.Title>
                                                </Card.Body>
                                                <Card.Footer>
                                                   <a className="text-tertiaryPink" href={`https://api.whatsapp.com/send?phone=${config.phone}&text=Hi,JNooNz%20Customer%20Care.`} rel="noopener" target="_blank">{pageState.content.whatsapp}</a>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    }

                                    {(pageState.content && pageState.content.email) &&
                                        <Col xl={4} lg={4} md={4} sm={4} sx={12}>
                                            <Card>
                                                <Card.Body className="">
                                                    <MdEmail className="icon" />
                                                    <Card.Title className="text">by Email</Card.Title>
                                                </Card.Body>
                                                <Card.Footer>
                                                   <a className="text-tertiaryPink" href="mailto:customercare@jnoonz.com">{pageState.content.email}</a> 
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    }
                                </Row>

                            </>
                        }



                    </Col>
                </Container>
                <div className="aboutusPattern bottom"></div>
            </div>

            <Container>
                <ContactForm />
            </Container>

        </>
    )
}

export default Contact;