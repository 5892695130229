import React, { useState, useRef, useEffect } from 'react'
import { IoMdEye } from "react-icons/io"
import { CloseButton } from '../CloseOverlayButton/CloseOverlay'
const QuickView = ({ specifications, itemImage, itemTitle, ...props }) => {
  const [showQuickView, setShowQuickView] = useState(false)
  const handleCloseButton = () => {
    setShowQuickView(false);
  }

  const refOutside = useRef();
  function useOnClickOutside(refOutside, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!refOutside.current || refOutside.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [refOutside, handler]
    );
  }

  useOnClickOutside(refOutside, () => {
    // alert("clicked outside");
    setShowQuickView(false);
  })



  return (
    <div>
      <div className="quickView" title="Quick View"
        onClick={() => { setShowQuickView(true) }}
      >
        <div className="quickViewContent" >
          <IoMdEye className="quickViewIcon" />
        </div>
      </div>
      {showQuickView && (
        <div className="quickViewContainer" ref={refOutside}>
          <div className="closeButtonContainer floatButton variantWhite">
            <CloseButton
              onClick={handleCloseButton}
            />
          </div>
          <div className="quickviewItemImage">
            <img
              src={`${process.env.REACT_APP_BASE_URL}${itemImage}`}
              alt={itemTitle}
            />
          </div>
          <ul>
            {
              (specifications && specifications.length > 0) &&
              <>
                {


                  specifications.map((quickItem, index) => (
                    <li key={index}>
                      <div className="heading">{quickItem.name}</div>
                      <div className="content">{quickItem.value}</div>
                    </li>
                  ))}
              </>
            }


          </ul>
        </div>
      )}
    </div>
  )
}

export default QuickView
