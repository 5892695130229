import React, { useState, useReducer, useContext } from 'react'
import './CustomizationButton.scss'
import { Modal, Col, Row, Form, Button, Badge } from 'react-bootstrap'
import { ContainerLoading } from '../../Loading/Loading'
import { formSubmissionReducer, formInitialState } from '../../../reducers/Reducers'
import { CustomizationReducer } from '../../../reducers/CustomizationReducer'
import SuccessMsg from '../../FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../Alert/Alert'
import { Field, Formik } from 'formik'
import { itemCustomizationValidation } from '../../FormPartials/Validation'
import FormInput from '../../FormPartials/FormInput'
import FormTextArea300 from '../../FormPartials/FormTextArea300'
import { CloseButton } from '../../CloseOverlayButton/CloseOverlay';
import axios from 'axios'
import { isTrue, getGuestSessionToken, getImageValidationError } from "../../../helpers/CommonHelper"
import { getSelectedCurrencySymbol, getPriceInSelectedCurrency } from "../../../helpers/MoneyHelper"
import { ImCross } from "react-icons/im";
import { CartContext } from '../../../contexts/CartContext'
import { GlobalContext } from './../../../contexts/GlobalContext';


const customizationInitialState = {
    options: {},
    showModal: false,
    isLoading: false,
    hasError: false
}



const CustomizationButton = ({ cart_item }) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;
    const [customization, customizationDispatch] = useReducer(CustomizationReducer, customizationInitialState);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [showSelectOneItemError, setShowSelectOneItemError] = useState(false)
    const [imageValidationError, setImageValidationError] = useState('')
    const { addCustomization, removeCustomization, setCartItems } = useContext(CartContext);
    const [updatedItems, setUpdatedItems] = useState(null);
    const [picture, setPicture] = useState({
        preview: null,
        asFile: null
    });

    const customizationFormDataInitialState = {
        selectedItems: {
            logo: cart_item.customization_logo ? true : false,
            toWhom: cart_item.customization_to_whom ? true : false,
            from: cart_item.customization_from ? true : false,
            note: cart_item.customization_note ? true : false,
        },
        logo: '',
        toWhom: cart_item.customization_to_whom ? cart_item.customization_to_whom : '',
        from: cart_item.customization_from ? cart_item.customization_from : '',
        note: cart_item.customization_note ? cart_item.customization_note : '',
        brief: cart_item.customization_brief ? cart_item.customization_brief : '',
        cart_item_id: cart_item.id,
        product_id: cart_item.product.id,
        guest_session_token: getGuestSessionToken(),
    }

    //picture.preview = (cart_item.customization_logo ? process.env.REACT_APP_BASE_URL + cart_item.customization_logo : '');

    const closeCustomizationModal = () => {
        if (updatedItems) {
            setCartItems(updatedItems)
        }
        customizationDispatch({
            type: 'CLOSE_CUSTOMIZATION_POPUP'
        });
    };

    const showCustomizationModal = () => {
        customizationDispatch({
            type: 'SHOW_CUSTOMIZATION_POPUP'
        });
        getCustomizationOptions();
    };

    const getCustomizationOptions = async (e) => {

        customizationDispatch({
            type: 'FETCH_CUSTOMIZATION_REQUEST'
        });

        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/customizations/${cart_item.product.id}`)
            customizationDispatch({
                type: "FETCH_CUSTOMIZATION_SUCCESS",
                payload: res.data.data
            });
        } catch (error) {
            customizationDispatch({
                type: "FETCH_CUSTOMIZATION_ERROR",
                payload: error.message
            });
        }
    }

    const checkIfAnyItemIsSelected = (values) => {
        if (
            !values.selectedItems.logo
            && !values.selectedItems.toWhom
            && !values.selectedItems.from
            && !values.selectedItems.note
        ) {
            setShowSelectOneItemError(true);
            setTimeout(() => {
                setShowSelectOneItemError(false);
            }, 5000);
            setImageValidationError(null)
        } else {
            setShowSelectOneItemError(false);
            if (values.selectedItems.logo && (!picture.preview && !cart_item.customization_logo)) {
                setImageValidationError('Upload logo/Image.')
            }
        }
    }

    const handleFormSubmit = async (data) => {
        // formDispatch({
        //     type: "FORM_SUBMISSION_START",
        // });
        // const submissionResult = await addCustomization(data);
        // if (submissionResult.status) {
        //     setUpdatedItems(submissionResult.data);
        //     setTimeout(() => {
        //         setCartItems(submissionResult.data)
        //     }, 5000);
        //     formDispatch({
        //         type: 'FORM_SUBMISSION_SUCCESS',
        //     });
        // } else {
        //     formDispatch({
        //         type: "FORM_SUBMISSION_FAILED",
        //     });
        //     setTimeout(() => {
        //         formDispatch({
        //             type: "FORM_INITIAL_STAGE",
        //         });
        //     }, 5000);
        // }

        //console.log('submitted values', data);
        formDispatch({
            type: "FORM_SUBMISSION_START",
        });


        const formData = new FormData();
        Object.keys(data).map((key) => {
            if (typeof data[key] === 'object') {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        })
        formData.append("logo", picture.asFile);
        const submissionResult = await addCustomization(formData);
        console.log('submissionResult', submissionResult);

        if (submissionResult.status) {
            setUpdatedItems(submissionResult.data);
            setTimeout(() => {
                setCartItems(submissionResult.data)
            }, 5000);
            formDispatch({
                type: 'FORM_SUBMISSION_SUCCESS',
            });
        } else {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
            });
            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE",
                });
            }, 5000);
        }
    }



    // Frontend Messages - //
    const { getFrontendMessage } = useContext(GlobalContext);

    const customizationMessage = {
        heading: getFrontendMessage('Checkout', 'Customization', 'Form Heading', 'Paragraphs'),
        addButton: getFrontendMessage('Checkout', 'Customization', 'Button Text', 'Text'),
        selectedOptionsHeading: getFrontendMessage('Checkout', 'Customization', 'Selected Options', 'Text'),
        cost: getFrontendMessage('Checkout', 'Customization', 'Cost', 'Text'),
        nonRefund: getFrontendMessage('Checkout', 'Customization', 'nonRefund', 'Text'),
    }





    return (
        <>
            {(isTrue(cart_item.product.is_customizable)) ?
                <>
                    <li className="customize_li mb-2">
                        <div className="customizationItem">
                            <Badge pill variant="secondaryPink" className="customizationItemLink"
                                onClick={() => showCustomizationModal()}
                            >
                                {/* <RiCheckboxCircleFill className="mr-1" /> */}
                                {cart_item.customization_id ? 'Update' : 'Add'} Customization
                            </Badge>

                            {cart_item.customization_id ? <> <div className="removeLink" onClick={() => removeCustomization(cart_item.id)}>
                                <ImCross className="icon" /> Remove
                            </div></> : <></>

                            }

                        </div>
                    </li>
                    <Modal
                        show={customization.showModal}
                        centered
                        onHide={() => closeCustomizationModal()}
                        animation={false}
                        className="customizationPopup"
                        size="lg"
                    >
                        <Modal.Body>
                            {customization.isLoading && <ContainerLoading />}
                            {customization.hasError && <ErrorComponent />}
                            {(!customization.isLoading && !customization.hasError) &&
                                <div className="customizationFormContainer">
                                    <div className="closeButtonContainer floatButton">
                                        <CloseButton onClick={() => closeCustomizationModal()} />
                                    </div>


                                    {formState.isFormSubmitted ? (

                                        <SuccessMsg
                                            msgTitle="Your selection(s) for the customization of the item is added."
                                            msgBody=""
                                        />


                                    ) : (


                                        <>
                                            <div className="customHeadingForForm">
                                                <p className="mb-2 pageHeading">
                                                    {customizationMessage.heading && customizationMessage.heading.length && customizationMessage.heading[0] ? customizationMessage.heading[0].text : ''}
                                                </p>
                                            </div>
                                            <Row>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={customizationFormDataInitialState}
                                                    validationSchema={itemCustomizationValidation}
                                                    validateOnChange={true}
                                                    validateOnBlur={false}
                                                    onSubmit={async (values, { setSubmitting, resetForm, event }) => {

                                                        if (showSelectOneItemError)
                                                            return;

                                                        if (imageValidationError)
                                                            return;

                                                        console.log('submit handler');


                                                        setSubmitting(true);
                                                        await handleFormSubmit(values);


                                                    }}
                                                >
                                                    {({ values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting,
                                                        setFieldValue
                                                    }) => (
                                                        <>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <div className="placeOrderitem customTab horizontal">
                                                                    <div className="formContainer">

                                                                        {formState.isFormSubmitting && (
                                                                            <ContainerLoading />
                                                                        )}

                                                                        {formState.hasSubmissionError && (
                                                                            <FormSubmissionFailed />
                                                                        )}






                                                                        <Form onSubmit={handleSubmit} className="mx-auto">

                                                                            {
                                                                                (customization.options && customization.options.logo_customization > 0) && (
                                                                                    <Form.Row>
                                                                                        <Col xs={12} >
                                                                                            <div className="mb-3 flexContainer">
                                                                                                <div className="hasCursor flex">


                                                                                                    <div className="form-check form-check-inline">
                                                                                                        <Field type="checkbox" name="selectedItems[logo]" id="selectedItemsLogo" className="form-check-input" checked={values.selectedItems.logo} />
                                                                                                        <label title="" htmlFor="selectedItemsLogo" className="form-check-label"> Add a Photo/Logo</label>
                                                                                                    </div>
                                                                                                    <p className="caption m-0">Image should be high resolution best quality in PNG or JPG format.</p>

                                                                                                    {
                                                                                                        (values.selectedItems.logo > 0) &&
                                                                                                        <>
                                                                                                            <Col className={`p-0 m-0 dropzoneContainer ${imageValidationError ? "errorParent" : ""}`}>
                                                                                                                <div className="form-group">
                                                                                                                    <input
                                                                                                                        type="file"
                                                                                                                        className="custom_file_upload mt-2"
                                                                                                                        name="file"
                                                                                                                        onChange={(event) => {
                                                                                                                            //console.log(event.target.files[0]);
                                                                                                                            let error = getImageValidationError(event.target.files[0])
                                                                                                                            setImageValidationError(error);
                                                                                                                            setPicture({
                                                                                                                                preview: URL.createObjectURL(event.target.files[0]),
                                                                                                                                asFile: event.target.files[0]
                                                                                                                            })
                                                                                                                        }}

                                                                                                                    />
                                                                                                                    {(imageValidationError) && <div className="errorMessage">{imageValidationError}</div>}
                                                                                                                </div>{console.log(errors)}


                                                                                                            </Col>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Form.Row>
                                                                                )
                                                                            }


                                                                            {
                                                                                (customization.options && customization.options.to_whom_customization > 0) &&
                                                                                <Form.Row>
                                                                                    <Col xs={12} >
                                                                                        <div className="mb-3 flexContainer">
                                                                                            <div className="hasCursor flex">
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <Field type="checkbox" name="selectedItems[toWhom]" id="selectedItemsToWhom" className="form-check-input" checked={values.selectedItems.toWhom} />
                                                                                                    <label title="" htmlFor="selectedItemsToWhom" className="form-check-label">To Whom</label>
                                                                                                </div>
                                                                                                <p className="caption m-0">Add a receiver name to whom you want to send this item.</p>

                                                                                                {
                                                                                                    values.selectedItems.toWhom &&
                                                                                                    <>
                                                                                                        <Col className={`p-0 m-0 ${errors.toWhom && touched.toWhom ? "errorParent" : ""}`}>
                                                                                                            <FormInput
                                                                                                                name="toWhom"
                                                                                                                controlId="toWhom"
                                                                                                                type="text"
                                                                                                                onChange={handleChange}
                                                                                                                onBlur={handleBlur}
                                                                                                                value={values.toWhom}


                                                                                                            />
                                                                                                        </Col>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Form.Row>
                                                                            }

                                                                            {
                                                                                (customization.options && customization.options.from_customization > 0) &&
                                                                                <Form.Row>
                                                                                    <Col xs={12} >
                                                                                        <div className="mb-3 flexContainer">
                                                                                            <div className="hasCursor flex">
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <Field type="checkbox" name="selectedItems[from]" id="selectedItemsFrom" className="form-check-input" checked={values.selectedItems.from} />
                                                                                                    <label title="" htmlFor="selectedItemsFrom" className="form-check-label">From</label>
                                                                                                </div>
                                                                                                <p className="caption m-0">Add a sender name who is going to send this item.</p>

                                                                                                {
                                                                                                    values.selectedItems.from &&
                                                                                                    <>
                                                                                                        <Col className={`p-0 m-0 ${errors.from && touched.from ? "errorParent" : ""}`}>
                                                                                                            <FormInput
                                                                                                                name="from"
                                                                                                                controlId="from"
                                                                                                                type="text"
                                                                                                                onChange={handleChange}
                                                                                                                onBlur={handleBlur}
                                                                                                                value={values.from}
                                                                                                            />
                                                                                                        </Col>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Form.Row>
                                                                            }

                                                                            {
                                                                                (customization.options && customization.options.message_customization > 0) &&
                                                                                <Form.Row>
                                                                                    <Col xs={12} >
                                                                                        <div className="mb-3 flexContainer">
                                                                                            <div className="hasCursor flex">
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <Field type="checkbox" name="selectedItems[note]" id="selectedItemsNote" className="form-check-input" checked={values.selectedItems.note} />
                                                                                                    <label title="" htmlFor="selectedItemsNote" className="form-check-label">Special Note</label>
                                                                                                </div>
                                                                                                <p className="caption m-0">Add a special note to send along with this item.</p>

                                                                                                {
                                                                                                    values.selectedItems.note &&
                                                                                                    <>
                                                                                                        <Col className={`p-0 m-0 ${errors.note && touched.note ? "errorParent" : ""}`}>

                                                                                                            <FormTextArea300



                                                                                                                label=""
                                                                                                                name="note"
                                                                                                                controlId="note"
                                                                                                                type="text"
                                                                                                                as="textarea"
                                                                                                                rows={4}
                                                                                                                onChange={handleChange}
                                                                                                                onBlur={handleBlur}
                                                                                                                value={values.note}

                                                                                                            />


                                                                                                        </Col>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Form.Row>
                                                                            }

                                                                            <div className="seperator hasTopBorderThin mb-2"></div>

                                                                            <Col className="p-0">
                                                                                <FormTextArea300
                                                                                    controlId="brief"
                                                                                    label="brief (optional)"
                                                                                    name="brief"
                                                                                    type="text"
                                                                                    as="textarea"
                                                                                    rows={4}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.brief}

                                                                                />
                                                                            </Col>
                                                                            <div className="mt-3 ">
                                                                                <Button type="submit" className="hasShadow hasPadding lessCorners mt-3" variant="PurpleButton" size="sm" onClick={() => checkIfAnyItemIsSelected(values)}>
                                                                                    {cart_item.customization_id ? 'Update' : 'Add'} Customization
                                                                                </Button>

                                                                                {
                                                                                    showSelectOneItemError &&
                                                                                    <div className="errorMessage">Pelase select at least one option.</div>
                                                                                }



                                                                            </div>
                                                                        </Form>



                                                                    </div>

                                                                </div>

                                                            </Col>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                <div className="customizationDetailsOnItem">
                                                                    {
                                                                        (
                                                                            !(
                                                                                (values.selectedItems.logo && ((picture && picture.preview) || (cart_item.customization_logo)))
                                                                                || (values.selectedItems.toWhom && values.toWhom)
                                                                                || (values.selectedItems.from && values.from)
                                                                                || (values.selectedItems.note && values.note)
                                                                            )
                                                                        ) ?
                                                                            <>
                                                                                <div className="pageHeading mb-4">
                                                                                    Select your customization now!
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="pageHeading mb-4">
                                                                                    {customizationMessage.selectedOptionsHeading}
                                                                                </div>
                                                                                <div className="customizationContainer">

                                                                                    <div className="customizationDetails">
                                                                                        <ul>
                                                                                            {

                                                                                                (values.selectedItems.logo)
                                                                                                && (
                                                                                                    (picture && picture.preview)
                                                                                                        ?
                                                                                                        <li>
                                                                                                            <div className="selectedOption">Your selected logo/photo</div>
                                                                                                            <div className="customizationImage mb-2" >
                                                                                                                <img src={picture.preview} alt="preview" />
                                                                                                            </div>

                                                                                                        </li>
                                                                                                        : (
                                                                                                            (cart_item.customization_logo)
                                                                                                            &&
                                                                                                            <li>
                                                                                                                <div className="selectedOption">Your selected logo/photo</div>
                                                                                                                <div className="customizationImage mb-2">
                                                                                                                    <img src={process.env.REACT_APP_BASE_URL + cart_item.customization_logo} alt="saved"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        )
                                                                                                )

                                                                                            }

                                                                                            {
                                                                                                (values.selectedItems.toWhom && values.toWhom)
                                                                                                &&
                                                                                                <li>
                                                                                                    <div className="heading">To Whom</div>
                                                                                                    <div className="selectedOption">{values.toWhom}</div>
                                                                                                </li>
                                                                                            }

                                                                                            {
                                                                                                (values.selectedItems.from && values.from)
                                                                                                &&

                                                                                                <li>
                                                                                                    <div className="heading">From</div>
                                                                                                    <div className="selectedOption">{values.from}</div>
                                                                                                </li>
                                                                                            }
                                                                                            {
                                                                                                (values.selectedItems.note && values.note)
                                                                                                &&

                                                                                                <li>
                                                                                                    <div className="heading">Special Note</div>
                                                                                                    <div className="selectedOption">{values.note}</div>
                                                                                                </li>
                                                                                            }


                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="customizationTotalCost hasBorderTop hasMediumTopPadding">
                                                                                    {customizationMessage.cost}: <span className="totalPrice">
                                                                                        {getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(customization.options.price, defaultSelectedCurrency, currencies, true)}
                                                                                    </span>
                                                                                    <div className="caption">{customizationMessage.nonRefund}</div>
                                                                                </div>

                                                                            </>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Formik>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            }
                        </Modal.Body>
                    </Modal>
                </>
                :
                (null)
            }


        </>
    )
}

export default CustomizationButton