import React from 'react'

export const fixedBody = () => {
  document.body.classList.add('fixed');
}

export const relativeBody = () => {
  document.body.classList.remove('fixed');
}

export const isTrue = (value) => {
  if (value === 1 || value === '1' || value === true || value === "true") return true;
  return false;
}

export const isItemInCart = (product_id, cart_items) => {
  if (cart_items !== undefined && cart_items.length) {
    const foundObject = (cart_items.find(x => x.product_id === product_id));
    if (foundObject !== undefined) {
      return true
    } else {
      return false;
    }
  }
}

export const isLogin = () => {
  if (localStorage.getItem('token')) {
    return true;
  }
  return false;
}

const countryList = [
  {
    id: 1,
    name: "United Arab Emirates",
  },
  {
    id: 2,
    name: "Lebanon",
  }
]
export const countryOptions = countryList.map((item) => {
  return (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  )
})


export const productSliderParams = {
  speed: 500,
  autoplay: { delay: 6000 },
  slidesPerView: 4,
  scrollbar: { draggable: true },
  breakpoints: {
    // when window width is >=300px
    320: {
      slidesPerView: 1,
      scrollbar: false,
    },
    // when window width is >= 768px
    700: {
      slidesPerView: 2,
      scrollbar: false,
    },
    960: {
      slidesPerView: 3,
    },
    1240: {
      slidesPerView: 4,
    },
  }
};