import React, { useReducer, useEffect, useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { FaFileInvoiceDollar, FaEnvelope } from "react-icons/fa"
import { ImCart } from "react-icons/im"
import { BsHeartFill } from "react-icons/bs"
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { AuthContext } from '../../contexts/AuthContext'
import { SkeletonDashboardItem } from '../../components/Skeleton/Skeleton'
import { buyerStatsAPI } from '../../apis/buyerAPIs'
import { ErrorComponent } from './../../components/Alert/Alert';



const DashboardStats = ({ setStatsLoaded }) => {
    let { url } = useRouteMatch();
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);



    const loadContent = async () => {
        if (userAuthState.user && userAuthState.user.token) {
            itemDispatch({
                type: "FETCH_REQUEST"
            });


            const apiResult = await buyerStatsAPI(getBearerTokenConfig());
            if (apiResult.status) {
                itemDispatch({
                    type: "FETCH_SUCCESS",
                    payload: apiResult.data
                });
                setStatsLoaded(true);
            } else {
                itemDispatch({
                    type: "FETCH_FAILURE"
                });
            }
        }
    }

    useEffect(() => {
        loadContent();
    }, []);




    return (
        <div className="dashboardItems">

            {
                (itemState.isFetching) ? (
                    <SkeletonDashboardItem />
                ) : (
                    (itemState.hasError)
                        ? <ErrorComponent />
                        : (
                            <>
                                {(itemState.item && itemState.item.stats) ? (
                                    <>
                                        <Row>
                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox">
                                                    <Card border="fourthGray">
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaFileInvoiceDollar />
                                                                    </div>
                                                                </Col>
                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-2">
                                                                        You Have
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.ordersCount} Order(s) with us
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`${url}/orders`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Order(s)
                                                                    </Card.Link>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>


                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox">
                                                    <Card border="fourthGray">
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaEnvelope />
                                                                    </div>
                                                                </Col>
                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-2">
                                                                        You Have
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.messagesCount} New Message(s)
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`${url}/messages`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Message(s)
                                                                    </Card.Link>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>


                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox">
                                                    <Card border="fourthGray">
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <ImCart />
                                                                    </div>
                                                                </Col>
                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-2">
                                                                        You Have
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.cartItemsCount} Items(s) in your Cart
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`/cart`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Cart
                                                                    </Card.Link>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>

                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox">
                                                    <Card border="fourthGray">
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <BsHeartFill />
                                                                    </div>
                                                                </Col>
                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-2">
                                                                        You Have
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.wishlistItemsCount} Items(s) in your Wishlist
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`/wishlist`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Wishlist
                                                                    </Card.Link>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <SkeletonDashboardItem />
                                )}
                            </>
                        )
                )
            }
        </div>
    )
}

export default DashboardStats
