import React, { useContext } from "react"
import { PopupContext } from "../../contexts/PopupContext"
import LoginForm from "./LoginForm"
export const Login = (props) => {
  const { popupDispatch } = useContext(PopupContext);


  const openSignupPopup = () => {
    popupDispatch({
      type: "POPUP_SIGNUP_OPEN"
    })
  }

  const closeAfterSignIn = () => {
    popupDispatch({
      type: "POPUP_SIGNIN_CLOSE"
    })
  }

  return (
    <>
      <LoginForm
        openSignupPopup={openSignupPopup}
        popupDispatch={popupDispatch}
        closeAfterSignIn={closeAfterSignIn}
      />
    </>
  );
};

export default Login;
