import React, { useContext } from 'react'
import { FaTrashAlt } from "react-icons/fa"
import { CartContext } from '../../../contexts/CartContext'
const RemoveFromCart = ({ cart_item_id, cartDispatch }) => {
    const { deleteCartItem } = useContext(CartContext)
    return (
        <>
            <FaTrashAlt
                className="removeFromCart"
                title="Remove From Cart"
                onClick={() => {
                    deleteCartItem(cart_item_id);
                }} />
        </>
    )
}

export default RemoveFromCart
