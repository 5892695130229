import axios from 'axios'

export const fetchStartupContentAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/startup-content`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}



export const fetchBannersAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/banners`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchTopSearchedProductsAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/top-searched-products`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getProductSuggestionsAPI = async (searchQuery, headers) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/suggestions?q=${searchQuery}`, headers)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getJnoonzDealAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/jnoonz-deals`)
        return { "status": true, "data": res.data.deal };
    } catch (error) {
        return { "status": false };
    }
}

export const getExclusiveProductsAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products?type=jnoonz-exclusive&per-page=8&sort=newest-to-oldest`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getLatestProductsAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products?per-page=8&sort=newest-to-oldest`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}
export const getC3ProductsAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products?supplier_id=122&per-page=8&sort=newest-to-oldest`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const getTopSellingProductsAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/top-selling-products`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getSpotlightAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/spotlight`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getProductTypeSpotlightAPI = async (position) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/product-type-spotlight/` + position)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getSignupSliderAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/signup-slides`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getSigninSliderAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/signin-slides`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const getCountryListAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/countries`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getDeliveryCountryListAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/delivery-countries`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getGenderListAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/gender`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchPageContentAPI = async (slug) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/page-content/${slug}`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const submitContactUsAPI = async (data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/contact-us`, data)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchMoneyTransferDetailAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/money-transfer-details`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const fetchCountriesAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/countries`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const fetchAgeGroupAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/age-group`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const fetchGenderAPI = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/gender`)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}