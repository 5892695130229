export const CartReducer = (state, action) => {

    switch (action.type) {
        case "FETCH_CARTITEM_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_CARTITEM_ERROR":
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case "FETCH_CARTITEM_SUCCESS":
            return {
                ...state,
                isFetching: false,
                hasError: false,
                cartItems: action.payload,
            };


        case "REMOVE_CARTITEM_REQUEST":
            return {
                ...state,
                // isUpdating: true,
                hasError: false
            };
        case "REMOVE_CARTITEM_SUCCESS":
            return {
                ...state,
                cartItems: action.payload,
                //  isUpdating: false,
                hasError: false
            };
        case "REMOVE_CARTITEM_ERROR":
            return {
                ...state,
                //  isUpdating: false,
                hasError: true
            };


        case "ADD_CARTITEM_REQUEST":
            return {
                ...state,
                // isAdding: true,
                hasError: false,
            };
        case "ADD_CARTITEM_SUCCESS":
            return {
                ...state,
                //guest_session_token: action.payload.guest_session_token,
                // isAdding: false,
                hasError: false,
                cartItems: action.payload
            };
        case "ADD_CARTITEM_ERROR":
            return {
                ...state,
                // isAdding: false,
                hasError: true
            };


        case "UPDATE_CARTITEM_QUANTITY_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "UPDATE_CARTITEM_QUANTITY_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };
        case "UPDATE_CARTITEM_QUANTITY_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };

        case "REMOVE_PACKAGING_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "REMOVE_PACKAGING_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };
        case "REMOVE_PACKAGING_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };




        case "REMOVE_CUSTOMIZATION_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "REMOVE_CUSTOMIZATION_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };
        case "REMOVE_CUSTOMIZATION_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };

        case "ADD_SHIPPING_ADDRESS_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "ADD_SHIPPING_ADDRESS_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };
        case "ADD_SHIPPING_ADDRESS_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };



        case "ADD_PAYMENT_METHOD_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "ADD_PAYMENT_METHOD_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };
        case "ADD_PAYMENT_METHOD_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };



        case "PLACE_ORDER_REQUEST":
            return {
                ...state,
                isUpdating: true,
                hasError: false,
            };
        case "PLACE_ORDER_SUCCESS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: []
            };
        case "PLACE_ORDER_ERROR":
            return {
                ...state,
                isUpdating: false,
                hasError: true
            };



        case "REMOVE_ERROR":
            return {
                ...state,
                hasError: false
            };





        case "SET_CARTITEMS":
            return {
                ...state,
                isUpdating: false,
                hasError: false,
                cartItems: action.payload
            };



        default:
            return state;
    }
};