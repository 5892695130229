import React from 'react'
import { Container, Col } from 'react-bootstrap'
import ChangePasswordForm from './ChangePasswordForm'

const ChangePassword = () => {
    return (
        <Container className="changePassword">
        <div className="pageHeading">Change Password</div>
        <div className="changePasswordContainer mt-4">
           <Col xl={7} xs={12} style={{margin:"0 auto"}}>
                <ChangePasswordForm/>
           </Col>
        </div>
        </Container>
    )
}

export default ChangePassword
