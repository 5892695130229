import React, { useState, useReducer, useContext, useEffect } from 'react'
import './Packing.scss'
import PackagingModal from './PackagingModal'
import axios from 'axios'
import { CartContext } from "../../../contexts/CartContext"
import { GlobalContext } from './../../../contexts/GlobalContext';
import { Badge } from 'react-bootstrap'
import { ImCross } from "react-icons/im";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { isTrue, getGuestSessionToken } from "../../../helpers/CommonHelper"
import { PackagingReducer } from '../../../reducers/PackagingReducer'
import { formSubmissionReducer, formInitialState } from '../../../reducers/Reducers'
import { Modal, Col, Row, Form, Button } from 'react-bootstrap'
import { ContainerLoading } from '../../Loading/Loading'
import { itemReducer, itemInitialState } from "../../../reducers/Reducers"
import { AuthContext } from "../../../contexts/AuthContext"
import SuccessMsg from '../../FormPartials/SuccessMsg'
import { ErrorComponent } from '../../Alert/Alert'
import { Formik } from 'formik'
import { itemPackingValidation } from '../../FormPartials/Validation'
import MendatoryFieldMsg from '../../FormPartials/MendatoryFieldMsg'
import image from '../../../assets/images/jnoonz_product_default.png'
import { CloseButton } from './../../CloseOverlayButton/CloseOverlay';
import { FormSubmissionFailed } from './../../Alert/Alert';
import Price from './../../ProductItem/Price';

import { getSelectedCurrencySymbol, getPriceInSelectedCurrency } from "../../../helpers/MoneyHelper"
const packagingInitialState = {
    options: [],
    showModal: false,
    isLoading: false,
    hasError: false
}

const PackingButton = ({ cart_item }) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;
    const [packaging, packagingDispatch] = useReducer(PackagingReducer, packagingInitialState);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { addPackaging, removePackaging, setCartItems } = useContext(CartContext);
    const [packagingLevel, setPackagingLevel] = useState('');
    const [updatedItems, setUpdatedItems] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const packagingFormDataInitialState = {
        packaging_id: cart_item.packaging_id ? cart_item.packaging_id : '',
        cart_item_id: cart_item.id,
        guest_session_token: getGuestSessionToken(),
    }

    const closePackagingModal = () => {
        if (updatedItems) {
            setCartItems(updatedItems)
        }
        packagingDispatch({
            type: "CLOSE_PACKAGING_POPUP"
        });
    };

    const showPackagingModal = (cart_item) => {
        packagingDispatch({
            type: "SHOW_PACKAGING_POPUP"
        });
        getPackagingOptions();
    };

    const getPackagingOptions = async (e) => {

        packagingDispatch({
            type: 'FETCH_PACKAGING_REQUEST'
        });

        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/packaging/${cart_item.product.id}`)
            //console.log('Fetch Result ', res);
            setPackagingLevel(res.data.packagingLevel);
            packagingDispatch({
                type: "FETCH_PACKAGING_SUCCESS",
                payload: res.data.data
            });
        } catch (error) {
            packagingDispatch({
                type: "FETCH_PACKAGING_ERROR",
                payload: error.message
            });
        }
    }

    const hanleFormSubmit = async (data) => {
        formDispatch({
            type: "FORM_SUBMISSION_START",
        });

        const submissionResult = await addPackaging(data);

        if (submissionResult.status) {
            setUpdatedItems(submissionResult.data);
            setTimeout(() => {
                setCartItems(submissionResult.data)
            }, 5000);
            formDispatch({
                type: 'FORM_SUBMISSION_SUCCESS',
            });
        } else {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
            });
            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE",
                });
            }, 5000);
        }
    }

    const updatePreview = (id) => {
        //console.log("called with " + id + " and " + packaging.options);
        if (id) {
            const filteredItems = packaging.options.filter(item => (1 * item.packaging_id) === (1 * id))
            if (filteredItems && filteredItems.length) {
                //console.log(filteredItems[0]);
                setSelectedOption(filteredItems[0])
            } else {
                setSelectedOption(null)
            }
        } else {
            setSelectedOption(null)
        }
    }

    const options = packaging.options.map((optionitem) => {
        return (
            <option key={optionitem.id} value={optionitem.packaging.id} id={optionitem.packaging.id}>
                {optionitem.packaging.title}
            </option>
        )
    })



    // Frontend Messages - Checkout - Packaging //
    const { getFrontendMessage } = useContext(GlobalContext);
    //    const packagingLinkText = getFrontendMessage('Checkout', 'Packaging', 'Link Text', 'Text')
    const packagingMessage = {
        heading: getFrontendMessage('Checkout', 'Packaging', 'Form Heading', 'Text'),
        addButton: getFrontendMessage('Checkout', 'Packaging', 'Button Text', 'Text'),
        selectedOptionHeading: getFrontendMessage('Checkout', 'Packaging', 'Selected Option', 'Text'),
        cost: getFrontendMessage('Checkout', 'Packaging', 'Cost', 'Text'),
        costCaption: getFrontendMessage('Checkout', 'Packaging', 'costCaption', 'Text'),
    }


    useEffect(() => {
        if (packaging && packaging.options && packaging.options.length) {
            updatePreview(cart_item.packaging_id)
        }
    }, [packaging.options])


    return (
        <>

            {(isTrue(cart_item.product.is_sp_packaging)) ?
                <>
                    <li>
                        <div className="packagingItem">
                            <Badge
                                className="packagingItemLink"
                                pill variant="secondaryGreen"
                                onClick={showPackagingModal}
                            >
                                {/* <RiCheckboxCircleFill className="mr-1" /> */}

                                {cart_item.packaging_id ? 'Update' : 'Add'} Packaging
                            </Badge>

                            {cart_item.packaging_id &&
                                <div className="removeLink" onClick={() => removePackaging(cart_item.id)}>
                                    <ImCross className="icon" /> Remove
                                </div>
                            }
                        </div>
                    </li>
                    <Modal
                        show={packaging.showModal}
                        centered
                        onHide={() => closePackagingModal()}
                        animation={false}
                        className="customizationPopup"
                        size="lg"
                    >
                        <Modal.Body>
                            {packaging.isLoading && <ContainerLoading />}
                            {packaging.hasError && <ErrorComponent />}
                            {(!packaging.isLoading && !packaging.hasError) &&
                                <div className="customizationFormContainer">
                                    <div className="closeButtonContainer floatButton">
                                        <CloseButton onClick={() => closePackagingModal()} />
                                    </div>

                                    {formState.isFormSubmitting && (
                                        <ContainerLoading />
                                    )}

                                    {formState.isFormSubmitted ? (

                                        <SuccessMsg
                                            msgTitle="Your selection for the packaging of the item is added."
                                            msgBody=""
                                        />
                                    ) : (
                                        <>
                                            <div className="pageHeading">
                                                <p>{packagingMessage.heading}</p>
                                            </div>

                                            <div className="placeOrderitem customTab horizontal">

                                                <Row className="mt-30">
                                                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                                        <div className="itemHolder noBorder noHover p-0">
                                                            <div className="itemImageHolder autoHeight">
                                                                <img
                                                                    className="itemImage"
                                                                    src={`${process.env.REACT_APP_BASE_URL}${cart_item.product.image}`}
                                                                    alt={cart_item.product.title}
                                                                    title={cart_item.product.title}
                                                                />
                                                            </div>

                                                            <div className="itemDetails mt-3">
                                                                <ul>
                                                                    <li className="itemCategory">
                                                                        {cart_item.product.category.name === "" ? (
                                                                            <p>{cart_item.product.type.name}</p>
                                                                        ) : (
                                                                            <>
                                                                                {cart_item.product.subCategory.name === "" ? (
                                                                                    <p>{cart_item.product.category.name}</p>
                                                                                ) : (
                                                                                    <p>{cart_item.product.subCategory.name}</p>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <>
                                                                        </>
                                                                    </li>
                                                                    <li className="itemTitle">
                                                                        {cart_item.product.title}
                                                                    </li>
                                                                    <li>
                                                                        <div className="cartItemBottom">
                                                                            <ul>
                                                                                <li className="priceContainer">
                                                                                    <Price price={cart_item.product.price} oldPrice={cart_item.product.old_price} />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                    {/* {(cart_item && cart_item.package_price) && (
                                                                <li>
                                                                    <div className="additionalCostinCartItem">Packaging Cost: <span className="additionalCost">${cart_item.package_price}</span></div>
                                                                </li>
                                                            )} */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                                        <div className="formContainer">
                                                            {formState.hasSubmissionError && (
                                                                <FormSubmissionFailed />
                                                            )}
                                                            <>
                                                                <Formik
                                                                    enableReinitialize={true}
                                                                    initialValues={packagingFormDataInitialState}
                                                                    validationSchema={itemPackingValidation}
                                                                    validateOnChange={true}
                                                                    validateOnBlur={false}
                                                                    onSubmit={async (values, { setSubmitting, resetForm, event }) => {
                                                                        setSubmitting(true);
                                                                        values["packaging_level"] = packagingLevel;
                                                                        await hanleFormSubmit(values);
                                                                    }}
                                                                >
                                                                    {({ values,
                                                                        errors,
                                                                        touched,
                                                                        handleChange,
                                                                        handleBlur,
                                                                        handleSubmit,
                                                                        isSubmitting,
                                                                        setFieldValue,
                                                                        setFieldTouched,
                                                                        error,
                                                                        form,
                                                                        field
                                                                    }) => (
                                                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                                                            <Col
                                                                                className={`mendatory ${errors.packaging_id && touched.packaging_id ? "errorParent" : ""}`}>
                                                                                <Form.Group controlId="packaging_id">
                                                                                    <Form.Label>Select a Packaging Option</Form.Label>
                                                                                    <Form.Control as="select"
                                                                                        name="packaging_id"
                                                                                        controlId="packaging_id"
                                                                                        onChange={(e) => {
                                                                                            updatePreview(e.target.value);
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        value={values.packaging_id}
                                                                                    >
                                                                                        <option value="">
                                                                                            Select an Option
                                                                                        </option>
                                                                                        {options}
                                                                                    </Form.Control>
                                                                                    {errors.packaging_id && <div className="errorMessage">{errors.packaging_id}</div>}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Form.Row className="mt-40">
                                                                                <Col>
                                                                                    <Button variant="PurpleButton"
                                                                                        className="hasPadding uppercase hasShadow bold lessCorners"
                                                                                        type="submit"
                                                                                        size="sm"
                                                                                        disabled={isSubmitting}
                                                                                    >
                                                                                        {cart_item.packaging_id ? 'Update' : 'Add'} Packaging
                                                                                    </Button>
                                                                                </Col>
                                                                            </Form.Row>
                                                                            <Col className="p-0 mt-3">
                                                                                <MendatoryFieldMsg />
                                                                            </Col>

                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </>








                                                        </div>
                                                    </Col>

                                                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                                        <div className="packingDetailsOnItem">
                                                            {selectedOption && selectedOption.id ?
                                                                <>
                                                                    <div className="pageHeading mb-4">
                                                                        {packagingMessage.selectedOptionHeading}
                                                                    </div>
                                                                    <div className="packingContainer">
                                                                        <div className="packingDetails">
                                                                            <ul>
                                                                                <li>

                                                                                    <Row>

                                                                                        <>
                                                                                            <Col xl={4} lg={4} md={4} sm={4} xs={12} className="pr-0">
                                                                                                <div className="packingImage">
                                                                                                    <img
                                                                                                        className="itemImage"
                                                                                                        src={`${process.env.REACT_APP_BASE_URL}${selectedOption.packaging.image}`}
                                                                                                        alt={selectedOption.packaging.title}
                                                                                                        title={selectedOption.packaging.title}
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                                                                                <div className="selectedOption">{selectedOption.packaging.title}</div>
                                                                                            </Col>
                                                                                        </>

                                                                                    </Row>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>


                                                                    <div className="packingTotalCost hasBorderTop hasMediumTopPadding">
                                                                        {packagingMessage.cost}: <span className="totalPrice">
                                                                            <span>
                                                                                {getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(selectedOption.price, defaultSelectedCurrency, currencies, true)}

                                                                            </span>

                                                                        </span>
                                                                        <div className="caption">{packagingMessage.costCaption}</div>
                                                                    </div>
                                                                </>
                                                                :

                                                                <></>

                                                            }
                                                            {/* <div className="mt-3 textRight">
                                                                <Button className="hasShadow hasPadding lessCorners" variant="PinkButton" size="sm"
                                                                    onClick={() => closePackagingModal()}
                                                                >
                                                                    Accept and Continue
                                                                </Button>
                                                            </div> */}


                                                        </div>
                                                    </Col>

                                                </Row>



                                            </div>
                                        </>
                                    )}
                                </div>
                            }



                        </Modal.Body>
                    </Modal>



                </>
                :
                (null)
            }
        </>
    )
}

export default PackingButton