import React, { useContext } from 'react'
import { SkeletonHorizontalItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent, ItemNotFoundDynamic } from '../../components/Alert/Alert'
import WishlistItem from '../WishlistItem/WishlistItem'
import { WishlistContext } from "../../contexts/WishlistContext"

const Wishlist = ({ item, props }) => {

    const { wishlist } = useContext(WishlistContext);

    return (
        <>
            {wishlist.isFetching ? (
                <SkeletonHorizontalItem />
            ) : wishlist.hasError ? (
                <ErrorComponent />
            ) : (
                <>
                    {wishlist.items.length > 0 ? (
                        <>
                            <ul >
                                {wishlist.items.map((item, index) =>

                                    <li key={index} className="itemHolder horizontalItemHolder">

                                        {wishlist.isRemoving ? (
                                            <SkeletonHorizontalItem />
                                        ) : (
                                            <WishlistItem key={item.id} item={item.product} />
                                        )}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <ItemNotFoundDynamic
                            className="itemNotFound textCenter mt-4"
                            msgTitle="Item not found"
                        />
                    )}
                </>
            )}
        </>
    )
}

export default Wishlist;