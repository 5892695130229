import React, { useReducer, useState, useContext } from 'react'
import { Form, Button, Col, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../Alert/Alert'
import AllowToMakeProfile from '../AllowToMakeProfile/AllowToMakeProfile'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'
import { checkEmailExist } from '../../apis/buyerAPIs'
import { CartContext } from '../../contexts/CartContext'
import { PopupContext } from '../../contexts/PopupContext'
import { OverlayContext } from '../../contexts/OverlayContext'
import { BackdropContext } from '../../contexts/BackdropContext'
import { AuthContext } from "../../contexts/AuthContext"
import { getGuestSessionToken } from '../../helpers/CommonHelper';
import "./orderbywhatsapp.scss"
import TelephoneInput from '../FormPartials/TelephoneInput'

const orderByWhatsAppValidation = Yup.object().shape({
    name: Yup.string()
        .required("Please enter your name"),

    email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter your email address"),

    mobile: Yup.number().required("Please enter your mobile number").test('len', 'Please enter your complete mobile number', val => val && val.toString().length > 9),

});
const OrderByWhatsApp = ({
    setOrderPlacedByWhatsApp,
    setOrderNumber,
    orderByWhatsAppFormHeading,
    orderByWhatsAppFormSuccessMessage,
    orderByWhatsAppFormButtonText,
    ...props
}) => {
    const { whatsAppCheckout, setCartItems } = useContext(CartContext);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [emailExistStatus, setEmailExistStatus] = useState(false)
    const { userAuthDispatch } = useContext(AuthContext);
    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const { userOverlayState, userOverlayDispatch } = useContext(OverlayContext);
    const { popupDispatch } = useContext(PopupContext);
    return (
        <div>
            <div className="formContainer">
                {formState && formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}
                        <>
                            <p>{orderByWhatsAppFormHeading}</p>
                            {formState.hasSubmissionError && <FormSubmissionFailed />}
                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    mobile: "",
                                    type: "WhatsApp",
                                    guest_cart_session_token: getGuestSessionToken()
                                }}
                                validationSchema={orderByWhatsAppValidation}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {


                                    // if (emailExistStatus)
                                    //     return false;


                                    setSubmitting(true);
                                    formDispatch({
                                        type: "FORM_SUBMISSION_START",
                                    });


                                    const submissionResult = await whatsAppCheckout(values);
                                    console.log(submissionResult);
                                    if (submissionResult.status) {

                                        setCartItems([]);

                                        setTimeout(() => {
                                            window.open(`https://api.whatsapp.com/send?phone=009613673169&text=Hi JNooNz, I would like to go with order number ${submissionResult.order_id}`, '_blank');
                                        }, 3000);
                                        setOrderPlacedByWhatsApp(true);
                                        setOrderNumber(submissionResult.order_id);

                                        // formDispatch({
                                        //     type: 'FORM_SUBMISSION_SUCCESS',
                                        // });
                                    } else {

                                        formDispatch({
                                            type: "FORM_SUBMISSION_FAILED",
                                        });
                                        console.log("error");
                                        setTimeout(() => {
                                            formDispatch({
                                                type: "FORM_INITIAL_STAGE",
                                            });
                                        }, 5000);
                                    }




                                    // 
                                }}
                            >
                                {({ values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} className="mx-auto">
                                        <Col className={`p-0 mendatory ${errors.name && touched.name ? "errorParent" : ""}`}>
                                            <FormInput
                                                controlId="name"
                                                label="Your Name"
                                                name="name"
                                                type="text"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Col>
                                        <Col className={`p-0 mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                                            <FormInput
                                                controlId="email"
                                                label="Your Email Address"
                                                name="email"
                                                type="text"
                                                value={values.email}
                                                onChange={(e) => { handleChange(e); emailExistStatus === true && setEmailExistStatus(false) }}

                                                onBlur={async (e) => {
                                                    handleBlur(e);
                                                    const result = await checkEmailExist(values.email);
                                                    setEmailExistStatus(result)
                                                }
                                                }
                                            />

                                            {(!errors.email && emailExistStatus) &&
                                                <Alert variant="warning">
                                                    We have detected an account associated with the email ID you provided. <br />
                                                    We recommend you to  <a onClick={() => {
                                                        popupDispatch({
                                                            type: "POPUP_SIGNIN_OPEN",
                                                        });
                                                        userOverlayDispatch({
                                                            type: "OVERLAY_CLOSE",
                                                        });
                                                        backdropDispatch({
                                                            type: "FETCH_BACKDROP_CLOSE",
                                                        });
                                                    }} className="primaryPink">login</a> to your account for better shopping experience or you can click the button below to continue your order through WhatsApp.

                                                </Alert>
                                            }

                                        </Col>




                                        <Col className={`p-0 mendatory ${errors.mobile && touched.mobile ? "errorParent" : ""}`}>
                                            <TelephoneInput
                                                controlId="mobile"
                                                label="Mobile"
                                                name="mobile"
                                                value={values.mobile}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                        </Col>

                                        {!emailExistStatus &&
                                            <AllowToMakeProfile
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                values={values}
                                            />
                                        }



                                        <Form.Row className="mt-4 buttonContainer">
                                            <Button variant="PurpleButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {orderByWhatsAppFormButtonText}
                                            </Button>
                                            <Col className="p-0 mt-3 textRight">
                                                <MendatoryFieldMsg />
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default OrderByWhatsApp
