import React, {useContext} from 'react'
import GoBack from '../../components/GoBack/GoBack'
import HowDidYouHear from './HowDidYourHear';
import LetUsKnowYourInterest from './LetUsKnowYourInterest';
import { AuthContext } from '../../contexts/AuthContext'
const Interest = (props) => {
const { history } = props;
const { userAuthState } = useContext(AuthContext);
    return (
        <div>
            <GoBack history = {history}/>
            <LetUsKnowYourInterest
                userAuthState={userAuthState}
            />
            <div className="mt-3 mb-3" style={{
                display:"inline-block",
                width:"100%"
            }}>
                <hr></hr>
            </div>
            <HowDidYouHear/>
        </div>
    )
}

export default Interest
