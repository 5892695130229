import React, { createContext, useReducer, useContext } from 'react';
import { AuthContext } from './AuthContext'
import { ShippingAddressReducer } from './../reducers/ShippingAddressReducer';
import axios from 'axios'


const initialState = {
    data: [],
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isRemoving: false,
    hasError: false,
    isUpdated: false,
    hasUpdateError: false
};


//Create Context
export const ShippingAddressContext = createContext(initialState)

//Create Provider
export const ShippingAddressProvider = ({ children }) => {

    const [state, dispatch] = useReducer(ShippingAddressReducer, initialState)
    const { getBearerTokenConfig } = useContext(AuthContext);





    //Get Items
    async function getShippingAddressList() {
        dispatch({
            type: "FETCH_ITEMS_REQUEST"
        });
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address`,
                getBearerTokenConfig()
            )
            // console.log(res);
            dispatch({
                type: "FETCH_ITEMS_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "FETCH_ITEMS_ERROR",
                payload: error.message
            });
        }
    }

    async function addShippingAddress(data) {


        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/create`,
                data,
                getBearerTokenConfig()
            )

            dispatch({
                type: "ADD_ITEM_SUCCESS",
                payload: res.data
            });
            return { "status": true, "data": res.data };
        } catch (error) {
            return { "status": false };
        }
    }

    async function setPrimaryAddress(id) {

        dispatch({
            type: "SET_PRIMARY_REQUEST",
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/primary`,
                { id },
                getBearerTokenConfig()
            )

            dispatch({
                type: "SET_PRIMARY_SUCCESS",
                payload: res.data
            });
            return { "status": true, "data": res.data };
        } catch (error) {
            dispatch({
                type: "SET_PRIMARY_ERROR",
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR",
                });
            }, 4000);
            return { "status": false };
        }
    }

    async function deleteAddress(id) {

        dispatch({
            type: "REMOVE_ITEM_REQUEST",
        });

        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/${id}`,
                getBearerTokenConfig()
            )

            dispatch({
                type: "REMOVE_ITEM_SUCCESS",
                payload: id
            });
            return { "status": true, "action": 'done' };
        } catch (error) {
            dispatch({
                type: "REMOVE_ITEM_ERROR",
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR",
                });
            }, 4000);
            return { "status": false };
        }
    }

    async function getAddress(id) {

        dispatch({
            type: "FETCH_ITEM_REQUEST",
        });
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/${id}`,
                getBearerTokenConfig()
            )
            dispatch({
                type: "FETCH_ITEM_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: "FETCH_ITEM_ERROR",
            });
        }
    }

    async function updateAddress(data) {
        //console.log(data)
        dispatch({
            type: "UPDATE_ITEM_REQUEST",
        });
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/update/${data.id}`,
                data,
                getBearerTokenConfig()
            )

            dispatch({
                type: "UPDATE_ITEM_SUCCESS",
                payload: res.data
            });

            setTimeout(() => {
                dispatch({
                    type: "SET_INITIAL",
                });
            }, 4000);

        } catch (error) {
            dispatch({
                type: "UPDATE_ITEM_ERROR",
            });
            setTimeout(() => {
                dispatch({
                    type: "REMOVE_ERROR",
                });
            }, 4000);

        }
    }



    return (<ShippingAddressContext.Provider value={{
        shippingAddressData: state,
        getShippingAddressList,
        addShippingAddress,
        setPrimaryAddress,
        deleteAddress,
        getAddress,
        updateAddress,
    }}>{children}</ShippingAddressContext.Provider>)
}



