import React from 'react'
import './Address.scss'
import {Container} from 'react-bootstrap'
import ShippingAddress from '../../components/ShippingAddress/ShippingAddress'
const Address = (props) => {
    return (
      <Container className="dashboardShippingAddress">
        <div className="pageHeading">Shipping Address(es)</div>
        <div className="caption2">You can add, remove and select your preferred shipping address.</div>
        <div className="shippingAddressContainer">
        <ShippingAddress/>
        </div>
      </Container>
    );
}

export default Address