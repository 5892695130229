import React from 'react'
import './Pagination.scss'
import { Pagination } from 'react-bootstrap'
const PaginationItem = ({ pages, setPagination }) => {

    let numberOptionsToShow = 3;
    let minOptionToShow = Math.max((pages.currentPage - numberOptionsToShow), 1)
    let maxOptionToShow = Math.min((pages.currentPage + numberOptionsToShow), pages.pageCount)

    let items = [];
    if (minOptionToShow > 1) {
        items.push(
            <Pagination.Ellipsis key={'first'} disabled={true} />
        );
    }
    for (let page = 1; page <= pages.pageCount; page++) {
        if (page >= minOptionToShow && page <= maxOptionToShow) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === pages.currentPage}

                    onClick={() => setPagination(page)}
                >
                    {page}
                </Pagination.Item>,
            );
        }
    }

    if (maxOptionToShow < pages.pageCount) {
        items.push(
            <Pagination.Ellipsis key={'last'} disabled={true} />
        );
    }

    return (
        <>
            {(pages && pages.pageCount > 1) ?
                <Pagination>


                    {(pages.currentPage > 1) &&
                        <>
                            < Pagination.First
                                disabled={(pages.currentPage === 1)}
                                onClick={() => setPagination(1)}
                            />
                            <Pagination.Prev
                                disabled={(pages.currentPage === 1)}
                                onClick={() => {
                                    if (pages.currentPage > 1) {
                                        setPagination(pages.currentPage - 1)
                                    }
                                }} />
                        </>
                    }



                    {items}



                    {(pages.currentPage < pages.pageCount) &&
                        <>
                            <Pagination.Next
                                disabled={(pages.currentPage === pages.pageCount)}
                                onClick={() => {
                                    if (pages.currentPage < pages.pageCount) {
                                        setPagination(pages.currentPage + 1)
                                    }
                                }}
                            />
                            <Pagination.Last
                                disabled={(pages.currentPage === pages.pageCount)}
                                onClick={() => setPagination(pages.pageCount)}
                            />

                        </>
                    }
                </Pagination>
                : <>
                </>

            }

        </>

    )
}

export default PaginationItem
