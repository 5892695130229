import React, { createContext, useReducer, useContext } from 'react';
import { WishlistReducer } from "../reducers/WishlistReducer";
import axios from 'axios'
import { AuthContext } from './AuthContext'
import { CartContext } from './CartContext'
import { toast } from "react-toastify";
import { ToastWishlistItemAdded } from '../components/Toasts/Toasts';
import * as DOMHelpers from "../helpers/DOMHelpers"
import { isEmpty } from "../helpers/CommonHelper"
import { useLocation } from 'react-router-dom';
import * as APIs from '../apis/wishlistAPIs'
import { getGuestSessionToken } from './../helpers/CommonHelper';



let initialState = {
    items: [],
    isFetching: false,
    isAdding: false,
    isUpdating: false,
    isRemoving: false,
    hasError: false,
};


//Create Context
export const WishlistContext = createContext(initialState)

//Create Provider
export const WishlistProvider = ({ children }) => {



    const [state, dispatch] = useReducer(WishlistReducer, initialState)
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);
    const { setCartItems } = useContext(CartContext);
    const location = useLocation();






    async function fetchWishlist() {
        dispatch({
            type: "FETCH_WISHLIST_REQUEST"
        });
        const submissionResult = await APIs.fetchWishlist({ user_id: getUserIDForWishlist() }, getBearerTokenConfig());
        if (submissionResult.status) {
            dispatch({
                type: 'FETCH_WISHLIST_SUCCESS',
                payload: submissionResult.data
            });
        } else {
            dispatch({
                type: 'FETCH_WISHLIST_ERROR',
                payload: submissionResult.data
            });
        }
    }

    async function addWishlistItem(productID) {
        dispatch({
            type: "ADD_WISHLIST_ITEM_REQUEST"
        });
        const submissionResult = await APIs.addWishlistItem({ user_id: getUserIDForWishlist(), product_id: productID }, getBearerTokenConfig());
        if (submissionResult.status) {
            dispatch({
                type: 'ADD_WISHLIST_ITEM_SUCCESS',
                payload: submissionResult.data.item
            });

            // if (location.pathname !== '/wishlist') {
            //     setTimeout(() => {
            //         DOMHelpers.fixedBody();
            //         toast.success(<ToastWishlistItemAdded />)
            //         setTimeout(() => {
            //             DOMHelpers.relativeBody();
            //         }, 5000);
            //     }, 100);
            // }
        } else {
            dispatch({
                type: "ADD_WISHLIST_ITEM_ERROR"
            });
        }
    }

    async function removeWishlistItem(productID) {

        dispatch({
            type: "REMOVE_WISHLIST_ITEM_REQUEST"
        });
        const submissionResult = await APIs.removeWishlistItem({ user_id: getUserIDForWishlist(), product_id: productID }, getBearerTokenConfig());
        if (submissionResult.status) {
            dispatch({
                type: 'REMOVE_WISHLIST_ITEM_SUCCESS',
                payload: productID
            });
        } else {
            dispatch({
                type: "REMOVE_WISHLIST_ITEM_ERROR"
            });
        }
    }


    async function moveWishlistItemToCart(productID) {
        // console.log("moveWishlistItemToCart called on " + productID);
        dispatch({
            type: "REMOVE_WISHLIST_ITEM_REQUEST"
        });
        const submissionResult = await APIs.moveWishlistItemToCart({ user_id: getUserIDForWishlist(), product_id: productID, 'guest_session_token': getGuestSessionToken() }, getBearerTokenConfig());
        // console.log('moveWishlistItemToCart submissionResult', submissionResult);
        if (submissionResult.status) {
            dispatch({
                type: 'REMOVE_WISHLIST_ITEM_SUCCESS',
                payload: productID
            });

            setCartItems(submissionResult.data.cart)

            if (submissionResult.data.guest_session_token) {
                localStorage.setItem("guest_session_token", JSON.stringify(submissionResult.data.guest_session_token));
            }

        } else {
            dispatch({
                type: "REMOVE_WISHLIST_ITEM_ERROR"
            });
        }
    }



    function getUserIDForWishlist() {
        let userID = null;
        if (userAuthState && userAuthState.user && userAuthState.user && userAuthState.user.token) {
            userID = userAuthState.user.id;
        } else {
            let wishlistUser = localStorage.getItem("wishlistUser");
            // console.log('wishlistUser', wishlistUser);
            if (wishlistUser && wishlistUser !== undefined && wishlistUser !== 'undefined') {
                wishlistUser = JSON.parse(wishlistUser)
            }
            if (wishlistUser && wishlistUser.id) {
                userID = wishlistUser.id;
            }
        }
        return userID;
    }

    function isItemInWishlist(product_id) {
        if (state && state.items && state.items.length) {
            const foundObject = (state.items.find(x => x.product_id === product_id));
            if (!isEmpty(foundObject) && foundObject.product_id === product_id) {
                return true
            } else {
                return false;
            }
        }
    }








    return (<WishlistContext.Provider value={{
        wishlist: state,
        fetchWishlist,
        addWishlistItem,
        removeWishlistItem,
        moveWishlistItemToCart,
        isItemInWishlist,
        getUserIDForWishlist
    }}>{children}</WishlistContext.Provider>)
}



