import axios from 'axios'

export const fetchNotificationsAPI = async (headers) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/notifications`, headers)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const removeNotificationAPI = async (notification_id, headers) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/notifications/remove`,
            { notification_id: notification_id },
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

