import React, { } from 'react'
import { Link } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
const SitemapExpendedMobile = ({ props, hideOverlay, itemState }) => {
  return (
    <>
      {itemState && (
        <>
          <ScrollArea
            speed={0.8}
            className="scrollContent siteMapScollForMobile"
            contentClassName="content"
            horizontal={false}
          >

            {itemState.item.map((type, index) => (

              <div
                className={`sitemapExpendedHolderMobile ${type.name === "Jnoonz Exclusive" ? 'isJnoonzExclusive' : ''}`}
                style={{ height: "auto" }} key={type.id}>
                <div key={type.id}>
                  <div className="typesName">
                    {type.name}
                  </div>
                  <div className="typesImage hasShadow">
                    <img
                      className="itemImage"
                      src={`${process.env.REACT_APP_BASE_URL}${type.image}`}
                      alt={type.name}
                      title={type.name}
                    />
                  </div>
                  <>
                    {type.child.map((category, index) => (
                      <div className="categoryContainer" key={category.id} >
                        <div className="categoryName">
                          {category.name}
                        </div>
                        <>
                          {(category.child && category.child.length) ? (

                            <div className="subCategoryContainer">
                              {category.child.map((subCategory, scindex) => (
                                <Link to={`/products?type=${type.slug}&category=${category.slug}&subCategory=${subCategory.slug}`}
                                  key={subCategory.id}
                                  className="colLastLinks"
                                  onClick={() => {
                                    hideOverlay()
                                  }}
                                >{subCategory.name}</Link>
                              ))}
                            </div>

                          ) : (null)}
                        </>
                      </div>

                    ))}
                  </>

                </div>
              </div>
            ))}

          </ScrollArea>
        </>
      )}


    </>
  )
}
export default SitemapExpendedMobile