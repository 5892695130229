import React, { useContext, useState, useReducer, useEffect } from 'react'
import '../Messages.scss'
import { Container, Modal, ListGroup, Row, Button, Col } from 'react-bootstrap'
import MessagesItem from '../MessagesItem'
import SendMessage from './SendMessage'
import { ClosePopup } from '../../../components/CloseOverlayButton/CloseOverlay'
import { itemReducer, itemInitialState } from "../../../reducers/Reducers"
import { AuthContext } from '../../../contexts/AuthContext'
import { SkeletonEmailItem } from '../../../components/Skeleton/Skeleton';
import { ErrorComponent, ItemNotFoundDynamic } from '../../../components/Alert/Alert'
import { Link } from 'react-router-dom'
import { isTrue } from "../../../helpers/DOMHelpers"
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getMessagesAPI } from '../../../apis/buyerMessagesAPIs'

const BuyerMessages = () => {
    const { getFrontendMessage } = useContext(GlobalContext);

    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const [showPopup, setShowPopup] = useState(false)
    const { getBearerTokenConfig } = useContext(AuthContext);

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [messages, setMessages] = useState([])




    const loadMessages = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const apiResult = await getMessagesAPI(`page=${currentPage}`, getBearerTokenConfig());
        console.log(apiResult);
        if (apiResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: []
            });
            setMessages([...messages, ...apiResult.data.items]);
            setTotalPages(apiResult.data._meta.pageCount)
        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            });
        }
    }
    useEffect(() => {
        if (currentPage > 0) {
            loadMessages();
        }

        if (currentPage === 0) {
            setMessages([]);
        }
    }, [currentPage]);


    const loadMore = () => {
        setLoadMoreLoading(true)
        setTimeout(function () {
            setCurrentPage(currentPage + 1);
            setLoadMoreLoading(false)
        }, 1500)
    }
    return (
        <>
            <Container className="dashboardMessages">
                <Row className="align-items-center">
                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                        <div className="pageHeading">{getFrontendMessage('Dashboard', 'Buyer Messages', 'Heading', 'Text')}</div>
                        <div className="caption2">{getFrontendMessage('Dashboard', 'Buyer Messages', 'Sub Heading', 'Text')}</div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                        <div className="buttonContainer mb-4 textRight textAlignInheritMobile">
                            <Button size="sm"
                                variant="PinkButton"
                                className="lessCorners uppercase"
                                onClick={() =>
                                    setShowPopup(true)
                                }>
                                {getFrontendMessage('Dashboard', 'Buyer Messages', 'Button Text', 'Text')}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className="dashboardMessagesContainer">
                    <div className=" customTab customTabStyle2 mt-4">
                        {(itemState.isFetching && messages.length <= 0) ? (
                            <SkeletonEmailItem />
                        ) : itemState.hasError ? (
                            <ErrorComponent />
                        ) : (
                            <>
                                {(messages && messages.length > 0) ? (
                                    <>
                                        <ListGroup variant="flush" as="ul">
                                            {messages.map((message, index) => (
                                                <React.Fragment key={index}>
                                                    <>
                                                        <ListGroup.Item as="li" variant="transparent"
                                                            className={`pr-2 pl-2 messageItemHolder ${message.last_message.sender_type === 'Admin' ? "receivedMessage" :
                                                                [
                                                                    message.last_message.sender_type === 'Buyer' ? "sentMessage" : [
                                                                    ]]}`}
                                                        >
                                                            <Link to={`/member/messages/${(message.last_message.reply_to) ? message.last_message.reply_to : message.last_message.id}`} className={`${isTrue(message.last_message.is_read) ? 'read' : 'unread'}`}>
                                                                <MessagesItem key={message.id} message={message.last_message} messageid={message.id} />
                                                            </Link>
                                                        </ListGroup.Item>
                                                    </>

                                                </React.Fragment>
                                            ))}
                                        </ListGroup>
                                        {loadMoreLoading ? <SkeletonEmailItem /> : ''}
                                        {totalPages !== currentPage &&
                                            <div className="loadMoreButton">
                                                <Button
                                                    className="hasShadow"
                                                    variant="whiteButton"
                                                    size="sm"
                                                    onClick={() => {
                                                        loadMore()
                                                    }}
                                                >
                                                    load more
                                                </Button>
                                            </div>
                                        }
                                    </>
                                ) : (
                                    <ItemNotFoundDynamic
                                        className="itemNotFound textCenter mt-4"
                                        msgTitle={getFrontendMessage('Dashboard', 'Buyer Messages', 'No Records', 'Text')}
                                    />
                                )}
                            </>
                        )}

                    </div>

                </div>
            </Container>

            <Modal
                show={showPopup}
                centered
                onHide={() =>
                    setShowPopup(false)
                }
                animation={false}
                className="customModal"
                size="md"
            >
                <Modal.Body>
                    <div className="closeButtonContainer floatButton">
                        <ClosePopup
                            setShowPopup={setShowPopup}
                        />
                    </div>
                    <div>
                        <SendMessage setCurrentPage={setCurrentPage} setShowPopup={setShowPopup} />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default BuyerMessages
