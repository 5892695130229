import React, { useState } from 'react'
import AttributeFilter from './AttributeFilter';

const FilterByAttributes = ({ availableFilters, appliedAttributeFilters, applyNewAttributeFilter }) => {
    //console.log('appliedAttributeFilters', appliedAttributeFilters);



    return (
        <>
            {
                (availableFilters && availableFilters.attributes && availableFilters.attributes.length)
                &&
                <>
                    {availableFilters.attributes.map((attribute) => (

                        <AttributeFilter
                            attribute={attribute}
                            appliedAttributesValues={(appliedAttributeFilters && appliedAttributeFilters[attribute.name]) ? appliedAttributeFilters[attribute.name] : undefined}
                            applyNewAttributeFilter={applyNewAttributeFilter}
                        />

                    ))}
                </>


            }
        </>
    )
}

export default FilterByAttributes