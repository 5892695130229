import React from "react";
// import {AuthContext} from "../../contexts/AuthContext";
// import {StoreContext} from "../../contexts/StoreContext"
// import ProductsItem from "../../components/ProductItem/ProductItem";
import HomeMainCarousel from "../../components/HomeMainCarousel/HomeMainCarousel";
import JnoonzofthedaySlider from '../../components/JnoonzofthedaySlider/JnoonzofthedaySlider';
import JnoonzExclusiveSlider from "../../components/JnoonzExclusiveSlider/JnoonzExclusiveSlider";
import LatestProductSlider from "../../components/LatestProductsSlider/LatestProductsSlider";
import TopSellerzSlider from "../../components/TopSellerzSlider/TopSellerzSlider";
import Spotlights from "../../components/Spotlights/Spotlights";
import ShopByCategory from '../../components/ShopByCategory/ShopByCategory'
import { Container } from "react-bootstrap";


import C3SupplierProducts from "../../components/C3Supplier/C3SupplierProducts";

export const Home = () => {
  // const { productState } = useContext(StoreContext);
  // const { userAuthState } = useContext(AuthContext);

  return (
    <>
      <HomeMainCarousel />
      <div className="page negativeContainerMarginTop">
        <JnoonzofthedaySlider />

        <JnoonzExclusiveSlider />
        <Container className="p-0">
          <ShopByCategory position="Jnoonz-Deals" />

          <LatestProductSlider />
          <C3SupplierProducts />
          <ShopByCategory position="Latest-Products" />
          <TopSellerzSlider />
          <ShopByCategory position="Top-Sellings" />

          <Spotlights />
          <ShopByCategory position="Spotlights" />
        </Container>
      </div>
    </>
  );
};

export default Home;