import React, { useReducer, useState, useContext } from 'react'
import { Form, Button, Col, Container } from 'react-bootstrap'
import { Formik } from 'formik'
import FormTextArea500 from '../../components/FormPartials/FormTextArea500'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'
import { AuthContext } from '../../contexts/AuthContext'
import { addProdutFeedbackAPI } from '../../apis/buyerOrdersAPIs'
import { orderFeedbackValidation } from '../../components/FormPartials/Validation'
import { getImageValidationError } from "../../helpers/CommonHelper"
import './customfileupload.scss'
const ProductFeedback = ({ productID, itemID, orderID, setShowAddFeedbackPopup, feedbackSubmittedForItem }) => {
    const { getBearerTokenConfig } = useContext(AuthContext);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [count, setCount] = useState(0);
    const [picture, setPicture] = useState({
        preview: null,
        asFile: null
    });
    const [imageValidationError, setImageValidationError] = useState('')



    return (
        <Container className="orderReview">
            <div className="formContainer mt-2">
                {formState && formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle="Your feedback is submitted."
                                msgBody="We will check your feedback and will contact you soon."
                            />
                        ) : (
                            <>
                                <div className="customHeadingForForm">
                                    <p>
                                        Enter your feedback related to the item.
                                    </p>
                                </div>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        file: "",
                                        comments: "",
                                    }}
                                    validationSchema={orderFeedbackValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        console.log('submit form called', values);
                                        if (imageValidationError)
                                            return;


                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });


                                        const formData = new FormData();
                                        formData.append("product_id", productID);
                                        formData.append("item_id", itemID);
                                        formData.append("order_id", orderID);
                                        formData.append("comments", values.comments);
                                        formData.append("file", picture.asFile);



                                        const submissionResult = await addProdutFeedbackAPI(formData, getBearerTokenConfig());
                                        console.log(submissionResult);
                                        if (submissionResult.status) {
                                            formDispatch({
                                                type: 'FORM_SUBMISSION_SUCCESS',
                                            });
                                            feedbackSubmittedForItem(itemID);
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                setShowAddFeedbackPopup(false);
                                            }, 5000);
                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto" encType="multipart/form-data">
                                            {formState.hasSubmissionError && (
                                                <FormSubmissionFailed />
                                            )}
                                            <Col className={`mb-2 p-0 mendatory ${imageValidationError ? "errorParent" : ""}`}>
                                                <input
                                                    className="custom_file_upload mt-2"
                                                    type="file"
                                                    name="file"
                                                    onChange={(event) => {
                                                        console.log(event.target.files[0]);
                                                        let error = getImageValidationError(event.target.files[0])
                                                        setImageValidationError(error);
                                                        setPicture({
                                                            preview: URL.createObjectURL(event.target.files[0]),
                                                            asFile: event.target.files[0]
                                                        })
                                                    }}
                                                />
                                                {(imageValidationError) && <div className="errorMessage">{imageValidationError}</div>}
                                            </Col>
                                            <Col className={`p-0 mendatory ${errors.review && touched.review ? "errorParent" : ""}`}>
                                                <FormTextArea500
                                                    controlId="comments"
                                                    label="Add your comments"
                                                    name="comments"
                                                    type="text"
                                                    as="textarea"
                                                    rows={3}
                                                    value={values.review}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setCount(e.target.value.length);
                                                    }}
                                                    onBlur={handleBlur}
                                                    count={count}
                                                />
                                            </Col>

                                            <Col className="p-0 buttonContainer">
                                                <Button variant="PurpleButton"
                                                    className="hasPadding uppercase hasShadow bold"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Submit your Feedback
                                                </Button>
                                            </Col>

                                            <Col className="p-0 mt-3 textRight">
                                                <MendatoryFieldMsg />
                                            </Col>

                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </Container >
    )
}

export default ProductFeedback