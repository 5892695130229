import React, { useContext } from 'react'
import { AuthContext } from "../../contexts/AuthContext"
import { PopupContext } from "../../contexts/PopupContext"
import { Modal, Row, Col } from 'react-bootstrap'
import Login from '../../pages/Login/Login'
import WishlistOverlay from '../Wishlist/WishlistOverlay'
import CartOverlay from '../Cart/CartOverlay'
import Signup from '../../pages/Signup/Signup'
import { IoCloseCircleSharp } from "react-icons/io5";
import UserOverlay from '../UserOverlay/UserOverlay'
import WishlistEmailAuth from '../Wishlist/WishlistEmailAuth';
import { Bubble } from '../Bubble/Bubble'
import SigninSlider from '../../pages/Login/SigninSlider'
import SignupSlider from '../../pages/Signup/SignupSlider'
import { BackdropContext } from "../../contexts/BackdropContext"
import { relativeBody } from "../../helpers/DOMHelpers"
const HeaderUserButtons = (props) => {
    const { userAuthState } = useContext(AuthContext);
    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const { popupState, popupDispatch } = useContext(PopupContext);

    return (
        <>
            <div className="headeruserButtons relative">
                {(userAuthState.user && userAuthState.user.type === 'Member') &&
                    <Bubble
                        title="You are successfully logged in."
                        caption="Check our latest deals and products."
                        className="displayloggedin"
                    />
                }
                <ul>
                    <li>
                        <WishlistOverlay />
                    </li>
                    <li>
                        <CartOverlay />
                    </li>
                    <li>
                        <UserOverlay />

                    </li>
                </ul>
            </div>

            <Modal
                show={popupState.openSigninPopup}
                centered
                animation={false}
                className="authModal signinModal"
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <div className='closeModalContainer'
                onClick={() => {
                    popupDispatch({ type: "POPUP_SIGNIN_CLOSE" })
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    relativeBody();
                }
                }
                >
                    <IoCloseCircleSharp className="closeModal" />
                </div>
                <Modal.Body>
                    <Row style={{ height: "100%" }} className="align-items-center">
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="bannerAreaContainer">
                            <SigninSlider />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="signin_signupArea">
                                <h4 className="signin_signupAreaHeading">My JNooNZ</h4>
                                <Login />
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>

            <Modal
                show={popupState.openSignupPopup}
                centered
                animation={false}
                className="authModal signupModal"
                size="lg"
            >
            <div className='closeModalContainer'
            onClick={() => {
            popupDispatch({ type: "POPUP_SIGNIN_CLOSE" })
            backdropDispatch({
            type: "FETCH_BACKDROP_CLOSE",
            });
            relativeBody();
            }
            }
            >
            <IoCloseCircleSharp className="closeModal" />
            </div>
                <Modal.Body>

                    <Row style={{ height: "100%" }} className="align-items-center">
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="bannerAreaContainer">
                            <SignupSlider />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="signin_signupArea">

                                <Signup />
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>

            <Modal
                size="sm"
                show={popupState.openWishlistEmailPopup}
                centered
                onHide={() =>
                    popupDispatch({ type: "POPUP_WISHLISTEMAIL_CLOSE" })
                }
                animation={false}
                className=""
            >
                <Modal.Body>
                    <div className="formContainer">
                        <WishlistEmailAuth />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default HeaderUserButtons;