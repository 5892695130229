import React, { useState, useEffect } from "react";
const Clock = ({ clockTime }) => {
  const [state, setState] = useState(clockTime);


  //console.log('Clock Componenet calledddddddd');
  useEffect(() => {
    setState(clockTime);
  }, [clockTime])






  function leading0(num) {
    return num < 10 ? "0" + num : num;
  };



  return (

    <div className="timerComponentContainer">

      <ul className="timerClock">
        {state.days !== 0 &&
          <>
            <li
              className="clockHolder"
            >
              <span className="timerHeading">
                DD
              </span>
              <span className="timeHolder">
                {leading0(state.days)}
              </span>
            </li>
            <li className="splitHolder"
            >
              <div className="split">:</div>
            </li>
          </>
        }

        <li
          className="clockHolder"
        >
          <span className="timerHeading">
            HR
          </span>
          <span className="timeHolder">
            {leading0(state.hours)}
          </span>
        </li>
        <li className="splitHolder"
        >
          <div className="split">:</div>
        </li>
        <li
          className="clockHolder"
        >
          <span className="timerHeading">
            MN
          </span>
          <span className="timeHolder">
            {leading0(state.minutes)}
          </span>
        </li>
        <li
          className="splitHolder"
        >
          <div className="split">:</div>
        </li>
        <li
          className="clockHolder"
        >
          <span className="timerHeading">
            SC
          </span>
          <span className="timeHolder">
            {leading0(state.seconds)}
          </span>
        </li>
      </ul>

    </div>
  )
}

export default Clock
