import React, { useEffect, useReducer } from "react";
import { Row } from "react-bootstrap";
import "./ShopByCategory.scss";
import CategoryItem from "./CategoryItem";

import { itemReducer, itemInitialState } from "../../reducers/Reducers";
import { getProductTypeSpotlightAPI } from "../../apis/generalAPIs";
const ShopByCategory = ({ position }) => {
  //console.log(position);
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);

  const loadContent = async () => {
    if (position) {
      const apiResult = await getProductTypeSpotlightAPI(position);
      //console.log(apiResult)
      if (apiResult.status) {
        itemDispatch({
          type: "FETCH_SUCCESS",
          payload: apiResult.data,
        });
      }
    }

  };

  useEffect(() => {

    loadContent();
  }, []);


  return (
    <>
      {(itemState.item && itemState.item.items && itemState.item.items.length > 0) &&
        <div className="categoryContainer">
          <Row>
            {itemState.item.items.map((type, index) => {
              if (type.categories && type.categories.length > 0) {
                return <CategoryItem type={type} key={index} />
              }
            })}

          </Row>
        </div>
      }
    </>
  );
};
export default ShopByCategory;
