import React, { useContext } from 'react'
import { FaTrashAlt } from "react-icons/fa"
import { WishlistContext } from "../../contexts/WishlistContext"

const RemoveFromWishlist = ({ product_id }) => {
    const { removeWishlistItem } = useContext(WishlistContext)
    return (
        <>
            <FaTrashAlt
                className="removeFromCart ml-3"
                title="Remove From Wishlist"
                onClick={() => {
                    removeWishlistItem(product_id);
                }} />
        </>
    )
}

export default RemoveFromWishlist
