export const WishlistReducer = (state, action) => {

    switch (action.type) {

        case "FETCH_WISHLIST_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_WISHLIST_ERROR":
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case "FETCH_WISHLIST_SUCCESS":
            return {
                ...state,
                isFetching: false,
                hasError: false,
                items: action.payload.items,
            };




        case "ADD_WISHLIST_ITEM_REQUEST":
            return {
                ...state,
                isAdding: true,
                hasError: false,
            };
        case "ADD_WISHLIST_ITEM_SUCCESS":
            return {
                ...state,
                hasError: false,
                items: [...state.items, action.payload]
            };
        case "ADD_WISHLIST_ITEM_ERROR":
            return {
                ...state,
                isAdding: false,
                hasError: true
            };


        case "REMOVE_WISHLIST_ITEM_REQUEST":
            return {
                ...state,
                isRemoving: true,
                hasError: false,
            };
        case "REMOVE_WISHLIST_ITEM_SUCCESS":
            return {
                ...state,
                isRemoving: false,
                items: state.items.filter(item => item.product_id !== action.payload)
            };
        case "REMOVE_WISHLIST_ITEM_ERROR":
            return {
                ...state,
                isRemoving: false,
                hasError: true
            };










        default:
            return state;
    }
};