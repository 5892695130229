import React from 'react'
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"

const SpotlightsItem = ({ item, props }) => {


    const handleBoxClick = (item) => {
        if (item.link_type === 'Box') {
            window.location.href = item.link
        }
    }

    return (
        <>

            <Col lg={4} sm={6} xs={12} className="mb-0">

                <div
                    onClick={() => handleBoxClick(item)}
                    className={`promotionalBanner ${item.template === 1 ? "hasPurpleBg" :
                        [item.template === 2 ? "hasGreenBg" : [
                            item.template === 3 ? "hasPinkBg" : ""
                        ]]}  ${item.link_type === 'Box' ? "hasLink" : ""} `}
                    style={{ marginLeft: 0 }}
                >
                    <div className="promotionalContent">
                        <div className="title">
                            {item.title}
                        </div>
                        {item.sub_title &&
                            <div className="subTitle">
                                {item.sub_title}
                            </div>
                        }


                        {(item.link_type === 'Button') &&
                            <a
                                className={`btn hasPadding hasExtraPadding uppercase hasShadow btn-lg bold mt-4 ${item.template === 1 ? "btn-PinkButton" :
                                    [item.template === 2 ? "btn-whiteButton tertiaryPink" : [
                                        item.template === 3 ? "btn-PurpleButton" : ""
                                    ]]}`}
                                href={item.link}
                                target="_blank" 
                            >
                                {item.button_text}
                            </a>
                        }

                    </div>

                    <img
                        className={`promotionalImage ${item.template === 1 ? "exclusiveImage" :
                            [item.template === 2 ? "sendagiftImage" : [
                                item.template === 3 ? "itemanddeals" : ""
                            ]]}`}
                        src={`${item.background_image}`}
                        alt={item.title}
                        title={item.title}
                    />

                </div>

            </Col>

        </>
    )
}

export default SpotlightsItem;
