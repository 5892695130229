import React from 'react'
import { Link } from "react-router-dom"
import Quantity from '../Quantity/Quantity'
import RemoveFromCart from './RemoveFromCart/RemoveFromCart'
import Price from './../ProductItem/Price';
const CartItem = ({ item, cartDispatch, props }) => {
    return (
        <>
            <div className="itemImageHolder">
                <Link to={`/product/${item.product.slug}`}>
                    <img
                        className="itemImage"
                        src={`${process.env.REACT_APP_BASE_URL}${item.product.image}`}
                        alt={item.product.title}
                        title={item.product.title}
                    />
                </Link>
            </div>

            <div className="itemDetails">
                <ul>

                    <li className="itemCategory">
                        {item.product.category.name === "" ? (
                            <p>{item.product.type.name} </p>
                        ) : (
                            <>
                                {item.product.subCategory.name === "" ? (
                                    <p>{item.product.category.name}</p>
                                ) : (
                                    <p>{item.product.subCategory.name}</p>
                                )}
                            </>
                        )}

                    </li>
                    <li className="itemTitle">
                        {item.product.title}
                    </li>
                    <li>
                        <div className="cartItemBottom">
                            <ul>
                                <li className="priceContainer">
                                    <Price price={item.product.price} oldPrice={item.product.old_price} />
                                </li>
                                <li>
                                    <ul className="cartItemActionButtons">
                                        <li className="priceContainer">
                                            <div className="adjustItemQuantity textCenter">
                                                <Quantity itemID={item.id} productQuantity={item.quantity} />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="removeFromCart textCenter">
                                                <RemoveFromCart
                                                    cart_item_id={item.id}
                                                    cartDispatch={cartDispatch}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </li>
                </ul>
            </div>
        </>
    )
}

export default CartItem;