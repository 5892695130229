import React, { useState, useEffect } from 'react'
import { fetchTopSearchedProductsAPI } from '../../apis/generalAPIs'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

const TopSearchedProducts = ({ handleCloseButton }) => {
    let history = useHistory();
    const [topSearchedItems, setTopSearchedItems] = useState([])
    const loadTopSearchedProducts = async () => {
        const submissionResult = await fetchTopSearchedProductsAPI();
        if (submissionResult.status) {
            setTopSearchedItems(submissionResult.data)
        }
    }
    useEffect(() => {
        loadTopSearchedProducts();
    }, [])
    return (
        <ul className="topSearchedContainer">

            {(topSearchedItems && topSearchedItems.length > 0) &&
                <>
                    <li>
                        <div className="searchResultInHeading mb-2">
                            Top Searched
                        </div>
                    </li>
                    {topSearchedItems.map((item, index) => (
                        <li key={item.id}>
                            <div
                                className="hasCursor"
                                onClick={(event) => {
                                    handleCloseButton();
                                    history.push(`/product/${item.slug}`)
                                }}
                            >
                                <Row className="align-items-center">
                                    <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                                        <div className="topSearchedItemImage">
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                                alt={item.title}
                                                title={item.title}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl={9} lg={9} md={9} sm={9} xs={12} className="pl-0">
                                        <p className="p-0 m-0">{item.title}</p>
                                    </Col>
                                </Row>
                            </div>
                        </li>
                    ))}
                </>
            }
        </ul>
    )
}

export default TopSearchedProducts
