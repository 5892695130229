import React, { useContext, useState } from 'react'
import './Checkout.scss'
import { Container, Button, Col, Row, Tab, Nav, Card } from 'react-bootstrap'
import InnerPageHeader from "../../components/InnerPageHeader/InnerPageHeader"
import PaymentOptions from './PaymentOptions'
import CartCheckout from "../../components/Cart/CartCheckout"
import { CartContext } from "../../contexts/CartContext"
import ShippingAddress from '../../components/ShippingAddress/ShippingAddress'
import StickyBox from "react-sticky-box";
import { Link } from 'react-router-dom'
import CartPreview from '../../components/Cart/CartPreview'
import { GlobalContext } from './../../contexts/GlobalContext';

import { ContainerLoading } from '../../components/Loading/Loading'
import NoItemFoundInCart from './../../components/Cart/NoItemFoundInCart';
import { AuthContext } from "../../contexts/AuthContext"

const Checkout = () => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const { userAuthState } = useContext(AuthContext);

   



    const { cart, memberCheckout } = useContext(CartContext);
    const [tabKey, setTabKey] = useState('cart');
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    // Frontend Messages - Checkout //

    const checkoutCartTabText = getFrontendMessage('Checkout', 'Checkout Tab', 'Cart', 'Text')
    const checkoutShippingTabText = getFrontendMessage('Checkout', 'Checkout Tab', 'Shipping', 'Text')
    const checkoutPaymentMethodTabText = getFrontendMessage('Checkout', 'Checkout Tab', 'Payment Method', 'Text')
    const checkoutPreviewTabText = getFrontendMessage('Checkout', 'Checkout Tab', 'Preview', 'Text')
    const editCartText = getFrontendMessage('Checkout', 'Edit Cart', 'Message', 'Text')
    const paymentMethodMsg = {
        heading: getFrontendMessage('Checkout', 'Payment Method', 'Heading', 'Text'),
        subHeading: getFrontendMessage('Checkout', 'Payment Method', 'Sub Heading', 'Text'),
    }





    return (
        <>
            <InnerPageHeader />
            {(cart.isFetching) && <ContainerLoading />}

            {orderSuccess && <Container>
                <Col className="confirmOrder innerpageCard hasMarginTop hasShadow">
                    <div className="confirmOrderMsg">
                        <div className="successIcon center mb4">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none"
                                    stroke="#73AF55" strokeWidth="6"
                                    strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className="path check"
                                    fill="none" stroke="#73AF55"
                                    strokeWidth="6" strokeLinecap="round"
                                    strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </div>
                        <div className="heading">Thank You.</div>
                        <div className="msgBody">
                            <p>
                                Your order has been received and will be processed soon.
                            </p>
                            <p>
                                
                                Your order number is: <strong className="secondaryPink">{orderNumber}</strong>
                            </p>
                        </div>
                        <Link to="/products" className="btn btn-PinkButton btn-lg hasPadding hasShadow uppercase bold mt-4">CONTINUE SHOPPING</Link>
                    </div>


                    <div className="additionalMsgAfterSubmission">
                        <div>
                            <p>We have sent an invoice with your order's details on your registered email address.</p>
                            <p>If you do not receive the message within a few minutes of your order, please check your Bulk Mail / Spam / Junk folders just in case the email got delivered there instead of your inbox. </p>
                            <p>To avoid future emails delivery issues we would recommend you to <strong> whitelist the domain : jnoonz.com </strong></p>
                        </div>
                        <div>
                            if you have any query, Kindly <Link to="/contact" className="link pink">Contact us </Link>
                        </div>
                    </div>

                </Col>
            </Container>}
            {!orderSuccess &&
                <>
                    {(cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0) ?


                        <Container>



                            <Col className="checkout innerpageCard hasMarginTop hasShadow">
                                <div className="pageHeading mb-4">
                                    Checkout - <span className="secondaryPink">
                                        {cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 ?
                                            <>
                                                {cart.cartItems.items.length} item(s)
                                            </>
                                            :
                                            null
                                        }
                                    </span>
                                </div>

                                <Row>
                                    <Col xl={8} lg={8} sm={7} xs={12} md={12}>
                                        <div className="customTab horizontal horizontalTabThemeTwo">
                                            <Tab.Container
                                                activeKey={tabKey}
                                                onSelect={(k) => {
                                                    if (
                                                        tabKey === 'shippingAddress'
                                                        && (k === "paymentMethod" || k === "itemandShipping")
                                                        && (!cart.cartItems.shippingAddress || !cart.cartItems.shippingAddress.id)
                                                    ) {
                                                        return;
                                                    }

                                                    if (
                                                        tabKey === 'paymentMethod'
                                                        && k === "itemandShipping"
                                                        && !cart.cartItems.paymentMethod
                                                    ) {
                                                        return;
                                                    }


                                                    setTabKey(k)

                                                }}
                                                transition={false}>
                                                <Row>
                                                    <Col sm={12} md={3} lg={3}>
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="cart" className="uppercase">{checkoutCartTabText}</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="shippingAddress" className="uppercase"> {checkoutShippingTabText} </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="paymentMethod" className={`  uppercase  ${(!cart.cartItems.shippingAddress || !cart.cartItems.shippingAddress.id) && 'disabled noclick'}`}> {checkoutPaymentMethodTabText} </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="itemandShipping" className={`  uppercase  ${(!cart.cartItems.paymentMethod) && 'disabled noclick'}`}> {checkoutPreviewTabText} </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={12} md={9} lg={9}>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="cart">
                                                                <Row className="align-items-center mb-4">
                                                                    <Col xl={9} lg={8} md={6} sm={8} xs={6}>
                                                                        <div className="pageHeading">{checkoutCartTabText}</div>
                                                                    </Col>
                                                                    <Col xl={3} lg={4} md={6} sm={4} xs={6} className="textRight">
                                                                        <Link to="/cart" className="link pink">{editCartText}</Link>
                                                                    </Col>
                                                                </Row>
                                                                <CartCheckout />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="shippingAddress">
                                                                <ShippingAddress />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="paymentMethod">
                                                                <PaymentOptions
                                                                    paymentMethodMsg={paymentMethodMsg}
                                                                />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="itemandShipping">
                                                                <div>
                                                                    <Row>
                                                                        <Col xl={9} lx={9} xs={12}>
                                                                            <div className="pageHeading mb-3">{checkoutPreviewTabText}</div>
                                                                        </Col>
                                                                        <Col xl={3} lx={3} xs={12} className="textRight hasCursor link purple">
                                                                            <span onClick={(k) => setTabKey("cart")}>{editCartText}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <CartPreview />
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </div>
                                    </Col>
                                    <Col xl={4} lg={4} sm={5} xs={12} md={12} className="orderSummaryColumn">
                                        <StickyBox offsetTop={120} offsetBottom={20}>
                                            <Card
                                                bg="whiteColor"
                                                className="orderSummaryContainer"
                                            >
                                                <Card.Body>
                                                    {cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 &&
                                                        <>
                                                            <div className="orderSummaryHeading">ORDER SUMMARY</div>
                                                            <div className="orderSummary">
                                                                <ul>
                                                                    <li>
                                                                        <span>Subtotal</span>
                                                                        <span>{cart.cartItems.selectedCurrency ? cart.cartItems.selectedCurrency.symbol : '$'}{cart.cartItems.invoice.subTotalWithAdditional}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Shipping Fee</span>
                                                                        <span>{cart.cartItems.selectedCurrency ? cart.cartItems.selectedCurrency.symbol : '$'}{cart.cartItems.invoice.shippingCharges}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="orderSummaryTotal">
                                                                <ul>
                                                                    <li>
                                                                        <span><span className="totalText">Total</span> (Inclusive of VAT)</span>
                                                                        <span className="total">{cart.cartItems.selectedCurrency ? cart.cartItems.selectedCurrency.symbol : '$'}{cart.cartItems.invoice.total}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {(cart && cart.cartItems && cart.cartItems.shippingAddress && cart.cartItems.shippingAddress.id) &&
                                                                <div className="orderSummaryShipTo">
                                                                    <ul>
                                                                        <li>
                                                                            <span className="heading">Ship To</span>
                                                                            <span className="edit link green"
                                                                                onClick={(k) => setTabKey("shippingAddress")}
                                                                            >
                                                                                Change
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <p>{cart.cartItems.shippingAddress.first_name + ' ' + cart.cartItems.shippingAddress.last_name}</p>
                                                                    <p>{cart.cartItems.shippingAddress.mobile}</p>
                                                                    <p>{cart.cartItems.shippingAddress.email}</p>
                                                                    <p>{cart.cartItems.shippingAddress.address}</p>
                                                                    <p>{cart.cartItems.shippingAddress.city + ", " + cart.cartItems.shippingAddress.country}</p>
                                                                </div>
                                                            }


                                                        </>
                                                    }
                                                </Card.Body>
                                            </Card>
                                            <Col className="textRight mt-4 p-0">
                                                {tabKey === 'cart' &&
                                                    <>
                                                        <Button
                                                            onClick={(k) => setTabKey("shippingAddress")} className="hasPadding hasShadow uppercase bold" variant="PurpleButton" size="lg">
                                                            Continue
                                                        </Button>
                                                    </>
                                                }
                                                {tabKey === 'shippingAddress' &&
                                                    <Button
                                                        className={`hasPadding hasShadow uppercase bold ${(!cart.cartItems.shippingAddress || !cart.cartItems.shippingAddress.id) && 'disabled noclick'}`}
                                                        onClick={(k) => {
                                                            if (cart.cartItems.shippingAddress && cart.cartItems.shippingAddress.id) {
                                                                setTabKey("paymentMethod")
                                                            }

                                                        }}
                                                        variant="PurpleButton" size="lg"
                                                    >
                                                        Continue
                                                    </Button>
                                                }
                                                {tabKey === 'paymentMethod' &&
                                                    <>
                                                        <Button
                                                            className={`hasPadding hasShadow uppercase bold ${(!cart.cartItems.paymentMethod) && 'disabled noclick'}`}
                                                            onClick={(k) => {
                                                                if (cart.cartItems.paymentMethod) {
                                                                    setTabKey("itemandShipping")
                                                                }
                                                            }}
                                                            variant="PurpleButton" size="lg">
                                                            Continue
                                                        </Button>
                                                    </>
                                                }
                                                {tabKey === 'itemandShipping' &&
                                                    <>
                                                        <Button
                                                            className={`hasPadding hasShadow uppercase bold ${(!cart.cartItems.shippingAddress || !cart.cartItems.shippingAddress.id || !cart.cartItems.paymentMethod) && 'disabled noclick'}`}
                                                            onClick={async (k) => {
                                                                if (cart && cart.cartItems && cart.cartItems.shippingAddress && cart.cartItems.shippingAddress.id && cart.cartItems.paymentMethod) {
                                                                    const submissionResult = await memberCheckout();
                                                               

                                                                    if (submissionResult.status) {
                                                                        setOrderSuccess(true);
                                                                        setOrderNumber(submissionResult.data.order_id);
                                                                    }
                                                                }
                                                            }}
                                                            variant="PurpleButton" size="lg">
                                                            Confirm Order
                                                        </Button>
                                                    </>
                                                    // <Link
                                                    //     className={`btn btn-PurpleButton btn-lg hasPadding hasShadow uppercase bold ${(!cart.cartItems.shippingAddress || !cart.cartItems.shippingAddress.id || !cart.cartItems.paymentMethod) && 'disabled noclick'}`}
                                                    //     to="/confirmorder" >
                                                    //     Confirm Order
                                                    // </Link>
                                                }
                                            </Col>
                                        </StickyBox>
                                    </Col>
                                </Row>




                            </Col>
                        </Container>



                        :
                        <Container>
                            
                            <Col className="shoppingCart innerpageCard hasMarginTop hasShadow">
                                <NoItemFoundInCart />
                            </Col>
                        </Container>


                    }
                </>
            }





        </>
    )
}

export default Checkout
