import React, { useReducer, useState, useContext, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../FormPartials/FormInput'
import FormTextArea500 from '../FormPartials/FormTextArea500'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../Loading/Loading"
import SuccessMsg from '../FormPartials/SuccessMsg'
import { ErrorComponent } from '../Alert/Alert'
import MendatoryFieldMsg from '../FormPartials/MendatoryFieldMsg'
import TelephoneInput from '../FormPartials/TelephoneInput'
import { shippingAddressValidation } from '../FormPartials/Validation'
import CountriesInput from '../FormPartials/CountriesInput'
import { ShippingAddressContext } from '../../contexts/ShippingAddressContext';
import { GlobalContext } from './../../contexts/GlobalContext';
import { AuthContext } from '../../contexts/AuthContext'
import { CartContext } from '../../contexts/CartContext'
import { useRouteMatch } from 'react-router-dom'

const AddShippingAddress = ({ toggleShippingAddress }) => {
    const { addShippingAddressToCart } = useContext(CartContext);
    const { userAuthState } = useContext(AuthContext);
    const { addShippingAddress } = useContext(ShippingAddressContext);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [count, setCount] = useState(0);
    const [initialValues, setInitialValues] = useState({
        buyer_id: userAuthState.user.id,
        first_name: "",
        last_name: "",
        email: "",
        country_id: "",
        city: "",
        address: "",
        mobile: "",
    });
    let { url } = useRouteMatch();
    console.log(url);

    const { getFrontendMessage } = useContext(GlobalContext);
    const addShippingAddressHeading = getFrontendMessage('Checkout', 'Shipping Address', 'Form Heading', 'Text')





    return (
        <div>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}

                        {formState.isFormSubmitted ? <SuccessMsg msgTitle="Shipping address successfully saved." />
                            :
                            <>
                                <Row>
                                    <Col xl={8} lg={8} xs={12}>
                                        <div className="customHeadingForForm">
                                            {addShippingAddressHeading}
                                        </div>
                                    </Col>
                                    {/* <Col xl={4} lg={4} xs={12} className="textRight">
                                        <MendatoryFieldMsg />
                                    </Col> */}
                                </Row>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={shippingAddressValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const submissionResult = await addShippingAddress(values);
                                        if (submissionResult.status) {

                                            formDispatch({
                                                type: 'FORM_SUBMISSION_SUCCESS',
                                            });

                                            setTimeout(() => {
                                                toggleShippingAddress();
                                                // if (url === '/checkout') {
                                                //     addShippingAddressToCart(submissionResult.data.item.id);
                                                // }

                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });

                                            }, 5000);
                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }


                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form className="mx-auto form-row-full-touchDevices" onSubmit={handleSubmit}>
                                            <input type="hidden" name="buyer_id" value={values.buyer_id} />
                                            <Form.Row>
                                                <Col className={`mendatory ${errors.first_name && touched.first_name ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="first_name"
                                                        label="Receiver's First Name"
                                                        name="first_name"
                                                        type="text"
                                                        value={values.first_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                                <Col className={`mendatory ${errors.last_name && touched.last_name ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="last_name"
                                                        label="Receiver's Last Name"
                                                        name="last_name"
                                                        type="text"
                                                        value={values.last_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col className={`mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="receiverEmail"
                                                        label="Receiver's Email Address"
                                                        name="email"
                                                        type="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                                {<Col className={`mendatory ${errors.mobile && touched.mobile ? "errorParent" : ""}`}>
                                                    <TelephoneInput
                                                        label="Mobile"
                                                        name="mobile"
                                                    />
                                                </Col>}
                                            </Form.Row>
                                            <Form.Row>
                                                <Col className={`mendatory ${errors.city && touched.city ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="city"
                                                        label="Receiver's City"
                                                        name="city"
                                                        type="text"
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                                <Col className={`p-0 mendatory ${errors.country_id && touched.country_id ? "errorParent" : ""}`}>
                                                    <CountriesInput
                                                        listType='delivery'
                                                        controlId="country_id"
                                                        label="Select Receiver's Country"
                                                        name="country_id"
                                                        as="select"
                                                        value={values.country_id}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col className={`mendatory ${errors.address ? "errorParent" : ""}`}>
                                                    <FormTextArea500
                                                        controlId="address"
                                                        label="Receiver's Full Address"
                                                        name="address"
                                                        type="text"
                                                        as="textarea"
                                                        rows={3}
                                                        value={values.address}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            setCount(e.target.value.length);
                                                        }}
                                                        onBlur={handleBlur}
                                                        count={count}
                                                    />
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="mt-4 buttonContainer">
                                                <Button variant="whiteButton"
                                                    className="hasPadding uppercase hasShadow bold"
                                                    type="submit"
                                                    disabled={isSubmitting}

                                                >
                                                    Add Address
                                                </Button>
                                                <Button className="hasShadow hasPadding uppercase" variant="whiteButton" size=""
                                                    onClick={() => toggleShippingAddress()}
                                                >
                                                    Close
                                                </Button>
                                                <Col className="p-0 mt-2 textRight">
                                                    <MendatoryFieldMsg />
                                                </Col>
                                            </Form.Row>

                                        </Form>
                                    )}
                                </Formik>
                            </>
                        }
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default AddShippingAddress
