
import React, { useContext, useState, useRef, useEffect } from 'react';

import "./Wishlist.scss";
import { BsHeartFill } from "react-icons/bs";
import { Popover, Overlay } from "react-bootstrap";
import { BackdropContext } from "../../contexts/BackdropContext"
import { OverlayContext } from "../../contexts/OverlayContext"
import * as DOMHelpers from "../../helpers/DOMHelpers"
import ScrollArea from 'react-scrollbar'

import { CloseOverlay } from '../CloseOverlayButton/CloseOverlay'
import { AuthContext } from "../../contexts/AuthContext"
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Wishlist from './Wishlist';
import { PopupContext } from "../../contexts/PopupContext"
import { WishlistContext } from "../../contexts/WishlistContext"


const WishlistOverlay = (props) => {
    const { wishlist, fetchWishlist, getUserIDForWishlist } = useContext(WishlistContext);
    const { userAuthState } = useContext(AuthContext);
    const { popupDispatch } = useContext(PopupContext);
    const { backdropDispatch } = useContext(BackdropContext);
    const { wishlistOverlayState, wishlistOverlayDispatch } = useContext(OverlayContext);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);


    useEffect(() => {
        fetchWishlist();
    }, [])


    const handleWishlistOverlay = (event) => {
        setTarget(event.target);
        wishlistOverlayDispatch({
            type: "OVERLAY_OPEN",
        });
        backdropDispatch({
            type: "FETCH_BACKDROPHORIZONTAL_OPEN",
        });
        DOMHelpers.fixedBody();
        if (userAuthState.user && userAuthState.user.token) {
            // getData();
        } else { }
    };
    return (
        <>
            <div
                className={`wishlistIcon notificationButton topHeaderLink ${wishlistOverlayState.isShowing ? "disabledLink" : ""}`}
                onClick={handleWishlistOverlay} ref={ref}>
                <div className="overlayItemIconHolder">
                    {wishlist && wishlist.items && wishlist.items.length > 0 &&
                        <Badge
                            className="customBadge right animate__animated animate__wobble animate__delay-3s animate__infinite"
                            variant="secondaryPink">
                            {wishlist.items.length}
                        </Badge>
                    }
                    <BsHeartFill
                        title="Your Wishlist"
                        className={"wishlistIcon " + (wishlistOverlayState.isShowing ? 'active' : null)}
                    />
                </div>

            </div>

            <Overlay
                show={wishlistOverlayState.isShowing}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
                rootClose={true}
                onHide={() => {
                    wishlistOverlayDispatch({
                        type: "OVERLAY_CLOSE",
                    });
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    DOMHelpers.relativeBody();
                }
                }
                transition={false}
            >
                <Popover className="customPopoverTheme whitePopover wishlist disabledElementChild">
                    <div className="closeButtonContainer blockButton">
                        <CloseOverlay />
                    </div>
                    <Popover.Content className="p-0">
                        {getUserIDForWishlist() ? (
                            <>
                                <h5 className="overlayTitle pl-2">Wishlist Items</h5>
                                <ScrollArea
                                    speed={0.8}
                                    className="scrollContent"
                                    contentClassName="content"
                                    horizontal={false}
                                >
                                    <Wishlist />
                                </ScrollArea>
                                {(wishlist.items && wishlist.items.length > 0) ? (
                                    <Row className="cartOverlayFooter borderTop" noGutters>
                                        <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                            <span className="totalItemsinCart mr-4">
                                                <span className="totalItemsCount">{wishlist.items.length}</span> item(s)
                                            </span>
                                        </Col>
                                        <Col xl={7} lg={7} md={7} sm={7} xs={7} className="pl-3 textRight">
                                            <Link to="/wishlist"
                                                onClick={() => {
                                                    wishlistOverlayDispatch({
                                                        type: "OVERLAY_CLOSE",
                                                    });
                                                    backdropDispatch({
                                                        type: "FETCH_BACKDROP_CLOSE",
                                                    });
                                                    DOMHelpers.relativeBody();
                                                }}
                                                className="btn btn-PurpleButton lessCornes hasPadding hasShadow btn-lg uppercase">Go To Wishlist</Link>
                                        </Col>
                                    </Row>
                                ) : (null)
                                }
                            </>
                        ) : (
                            <>
                                <div className="userShouldLoggedinContainer mb-3">
                                    <h5 className="overlayTitle">Please Sign in or Sign up to add an item into your wishlist</h5>
                                    <div className="innerContainer">
                                        <Row noGutters className="align-items-center">
                                            <Col xl={3} lg={3} md={3} sm={3} xs={12}>
                                                <Button variant="PinkButton" block size="sm" className="block hasShadow lessCorners"
                                                    onClick={() => {
                                                        popupDispatch({
                                                            type: "POPUP_SIGNIN_OPEN"
                                                        })
                                                        wishlistOverlayDispatch({
                                                            type: "OVERLAY_CLOSE",
                                                        });
                                                        backdropDispatch({
                                                            type: "FETCH_BACKDROP_CLOSE",
                                                        });
                                                    }}
                                                >Sign in
                                                </Button>
                                            </Col>
                                            <Col xl={3} lg={3} md={1} sm={2} xs={12}>
                                                <div className="dividor columnView"> -- or -- </div>
                                            </Col>
                                            <Col xl={6} lg={6} md={1} sm={2} xs={12}>
                                                <Button variant="PurpleButton" block size="sm" className="block hasShadow lessCorners"
                                                    onClick={() => {
                                                        popupDispatch({
                                                            type: "POPUP_SIGNUP_OPEN"
                                                        })
                                                        wishlistOverlayDispatch({
                                                            type: "OVERLAY_CLOSE",
                                                        });
                                                        backdropDispatch({
                                                            type: "FETCH_BACKDROP_CLOSE",
                                                        });
                                                    }}
                                                >New customer? Start here
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </>
                        )}
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    )
}

export default WishlistOverlay;