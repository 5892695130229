import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import axios from "axios";
import { productReducer, productsInitialState } from "../../reducers/Reducers";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductItem from "../ProductItem/ProductItem";
import { SkeletonProductSlider } from "../Skeleton/Skeleton";
import { ErrorComponent } from "../Alert/Alert";
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { productSliderParams } from '../../helpers/DOMHelpers'
import { AuthContext } from '../../contexts/AuthContext'
SwiperCore.use([Navigation, Autoplay, Scrollbar]);
const ProductSuggestionsSlider = (props) => {
  const { userAuthState } = useContext(AuthContext);
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [productState, productDispatch] = useReducer(productReducer, productsInitialState);
  useEffect(() => {
    productDispatch({
      type: "FETCH_PRODUCTS_REQUEST"
    });
    axios.get(`${process.env.REACT_APP_BASE_URL}api/buyers/get-product-suggestions`,
      {
        headers: {
          Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
        },
      }
    )
      .then(res => {
        // console.log(res.data);
        productDispatch({
          type: "FETCH_PRODUCTS_SUCCESS",
          payload: res.data
        });
      })
      .catch(error => {
        // console.log(error);
        productDispatch({
          type: "FETCH_PRODUCTS_FAILURE"
        });
      });
  }, []);

  return (
    <>
      <Container className="productSlider sliderBottomSpacing">
        <Row>
          <Col className="productSliderHeadingContainer" lg={3} md={4} sm={4} xs={12} xl={2}>
            <div className="heading blue">
              Suggested Products
            </div>
          </Col>
          <Col
            className={`productSliderContainer ${productState.hasError ? 'hasError' : ''}`}
            lg={9} md={8} sm={8} xs={12} xl={10}>
            {productState.isFetching ? (
              <SkeletonProductSlider />
            ) : productState.hasError ? (
              <ErrorComponent />
            ) : (
              <>
                {productState.products.length > 0 ? (
                  <>
                    <div
                      onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                      onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                      className="sliderWrapper"
                    >
                      <Swiper
                        ref={swiperRef}
                        navigation={{
                          prevEl: navigationPrevRef.current,
                          nextEl: navigationNextRef.current,
                        }}
                        {...productSliderParams}
                        onSwiper={(swiper) => {
                          if (swiper) {
                            setTimeout(() => {
                              swiper.params.navigation.prevEl = navigationPrevRef.current
                              swiper.params.navigation.nextEl = navigationNextRef.current
                              setIsFirstSlide(true);
                              swiper.navigation.destroy()
                              swiper.navigation.init()
                              swiper.navigation.update()
                            }, 500)
                          }

                        }}
                        onSlideChange={(swiper) => {
                          if (swiper.activeIndex === 0) {
                            setIsFirstSlide(true);
                          }
                          else if (swiper.isEnd) {
                            setIsLastSlide(true);
                          }
                          else {
                            setIsFirstSlide(false);
                            setIsLastSlide(false);
                          }
                        }}
                      // onReachBeginning ={() => {console.log('slide reach on start')}}
                      // onReachEnd={() => {console.log('slide reach on end')}}
                      >

                        {productState.products.map((item, index) => (
                          <SwiperSlide className="swiper-slide" key={index}>

                            <ProductItem key={item.id.toString()} item={item} />

                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div ref={navigationPrevRef}
                        className={`customSliderNavigation prev ${isFirstSlide ? "hide" : ""}`}
                      >
                        <FaCaretRight className="customSliderPrevIcon" />
                      </div>
                      <div ref={navigationNextRef}
                        className={`customSliderNavigation next ${isLastSlide ? "hide" : ""}`}
                      >
                        <FaCaretLeft className="customSliderNextIcon" />
                      </div>


                    </div>
                  </>
                ) :
                  (
                    <SkeletonProductSlider />
                  )}
              </>
            )}

          </Col>
        </Row>

      </Container>
    </>
  )
}

export default ProductSuggestionsSlider;