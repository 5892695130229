import React, { useEffect, useState } from 'react'
import './ResetPassword.scss'
import { Col, Container } from 'react-bootstrap'
import InnerPageHeader from '../../components/InnerPageHeader/InnerPageHeader'
import ResetPasswordForm from './ResetPasswordForm'
import axios from 'axios'
import PageNotFound from '../../components/Errors/PageNotFound'
import { ContainerLoading } from '../../components/Loading/Loading'

const ResetPassword = (props) => {
    const [hashStatus, setHashStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const { match } = props;
    const hash = match.params.hash;
    console.log(hash);
    useEffect(() => {
        const getResetHash = async () => {
            await axios.post(`${process.env.REACT_APP_BASE_URL}api/buyers/check-reset-password-hash`, {
                hash: hash
            })
                .then(res => {
                    console.log(res.data);

                    if (res.data && res.data.status === 'success') {
                        setHashStatus(true)

                    }
                    setLoading(false)

                })
                .catch(error => {
                });
        }
        getResetHash()
    }, []);

    return (
        <>

            {loading
                ? <ContainerLoading />
                : <>
                    {hashStatus ?
                        <>
                            <div className="resetPasswordBreadcrumbs">
                                <InnerPageHeader lastItemTitle="Reset Password" />
                            </div>
                            <Container className="passwordFormContainer">
                                <Col className="innerpageCard hasMarginTop hasShadow">
                                    <Col xl={6} xs={12} className="passwordFormHolder">
                                        <ResetPasswordForm hash={hash} />
                                    </Col>
                                </Col>
                            </Container>
                        </>
                        :
                        <PageNotFound />
                    }
                </>
            }





        </>
    )
}

export default ResetPassword
