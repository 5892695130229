
import 'animate.css'
import "./App.scss"
import AuthContextProvider from "./contexts/AuthContext"
import { CartProvider } from './contexts/CartContext'
import { WishlistProvider } from './contexts/WishlistContext'
import BackdropContextProvider from "./contexts/BackdropContext"
import OverlayContextProvider from "./contexts/OverlayContext"
import PopupContextProvider from './contexts/PopupContext'
import { GlobalContextProvider } from './contexts/GlobalContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router'
import WebWrapper from './components/WebWrapper/WebWrapper';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-182672787-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props) {


  return (
    <AuthContextProvider>
      <GlobalContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <BackdropContextProvider>
          <OverlayContextProvider>
            <PopupContextProvider>
              <CartProvider>
                <WishlistProvider >
                  <WebWrapper />
                </WishlistProvider>
              </CartProvider>
            </PopupContextProvider>
          </OverlayContextProvider>
        </BackdropContextProvider>


      </GlobalContextProvider>
    </AuthContextProvider>



  );
}
export default withRouter(App)