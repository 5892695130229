import React,{useReducer} from 'react'
import {formSubmissionReducer, formInitialState} from '../../reducers/Reducers'
import {ContainerLoading} from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent } from '../../components/Alert/Alert'
import { Form, Button, Col} from 'react-bootstrap'
import { Formik } from 'formik'
import axios from 'axios'
import FormInput from '../../components/FormPartials/FormInput'
import {forgotPasswordValidation} from '../../components/FormPartials/Validation'
const ForgotPasswordForm = (props) => {
const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
        return (
        <>
        <div className="formContainer">
        {formState && formState ? (
        <>
        {formState.isFormSubmitting && (
        <ContainerLoading />
        )}
        {formState.isFormSubmitted && (
        <SuccessMsg
        msgTitle= "Password Reset Email Sent"
        msgBody="We have sent you a reset password link to your email, please check your inbox"
        />
        )}

        {formState.inFormInitial && (
        <Formik
        initialValues={{
        email:"",
        }}
        validationSchema={forgotPasswordValidation}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(true);            
        formDispatch({
        type: "FORM_SUBMISSION_START",
        });
        setTimeout(() => {
        formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        });
        const sendData = async (e) => {  
        await axios.post(`${process.env.REACT_APP_BASE_URL}api/buyers/forgot-password`,
        values
        )
        .then(res => {
        console.log(res.data);
        localStorage.setItem("hash", JSON.stringify(res.data.hash));
        })
        .catch(error => {
        console.log(error);
        }); 
        } 
        sendData();
        }, 500);
        console.log(values);
        }}

        >
        {( {values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
        <div className="customHeadingForForm">
        <p>
        Enter your registered email address and we'll send you a link to reset your password
        </p>
        </div>
        <Col className={`p-0 mendatory ${errors.email  && touched.email ? "errorParent" : ""}` }>
        <FormInput
        controlId="email"
        label="Email Address"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        />
        </Col>

        <Button variant="PurpleButton mt-2"
        className="hasPadding uppercase hasShadow bold"
        type="submit"
        disabled={isSubmitting}
        >
        Submit
        </Button>
        </Form>
        )}
        </Formik>
        )}
        </>
        ) : (
        <ErrorComponent/>
        )}
        </div>
            </>
        )
    }

export default ForgotPasswordForm
