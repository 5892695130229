import React, { useState, useEffect, useReducer } from 'react';
import axios from "axios";
import { productReducer, productsInitialState } from "../../reducers/Reducers";
import { Container, Col, Row } from "react-bootstrap";
import ProductItem from "../ProductItem/ProductItem";
import { SkeletonProductSlider } from "../Skeleton/Skeleton";
import { ErrorComponent } from "../Alert/Alert";
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { fetchBoughtTogetherProductsAPI } from '../../apis/productAPIs'


SwiperCore.use([Navigation, Autoplay, Scrollbar]);

const FrequentlyBoughtTogether = ({ productid, style, props }) => {
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const productSliderParams = {
    speed: 500,
    autoplay: { delay: 65555000 },
    slidesPerView: 4,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    scrollbar: { draggable: true },
    breakpoints: {
      // when window width is >=300px
      320: {
        slidesPerView: 1,
        scrollbar: false,
      },
      // when window width is >= 768px
      700: {
        slidesPerView: 2,
        scrollbar: false,
      },
      960: {
        slidesPerView: 3,
      },
      1240: {
        slidesPerView: 4,
      },
    }
  };

  const [productState, productDispatch] = useReducer(productReducer, productsInitialState);
  const loadContent = async () => {
    const apiResult = await fetchBoughtTogetherProductsAPI(productid);
    if (apiResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: apiResult.data
      });
    }
  }

  useEffect(() => {
    loadContent()
  }, [productid]);

  return (
    <>

      {
        (productState.products && productState.products.length > 0) &&
        <Container className="productSlider" style={style}>
          <Row>
            <Col className="productSliderHeadingContainer" xl={2} lg={3} md={4} sm={4} xs={12}>
              <div className="heading secondaryPurple">
                Frequently Bought Together
              </div>
            </Col>
            <Col
              className={`productSliderContainer ${productState.hasError ? 'hasError' : ''}`}
              xl={10} lg={9} md={8} sm={8} xs={12} >

              <div style={{
                position: "relative",
                height: "100%",
              }}>
                <Swiper
                  {...productSliderParams}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      swiper.params.navigation.prevEl = navigationPrevRef.current
                      swiper.params.navigation.nextEl = navigationNextRef.current
                      setIsFirstSlide(true);
                      swiper.navigation.destroy()
                      swiper.navigation.init()
                      swiper.navigation.update()
                    })
                  }}
                  onSlideChange={(swiper) => {
                    if (swiper.activeIndex === 0) {
                      setIsFirstSlide(true);
                    }
                    else if (swiper.isEnd) {
                      setIsLastSlide(true);
                    }
                    else {
                      setIsFirstSlide(false);
                      setIsLastSlide(false);
                    }
                  }}
                  // onReachBeginning ={() => {console.log('slide reach on start')}}
                  // onReachEnd={() => {console.log('slide reach on end')}}
                  style={{ height: "100%" }}
                >

                  {productState.products.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>

                      <ProductItem key={item.id.toString()} item={item.product} />

                    </SwiperSlide>
                  ))}
                </Swiper>
                <div ref={navigationPrevRef}
                  className={`customSliderNavigation prev ${isFirstSlide ? "hide" : ""}`}
                >
                  <FaCaretRight className="customSliderPrevIcon" />
                </div>
                <div ref={navigationNextRef}
                  className={`customSliderNavigation next ${isLastSlide ? "hide" : ""}`}
                >
                  <FaCaretLeft className="customSliderNextIcon" />
                </div>


              </div>


            </Col>
          </Row>

        </Container>
      }


    </>
  )
}

export default FrequentlyBoughtTogether;