import React from 'react'
import Rating from 'react-rating'
import ratingStar from '../../assets/images/rating_star.png';
import ratedStar from '../../assets/images/rated_star.png';
const ProductRating = ({initialRating, onHover, className, readonly, props}) => {
    return (
            <Rating 
                emptySymbol={<img src={ratingStar}className="icon" alt=""/>}
                fullSymbol={<img src={ratedStar}className="icon" alt=""/>}
                initialRating={initialRating}
                readonly={readonly}
                onHover={onHover}
                className={className}
                style={{
                    position: 'relative',
                    bottom: "2px"
                }}
            /> 
    )
}

export default ProductRating