import React from 'react'
import { Container, Col} from 'react-bootstrap'
import InnerPageHeader from "../../components/InnerPageHeader/InnerPageHeader"
import Wishlist from '../../components/Wishlist/Wishlist'
const WishlistScreen = () => {
    return (
        <>
        <InnerPageHeader/>
        <Container>
        <Col className="whichlistScreen innerpageCard hasMarginTop hasShadow">
            <h2 className="heading uppercase">Your Wishlist Item(s)</h2>
            <Col>
                <Wishlist/>
            </Col>
        </Col>
        </Container>
        </>
    )
}

export default WishlistScreen
