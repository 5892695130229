import React, { useContext } from 'react'
import { AuthContext } from "../../contexts/AuthContext"
import { FaTimes } from "react-icons/fa";
import { removeNotificationAPI } from '../../apis/notificationAPIs'

const RemoveNotification = ({ notification_id, itemDispatch, ...props }) => {
  const { getBearerTokenConfig } = useContext(AuthContext);


  return (
    <div className="dismissNotificationHolder">
      <span
        className="dismissNotification"
        onClick={async () => {

          itemDispatch({
            type: "REMOVE_ITEM_REQUEST"
          });
          const submissionResult = await removeNotificationAPI(notification_id, getBearerTokenConfig());
          console.log(submissionResult);
          if (submissionResult.status) {
            itemDispatch({
              type: "REMOVE_ITEM_SUCCESS",
              payload: notification_id
            })
          } else {
            itemDispatch({
              type: "REMOVE_ITEM_FAILURE"
            })
          }

        }}
      ><FaTimes /> Dismiss</span>
    </div>
  )
}

export default RemoveNotification