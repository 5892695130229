import React, { useState, useContext } from 'react'
import AddToCart from '../Cart/AddToCart/AddToCart'
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { CartContext } from "../../contexts/CartContext"
import { isItemInCart } from "../../helpers/DOMHelpers"
import AddedToCart from "../Cart/AddedToCart/AddedToCart"
import RemoveFromCart from './../Cart/RemoveFromCart/RemoveFromCart';
import RemoveFromWishlist from './RemoveFromWishlist';
import MoveFromWishlistToCart from './../Wishlist/MoveFromWishlistToCart';
import Price from './../ProductItem/Price';
import { IoBanOutline } from "react-icons/io5";
const WishlistItem = ({ item }) => {
    const [openPopup, setOpenPopup] = useState(false)
    const [isCartButtonStyle, setIsCartButtonStyle] = useState(false)
    const { cart } = useContext(CartContext);
    return (
        <>
            <div className="itemHolder horizontalItemHolder pr-3 pl-3">
                <div className="itemImageHolder">
                    <Link to={`/product/${item.slug}`}>
                        <img
                            className="itemImage"
                            src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                            alt={item.title}
                            title={item.title}
                        />
                    </Link>
                </div>

                <div className="itemDetails">
                    <ul>
                        <li className="itemCategory">
                            {item.category.name === "" ? (
                                <p>{item.type.name}</p>
                            ) : (
                                <>
                                    {item.subCategory.name === "" ? (
                                        <p>{item.category.name}</p>
                                    ) : (
                                        <p>{item.subCategory.name}</p>
                                    )}
                                </>
                            )}
                            <>
                            </>
                        </li>
                        <li className="itemTitle">
                            {item.title}
                        </li>
                        <li className="itemRating">
                            {/* <Rater total={5} rating={item.reviews.rating} interactive={false}/> */}
                        </li>
                        <li>
                            <Row className="d-flex align-items-center justify-content-between">
                                <Col xs={6} >
                                    <Price price={item.price} oldPrice={item.old_price} />
                                 
                                </Col>
                                {/* <Col className="addToCart textCenter"  xs={6}>
                                    <Row className="d-flex align-items-center justify-content-end">
                                        <Col xl={2}>
                                        </Col>
                                        <Col xs={3} xl={3} md={3} lg={2} className="p-0">
                                            <MoveFromWishlistToCart product_id={item.id} /> 
                                        </Col>
                                        
                                        <Col xs={4} xl={3} md={3} lg={2} className="p-0">
                                            <RemoveFromWishlist product_id={item.id} />
                                        </Col>
                                        <Col xl={2}>
                                        </Col>
                                    </Row>
                                </Col> */}
                                <Col className="addToCart textCenter"  xs={6}>
                                    <Row className="d-flex align-items-center ml-3">
                                        {item.in_stock ? <><MoveFromWishlistToCart  product_id={item.id} /> </>:<><IoBanOutline  title="Out of stock"
                                        onClick={() => setOpenPopup(true)}
                                        
                            className="icon textCenter"  style={{width:'1.65rem',height:'1.65rem',cursor:'pointer'}}
                        /></>}
                                    
                                    <RemoveFromWishlist   product_id={item.id} />
                                       
                                    </Row>
                                </Col>
                            </Row>
                        </li>

                    </ul>
                </div>

            </div>
        </>
    )
}

export default WishlistItem;