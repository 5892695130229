import React, { useState, useContext, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../components/FormPartials/FormInput'
import FormTextArea500 from '../../components/FormPartials/FormTextArea500'

import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'

import { AuthContext } from '../../contexts/AuthContext'

import { useHistory } from 'react-router-dom';
import { shippingAddressValidation } from '../../components/FormPartials/Validation'
import CountriesInput from '../../components/FormPartials/CountriesInput'
import GoBack from '../../components/GoBack/GoBack'
import { ShippingAddressContext } from '../../contexts/ShippingAddressContext';


const UpdateShippingAddressForm = ({ id }) => {

    let history = useHistory()
    const { userAuthState } = useContext(AuthContext);


    const [count, setCount] = useState(0);
    const { shippingAddressData, getAddress, updateAddress } = useContext(ShippingAddressContext);

    useEffect(() => {
        if (id && id > 0) {
            getAddress(id);
        }
    }, [id]);


    return (

        <div>
            <GoBack history={history} />
            <div className="formContainer">
                {shippingAddressData && (
                    <>
                        {(shippingAddressData.isFetching || shippingAddressData.isUpdating) && <ContainerLoading />}
                        {shippingAddressData.hasError
                            ? <ErrorComponent />
                            :
                            <>
                                {shippingAddressData.hasUpdateError && <FormSubmissionFailed />}
                                {
                                    shippingAddressData.isUpdated
                                        ?
                                        <SuccessMsg
                                            msgTitle="Shipping address updated successfully."
                                        />
                                        : (
                                            <>
                                                <Row>
                                                    <Col xl={8} lg={8} xs={12}>
                                                        <div className="customHeadingForForm">
                                                            Update Shipping Address
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{

                                                        buyer_id: userAuthState.user.id,
                                                        id: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.id : '',
                                                        first_name: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.first_name : '',
                                                        last_name: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.last_name : '',
                                                        email: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.email : '',
                                                        country_id: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.country_id : '',
                                                        city: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.city : '',
                                                        address: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.address : '',
                                                        countryCodeMobile: '',
                                                        mobile: (shippingAddressData.items && shippingAddressData.items.address) ? shippingAddressData.items.address.mobile : '',
                                                    }}
                                                    validationSchema={shippingAddressValidation}
                                                    validateOnChange={true}
                                                    validateOnBlur={false}
                                                    onSubmit={async (values, { setSubmitting, resetForm }) => {

                                                        setSubmitting(true);
                                                        await updateAddress(values)
                                                        setSubmitting(false);
                                                        // formDispatch({
                                                        //     type: "FORM_SUBMISSION_START",
                                                        // });
                                                        // setTimeout(() => {
                                                        //     formDispatch({
                                                        //         type: "FORM_SUBMISSION_SUCCESS",
                                                        //     });

                                                        //     const sendData = async (e) => {
                                                        //         await axios.post(`${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address/update/${id}`,
                                                        //             values,
                                                        //             {
                                                        //                 headers: {
                                                        //                     Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
                                                        //                 }
                                                        //             }
                                                        //         )
                                                        //             .then(res => {
                                                        //                 console.log(res.data);
                                                        //             })
                                                        //             .catch(error => {
                                                        //                 console.log(error);
                                                        //             });
                                                        //     }
                                                        //     sendData();
                                                        // }, 500);
                                                        // console.log(values);
                                                        // setTimeout(() => {
                                                        //     getData();
                                                        //     formDispatch({
                                                        //         type: "FORM_INITIAL_STAGE",
                                                        //     });
                                                        // }, 2500);
                                                    }}
                                                >
                                                    {({ values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        isSubmitting }) => (
                                                        <Form onSubmit={handleSubmit} className="mx-auto form-row-full-touchDevices">
                                                            <input type="hidden" name="buyer_id" value={values.buyer_id} />
                                                            <input type="hidden" name="buyer_id" value={values.buyer_id} />
                                                            <Form.Row>
                                                                <Col className={`mendatory ${errors.first_name && touched.first_name ? "errorParent" : ""}`}>
                                                                    <FormInput
                                                                        controlId="first_name"
                                                                        label="Receiver's First Name"
                                                                        name="first_name"
                                                                        type="text"
                                                                        value={values.first_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                                <Col className={`mendatory ${errors.last_name && touched.last_name ? "errorParent" : ""}`}>
                                                                    <FormInput
                                                                        controlId="last_name"
                                                                        label="Receiver's Last Name"
                                                                        name="last_name"
                                                                        type="text"
                                                                        value={values.last_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                            </Form.Row>

                                                            <Form.Row>
                                                                <Col className={`mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                                                                    <FormInput
                                                                        controlId="receiverEmail"
                                                                        label="Receiver's Email Address"
                                                                        name="email"
                                                                        type="email"
                                                                        value={values.email}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                                <Col className={`mendatory ${errors.mobile ? "errorParent" : ""}`}>
                                                                    <FormInput
                                                                        controlId="mobile"
                                                                        label="Receiver's Mobile"
                                                                        name="mobile"
                                                                        type="tel"
                                                                        value={values.mobile}
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col className={`mendatory ${errors.city && touched.city ? "errorParent" : ""}`}>
                                                                    <FormInput
                                                                        controlId="city"
                                                                        label="Receiver's City"
                                                                        name="city"
                                                                        type="text"
                                                                        value={values.city}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                                <Col className={`p-0 mendatory ${errors.country_id && touched.country_id ? "errorParent" : ""}`}>
                                                                    <CountriesInput
                                                                        listType='delivery'
                                                                        controlId="country_id"
                                                                        label="Select Receiver's Country"
                                                                        name="country_id"
                                                                        as="select"
                                                                        value={values.country_id}
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col className={`mendatory ${errors.address ? "errorParent" : ""}`}>
                                                                    <FormTextArea500
                                                                        controlId="address"
                                                                        label="Receiver's Full Address"
                                                                        name="address"
                                                                        type="text"
                                                                        as="textarea"
                                                                        rows={3}
                                                                        value={values.address}
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                            setCount(e.target.value.length);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        count={count}
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row className="mt-4 buttonContainer">
                                                                <Button variant="whiteButton"
                                                                    className="hasPadding uppercase hasShadow bold"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Save Address
                                                                </Button>
                                                                <Button className="hasShadow hasPadding uppercase" variant="whiteButton" size=""
                                                                    onClick={() => history.goBack()}
                                                                >
                                                                    Back
                                                                </Button>
                                                                <Col className="p-0 textRight">
                                                                    <MendatoryFieldMsg />
                                                                </Col>
                                                            </Form.Row>

                                                        </Form>
                                                    )}
                                                </Formik>
                                            </>
                                        )
                                }
                            </>
                        }


                    </>
                )}
            </div>
        </div>

    )
}

export default UpdateShippingAddressForm
