import React, { useState, useEffect, useContext } from "react"
import "./Header.scss"
import {
  Form, Navbar, Container, Modal, Col
} from 'react-bootstrap'
import logo from '../../assets/images/logo@2x.png'
import TopHeader from '../TopHeader/TopHeader'
import Menu from '../Menu/Menu'
import Search from '../Search/Search'
import HeaderUserButtons from '../HeaderUserButtons/HeaderUserButtons'
import { Link } from 'react-router-dom'
import * as DOMHelpers from "../../helpers/DOMHelpers"
import { BackdropContext } from "../../contexts/BackdropContext"
import { OverlayContext } from "../../contexts/OverlayContext"
// import ProductFeedback from "../../userdashboard/ProductFeedback/ProductFeedback"

// import {InvoicePDF} from '../../userdashboard/Orders/InvoicePDF'
import CurrencySelecter from './CurrencySelecter';

export const Header = ({ props }) => {
  const { backdropState, backdropDispatch } = useContext(BackdropContext);
  const { overlayState, overlayDispatch } = useContext(OverlayContext);
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(false)
  const handleScroll = () => {
    const offsetTop = window.scrollY;
    if (offsetTop > 60) {
      setScrolled(true); 
    }
    else {
      setScrolled(false);
    }
  }

  const hideOverlay = () => {
    overlayDispatch({
      type: "OVERLAY_CLOSE",
    });
    backdropDispatch({
      type: "FETCH_BACKDROP_CLOSE",
    });
    DOMHelpers.relativeBody();
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  let navbarClasses = ['navbar'];
  if (scrolled) {
    navbarClasses.push('stickHeader');
  }

  return (
    <>
      <TopHeader />
      <Navbar bg="secondaryPurple" expand="lg" className={"appHeader " + (navbarClasses.join(" "))}
      >
        <Container className="relative">

          <div className="headerElementsRow relative">
            <div className="headerElementsContentRow left">
              <div className="appLogoHolder">
                <Navbar.Brand>
                  <Link to="/" title="Go To Home">
                    <img src={logo} className="appLogo" alt="JNOONZ" />
                    {/* <div onClick={()=> setShow(true)}>click here</div> */}
                    {/* <div className="icon" onClick={()=> InvoicePDF()}>see PDF</div> */}
                  </Link>
                </Navbar.Brand>
                <div>
                </div>


              </div>
              {/* <div className="menuHolder">
                <Menu
                  hideOverlay={hideOverlay}
                />
              </div> */}
            </div>
            <div className="headerElementsContentRow middle">
              <div className="searchHolder">
                <Search
                  hideOverlay={hideOverlay}
                />
              </div>
            </div>
            <div className="headerElementsContentRow right">
              <div className="userButtonsHolder">
                <HeaderUserButtons
                  hideOverlay={hideOverlay}
                />
              </div>
              <div className="headerCurrencyHolder">
                <CurrencySelecter />
              </div>
            </div>
          </div>


          {/* <Row lg={12} className="headerElementsRow fullWidth align-items-center m-0">

    <Col sm={12} xm={12} className="headerElementsContentRow">
      <Col sm={6} xm={6} md={1}>
        <Navbar.Brand>
        <Link to="/" title="Go To Home">
        <img src={logo} className="appLogo" alt="JNOONZ"/>
        </Link>
        </Navbar.Brand>
      </Col>

      <Col lg={1} sm={6} xm={6} className="textCenter"
        style={{position: "inherit"}}>
          <Menu/>
      </Col>
    </Col>

    <Col sm={12} xm={12} className="headerElementsContentRow">
      <Col lg={8} sm={6} xm={6} md={8} className="textCenter" style={{position:"inherit"}}>
          <HeaderSearch />
      </Col>
      <Col lg={2} sm={6} xm={6} md={2} className="textCenter">
          <HeaderUserButtons/>
      </Col>
    </Col>
    <Col lg={1} sm={12} xm={12} md={1}>
      <Form.Control as="select" custom size="lg" className="appCurrency">
          <option value="usd">USD</option>
      </Form.Control>
    </Col>
</Row> */}
        </Container>
      </Navbar>

      {/* <Modal
        show={show}
        centered
        onHide={() =>
          setShow(false)
        }
        animation={false}
        className=""
        size="md"
      >
        <Modal.Body>
          <Col className="p-4">
            <ProductFeedback />
          </Col>
        </Modal.Body>
      </Modal> */}

    </>
  );
};

export default Header;

