import React, { useContext, useState, useRef, useEffect } from 'react'
import Cart from '../../components/Cart/Cart'
import "./CartOverlay.scss"
import { Popover, Overlay, Row, Col, Badge } from "react-bootstrap"
import { BackdropContext } from "../../contexts/BackdropContext"
import { OverlayContext } from "../../contexts/OverlayContext"
import { CartContext } from "../../contexts/CartContext"
import * as DOMHelpers from "../../helpers/DOMHelpers"
import ScrollArea from 'react-scrollbar'
import { ImCart } from 'react-icons/im'
import { Link } from "react-router-dom"
import { CloseOverlay } from '../CloseOverlayButton/CloseOverlay'
const CartOverlay = ({ item }) => {
    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const { cartOverlayState, cartOverlayDispatch } = useContext(OverlayContext);
    const { cart, getCartItems } = useContext(CartContext);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const handleCartOverlay = (event) => {
        setTarget(event.target);
        cartOverlayDispatch({
            type: "OVERLAY_OPEN",
        });
        backdropDispatch({
            type: "FETCH_BACKDROPHORIZONTAL_OPEN",
        });
        DOMHelpers.fixedBody();
    };

    useEffect(() => {
        getCartItems();
    }, [])

    return (
        <>
            <div
                className={`cartIcon notificationButton topHeaderLink ${backdropState.isShowingHorizontal ? "disabledLink" : ""}`}
                onClick={handleCartOverlay} ref={ref}>
                <div className="overlayItemIconHolder">
                    {cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 &&
                        <Badge
                            className="customBadge right animate__animated animate__wobble animate__delay-3s animate__infinite"
                            variant="secondaryPink">
                            {cart.cartItems.items.length}
                        </Badge>
                    }
                    <ImCart
                        title="Your Cart"
                        className={"cartIcon " + (cartOverlayState.isShowing ? 'active' : null)}
                    />
                </div>
            </div>

            <Overlay
                show={cartOverlayState.isShowing}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
                rootClose={true}
                onHide={() => {
                    cartOverlayDispatch({
                        type: "OVERLAY_CLOSE",
                    });
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    DOMHelpers.relativeBody();
                }
                }
                transition={false}
            >
                <Popover className="customPopoverTheme whitePopover cartOverlay disabledElementChild">
                    <div className="closeButtonContainer blockButton">
                        <CloseOverlay />
                    </div>
                    <Popover.Content className="p-0">
                        <h5 className="overlayTitle pl-2 showOnMobile">Shopping Cart</h5>
                        <>
                            <ScrollArea
                                speed={0.8}
                                className="scrollContent"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <Cart item={item} />
                            </ScrollArea>
                            {cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 &&
                                <Row className="cartOverlayFooter align-items-center borderTop">
                                    <Col lg={7} sm={12} xs={12}>
                                        <Row className="align-items-center">
                                            <span className="totalItemsCount">{cart.cartItems.items.length}</span>
                                            <span className="totalItemsinCart">item(s) </span>
                                            <span className="totalPriceOfCart">
                                                <span className="totalPriceinCart">{cart.cartItems.selectedCurrency ? cart.cartItems.selectedCurrency.symbol : '$'}{cart.cartItems.invoice.subTotal}</span>
                                            </span>
                                        </Row>
                                    </Col>
                                    <Col lg={5} sm={12} xs={12} className="pl-3 textRight">
                                        <Link to="/cart"
                                            onClick={() => {
                                                cartOverlayDispatch({
                                                    type: "OVERLAY_CLOSE",
                                                });
                                                backdropDispatch({
                                                    type: "FETCH_BACKDROP_CLOSE",
                                                });
                                                DOMHelpers.relativeBody();
                                            }}
                                            className="btn btn-PurpleButton hasPadding hasShadow btn-lg uppercase">Checkout</Link>
                                    </Col>
                                </Row>
                            }
                        </>

                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    )
}

export default CartOverlay;