import React, { useContext } from 'react'
import CartItem from "../../components/Cart/CartItem"
import { SkeletonHorizontalItem } from '../../components/Skeleton/Skeleton'
import NoItemFoundInCart from './NoItemFoundInCart'

import { CartContext } from "../../contexts/CartContext"
import { ErrorComponent } from './../Alert/Alert';

const Cart = (props) => {
    const { cart } = useContext(CartContext);




    return (
        <>
   
            {cart.isFetching ? (
                <SkeletonHorizontalItem />
            ) : cart.hasError ? (
                <ErrorComponent />
            ) : (
                <>
                    {cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 ? (
                        <>

                            <ul >
                                {cart.cartItems.items.map((item, index) =>

                                    <li key={index} className="itemHolder horizontalItemHolder">
                                        {cart.isUpdating ? (
                                            <SkeletonHorizontalItem />
                                        ) : (
                                            <CartItem
                                                key={item.id}
                                                item={item}
                                                cart={cart}
                                            />
                                        )}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (

                        <NoItemFoundInCart />
                    )}
                </>
            )}
        </>
    )
}

export default Cart;