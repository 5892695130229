import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap'
import axios from 'axios'
const TelephoneInput = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  ...props
}) => {
  const [state, setstate] = useState()
  function fetchDialCode(initialValues, setInitialValues) {
    axios.get('https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3')
      .then((response) => {
        let data = response.data;
        setstate(data.country)
        setTimeout(() => {
          let aaa = (state || '').toLowerCase()
          setstate(aaa)
        }, 1000);
      }).catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    if (!value) {
      fetchDialCode();
    }

  }, []);

  // https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3

  const [telephoneValid, setTelephoneValid] = useState(true);
  const setValidity = valid => {
    setTelephoneValid(valid);
  };
  // process number into string with area code for submission
  const processNumber = (isValid, phone, country) => {
    return `+${country.dialCode} ${phone}`;
  };
  return (
    <>
      <Field name={name}>
        {(
          { field: { value },
            form: { isSubmitting, setFieldTouched, setFieldValue } }) =>
        (
          <Form.Group md={md} controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <IntlTelInput
              {...props}
              containerClassName="intl-tel-input"
              defaultCountry={(state) ? state : "ae"}
              inputClassName={telephoneValid ? 'valid' : 'invalid'}
              label="telephone"
              defaultValue={value}
              disabled={isSubmitting}
              fieldId={name}
              fieldName={name}
              format={true}
              geoIpLookup={state}
              onPhoneNumberBlur={(isValid) => {
                setFieldTouched(name, true);
                setValidity(isValid);
              }}
              onPhoneNumberChange={(isValid, phone, country) => {
                setFieldValue(name, processNumber(isValid, phone, country));
              }}
            />
            <ErrorMessage component="div" name={name} className="errorMessage" />
          </Form.Group>
        )
        }
      </Field>
    </>
  );
};

TelephoneInput.propTypes = {
  name: PropTypes.string.isRequired,
};
export default TelephoneInput;