import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {Time} from '../../components/TimeFormat/TimeFormat'
import {isTrue} from "../../helpers/DOMHelpers"
import { FaCircle } from "react-icons/fa";
const MessagesItem = ({message, props}) => {
    return (
        <>
            <div className="messageGridHolder">
              <div className="colLeft">
                {isTrue(message.is_read) ? (
                  <FaCircle
                    className="readStatus"
                  />
                ):(
                  <FaCircle
                    className="unreadStatus"
                  />
                )}
              </div>
              <div className="colRight">
                <Row className="mb-2">
                <Col lg={9} sm={9} xs={12}>
                {message.sender_type === "Admin" && 
                  <div className="by">{message.sender_type}</div>
                }
                {message.sender_type === "Buyer" && 
                  <div className="by">{message.sender_type}</div>
                }
                </Col>
                <Col lg={3} sm={3} xs={12}>
                <div className="messageDate textRight textAlignInheritMobile"><Time time={message.created_time}/></div>
                </Col>
                </Row>
                <Row>
                <Col>
                  <div className="messageSubject mb-1">{message.subject} </div>
                  <div className="messageText textoverflow">{message.message}</div>
                </Col>
                </Row>
              </div>
            </div>

        </>
    )
}

export default MessagesItem
