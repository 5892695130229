import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Classification.scss";
import { fetchProductGroupsAPI } from '../../apis/productAPIs'
import { Link } from "react-router-dom";

const Classification = ({ productID }) => {
  const [groupedProducts, setGroupedProducts] = useState([]);



  useEffect(() => {
    if (productID) {
      const loadGroupedProducts = async () => {
        const apiResult = await fetchProductGroupsAPI(productID);
        if (apiResult.status && apiResult.data && apiResult.data.length) {
          setGroupedProducts(apiResult.data);
        }
      }
      loadGroupedProducts();
    }
  }, [productID]);



  return (
    <>
      {
        (groupedProducts && groupedProducts.length > 0)
        &&
        (
          groupedProducts.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {
                (group && group.products && group.products.length > 0) && (
                  <Col className="classification" xl={12} lg={12} md={12} xs={12}>

                    <h6>{group.groupName}</h6>
                    <ul>
                      {group.products &&
                        <>
                          {group.products.map((product, productIndex) => (

                            <li key={productIndex} className={`imageclass`}>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {product.title}
                                  </Tooltip>
                                }
                              >
                                <Link to={`/product/${product.slug}`} >
                                  <img
                                    className="corresponding_image"
                                    style={{ width: "60px", height: "50px" }}
                                    src={product.image}
                                    alt=""
                                  />
                                </Link>
                              </OverlayTrigger>
                            </li>
                          ))}
                        </>
                      }
                    </ul>
                  </Col>
                )
              }
            </React.Fragment>
          )
          ))
      }
    </>


  );
};

export default Classification;
