import axios from 'axios'

export const getOrdersAPI = async (queryString, headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-orders${queryString}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getOrderDetailsAPI = async (id, headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-orders/${id}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const addProdutReviewAPI = async (data, headers) => {
    if (!data || !data.product_id || !data.item_id || !data.order_id || !data.rating || !data.comments || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/product-reviews/create`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const addProdutFeedbackAPI = async (data, headers) => {
    // if (!data || !data.product_id || !data.item_id || !data.order_id || !data.comments || !headers)
    //     return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/product-feedbacks/create`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const submitProofOfPaymentAPI = async (data, headers) => {
    // if (!data || !data.product_id || !data.item_id || !data.order_id || !data.comments || !headers)
    //     return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/buyer-orders/proof-of-payment`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}
