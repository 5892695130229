import React,{createContext, useReducer} from 'react';
import { popupReducer, popupInitialState} from "../reducers/Reducers";
export const PopupContext = createContext();
const PopupContextProvider = (props)=>{
const [popupState, popupDispatch] = useReducer(popupReducer, popupInitialState)
    return(
        <PopupContext.Provider value={{
            popupState,
            popupDispatch
        }}>
              {props.children}
        </PopupContext.Provider>
    );
}
export default PopupContextProvider;