import React from 'react'
import {Link} from 'react-router-dom'
const ContinueShoppingButton = ({msgTitle, props}) => {
    return (
        <div>
        <p>{msgTitle}</p>
        <div className="mt2">
            <Link to="/products" className="btn btn-PurpleButton hasPadding hasShadow">
                Continue Shopping
            </Link>
        </div>
    </div>
    )
}

export default ContinueShoppingButton