import axios from 'axios'





export const orderStatsAPI = async (headers) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-orders/stats`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const orderLogAPI = async (headers) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyer-orders/log`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}