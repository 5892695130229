import React, { useContext, useState, useRef, useReducer } from 'react'
import { FaBell, FaCertificate } from "react-icons/fa"
import { Popover, Overlay, ListGroup, Row, Col, Badge } from "react-bootstrap"

import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { BackdropContext } from "../../contexts/BackdropContext"
import * as DOMHelpers from "../../helpers/DOMHelpers"
import { CloseOverlay } from '../CloseOverlayButton/CloseOverlay'
import { AuthContext } from "../../contexts/AuthContext"
import RemoveNotification from "./RemoveNotification"
import ScrollArea from 'react-scrollbar'
import { SkeletonQuestionItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent, ItemNotFoundDynamic } from '../../components/Alert/Alert'
import { fetchNotificationsAPI } from '../../apis/notificationAPIs'
const Notifications = (props) => {
    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    // console.log(userAuthState.token);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)

    const loadContent = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const submissionResult = await fetchNotificationsAPI(getBearerTokenConfig());
        if (submissionResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: submissionResult.data.items
            })
        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            })
        }
    }

    const handleOverlay = async (event) => {
        setShow(!show);
        setTarget(event.target);
        backdropDispatch({
            type: "FETCH_BACKDROP_OPEN",
        });
        DOMHelpers.fixedBody();
        await loadContent();
    };

    return (
        <>
            <div
                className={`notificationButton topHeaderLink ${backdropState.isShowing ? "disabledLink" : ""}`}
                onClick={handleOverlay} ref={ref}>
                <div className="overlayItemIconHolder">
                    {!show && itemState.item.length > 0 &&
                        <Badge
                            className="customBadge left animate__animated animate__wobble animate__delay-3s animate__infinite"
                            variant="secondaryPink">
                            {itemState.item.length}
                        </Badge>
                    }
                    <FaBell className="topHeaderLinksIcons mr-2" />
                </div>
                <span className="optionalTextToShow">Notification(s)</span>
            </div>

            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
                rootClose={true}
                onHide={() => {
                    setShow(false);
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    DOMHelpers.relativeBody();
                }
                }
                transition={false}
            >
                <Popover className="customPopoverTheme notificationPopover disabledElementChild">
                    <div className="closeButtonContainer blockButton">
                        <CloseOverlay />
                    </div>
                    <Popover.Content>

                        {itemState.isFetching ? (
                            <SkeletonQuestionItem />
                        ) : itemState.hasError ? (
                            <ErrorComponent />
                        ) : (
                            <>
                                {(itemState.item && itemState.item.length) ? (
                                    <ScrollArea
                                        speed={0.8}
                                        className="scrollContent"
                                        contentClassName="content"
                                        horizontal={false}
                                    >
                                        <ListGroup variant="flush" as="ul">
                                            {itemState.item.map((notification, index) => (
                                                <>
                                                    {itemState.isRemoving ? (
                                                        <SkeletonQuestionItem />
                                                    ) : (
                                                        <ListGroup.Item key={index} as="li" className={`${notification.priority === 'High' ? "highPriority" : "lowPriority"}`}>
                                                            <Row noGutters>
                                                                <Col lg={1} xs={1} className="mr-2"><FaCertificate className="notificationIcon" /></Col>
                                                                <Col lg={10} xs={10}>{notification.note}</Col>
                                                            </Row>
                                                            {userAuthState.user && userAuthState.user.token &&
                                                                <RemoveNotification
                                                                    notification_id={notification.id}
                                                                    itemDispatch={itemDispatch}
                                                                />
                                                            }
                                                        </ListGroup.Item>
                                                    )}
                                                </>

                                            ))}
                                        </ListGroup>
                                    </ScrollArea>
                                ) : (
                                    <ItemNotFoundDynamic
                                        className="itemNotFound textCenter mt-4"
                                        msgTitle="Notifications are not available"
                                    />
                                )}
                            </>
                        )}


                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    )
}

export default Notifications;