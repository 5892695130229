import React, { useEffect, useState, useContext } from "react";
import "./ProductGallery.scss";
import { Col, Row } from "react-bootstrap";
import { FullPageLoading } from "../Loading/Loading";
import axios from "axios";
import ReactImageMagnify from "react-image-magnify";
import { FaPlayCircle } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Autoplay ,Scrollbar} from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
import { Link } from "react-router-dom";
import { GlobalContext } from "./../../contexts/GlobalContext";
import { fetchProductGalleryAPI } from "../../apis/productAPIs";
import promotionLogo from "../../assets/images/c3logo2.png";
SwiperCore.use([Navigation, Thumbs, Autoplay,Scrollbar]);

const ProductGallery = ({ supplier, productid, itemImage, ...props }) => {
  const [itemGallery, setItemGallery] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { getFrontendMessage } = useContext(GlobalContext);

  const loadContent = async () => {
    const apiResult = await fetchProductGalleryAPI(productid);
    if (apiResult.status) {
      setItemGallery(apiResult.data);
    }
  };

  useEffect(() => {
    if (productid) {
      loadContent();
    }
  }, []);
  console.log(supplier);
  const sliderParams = {
    direction: "vertical",
    breakpoints: {
      // when window width is >=300px
      320: {
        slidesPerView: 1,
        scrollbar: false,
        direction: "horizontal",
        autoplay: { delay: 6000 },
      },
      // when window width is >= 768px
      700: {
        slidesPerView: 1,
        scrollbar: false,
        direction: "horizontal",
        autoplay: false,
      },
      1024: {
        slidesPerView: 1,
        direction: "horizontal",
        autoplay: false,
      },
      1200: {
        slidesPerView: 1,
        direction: "vertical",
        autoplay: false,
      },
    },
    thumbs: { swiper: thumbsSwiper },
  };

  const thumbSliderParams = {
    direction: "vertical",
    spaceBetween: 13,
    slidesPerView: 5,
    scrollbar: {
      enabled: true,
      draggable: true,
    },
    freeMode: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      // when window width is >= 768px
      300: {
        direction: "horizontal",
        slidesPerView: 3,
      },
      960: {
        direction: "horizontal",
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
        direction: "vertical",
      },
    },
  };

  return (
    <>
      <div className="productDetailsGallery">
        <>
          {/* <Col xl={11} lg={11} sm={12} md={12} xs={12} className="p-0">
            <div className="promoteProduct mb-3">
              <Link to="" className="link white">{getFrontendMessage('Product', 'Promote this Product', 'Message', 'Text')}</Link>
            </div>
          </Col> */}

          {itemGallery && itemGallery.length > 0 ? (
            <>
              <Row style={{ height: "100%" }} noGutters>
                <Col
                  xl={10}
                  lg={12}
                  md={12}
                  style={{ height: "100%" }}
                  className="p-0 m-0"
                >
                  <div id="enlargeImageContainer"></div>
                  {supplier.logo && (
                    <div
                      className="overlayContainer"
                      style={{
                        height: "50px",
                        right: 10,
                        backgroundColor: "transparent",
                        opacity: 1,
                        zIndex: 100,
                        bottom: 0,
                        width: "100%",
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="OverlayLogoContainer">
                        <img
                          src={supplier.logo}
                          alt={supplier.logo}
                          title={supplier.logo}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <Swiper {...sliderParams} className="largeSwiper">
                    {itemGallery.map((item, index) => (
                      <SwiperSlide
                        className="swiper-slide position-relative"
                        key={index}
                      >
                        {item.type === "Image" && (
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Jnoonz",
                                isFluidWidth: true,
                                src: `${process.env.REACT_APP_BASE_URL}${item.image}`,
                              },
                              largeImage: {
                                src: `${process.env.REACT_APP_BASE_URL}${item.image}`,
                                width: 1200,
                                height: 920,
                              },
                              enlargedImagePosition: "beside",
                              enlargedImagePortalId: "enlargeImageContainer",
                              // enlargedImageContainerDimensions:{width: '600px', height: '600px'},
                              // enlargedImageContainerClassName:"enlargeContainer"
                              // isEnlargedImagePortalEnabledForTouch: true,
                            }}
                          />
                        )}

                        {item.type === "Video" && (
                          <iframe
                            title={item.caption}
                            width="100%"
                            height="402"
                            src={item.video_link}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>

                <Col xl={2} lg={12} md={12} style={{ height: "100%" }}>
                  <Swiper
                    {...thumbSliderParams}
                    onSwiper={setThumbsSwiper}
                    className="thumbSwiper"
                  >
                    {itemGallery.map((item, index) => (
                      <SwiperSlide className="swiper-slide" key={index}>
                        <div className="thumbImageHolder">
                          {item.type === "Image" && (
                            <img
                              className="thumbImage"
                              src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                              alt={item.title}
                              title={item.title}
                            />
                          )}
                          {item.type === "Video" && (
                            <div className="videoThumb row align-items-center justify-content-center m-0">
                              <FaPlayCircle />
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
              </Row>
            </>
          ) : (
            <Row style={{ height: "100%" }} noGutters>
              <Col
                xl={11}
                lg={11}
                md={12}
                style={{ height: "100%" }}
                className="p-0 m-0"
              >
                <div id="enlargeImageContainer"></div>
                <div className="imageHolder position-relative">
                  {supplier.logo && (
                    <div
                      className="overlayContainer"
                      style={{
                        height: "50px",
                        right: 10,
                        backgroundColor: "transparent",
                        opacity: 1,
                        zIndex: 100,
                        bottom: 0,
                        width: "100%",
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="OverlayLogoContainer">
                        <img
                          src={supplier.logo}
                          alt={supplier.logo}
                          title={supplier.logo}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* <img
                    src={`${process.env.REACT_APP_BASE_URL}${itemImage}`}
                    alt={itemImage}
                    title={itemImage}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />  */}
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: `${process.env.REACT_APP_BASE_URL}${itemImage}`,
                      },
                      largeImage: {
                        src: `${process.env.REACT_APP_BASE_URL}${itemImage}`,
                        width: 1200,
                        height: 920,
                      },
                      enlargedImagePosition: "beside",
                      enlargedImagePortalId: "enlargeImageContainer",
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </>
      </div>
    </>
  );
};

export default ProductGallery;
