import React, {useState, useEffect} from 'react'
import './Bubble.scss'
export const Bubble = ({caption, title, props})=>{
const [talkBubble, setTalkBubble] = useState(true)
useEffect(() => {
    setTimeout(() => {
        setTalkBubble(false);
    }, 10000);
}, [])
    return(
            <>
            {talkBubble && 
                <div className="talk-bubble hasAnimation tri-right btm-right">
                    <p>
                    {title}
                    </p>
                    <p>
                    {caption}
                    </p>
                </div>
            }
            </>
    )
}