import React, { useReducer, useContext, useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import ToastFormSubmission from '../../components/Toasts/ToastFormSubmission'
import { toast } from "react-toastify";
import { PopupContext } from "../../contexts/PopupContext"
import { registerWishlist } from '../../apis/buyerAPIs'
import { AuthContext } from "../../contexts/AuthContext"

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Email must be a valid email address")
        .required("Please enter email address"),
});

const WishlistEmailAuth = (props) => {
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { popupState, popupDispatch } = useContext(PopupContext);
    const { userAuthState, userAuthDispatch } = useContext(AuthContext);
    const [emailExistStatus, setEmailExistStatus] = useState(false)
    return (
        <div>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}
                        {formState.isFormSubmitted ?
                            <div>
                                <SuccessMsg
                                    msgTitle="Form Submitted Successfully"
                                    msgBody="Now you can add items to wishlist."
                                />
                            </div>
                            :
                            <>
                                <div className="customHeadingForForm">If you dont have an account with us kindly provide us your active email.</div>
                                {formState.hasSubmissionError && <FormSubmissionFailed />}
                                <Formik
                                    initialValues={{
                                        email: "",
                                        type: "Wishlist"
                                    }}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        //console.log(values)
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });


                                        const submissionResult = await registerWishlist(values);
                                        //console.log("submissionResult ", submissionResult);

                                        if (submissionResult.status) {
                                            console.log("submissionResult Data", submissionResult.data);

                                            if (submissionResult.data.status === 'failed' && submissionResult.data.error === 'Email registered') {
                                                console.log('Email registered error ');
                                                setEmailExistStatus(true);
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                setTimeout(() => {
                                                    setEmailExistStatus(false);
                                                }, 5000);
                                                return true;
                                            }
                                            else if (submissionResult.data.status === 'success' && submissionResult.data.user) {
                                                console.log('in success');

                                                localStorage.setItem("wishlistUser", JSON.stringify(submissionResult.data.user));
                                                formDispatch({
                                                    type: 'FORM_SUBMISSION_SUCCESS',
                                                });

                                                setTimeout(() => {
                                                    popupDispatch({
                                                        type: "POPUP_SIGNIN_CLOSE"
                                                    })

                                                }, 5000);
                                            }



                                        } else {
                                            console.log('failed dispatch');
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (

                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            <Col className={`p-0 ${errors.email ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="email"
                                                    label="Email Address"
                                                    name="email"
                                                    type="email"
                                                    value={values.email}
                                                    onChange={(e) => { handleChange(e); emailExistStatus === true && setEmailExistStatus(false) }}
                                                    onBlur={handleBlur}
                                                />
                                                {(emailExistStatus) &&
                                                    <div className="errorMessage positionAbsolute" >
                                                        <p>Email is already exist</p>
                                                    </div>
                                                }
                                            </Col>

                                            <Button
                                                variant="PurpleButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                title="Add To Wishlist"
                                                disabled={isSubmitting}
                                            >
                                                Add To Wishlist
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>

                                <div className="dividor mt-4 mb-4">
                                    -- or --
                                </div>

                                <div>
                                    Already A Member? &nbsp;
                                    <span
                                        onClick={() =>
                                            popupDispatch({
                                                type: "POPUP_SIGNIN_OPEN"
                                            })
                                        }
                                        className="link green">Sign In
                                    </span>
                                </div>
                            </>
                        }


                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default WishlistEmailAuth