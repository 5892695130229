import React, { useEffect, useReducer, useState, useContext } from "react";
import "./Products.scss";
import { ProductListingReducer } from "../../reducers/ProductListingReducer";

import ProductItem from "../../components/ProductItem/ProductItem";
import Filter from "./Filters/Filter";
import { ErrorComponent, ItemNotFound } from "../../components/Alert/Alert";
import { SkeletonProductItem } from "../../components/Skeleton/Skeleton";
import { Container, Col, Row, Button } from "react-bootstrap";
import InnerPageHeaderProducts from "../../components/InnerPageHeader/InnerPageHeaderProducts";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "../../contexts/AuthContext";
import { FullPageLoading } from "../../components/Loading/Loading";
import { fetchProductsAPI } from "../../apis/productAPIs";
import ProductSorting from "./Sorting/ProductSorting";
import PaginationItem from "./../../components/Pagination/Pagination";
import Collapsible from "react-collapsible";
import { RiArrowDropDownFill, RiCloseFill } from "react-icons/ri";

const Listing = (props) => {
  const productsInitialState = {
    data: {},
    isLoading: false,
    hasError: false,
  };

  let history = useHistory();
  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isActive, setIsActive] = useState(false);
  const { getBearerTokenConfig } = useContext(AuthContext);
  const [productState, productDispatch] = useReducer(
    ProductListingReducer,
    productsInitialState
  );

  const setSorting = (value) => {
    //console.log("sortingChange called", value);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));
    newAppliedFilters["sort"] = value;
    let newURL = queryString.stringify(newAppliedFilters);
    history.push({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const setPagination = (value) => {
    //console.log("setPagination called", value);

    //console.log(productState.data._meta);

    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));
    newAppliedFilters["page"] = value;
    let newURL = queryString.stringify(newAppliedFilters);
    history.push({ search: newURL });
  };

  const fetchProducts = async () => {
    productDispatch({
      type: "FETCH_PRODUCTS_REQUEST",
    });
    const submissionResult = await fetchProductsAPI(
      search,
      getBearerTokenConfig()
    );
    //console.log(submissionResult);
    if (submissionResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: submissionResult.data,
      });
    } else {
      productDispatch({
        type: "FETCH_PRODUCTS_FAILURE",
      });
    }
  };

  useEffect(() => {
    let tempFilters = search ? queryString.parse(search) : {};
    setAppliedFilters(tempFilters);
    setIsActive(false)
    fetchProducts();
  }, [search]);

  return (
    <>
      <InnerPageHeaderProducts
        productTypes={
          productState.data.filters && productState.data.filters.productTypes
            ? productState.data.filters.productTypes
            : null
        }
        productCategories={
          productState.data.filters &&
            productState.data.filters.productCategories
            ? productState.data.filters.productCategories
            : null
        }
        productSubCategories={
          productState.data.filters &&
            productState.data.filters.productSubCategories
            ? productState.data.filters.productSubCategories
            : null
        }
      />
      <Container>
        {/* {console.log(productState.data.filters)} */}
        <Col className="productListing innerpageCard hasMarginTop hasShadow">
          {productState.isLoading ? (
            <FullPageLoading />
          ) : (
            <>
              {productState.hasError ? (
                <ErrorComponent />
              ) : (
                <Row>
                  <Col lg={3} sm={4} xs={12} className="LeftSidefilterArea">
                    {/* {console.log(productState.data)} */}
                    <div className="pageCategory uppercase">
                      {productState.data.query && (
                        <>
                          {!productState.data.query.subCategory &&
                            !productState.data.query.category ? (
                            <>
                              {appliedFilters.type && (
                                <>
                                  {productState.data.filters
                                    .productTypes &&
                                    productState.data.filters.productTypes
                                      .length && (
                                      <>
                                        {productState.data.filters.productTypes.map(
                                          (productTypes, index) =>
                                            productTypes &&
                                            productTypes.slug ===
                                            appliedFilters.category && (
                                              <React.Fragment key={index}>{productTypes.name}</React.Fragment>
                                            )
                                        )}
                                      </>
                                    )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {productState.data.query.type &&
                                !productState.data.query.subCategory ? (
                                <>
                                  {appliedFilters.category && (
                                    <>
                                      {productState.data.filters
                                        .productCategories &&
                                        productState.data.filters
                                          .productCategories.length && (
                                          <>
                                            {productState.data.filters.productCategories.map(
                                              (productCategory, index) =>
                                                productCategory &&
                                                productCategory.slug ===
                                                appliedFilters.category && (
                                                  <React.Fragment key={index}>{productCategory.name}</React.Fragment>
                                                )
                                            )}
                                          </>
                                        )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {appliedFilters.subCategory && (
                                    <>
                                      {productState.data.filters
                                        .productSubCategories &&
                                        productState.data.filters
                                          .productSubCategories.length && (
                                          <>
                                            {productState.data.filters.productSubCategories.map(
                                              (productSubCategory, index) =>
                                                productSubCategory &&
                                                productSubCategory.slug ===
                                                appliedFilters.subCategory && (
                                                  <React.Fragment key={index}>{productSubCategory.name}</React.Fragment>
                                                )
                                            )}
                                          </>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>

                    {productState.data.filters && (
                      <>
                        <div className="hideOnMobile">
                          <Filter
                            className="hideonMobile"
                            availableFilters={productState.data.filters}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                          />
                        </div>

                        <Collapsible
                          trigger={
                            <Button
                              className="text-fourthGray rounded  btn-light showOnMobile d-flex align-items-center justify-content-between"
                              style={{
                                backgroundColor: "white",
                                padding: "7px",
                                outline: "0",
                                border: "0",
                                width: "100%",
                              }}
                            // onClick={() => setIsActive(!isActive)}
                            >
                              <p className="mb-0  text-weight-light">
                                {" "}
                                {productState.data._meta.totalCount ? "Filters by" : "Categories"}

                              </p>
                              {isActive ? (
                                <RiCloseFill
                                  style={{
                                    fontSize: "18px",
                                    margin: "8px",
                                    color: "gray",
                                  }}
                                />
                              ) : (
                                <RiArrowDropDownFill
                                  className="text-tertiaryGray"
                                  style={{
                                    fontSize: "32px",
                                    color: "gray",
                                  }}
                                />
                              )}
                            </Button>
                          }
                          contentInnerClassName="content-inner mt-1"
                          className="border border-primaryGray showOnMobile rounded"
                          triggerOpenedClassName="collapsible_open"
                          triggerClassName="collapsible_closed"
                          onOpen={() => setIsActive(true)}
                          onClose={() => setIsActive(false)}


                        >
                          <Filter
                            productsCount={productState.data._meta.totalCount}
                            availableFilters={productState.data.filters}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                          />
                        </Collapsible>
                      </>
                    )}
                    {productState.data &&
                      productState.data.items &&
                      productState.data.items.length > 1 && (
                        <div className="showOnMobile mt-3">
                          <ProductSorting
                            appliedSorting={appliedFilters.sort}
                            setSorting={setSorting}
                          />
                        </div>
                      )}
                  </Col>

                  <Col lg={9} sm={8} xs={12}>
                    <div className="productListingHeadArea">
                      <ul>
                        {productState.data.items ? (
                          <li className="totalItemsCount fs-3 ">

                            Products {(productState.data._meta.totalCount > 0) ? <>({productState.data._meta.totalCount})</> : <></>}
                            {appliedFilters.q ? (
                              <p
                                className="text-tertiaryGray fw-light "
                                style={{ fontSize: "13px" }}
                              >
                                showing results for{" "}
                                <span className="text-danger">
                                  "{appliedFilters.q}"
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                          </li>
                        ) : (
                          <></>
                        )}

                        {productState.data &&
                          productState.data.items &&
                          productState.data.items.length > 1 && (
                            <li className="hideOnMobile">
                              <ProductSorting
                                appliedSorting={appliedFilters.sort}
                                setSorting={setSorting}
                              />
                            </li>
                          )}
                      </ul>
                    </div>

                    {productState.isFetching ? (
                      <SkeletonProductItem />
                    ) : productState.hasError ? (
                      <ErrorComponent />
                    ) : (
                      <>
                        <Row>
                          {productState.data &&
                            productState.data.items &&
                            productState.data.items.length ? (
                            <>
                              {productState.data.items.map((item, index) => (
                                <Col
                                  lg={4}
                                  sm={6}
                                  xs={12}
                                  className="itemListingHolder"
                                  key={item.id.toString()}
                                >
                                  <ProductItem item={item} />
                                </Col>
                              ))}
                            </>
                          ) : (
                            <ItemNotFound />
                          )}
                        </Row>

                        {productState.data &&
                          productState.data._meta &&
                          productState.data._meta &&
                          productState.data._meta.currentPage &&
                          productState.data._meta.pageCount &&
                          productState.data._meta.currentPage <=
                          productState.data._meta.pageCount ? (
                          <Row className="justify-content-center">
                            <div className="mt-4">
                              <PaginationItem
                                pages={productState.data._meta}
                                setPagination={setPagination}
                              />
                            </div>
                          </Row>
                        ) : null}
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Container>
    </>
  );
};

export default Listing;
