import React, { useReducer, useState, useContext } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../../components/FormPartials/FormInput'
import FormTextArea300 from '../../../components/FormPartials/FormTextArea300'
import { formSubmissionReducer, formInitialState } from '../../../reducers/Reducers'
import { ContainerLoading } from "../../../components/Loading/Loading"
import SuccessMsg from '../../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../../components/Alert/Alert'
import { sendMessageValidation } from '../../../components/FormPartials/Validation'
import { sendOrderMessagesAPI } from '../../../apis/orderMessagesAPIs'
import { AuthContext } from '../../../contexts/AuthContext'

const SendOrderMessage = ({ orderID, setCurrentPage, setShowPopup, ...props }) => {
    const { getBearerTokenConfig } = useContext(AuthContext);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [countTwo, setCountTwo] = useState(0);
    return (
        <div>
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}

                        {formState.isFormSubmitted ? (
                            <div>
                                <SuccessMsg
                                    msgTitle="Your message sent to Admin"
                                    msgBody=""
                                />
                            </div>
                        ) : (
                            <>
                                <div className="pageHeading mb-3">
                                    Start a New Communication with JNooNz
                                </div>
                                <Formik
                                    initialValues={{
                                        subject: "",
                                        message: "",
                                    }}
                                    validationSchema={sendMessageValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });

                                        const apiResult = await sendOrderMessagesAPI(orderID, values, getBearerTokenConfig());
                                        //console.log(apiResult.data.item);
                                        if (apiResult.status) {
                                            setCurrentPage(0);
                                            setTimeout(() => {
                                                setCurrentPage(1);
                                            }, 500);
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });

                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                setShowPopup(false);
                                            }, 5000);
                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                        }
                                    }}
                                // onSubmit={(values, { setSubmitting, resetForm }) => {
                                //     setSubmitting(true);
                                //     formDispatch({
                                //         type: "FORM_SUBMISSION_START",
                                //     });
                                //     formDispatch({
                                //         type: "FORM_SUBMISSION_START",
                                //     });
                                //     setTimeout(() => {
                                //         formDispatch({
                                //             type: "FORM_SUBMISSION_SUCCESS",
                                //         });

                                //         const sendData = async (e) => {
                                //             await axios.post(`${process.env.REACT_APP_BASE_URL}api/order-messages/send-message/${orderID}`,
                                //                 values,
                                //                 {
                                //                     headers: {
                                //                         Authorization: `Bearer ${(userAuthState.user && userAuthState.user.token) && userAuthState.user.token}`,
                                //                     },
                                //                 },
                                //             )
                                //                 .then(res => {
                                //                     console.log(res.data);
                                //                 })
                                //                 .catch(error => {
                                //                     console.log(error);
                                //                 });
                                //         }
                                //         sendData();
                                //     }, 500);

                                //     setTimeout(() => {
                                //         formDispatch({
                                //             type: "FORM_INITIAL_STAGE",
                                //         });
                                //     }, 6000);

                                //     console.log(values);
                                // }}

                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {formState.hasSubmissionError && <FormSubmissionFailed />}
                                            <Col className={`p-0 ${errors.subject ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="subject"
                                                    label="Subject"
                                                    name="subject"
                                                    type="text"
                                                    value={values.subject}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col className={`p-0 ${errors.message && touched.message ? "errorParent" : ""}`}>
                                                <FormTextArea300
                                                    controlId="message"
                                                    label="Message"
                                                    name="message"
                                                    type="text"
                                                    as="textarea"
                                                    rows={5}
                                                    value={values.message}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setCountTwo(e.target.value.length);
                                                    }}
                                                    onBlur={handleBlur}
                                                    countTwo={countTwo}
                                                />
                                            </Col>

                                            <Button variant="PurpleButton mt-4"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Send
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default SendOrderMessage
