import React from 'react'
import { Form } from 'react-bootstrap'

const AttributeFilter = ({ attribute, appliedAttributesValues, applyNewAttributeFilter }) => {
    //console.log('appliedAttributesValues', appliedAttributesValues);
    return (

        <>
            {
                (attribute && attribute.name && attribute.values && attribute.values.length)
                &&
                <div className="checkboxFilterContainer filterSectionTopMargin">

                    <div className="filterSectionHeading">
                        {attribute.name}
                    </div>

                    <ul>

                        <>
                            {attribute.values.map((attrValue, index) => (
                                <li className="filterElementsHolder hasCursor" key={index}>
                                    <Form.Check
                                        type="checkbox"
                                        id={attribute.name + "_" + index}
                                        label={attrValue}
                                        className="checkbox"
                                        checked={(appliedAttributesValues && appliedAttributesValues.length && appliedAttributesValues.includes(attrValue))}
                                        onClick={() => {
                                            let removeFlag = (appliedAttributesValues && appliedAttributesValues.length && appliedAttributesValues.includes(attrValue))


                                            applyNewAttributeFilter(attribute.name, attrValue, removeFlag)
                                            // let newValue = ((appliedFilters.customizable === true || appliedFilters.customizable === 'true') ? false : true);
                                            // setAppliedFilters({ ...appliedFilters, customizable: newValue })
                                            // newFilterApplied('customizable', newValue)
                                        }}
                                    />
                                </li>
                            ))}
                        </>
                    </ul>
                </div>
            }

        </>
    )
}

export default AttributeFilter
