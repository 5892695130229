import React from 'react';
import { FaArrowLeft } from "react-icons/fa";
const GoBack = (props) => {
const { history } = props;
  return(
      <div
      className="goBackButton mb-3"
      onClick={() =>{
        history.goBack();
      }}
      >
        <FaArrowLeft className="icon"/>  Back
      </div>
  )
}

export default GoBack;
