import React, { useContext, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'react-router-dom'
import Cart from "../../components/Cart/Cart"
import CartTotal from "../../components/Cart/CartTotal"
import InnerPageHeader from "../../components/InnerPageHeader/InnerPageHeader"
import PlaceOrderItem from "../../components/PlaceOrderItem/PlaceOrderItem"
import ProductSuggestionsSlider from "../../components/ProductSuggestionsSlider/ProductSuggestionsSlider"
import { AuthContext } from "../../contexts/AuthContext"
import { CartContext } from "../../contexts/CartContext"
import { GlobalContext } from './../../contexts/GlobalContext';

import SuccessMsg from './../../components/FormPartials/SuccessMsg';
import NoItemFoundInCart from './../../components/Cart/NoItemFoundInCart';
import "./shopping.scss"
const ShoppingCart = () => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const { userAuthState } = useContext(AuthContext);
    // console.log(userAuthState);
    const [orderPlacedByWhatsApp, setOrderPlacedByWhatsApp] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const { cart } = useContext(CartContext);
    const orderByWhatsAppFormSuccessMessage = getFrontendMessage('Forms', 'Order by WhatsApp', 'Success Message', 'Paragraphs');

    return (
        <>


            <InnerPageHeader />

            <Container >

                <Col className="shoppingCart innerpageCard hasMarginTop hasShadow">

                    {
                        orderPlacedByWhatsApp
                            ? <SuccessMsg
                                msgTitle={orderByWhatsAppFormSuccessMessage && orderByWhatsAppFormSuccessMessage.length ? orderByWhatsAppFormSuccessMessage[0].text : ''}

                                msgBody={`Your Order number is ${orderNumber} , now you can start communicating for your order to one of our representatives via WhatsApp.`}
                            />
                            :
                            <>

                                <Row className="hasTwoColumnSpacing">
                                    <Col
                                        xl={`${cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 ? 5 : 12}`}
                                        lg={`${cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 ? 6 : 12}`}
                                        md={`${cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 ? 12 : 12}`}
                                        sm={12}
                                        xs={12}
                                        className="columnOne">

                                        {/* <CartTotal itemsCount={(cart.cartItems.items) ? cart.cartItems.items.length : 0} subTotal={(cart.cartItems.invoice) ? cart.cartItems.invoice.subTotal : 0} /> */}
                                        <Cart />
                                    </Col>

                                    <Col xl={7} lg={6} md={12} sm={12} xs={12} className="columnTwo">
                                        {userAuthState.user && userAuthState.user.token && cart && cart.cartItems && cart.cartItems.items && cart.cartItems.items.length > 0 &&
                                            <div className="goToCheckout mb-4">
                                                <Link to="/checkout" className="btn btn-PurpleButton hasShadow hasPadding uppercase bold"
                                                    title="Place Order"
                                                > Place Order</Link>
                                            </div>
                                        }
                                        <PlaceOrderItem
                                            setOrderPlacedByWhatsApp={setOrderPlacedByWhatsApp}
                                            setOrderNumber={setOrderNumber}

                                        />

                                    </Col>
                                </Row>

                            </>


                    }





                </Col>
            </Container>

            {/* <Container>
                <ProductSuggestionsSlider />
            </Container> */}
        </>
    )
}

export default ShoppingCart