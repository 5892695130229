export const isLoggedInReducer = (state, action) => {
    switch (action.type) {
        case "LOGGED_IN_VERIFICATION_SUCCESS":
            console.log('LOGGED_IN_VERIFICATION_SUCCESS');
            return {
                ...state,
                isChecking: false,
                isLoggedIn: ((action.payload && action.payload.is_logged_in) ? true : false),
                data: action.payload,
            };
        case "LOGGED_IN_VERIFICATION_FAILED":
            console.log('LOGGED_IN_VERIFICATION_FAILED');
            return {
                ...state,
                isChecking: false,
                isLoggedIn: false,
                data: {}
            };

        default:
            return state;
    }
}

export const isLoggedInInitialState = {
    data: {},
    isChecking: true,
    isLoggedIn: false,
}