import React, { useContext } from 'react';
import './Footer.scss';
import { Container, Col, ListGroup, Row, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { GlobalContext } from './../../contexts/GlobalContext';
import { upperCaseFirst } from "../../helpers/CommonHelper"




const Footer = ({ props }) => {

    let year = new Date().getFullYear();

    const { socialChannels, footerProductTypes } = useContext(GlobalContext).globalData.data;


    const productCategory = [
        {
            id: 1,
            name: 'Mobile',
        },
        {
            id: 2,
            name: 'Fashion & Jewelry',
        },
        {
            id: 3,
            name: 'Beauty Products',
        },
        {
            id: 4,
            name: 'Electronics',
        },
    ]

    const helpPages = [
        {
            id: 1,
            name: 'Payments',
            url: '/payments'
        },
        {
            id: 2,
            name: 'Shipping',
            url: '/shipping'
        },
        {
            id: 3,
            name: 'Cancellation & Returns',
            url: '/cancellation-returns'
        }
    ]

    const Pages = [
        {
            id: 1,
            name: 'Contact Us',
            path: '/contact'
        },
        {
            id: 2,
            name: 'About Us',
            path: '/about'
        },
    ]
    return (
        <footer>
            <Col className="footer">

                <Container>
                    <ul className="footerLinksContainer">
                        {(footerProductTypes && footerProductTypes.length > 0) &&
                            <li>
                                <Col as="h6" className="footerHeding p-0">Top Categories</Col>
                                <ListGroup variant="flush" as="ul">
                                    {footerProductTypes.map((type, index) => (
                                        <ListGroup.Item key={index} as="li">
                                            <Link to={`/products?type=${type.slug}`}> {type.name}</Link>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </li>
                        }


                        <li>
                            <Col as="h6" className="footerHeding p-0">Policy</Col>
                            <ListGroup variant="flush" as="ul">
                                {helpPages.map((helpPage, index) => (
                                    <ListGroup.Item key={index} as="li">
                                        <Link to={helpPage.url}>{helpPage.name}</Link>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </li>

                        {/* <li>
                            <Col as="h6" className="footerHeding p-0">Policy</Col>
                            <ListGroup variant="flush" as="ul">
                                {productCategory.map((categoryName, index) => (
                                    <ListGroup.Item key={index} as="li">
                                        <Link to="/products"> {categoryName.name}</Link>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </li> */}
                        {(socialChannels && (socialChannels.facebook || socialChannels.twitter || socialChannels.instagram || socialChannels.linkedin || socialChannels.youtube)) &&
                            <li>
                                <Col as="h6" className="footerHeding p-0">Social</Col>
                                <ListGroup variant="flush" as="ul">
                                    {
                                        Object.keys(socialChannels).map((key, index) => (
                                            < span key={key}>
                                                {socialChannels[key] &&
                                                    <ListGroup.Item as="li" key={index}>
                                                        <Nav.Link
                                                            className="p-0"
                                                            href={socialChannels[key]} target="_blank">
                                                            {upperCaseFirst(key)}
                                                        </Nav.Link>
                                                    </ListGroup.Item>
                                                }
                                            </span>
                                        ))
                                    }
                                </ListGroup>
                            </li>
                        }


                        <li>
                            <Col as="h6" className="footerHeding p-0">About</Col>
                            <ListGroup variant="flush" as="ul">
                                {Pages.map((page, index) => (

                                    <ListGroup.Item key={index} as="li">
                                        <Link to={page.path}>
                                            {page.name}
                                        </Link>
                                    </ListGroup.Item>


                                ))}
                            </ListGroup>

                        </li>
                        {/* <li>
                      
                       <Button Button style={{textDecoration: 'none'}} variant="outline-light"  href="https://api.whatsapp.com/send?phone=009613673169&text=Hi JNooNz" target="_blank"> chat with us <BsWhatsapp className="ml-2"/> </Button>
                        </li> */}

                    </ul>
                </Container>
            </Col>
            <Col className="footerBottom">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={10} md={10} sm={12} xs={12} className="colLeft">
                            ©{year} Copyright JNooNZ
                        </Col>
                        <Col lg={2} md={2} sm={12} xs={12} className="colRight">
                            <Nav>
                                <Nav.Link
                                    href="https://www.ahead.pro" target="_blank"
                                    className="aheadLogoAnimation"
                                ></Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </footer >
    )
}

export default Footer;