import React, { useContext } from 'react'
import { Alert, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { ErrorComponent } from './../../components/Alert/Alert';
const ExchangeTransferDetails = ({ transferDetails }) => {
    const { userAuthState } = useContext(AuthContext)
    return (
        <div className="bankTransferDetails">

            {
                (transferDetails && transferDetails.personName) ?
                    <>
                        <div className="sectionHeading">
                            If you wish to pay via money exchange transfer, send your payment to the following details.
                        </div>
                        <div className="details">
                            <ul>
                                <li>Receiver Name:</li>
                                <li>{transferDetails.personName}</li>

                                {
                                    transferDetails.personPhoneNumber &&
                                    <>
                                        <li>Receiver Phone:</li>
                                        <li>{transferDetails.personPhoneNumber}</li>
                                    </>
                                }


                            </ul>
                        </div>
                    </>
                    :
                    <ErrorComponent />
            }



            <Alert variant="purple" className="mt-4">
                {userAuthState.user ? (
                    <>
                        <p className="mb-0">
                            After your successful money exchange transfer kindly upload the transfer slip to us from your dashboard.
                        </p>
                        {/* <p className="mt-2 mb-0">
                            <Link to="/dashboard/uploadTransferSlip" className="link white"> Upload slip now </Link>
                        </p> */}
                    </>
                ) : (
                    <>
                        <p className="mb-0">
                            After your successful money exchange transfer kindly send transaction slip to our email.
                        </p>
                        <p className="mt-2 mb-0">
                            <Nav.Link
                                href="mailto:order@jnoonz.com?subject='Proff of payment'"
                                className="link white p-0">
                                order@jnoonz.com
                            </Nav.Link>
                        </p>
                    </>
                )}
            </Alert>


        </div>
    )
}

export default ExchangeTransferDetails
