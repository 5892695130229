import React from 'react'
import './ResetPassword.scss'
import { Col, Container } from 'react-bootstrap'
import InnerPageHeader from '../../components/InnerPageHeader/InnerPageHeader'
import ForgotPasswordForm from './ForgotPasswordForm'
const ForgotPassword = (props) => {
        return (
        <>
            <InnerPageHeader/>
            <Container className="passwordFormContainer">
                <Col className="innerpageCard hasMarginTop hasShadow">
                    <Col xl={6} xs={12} className="passwordFormHolder">
                    <div className="pageHeading mb-2">Forgot Password</div>
                    <ForgotPasswordForm/>
                    </Col>
                </Col>
            </Container>
        </>
        )
    }

export default ForgotPassword
