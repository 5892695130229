import React, { useReducer, useEffect, useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FaUser, FaLock, FaEnvelope, FaMapMarkerAlt, FaThumbsUp } from "react-icons/fa";
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { getProfileStatsAPI } from '../../apis/buyerAPIs'
import { AuthContext } from "../../contexts/AuthContext"
import { SkeletonDashboardItem } from '../../components/Skeleton/Skeleton';
import { ErrorComponent } from '../../components/Alert/Alert';

const profileDashboardItem = [
    {
        type: "personal",
        title: "Update personal details",
        isUpdated: false
    },
    {
        type: "emailids",
        title: "Add alternate email address",
        isUpdated: true
    },
    {
        type: "address",
        title: "Update shipping address",
        isUpdated: false
    },
    {
        type: "interest",
        title: "Update your interest",
        isUpdated: false
    },
    {
        type: "changepassword",
        title: "Change password",
        isUpdated: false
    },
]

const ProfileDashboard = (props) => {
    let { url } = useRouteMatch();
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const { getBearerTokenConfig } = useContext(AuthContext);



    const loadContent = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const apiResult = await getProfileStatsAPI(getBearerTokenConfig());
        console.log(apiResult);
        if (apiResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: apiResult.data
            });
        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            });
        }
    }
    useEffect(() => {

        loadContent();

    }, []);

    return (
        <Container className="dashboardItems ordersDashboard">
            <div className="pageHeading">Your Account</div>
            <div className="caption2">Manage your details.</div>
            <div className="mt-3">
                <Row>


                    {itemState.isFetching ? (
                        <SkeletonDashboardItem />
                    ) : itemState.hasError ? (
                        <ErrorComponent />
                    ) : (
                        <>
                            {(itemState.item && itemState.item.stats) ? (
                                <>
                                    <Col xl={6} lg={6} xs={12}>
                                        <Link className="nounderline dashboardItemBoxLink" to="/member/profile/update" >

                                            <div className={`dashboardBox personal ${itemState.item.stats.profileUpdateRequired ? 'shakeInfinite' : ''}`}>
                                                <Card
                                                    className={`${itemState.item.stats.profileUpdateRequired ? 'notUpdatedInfo' : 'updatedInfo'}`}
                                                    border={`${itemState.item.stats.profileUpdateRequired ? 'secondaryPink' : 'fourthGray'}`}
                                                >
                                                    <Card.Body>
                                                        <Row className="align-items-center">
                                                            <Col lg={3} sm={12} xs={12}>
                                                                <div className="icon">
                                                                    <FaUser className={`${itemState.item.stats.profileUpdateRequired ? 'bounceInfinite' : ''}`} />
                                                                </div>
                                                            </Col>
                                                            <Col lg={9} sm={12} xs={12} style={{ height: "100%" }}>
                                                                <Card.Title className="mb-0">

                                                                    {itemState.item.stats.profileUpdateRequired ? (
                                                                        <>
                                                                            Your profile is not completed, please update now!
                                                                        </>
                                                                    ) : (<>
                                                                        Update Profile
                                                                    </>
                                                                    )}



                                                                </Card.Title>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Link>
                                    </Col>





                                    <Col xl={6} lg={6} xs={12}>
                                        <Link className="nounderline dashboardItemBoxLink" to="/member/profile/alternate-email" >

                                            <div className={`dashboardBox personal ${itemState.item.stats.alternateEmailUpdateRequired ? 'shakeInfinite' : ''}`}>
                                                <Card
                                                    className={`${itemState.item.stats.alternateEmailUpdateRequired ? 'notUpdatedInfo' : 'updatedInfo'}`}
                                                    border={`${itemState.item.stats.alternateEmailUpdateRequired ? 'secondaryPink' : 'fourthGray'}`}
                                                >
                                                    <Card.Body>
                                                        <Row className="align-items-center">
                                                            <Col lg={3} sm={12} xs={12}>
                                                                <div className="icon">
                                                                    <FaEnvelope className={`${itemState.item.stats.alternateEmailUpdateRequired ? 'bounceInfinite' : ''}`} />
                                                                </div>
                                                            </Col>
                                                            <Col lg={9} sm={12} xs={12} style={{ height: "100%" }}>
                                                                <Card.Title className="mb-0">

                                                                    {itemState.item.stats.alternateEmailUpdateRequired ? (
                                                                        <>
                                                                            Please add your alternate email address!
                                                                        </>
                                                                    ) : (<>
                                                                        Update alternate email address
                                                                    </>
                                                                    )}



                                                                </Card.Title>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Link>
                                    </Col>





                                    <Col xl={6} lg={6} xs={12}>
                                        <Link className="nounderline dashboardItemBoxLink" to="/member/profile/interests" >

                                            <div className={`dashboardBox personal ${itemState.item.stats.interestsUpdatedRequired ? 'shakeInfinite' : ''}`}>
                                                <Card
                                                    className={`${itemState.item.stats.interestsUpdatedRequired ? 'notUpdatedInfo' : 'updatedInfo'}`}
                                                    border={`${itemState.item.stats.interestsUpdatedRequired ? 'secondaryPink' : 'fourthGray'}`}
                                                >
                                                    <Card.Body>
                                                        <Row className="align-items-center">
                                                            <Col lg={3} sm={12} xs={12}>
                                                                <div className="icon">
                                                                    <FaThumbsUp className={`${itemState.item.stats.interestsUpdatedRequired ? 'bounceInfinite' : ''}`} />
                                                                </div>
                                                            </Col>
                                                            <Col lg={9} sm={12} xs={12} style={{ height: "100%" }}>
                                                                <Card.Title className="mb-0">

                                                                    {itemState.item.stats.interestsUpdatedRequired ? (
                                                                        <>
                                                                            Please add your interests
                                                                        </>
                                                                    ) : (<>
                                                                        Update your interests
                                                                    </>
                                                                    )}
                                                                </Card.Title>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Link>
                                    </Col>




                                    <Col xl={6} lg={6} xs={12}>
                                        <Link className="nounderline dashboardItemBoxLink" to="/member/change-password" >

                                            <div className={`dashboardBox personal ${itemState.item.stats.passwordUpdatedRequired ? 'shakeInfinite' : ''}`}>
                                                <Card
                                                    className={`${itemState.item.stats.passwordUpdatedRequired ? 'notUpdatedInfo' : 'updatedInfo'}`}
                                                    border={`${itemState.item.stats.passwordUpdatedRequired ? 'secondaryPink' : 'fourthGray'}`}
                                                >
                                                    <Card.Body>
                                                        <Row className="align-items-center">
                                                            <Col lg={3} sm={12} xs={12}>
                                                                <div className="icon">
                                                                    <FaLock className={`${itemState.item.stats.passwordUpdatedRequired ? 'bounceInfinite' : ''}`} />
                                                                </div>
                                                            </Col>
                                                            <Col lg={9} sm={12} xs={12} style={{ height: "100%" }}>
                                                                <Card.Title className="mb-0">

                                                                    {itemState.item.stats.passwordUpdatedRequired ? (
                                                                        <>
                                                                            Please update your password
                                                                        </>
                                                                    ) : (<>
                                                                        Update password
                                                                    </>
                                                                    )}
                                                                </Card.Title>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Link>
                                    </Col>


                                </>
                            ) :
                                <ErrorComponent />
                            }
                        </>
                    )}



                </Row>
            </div>

        </Container>
    )
}
export default ProfileDashboard