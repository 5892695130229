import accounting from 'accounting'


// export const getDefaultSelectedCurrency = () => {
//     return (localStorage.getItem('defaultCurrency') ? localStorage.getItem('defaultCurrency') : 'USD')
// }

export const formatFractionValues = (amount) => {
    if (amount && amount > 0) {
        let isFloat = (((Number(amount) === amount && (amount % 1) !== 0)) ? true : false);
        //console.log(isFloat ? 'float' : 'whole');
        if (isFloat) {
            let fraction = parseFloat(accounting.toFixed((amount - Math.floor(amount)), 3));
            //console.log(' fraction ==> ' + fraction);
            let fractionLength = fraction.toString().length;
            fractionLength = fractionLength - 2;
            if (fractionLength > 3) {
                amount = accounting.toFixed(amount, 3);
            }


            fractionLength = fractionLength > 3 ? 3 : fractionLength;
            //console.log(' fractionLength ==> ' + fractionLength);
           
            return accounting.formatMoney(amount, '', fractionLength); // â‚¬4.999,99
        }
    }
    return amount;
}
export const formatFractionValuesLBP = (amount) => {
    if (amount && amount > 0) {
        let isFloat = (((Number(amount) === amount && (amount % 1) !== 0)) ? true : false);
        //console.log(isFloat ? 'float' : 'whole');
        if (isFloat) {
        
          return( Math.ceil(amount));


        }
    }
    return amount;
}

export const getPriceInSelectedCurrency = (price, selectedCurrencyCode, currencies, formatFractionValue = false) => {
    //console.log('getPriceInSelectedCurrency');
    //console.log(price, selectedCurrencyCode, currencies, formatFractionValue)
    
    if (
        currencies
        && selectedCurrencyCode !== 'USD'
        && currencies[selectedCurrencyCode]
        && currencies[selectedCurrencyCode].exchange_rate
    ) {
        price = ((price * 1) * (currencies[selectedCurrencyCode].exchange_rate * 1))
        if(currencies
            && selectedCurrencyCode == 'LBP'){
                if (formatFractionValue) {
       
                    return formatFractionValuesLBP(price);
                }
            }
    }


    if (formatFractionValue) {
       
        return formatFractionValues(price);
    }else {
            
    
        return price;
    }
}

export const getSelectedCurrencySymbol = (selectedCurrencyCode, currencies) => {
    if (
        currencies
        && currencies[selectedCurrencyCode]
        && currencies[selectedCurrencyCode].symbol
    ) {
        // console.log(currencies[selectedCurrencyCode].symbol);
        return currencies[selectedCurrencyCode].symbol
    }
}


export const getPriceClasses = (price, type, old_price) => {
    let classes = '';
    let length = (price + '').length
    // console.log(length)
    if (old_price !== undefined && old_price !== price && old_price !== null) {
        classes = 'isDiscountedprice';
    }



    if (type === 'product_item') {
        if (length > 11) {
            classes += ' price_font_xxsmall';
        } else if (length > 9) {
            classes += ' price_font_xsmall';
        } else if (length > 7 ) {
            classes += ' price_font_small';
        } else if (length > 5) {
            classes += ' price_font_medium';
        }
        else if (length < 4) {
            classes += ' price_font_large';
        }
       
    } else if (type === 'medium_box') {
        if (length > 10) {
            classes += ' price_font_small';
        } else if (length > 8) {
            classes += ' price_font_medium';
        }
    } else if (type === 'basket_box') {
        if (length > 8) {
            classes += ' price_font_small';
        } else if (length > 7) {
            classes += ' price_font_medium';
        }
    } else if (type === 'final_checkout') {
        if (length > 15) {
            classes += ' price_font_xxsmall';
        } else if (length > 12) {
            classes += ' price_font_xsmall';
        } else if (length > 10) {
            classes += ' price_font_small';
        } else if (length > 8) {
            classes += ' price_font_medium';
        }
    }
//   console.log(classes);
    return classes;
   
}

export const getPriceInUSD = (price, selectedCurrencyCode, currencies, formatFractionValue = false) => {
    
    if (
        currencies
        && selectedCurrencyCode !== 'USD'
        && currencies[selectedCurrencyCode]
        && currencies[selectedCurrencyCode].exchange_rate
    ) {
        price = ((price * 1) / (currencies[selectedCurrencyCode].exchange_rate * 1))
    }

    if (formatFractionValue) {
        return formatFractionValues(price);
    } else {
        return price;
    }
}