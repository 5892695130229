import React, { useReducer, useState, useEffect, useContext } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInput from '../../components/FormPartials/FormInput'
import CountriesInput from '../../components/FormPartials/CountriesInput'
import GenderInput from '../../components/FormPartials/GenderInput'
import AgeGroupInput from '../../components/FormPartials/AgeGroupInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { AuthContext } from '../../contexts/AuthContext'
import GoBack from '../../components/GoBack/GoBack'
import { updateProfileAPI } from '../../apis/buyerAPIs'
const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter your first name"),
    last_name: Yup.string().required("Please enter your last name"),
    mobile: Yup.string()
        .required("Please enter your mobile")
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
            , 'Mobile number is not valid'),
    // countryCodeMobile: Yup.string().required("Please enter dial code")
    //     .matches(/(?=.*[0-9])/, "Please enter numbers only")
    //     .min(1, "Min 1 digits.")
    //     .max(5, "Max 4 digits."),
    country_id: Yup.string().required('Please select your country'),
    gender: Yup.string().required('Please select your gender'),
    age_group: Yup.string().required('Please select your age group'),
});


const EditPersonal = (props) => {
    const { history } = props;
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { userAuthState, getBearerTokenConfig, userAuthDispatch } = useContext(AuthContext)

    console.log('userAuthState', userAuthState.user);
    const [initialValues, setInitialValues] = useState({
        id: userAuthState.user.id,
        first_name: userAuthState.user.first_name,
        last_name: userAuthState.user.last_name,
        mobile: userAuthState.user.mobile,
        gender: userAuthState.user.gender,
        country_id: userAuthState.user.country_id,
        age_group: userAuthState.user.age_group
    });

    return (
        <div>
            <GoBack history={history} />
            <div className="pageHeading">Edit Your Personal Details </div>
            <div className="caption2">You can update your personal information with JNooNz by providing/updating below information.</div>
            <div className="formContainer mt-4">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle="Your personal details are updated successfully"
                                msgBody=""
                            />
                        ) : (
                            <>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const apiResult = await updateProfileAPI(values, getBearerTokenConfig());
                                        console.log(apiResult);
                                        if (apiResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                            userAuthDispatch({
                                                type: "LOGIN",
                                                payload: {
                                                    user: apiResult.data.user
                                                }
                                            })
                                            setInitialValues({
                                                id: apiResult.data.user.id,
                                                first_name: apiResult.data.user.first_name,
                                                last_name: apiResult.data.user.last_name,
                                                mobile: apiResult.data.user.mobile,
                                                gender: apiResult.data.user.gender,
                                                country_id: apiResult.data.user.country_id,
                                                age_group: apiResult.data.user.age_group
                                            })

                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED"
                                            });
                                        }

                                        setTimeout(() => {
                                            formDispatch({
                                                type: "FORM_INITIAL_STAGE",
                                            });
                                        }, 5000);
                                        setSubmitting(false); //// Important
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {formState.hasSubmissionError && <FormSubmissionFailed />}
                                            <Form.Row>
                                                <Col xl={6} lg={12} md={12} sm={12} xs={12} className={`mendatory ${errors.first_name && touched.first_name ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="first_name"
                                                        label="My First Name"
                                                        name="first_name"
                                                        type="text"
                                                        value={values.first_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                                <Col xl={6} lg={12} md={12} sm={12} xs={12} className={`mendatory ${errors.last_name && touched.last_name ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="last_name"
                                                        label="My Last Name"
                                                        name="last_name"
                                                        type="text"
                                                        value={values.last_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Col xl={6} lg={12} md={12} sm={12} xs={12}
                                                    className={`mendatory ${errors.mobile ? "errorParent" : ""}`}>
                                                    <FormInput
                                                        controlId="mobile"
                                                        label="Receiver's Mobile"
                                                        name="mobile"
                                                        type="tel"
                                                        value={values.mobile}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>

                                                <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                                                    <Form.Row>
                                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}
                                                            className={`mendatory ${errors.gender && touched.gender ? "errorParent" : ""}`}
                                                        >
                                                            <GenderInput
                                                                controlId="gender"
                                                                label="My Gender"
                                                                name="gender"
                                                                as="select"
                                                                value={values.gender}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </Col>
                                                        <Col xl={6} lg={12} md={12} sm={12} xs={12}
                                                            className={`mendatory ${errors.age_group && touched.age_group ? "errorParent" : ""}`}
                                                        >
                                                            <AgeGroupInput
                                                                controlId="age_group"
                                                                label="My Age Group"
                                                                name="age_group"
                                                                as="select"
                                                                value={values.age_group}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </Col>

                                                    </Form.Row>
                                                </Col>

                                                <Col xl={6} lg={12} md={12} sm={12} xs={12} className={`${errors.country && touched.country ? "errorParent" : ""}`}>
                                                    <CountriesInput
                                                        listType='all'
                                                        controlId="country_id"
                                                        label="Select Country of Residence"
                                                        name="country_id"
                                                        as="select"
                                                        value={values.country_id}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>

                                            </Form.Row>

                                            <Button variant="PurpleButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default EditPersonal
