import React from 'react';
import "./Loading.scss";
import loadingImage from '../../assets/images/jnoon_animatedLoading.gif'
export const ContainerLoading = () => {
  return(
    <div className="loadingContainer hasLightBg">
      <div className="loadingImage">
          <img src={loadingImage} alt=""/>
      </div>
    </div>
  )
}

export const FullPageLoading = () => {
  return(
    <div className="loadingContainer hasBg fixed">
      <div className="loadingImage">
          <img src={loadingImage} alt=""/>
      </div>
    </div>
  )
}

export const FullPageLoadingLight = () => {
  return(
    <div className="loadingContainer hasLightBg fixed">
      <div className="loadingImage">
          <img src={loadingImage} alt=""/>
      </div>
    </div>
  )
}