import React, { useEffect, useState, useReducer, useRef } from 'react'
import "./JnoonzofthedaySlider.scss";
import { Container, Col, Row } from "react-bootstrap"
import { jnoonzDealsReducer, jnoonzDealsInitialState } from "../../reducers/Reducers"
import ProductItem from "../ProductItem/ProductItem"
import { SkeletonProductSlider } from "../Skeleton/Skeleton"
import { ErrorComponent } from "../Alert/Alert"
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper'
import { FaCaretRight, FaCaretLeft } from "react-icons/fa"
import dealclosedBanner from '../../assets/images/dealclosedBanner.png'
import { BiStopwatch } from "react-icons/bi"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import { productSliderParams } from '../../helpers/DOMHelpers'
import { ContainerLoading } from './../Loading/Loading';
import Clock from './../Clock/Clock';
import { getJnoonzDealAPI } from '../../apis/generalAPIs'

SwiperCore.use([Navigation, Autoplay, Scrollbar]);
const JnoonzofthedaySlider = (props) => {
  const clockDefaultState = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  const [clockTime, setClockTime] = useState(clockDefaultState);
  const [dealIsFinished, setDealIsFinished] = useState(false);


  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [dealState, dealDispatch] = useReducer(jnoonzDealsReducer, jnoonzDealsInitialState);


  const loadContent = async () => {
    dealDispatch({
      type: "FETCH_DEAL_REQUEST"
    });
    const submissionResult = await getJnoonzDealAPI();
    if (submissionResult.status) {
      dealDispatch({
        type: "FETCH_DEAL_SUCCESS",
        payload: submissionResult.data
      });
    } else {
      dealDispatch({
        type: "FETCH_DEAL_FAILURE"
      });
    }

  }

  useEffect(() => {
    loadContent();
  }, []);

  function updateClock(timeRemaining) {
    //console.log(timeRemaining);

    if (timeRemaining <= 0) {

      setClockTime(clockDefaultState);

      //toggleTimer(false);
      setDealIsFinished(true);
    } else {

      const seconds = Math.floor((timeRemaining) % 60);
      const minutes = Math.floor((timeRemaining / 60) % 60);
      const hours = Math.floor((timeRemaining / (60 * 60)) % 24);
      const days = Math.floor(timeRemaining / (60 * 60 * 24));
      setClockTime({ days: days, hours: hours, minutes: minutes, seconds: seconds });
      //setState({ days, hours, minutes, seconds });
      //console.log('clock time set', { days: days, hours: hours, minutes: minutes, seconds: seconds });
    }


  }

  useEffect(() => {

    if (dealState.isLoaded) {
      // console.log('useEffect of dealState called', dealState.isLoaded);


      if (dealState.deal) {
        let timeRemaining = (dealState.deal && dealState.deal.end_date_time) ? (dealState.deal.end_date_time) : 0
        setInterval(() => {
          timeRemaining = Math.max((timeRemaining - 1), 0);
          updateClock(timeRemaining)
        }, 1000);
      } else {
        setDealIsFinished(true);
      }
    }
  }, [dealState.isLoaded])



  return (
    <>
      <Container className="productSlider jnoonzoftheDaySlider sliderBottomSpacing">
        <Row>
          {dealState.isFetching
            ?
            <Col className="hasThemeBg" xl={12} lg={12} md={12} sm={12} xs={12}>
              <ContainerLoading />
            </Col>
            : (dealState.hasError) ?
              <Col className="hasThemeBg" xl={12} lg={12} md={12} sm={12} xs={12}>
                <ErrorComponent />
              </Col>
              : ((dealState.isLoaded) &&
                <>
                  <Col className="productSliderHeadingContainer hasThemeBg" 
                  xl={2} lg={3} md={12} sm={12} xs={12}>
                    <BiStopwatch className="stopWatchIcon" />


                    {(dealIsFinished) ?
                      <div className="dealIsFinished">
                        <p>Deal is finished now!</p>
                      </div> :
                      <Clock clockTime={clockTime} />
                    }

                    <div className="heading">
                      <span className="jnoonzWord">
                        <span className="firstLetter">j</span>
                        <span className="secondLetter">n</span>
                        <span className="thirdLetter">o</span>
                        <span className="fourthLetter">o</span>
                        <span className="fifthLetter">n</span>
                        <span className="sixthLetter">z</span>
                        <span className="starburst white  " ></span>
                      </span> of the day
                    </div>
                  </Col>




                  <Col
                    className={`productSliderContainer ${dealState.hasError ? 'hasError' : ''}`}
                    xl={10} lg={9} md={12} sm={12} xs={12} >

                    {dealIsFinished ? (
                      <>
                        <div className="dealClosedContainer">
                          <img src={dealclosedBanner}
                            alt="JNooNZ deals"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {dealState.hasError ? (
                          <ErrorComponent />
                        ) : (
                          <>
                            {dealState.deal && dealState.deal.items && dealState.deal.items.length > 0 ? (
                              <>
                                <div
                                  onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                                  onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                                  className="sliderWrapper"
                                >
                                  <Swiper
                                    ref={swiperRef}
                                    navigation={{
                                      prevEl: navigationPrevRef.current,
                                      nextEl: navigationNextRef.current,
                                    }}
                                    {...productSliderParams}
                                    onSwiper={(swiper) => {
                                      setTimeout(() => {

                                        if (swiper && swiper.params && swiper.params.navigation) {
                                          swiper.params.navigation.prevEl = navigationPrevRef.current
                                          swiper.params.navigation.nextEl = navigationNextRef.current
                                          setIsFirstSlide(true);
                                          swiper.navigation.destroy()
                                          swiper.navigation.init()
                                          swiper.navigation.update()
                                        }

                                      })
                                    }}
                                    onSlideChange={(swiper) => {
                                      if (swiper.activeIndex === 0) {
                                        setIsFirstSlide(true);
                                      }
                                      else if (swiper.isEnd) {
                                        setIsLastSlide(true);
                                      }
                                      else {
                                        setIsFirstSlide(false);
                                        setIsLastSlide(false);
                                      }
                                    }}
                                  // onReachBeginning ={() => {console.log('slide reach on start')}}
                                  // onReachEnd={() => {console.log('slide reach on end')}}
                                  >

                                    {dealState.deal.items.map((item, index) => (

                                      (item.product && item.product.status)
                                      && <SwiperSlide className="swiper-slide productSlideContent" key={index}>

                                        <ProductItem key={index} item={item.product} />

                                      </SwiperSlide>

                                    ))}
                                  </Swiper>
                                  <div ref={navigationPrevRef}
                                    className={`customSliderNavigation prev ${isFirstSlide ? "hide" : ""}`}
                                  >
                                    <FaCaretRight className="customSliderPrevIcon" />
                                  </div>
                                  <div ref={navigationNextRef}
                                    className={`customSliderNavigation next ${isLastSlide ? "hide" : ""}`}
                                  >
                                    <FaCaretLeft className="customSliderNextIcon" />
                                  </div>
                                </div>
                              </>
                            ) :
                              (
                                <SkeletonProductSlider />
                              )}
                          </>
                        )
                        }
                      </>
                    )}


                  </Col>



                </>
              )




          }




        </Row>
      </Container>
    </>
  )
}

export default JnoonzofthedaySlider;