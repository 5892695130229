import React from 'react'
import { ShippingAddressProvider } from '../../contexts/ShippingAddressContext'
import UpdateShippingAddressForm from './UpdateShippingAddressForm';


const UpdateShippingAddress = (props) => {
    const { match } = props;
    const id = match.params.id;
    return (
        <ShippingAddressProvider>
            <UpdateShippingAddressForm id={id} />
        </ShippingAddressProvider>
    )
}

export default UpdateShippingAddress
