import React, { useContext } from 'react';
import "./TopHeader.scss";
import {
    Container, Card
} from 'react-bootstrap';
import { IoLogoWhatsapp } from "react-icons/io";
import Notifications from "../Notifications/Notifications";
import JnoonzSpecialZone from "../JnoonzSpecialZone/JnoonzSpecialZone";
import { BackdropContext } from "../../contexts/BackdropContext";
import { GlobalContext } from './../../contexts/GlobalContext';
import { FullPageLoadingLight } from '../Loading/Loading';

const TopHeader = ({ configsItem, props }) => {
    const { backdropState } = useContext(BackdropContext);
    const { config } = useContext(GlobalContext).globalData.data;

    //console.log(config);

    return (
        <>
       
            <Card bg="tertiaryPink" text="primaryGray"
                className={`topHeader ${backdropState.isShowing ? "bigZindex" : ""}`}
            >
                <Container className="topHeaderContainer">
                    <div className="topHeaderButtons">
                        <ul>
                            <li>
                                <JnoonzSpecialZone />
                            </li>
                            <li>
                                <Notifications />
                            </li>
                            {(config && config.phone) && <li>
                                <span className="optionalTextToShow">Customer Services</span>
                                <a href={`https://api.whatsapp.com/send?phone=${config.phone}&text=Hi, JNooNZ Customer Care.`}
                                    target="_blank"
                                    className="topHeaderLinksIcons"
                                    rel="noopener noreferrer">
                                    <IoLogoWhatsapp className="icon ml-2 mr-2" />
                                    <span className="optionalTextToShow">{config.phone}</span>
                                    {/* +961 3 673169 */}
                                </a>
                            </li>}

                            {(config && config.hashtag) && <li className="hashTag ">
                                <div className="">
                                    <a className="twitter-share-button"
                                        target="_blank"
                                        aria-label="#jnoonz"
                                        rel="noopener noreferrer"
                                        href={`https://twitter.com/intent/tweet?text=Hi%20%23jnoonz%20`}
                                    >
                                        {config.hashtag}</a>
                                </div>
                            </li>}

                        </ul>
                    </div>
                    {/* <Row className="justify-content-md-center align-items-center fullHeight">
            <Col lg={7} xs={8} sm={8} className="justify-content-md-center fullHeight" style={{
                    margin: "0 auto",
                }}>
                <Row noGutters sm={12} className="align-items-center fullHeight">
                <Col className="textCenter topHeaderLinks" xs={4} sm={4}>
                    <JnoonzSpecialZone/>
                    </Col>
                <Col className="textCenter topHeaderLinks" xs={4} sm={4}>
                    <Notifications/>
                </Col>
                <Col className="textCenter topHeaderLinks" xs={4} sm={4}>
                    <span className="optionalTextToShow">Customer Services</span>
                    <FaWhatsapp className="topHeaderLinksIcons ml-2 mr-2"/>
                    <a href="https://api.whatsapp.com/send?phone=009613673169&text=Hi, JNooNZ Customer Care."
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="optionalTextToShow">+961 3 673169</span>
                    </a>
                </Col>
                </Row>
            </Col>
            <Col className="hashTag textRight" lg={1} xs={4} sm={4}>
            <a className="twitter-share-button"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/intent/tweet?text=Hello%20world"
            >
        #JNooNz</a>
            </Col>
            </Row> */}
                </Container>
            </Card>
        </>
    )
}
export default TopHeader;