import React, { useState, useEffect } from 'react'
import { Form, Col, Button } from "react-bootstrap"
import FormInput from '../../../components/FormPartials/FormInput'
import { Formik } from 'formik'


const FilterByPrice = ({ appliedFilters, applyNewPriceFilter }) => {

    const [min, setMin] = useState((appliedFilters['price-min'] !== undefined && appliedFilters['price-min'] > 0) ? appliedFilters['price-min'] : '');
    const [max, setMax] = useState((appliedFilters['price-max'] !== undefined && appliedFilters['price-max'] > 0) ? appliedFilters['price-max'] : '');
    const [error, setError] = useState('');


    const changeNegativeValueToPositive = (value) => {
        if (value && value < 0) {
            value = (value * -1)
        }
        return value
    }

    useEffect(() => {
        let minValue = (min !== '') ? (1 * min) : min;
        let maxValue = (max !== '') ? (1 * max) : max;

        if (minValue === 0) {
            minValue = '';
            setMin('')
        }

        if (minValue === '' || maxValue === '') {
            setError("")
        } else if (minValue < maxValue) {
            setError("")
        } else {
            if (maxValue && minValue >= maxValue) {
                setError("Please enter a value less than max value")
            }
        }
    }, [min])


    useEffect(() => {
        let minValue = (min !== '') ? (1 * min) : min;
        let maxValue = (max !== '') ? (1 * max) : max;


        if (maxValue === 0) {
            maxValue = '';
            setMax('')
        }

        if (minValue === '' || maxValue === '') {
            setError("")
        } else if (minValue < maxValue) {
            setError("")
        } else {
            if (minValue && maxValue <= minValue) {
                setError("Please enter a value more than min value")
            }
        }
    }, [max])

    return (
        <>
            <div className="checkboxFilterContainer filterSectionTopMargin">
                <div className="filterSectionHeading">
                    By Price
                </div>
                <ul>
                    <li className="filterElementsHolder hasCursor">
                        <div className="formContainer mt-2">

                            <Formik
                                initialValues={{
                                    min: "",
                                    max: "",
                                }}
                                enableReinitialize={true}
                                validationSchema=""
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    console.log("handle submit called with values", min, max);
                                    let minValue = (min !== '') ? (1 * min) : min;

                                    let maxValue = (max !== '') ? (1 * max) : max;

                                    if (error === '') {
                                        applyNewPriceFilter(minValue, maxValue)
                                    }

                                }}
                            >
                                {({ values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} className="mx-auto">

                                        <Form.Row>
                                            <Col xl={4} lg={4}>
                                                <div className="inputHolder hideInputLabel">
                                                    <FormInput
                                                        controlId="min"
                                                        placeholder="From"
                                                        name="min"
                                                        type="number"
                                                        value={min || ''}
                                                        onChange={(e) => {
                                                            setMin(changeNegativeValueToPositive(e.target.value))
                                                        }
                                                        }
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4}>
                                                <div className="inputHolder hideInputLabel">
                                                    <FormInput
                                                        controlId="max"
                                                        placeholder="To"
                                                        name="max"
                                                        type="number"
                                                        value={max || ''}
                                                        onChange={(e) => {
                                                            setMax(changeNegativeValueToPositive(e.target.value))
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={4} lg={4}>
                                                <Button
                                                    type="submit"
                                                    disabled={error !== ''}
                                                    className="lessCorners" variant="PurpleButton" block>
                                                    Go
                                                </Button>
                                            </Col>
                                        </Form.Row>


                                        {error && <Form.Row>
                                            <Col xl={12} lg={12}>
                                                <div className="errorMessage" style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>
                                                    {error}
                                                </div>
                                            </Col>
                                        </Form.Row>}


                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default FilterByPrice
