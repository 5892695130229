export const productsInitialState = {
  products: {},
  qa: [],
  isFetching: false,
  hasError: false,

};

export const productReducer = (productState, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS_REQUEST":
      return {
        ...productState,
        isFetching: true,
        hasError: false
      };
    case "FETCH_PRODUCTS_SUCCESS":
      // localStorage.setItem("productState", JSON.stringify(action.payload));
      return {
        ...productState,
        isFetching: false,
        products: action.payload
      };
    case "FETCH_PRODUCTS_FAILURE":
      return {
        ...productState,
        hasError: true,
        isFetching: false
      };
    default:
      return productState;
  }
};

export const jnoonzDealsInitialState = {
  deal: {

  },

  isFetching: false,
  isLoaded: false,
  hasError: false,
};

export const jnoonzDealsReducer = (dealState, action) => {
  switch (action.type) {
    case "FETCH_DEAL_REQUEST":
      return {
        ...dealState,
        isFetching: true,
      };
    case "FETCH_DEAL_SUCCESS":
      return {
        ...dealState,
        isFetching: false,
        isLoaded: true,
        deal: action.payload
      };
    case "FETCH_DEAL_FAILURE":
      return {
        ...dealState,
        hasError: true,
        isFetching: false,
        isLoaded: false
      };
    default:
      return dealState;
  }
};


export const itemInitialState = {
  item: [],
  isFetching: false,
  hasError: false,
  isConfigLoaded: false,
  isRemoving: false,
  isLoggedIn: false,
};

export const itemReducer = (itemState, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...itemState,
        isFetching: true,
        hasError: false
      };
    case "FETCH_SUCCESS":
      return {
        ...itemState,
        isFetching: false,
        isConfigLoaded: true,
        item: action.payload
      };
    case "FETCH_FAILURE":
      return {
        ...itemState,
        hasError: true,
        isFetching: false
      };
    case "REMOVE_ITEM_REQUEST":
      return {
        ...itemState,
        isRemoving: true,
        hasError: false
      };
    case "REMOVE_ITEM_SUCCESS":
      return {
        ...itemState,
        isRemoving: false,
        hasError: false,
        item: itemState.item.filter(item => item.id !== action.payload)
      };
    case "LOGGED_IN_STATUS":
      return {
        ...itemState,
        isLoggedIn: true,
        item: action.payload
      };
    default:
      return itemState;
  }
};

export const formInitialState = {
  isFormSubmitting: false,
  isFormSubmitted: false,
  hasSubmissionError: false,
  inFormInitial: true,
};

export const formSubmissionReducer = (formState, action) => {
  switch (action.type) {
    case "FORM_INITIAL_STAGE":
      return {
        inFormInitial: true,
        isFormSubmitting: false,
        isFormSubmitted: false,
        hasSubmissionError: false
      };
    case "FORM_SUBMISSION_START":
      return {
        inFormInitial: false,
        isFormSubmitting: true,
        isFormSubmitted: false,
        hasSubmissionError: false
      };
    case "FORM_SUBMISSION_SUCCESS":
      return {
        inFormInitial: false,
        isFormSubmitted: true,
        isFormSubmitting: false,
        hasSubmissionError: false
      };
    case "FORM_SUBMISSION_FAILED":
      return {
        inFormInitial: false,
        isFormSubmitting: false,
        isFormSubmitted: false,
        hasSubmissionError: true,

      };
    default:
      return formState;
  }
}


export const backdropInitialState = {
  isShowing: false,
  isShowingHorizontal: false,
  hasError: false,
  isHorizontal: false,
  isShowingSolid: false
};

export const backdropReducer = (backdropState, action) => {
  switch (action.type) {
    case "FETCH_BACKDROP_OPEN":
      return {
        isShowing: true,
        hasError: false,
        isHorizontal: false,
      }
    case "FETCH_BACKDROPHORIZONTAL_OPEN":
      return {
        isShowingHorizontal: true,
        hasError: false,
        isHorizontal: true,
      }
    case "FETCH_BACKDROPSOLID_OPEN":
      return {
        isShowingSolid: true,
      }
    case "FETCH_BACKDROPSOLID_CLOSE":
      return {
        isShowingSolid: false,
      }
    case "FETCH_BACKDROP_CLOSE":
      return {
        isShowing: false,
        isShowingHorizontal: false,
        hasError: false,
      }
    case "FETCH_BACKDROP_FAILURE":
      return {
        isShowing: false,
        isShowingHorizontal: false,
        hasError: true
      }
    default:
      return backdropState;
  }
};


export const overlayInitialState = {
  isShowing: false,
  hasError: false,
};

export const overlayReducer = (overlayState, action) => {
  switch (action.type) {
    case "OVERLAY_OPEN":
      return {
        isShowing: true,
        hasError: false,
      }
    case "OVERLAY_CLOSE":
      return {
        isShowing: false,
        hasError: false,
      }
    case "OVERLAY_FAILURE":
      return {
        isShowing: false,
        hasError: true
      }
    default:
      return overlayState;
  }
};

export const searchOverlayReducer = (searchOverlayState, action) => {
  switch (action.type) {
    case "OVERLAY_OPEN":
      return {
        isShowing: true,
        hasError: false,
      }
    case "OVERLAY_CLOSE":
      return {
        isShowing: false,
        hasError: false,
      }
    case "OVERLAY_FAILURE":
      return {
        isShowing: false,
        hasError: true
      }
    default:
      return searchOverlayState;
  }
};

export const CartOverlayReducer = (cartOverlayState, action) => {
  switch (action.type) {
    case "OVERLAY_OPEN":
      return {
        isShowing: true,
        hasError: false,
      }
    case "OVERLAY_CLOSE":
      return {
        isShowing: false,
        hasError: false,
      }
    case "OVERLAY_FAILURE":
      return {
        isShowing: false,
        hasError: true
      }
    default:
      return cartOverlayState;
  }
};

export const WishlistOverlayReducer = (wishlistOverlayState, action) => {
  switch (action.type) {
    case "OVERLAY_OPEN":
      return {
        isShowing: true,
        hasError: false,
      }
    case "OVERLAY_CLOSE":
      return {
        isShowing: false,
        hasError: false,
      }
    case "OVERLAY_FAILURE":
      return {
        isShowing: false,
        hasError: true
      }
    default:
      return wishlistOverlayState;
  }
};

export const UserOverlayReducer = (userOverlayState, action) => {
  switch (action.type) {
    case "OVERLAY_OPEN":
      return {
        isShowing: true,
        hasError: false,
      }
    case "OVERLAY_CLOSE":
      return {
        isShowing: false,
        hasError: false,
      }
    case "OVERLAY_FAILURE":
      return {
        isShowing: false,
        hasError: true
      }
    default:
      return userOverlayState;
  }
};

export const popupInitialState = {
  openSigninPopup: false,
  openWishlistEmailPopup: false,
  openSignupPopup: false,
  openPopup: false,
  hasError: false,
};

export const popupReducer = (popupState, action) => {
  switch (action.type) {
    case "POPUP_OPEN":
      return {
        openPopup: true,
      }
    case "POPUP_CLOSE":
      return {
        openPopup: false,
      }
    case "POPUP_SIGNIN_OPEN":
      return {
        openSigninPopup: true,
      }
    case "POPUP_SIGNIN_CLOSE":
      return {
        openSigninPopup: false,
      }
    case "POPUP_SIGNUP_OPEN":
      return {
        openSignupPopup: true,
      }
    case "POPUP_SIGNUP_CLOSE":
      return {
        openSignupPopup: false,
      }
    case "POPUP_WISHLISTEMAIL_OPEN":
      return {
        openWishlistEmailPopup: true,
      }
    case "POPUP_WISHLISTEMAIL_CLOSE":
      return {
        openWishlistEmailPopup: false,
      }
    case "POPUP_FAILURE":
      return {
        openSigninPopup: false,
        openSignupPopup: false,
        openPopup: false,
        hasError: true
      }
    default:
      return popupState;
  }
};

export const userAuthReducer = (userAuthState, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.removeItem("guest_session_token");
      localStorage.removeItem("wishlistUser");

      return {
        ...userAuthState,
        isAuthenticated: true,
        user: action.payload.user,
        status: action.payload.status,
        error: action.payload.errors,
        token: action.payload.user ? action.payload.user.token : null
      };
    case "LOGOUT":
      //localStorage.clear();
      localStorage.removeItem("user");
      //localStorage.removeItem("token");
      localStorage.removeItem("guest_session_token");
      return {
        ...userAuthState,
        isAuthenticated: false,
        user: null,
        status: null,
        error: null,
        token: null
      };
    default:
      return userAuthState;
  }
};



export const toastInitialState = {
  isShowingToast: false,
};
export const ToastReducer = (showToastState, action) => {
  switch (action.type) {
    case "TOAST_SHOW":
      return {
        isShowingToast: true,
      }
    case "TOAST_CLOSE":
      return {
        isShowingToast: false,
      }
    default:
      return showToastState;
  }
};