import React, { useReducer, useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../components/FormPartials/FormInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { FullPageLoadingLight } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { contactFormValidation } from '../../components/FormPartials/Validation'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'
import FormTextArea300 from '../../components/FormPartials/FormTextArea300'
import { submitContactUsAPI } from '../../apis/generalAPIs'


const ContactForm = (props) => {
    const [formSubmissionMessage, setFormSubmissionMessage] = useState()
    const [countTwo, setCountTwo] = useState(0);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);


    return (
        <div className="contactFormContainer relative">
            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <FullPageLoadingLight />}
                        {formState.isFormSubmitted ? (
                            <>
                                {formSubmissionMessage &&
                                    <SuccessMsg
                                        msgTitle={formSubmissionMessage.heading}
                                        msgBody={formSubmissionMessage.message}
                                    />
                                }
                            </>
                        ) : (
                            <>
                                <div className="pageHeading">
                                    Send your Inquiry
                                </div>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        full_name: "",
                                        email: "",
                                        message: "",
                                    }}
                                    validationSchema={contactFormValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {

                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const submissionResult = await submitContactUsAPI(values);
                                        
                                        if (submissionResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                            setFormSubmissionMessage(submissionResult.data)
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);

                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                        }
                                        setSubmitting(false);

                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {formState.hasSubmissionError && <FormSubmissionFailed />}
                                            <Form.Row>
                                                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <div className="colLeft">
                                                        <Col className={`p-0 mendatory ${errors.full_name && touched.full_name ? "errorParent" : ""}`}>
                                                            <FormInput
                                                                controlId="full_name"
                                                                label="Full Name"
                                                                name="full_name"
                                                                type="text"
                                                                value={values.full_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                        </Col>
                                                        <Col className={`p-0 mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                                                            <FormInput
                                                                controlId="email"
                                                                label="Your Email Address"
                                                                name="email"
                                                                type="email"
                                                                value={values.email}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </Col>

                                                    </div>
                                                </Col>
                                                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <div className="colRight">
                                                        <Col className={`p-0 mendatory ${errors.message && touched.message ? "errorParent" : ""}`}>
                                                            <FormTextArea300
                                                                controlId="message"
                                                                label="Your Inquiry"
                                                                name="message"
                                                                type="text"
                                                                as="textarea"
                                                                rows={4}
                                                                value={values.message}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    setCountTwo(e.target.value.length);
                                                                }}
                                                                onBlur={handleBlur}
                                                                countTwo={countTwo}
                                                            />
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row className="align-items-center mt-2">
                                                <Col className="p-0 buttonContainer" xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <Button variant="PurpleButton"
                                                        className="hasPadding uppercase hasShadow bold"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Col>
                                                <Col className="p-0" xl={6} lg={6} md={6} sm={6} xs={12}>
                                                    <MendatoryFieldMsg />
                                                </Col>
                                            </Form.Row>



                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default ContactForm
