import React, { useEffect, useReducer, useRef } from 'react';
import "./HomeMainCarousel.scss";
import { Nav, Container, Row } from "react-bootstrap";
import { ContainerLoading } from "../Loading/Loading";
import { productReducer, productsInitialState } from "../../reducers/Reducers";
import { ErrorComponentLight } from "../Alert/Alert";
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import { fetchBannersAPI } from '../../apis/generalAPIs'


SwiperCore.use([Pagination, Autoplay]);

const HomeMainCarousel = () => {
  const [productState, productDispatch] = useReducer(productReducer, productsInitialState);

  async function loadContent() {
    const submissionResult = await fetchBannersAPI();
    if (submissionResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: submissionResult.data
      });
    } else {
      productDispatch({
        type: "FETCH_PRODUCTS_FAILURE"
      });
    }
  }


  useEffect(() => {
    loadContent();
  }, []);

  const sliderParams = {

    speed: 600,
    pagination: { clickable: true },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,

    },
    breakpoints: {
      // when window width is >= 768px
      767: {
        direction: 'vertical',

      },
    }
  };
  const swiperRef = useRef(null)
  return (
    <>
      <div className="mainCarousel relative">
        <div className="homeSliderPattern top"></div>
        <Container className={`${productState.hasError ? 'hasError' : ''}`}>
          {productState.isFetching ? (
            <ContainerLoading />
          ) : productState.hasError ? (
            <ErrorComponentLight />

          ) : (
            <>
              {productState.products.length > 0 && (
                <>
                  <div
                    onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                    onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                    style={{
                      position: "relative",
                      height: "100%"
                    }}>
                    <Swiper
                      ref={swiperRef}
                      {...sliderParams}
                      onSlideChange={() => { }}
                      onSwiper={(swiper) => { }}
                      style={{ height: "100%" }}
                    >
                      {productState.products.slice(0, 5).map((item, index) => (
                        <SwiperSlide className="swiper-slide"
                          key={index}>
                          <Row
                            className="align-items-center justify-center homeSliderContentRow"
                            style={{ height: "100%" }}
                          >
                            <div className="carousel-caption">
                              <h3 className="mainSliderTitle">{item.title}</h3>
                              <h5 className="mainSliderSubTitle">{item.sub_title}</h5>

                              {(item.button_text && item.button_link) && (
                                <Nav className="mainSliderLinkHolder">
                                  <Nav.Link eventKey="1" target="_blank" className="btn btn-whiteButton hasPadding hasShadow uppercase" href={item.button_link}>
                                    {item.button_text}
                                  </Nav.Link>
                                </Nav>
                              )}

                            </div>
                            <div className="mainSliderImageHolder">
                              {item.offer_tag && (
                                <div className="offerTag starburst md">
                                  <div className="text">{item.offer_tag}</div>
                                </div>
                              )}
                              <img
                                className="mainSliderProductImage"
                                src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                alt={item.title}
                                title={item.title}
                              />
                            </div>
                          </Row>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="homeSliderProdPattern"></div>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
        <div className="homeSliderPattern bottom"></div>
      </div>
    </>
  )
};

export default HomeMainCarousel;