export const PackagingReducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_PACKAGING_POPUP':
            return {
                ...state,
                showModal: true,
                isLoading: true,
                hasError: false
            };

        case 'CLOSE_PACKAGING_POPUP':
            return {
                ...state,
                showModal: false,
                isLoading: false,
                hasError: false
            };

        case 'FETCH_PACKAGING_REQUEST':
            return {
                ...state,
                showModal: true,
                isLoading: true,
                hasError: false
            };
        case "FETCH_PACKAGING_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                options: action.payload,
            };
        case "FETCH_PACKAGING_ERROR":
            return {
                ...state,
                isLoading: false,
                hasError: true,
                options: []
            };


        default:
            return state;
    }
}