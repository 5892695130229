import React from 'react'
import {ListGroup, Row, Col} from 'react-bootstrap'
import {Time} from '../../components/TimeFormat/TimeFormat'
import { RiFolderReceivedLine, RiFolderSharedLine} from "react-icons/ri";

const MessageThreadItem = ({message, messageid, props}) => {
    return (
        <>
        <ListGroup.Item as="li" variant="transparent"
        className={`pr-2 pl-2 messageItemHolder ${
          message.sender_type === 'Admin' ? "receivedMessage" : 
        [
          message.sender_type === 'Buyer' ? "sentMessage" : [
        ]]}`}
        >
            <div className="messageGridHolder">
              <div className="colLeft">
                {message.sender_type === "Buyer" ? (
                  <RiFolderReceivedLine
                    className="sentMessageStatus"
                  />
                ):(
                  <RiFolderSharedLine
                    className="receivedMessageStatus"
                  />
                )}
              </div>
              <div className="colRight">
                <Row className="mb-2">
                <Col lg={9} sm={9} xs={12}>
                {message.sender_type === "Admin" && 
                  <div className="by">{message.sender_type}</div>
                }
                {message.sender_type === "Buyer" && 
                  <div className="by">You</div>
                }
                </Col>
                <Col lg={3} sm={3} xs={12}>
                <div className="messageDate textRight textAlignInheritMobile"><Time time={message.created_time}/></div>
                </Col>
                </Row>
                <Row>
                <Col>
                  <div className="messageSubject mb-1">{message.subject} </div>
                  <div className="messageText">{message.message}</div>
                </Col>
                </Row>
              </div>
            </div>
        </ListGroup.Item>
        </>
    )
}

export default MessageThreadItem
