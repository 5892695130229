import React, { useContext, useReducer } from 'react'
import './Dashboard.scss'
import { Container, Col, Row } from 'react-bootstrap'
import DashboardItems from './DashboardItems/DashboardItems'
import Orders from './Orders/Orders'
import Address from './Address/Address'
import UpdateShippingAddress from './Address/UpdateShippingAddress'
import Interest from './UserProfile/Interest'
import BuyerMessages from './Messages/BuyerMessages/BuyerMessages'
import BuyerMessageThread from './Messages/BuyerMessages/BuyerMessageThread';
import ChangePassword from './ChangePassword/ChangePassword'
import PageNotFound from '../components/Errors/PageNotFound'
import { useRouteMatch, Switch, NavLink, Route } from 'react-router-dom'
import {
    BiBarChartAlt, BiListCheck, BiLocationPlus, BiEnvelope, BiUser, BiLock
} from "react-icons/bi"
import { AuthContext } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import OrdersDashboard from './Orders/OrdersDashboard'
import OrderDetails from './Orders/OrderDetails'
import ProfileDashboard from './UserProfile/ProfileDashboard'
import EditPersonal from './UserProfile/EditPersonal'
import EditEmailID from './UserProfile/EditEmailID'
import OrderMessages from './Messages/OrderMessages/OrderMessages'
import OrderMessagesThread from './Messages/OrderMessages/OrderMessageThread'
import { logoutAPI } from '../apis/buyerAPIs'
import { itemReducer, itemInitialState } from "../reducers/Reducers"
import { CartContext } from "../contexts/CartContext"
const DashboardLanding = (props) => {
    const { setCartItems } = useContext(CartContext);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)

    let { path, url } = useRouteMatch();
    //console.log(path);
    //console.log(url);
    let history = useHistory();
    const { userAuthState, userAuthDispatch, getBearerTokenConfig } = useContext(AuthContext);
    return (
        <>
            <Container>
                <div className="dashboardLanding innerpageCard hasShadow">
                    {userAuthState.user ? (
                        <Row className="loggedinUserInformation mb-4">
                            <Col lg={11} sm={12} xs={9}>
                                <div className="userName flexContainer">
                                    Hello, &nbsp; <span className="secondaryPink">{userAuthState.user.first_name} 
                                    {/* {userAuthState.user.last_name} */}
                                    </span>
                                </div>
                            </Col>
                            <Col lg={1} sm={12} xs={3}>
                                <div
                                    onClick={async (e) => {

                                        if (userAuthState && userAuthState.user && userAuthState.user.token) {
                                            const apiResult = await logoutAPI(getBearerTokenConfig());
                                            if (apiResult.status) {
                                                userAuthDispatch({ type: "LOGOUT" })
                                                itemDispatch({
                                                    type: "FETCH_SUCCESS",
                                                    payload: apiResult.data
                                                });
                                                history.push('/')
                                                setCartItems([]);
                                            } else {
                                                itemDispatch({
                                                    type: "FETCH_FAILURE"
                                                });
                                            }
                                        } else {
                                            userAuthDispatch({ type: "LOGOUT" })

                                            itemDispatch({
                                                type: "FETCH_SUCCESS",
                                                payload: []
                                            });
                                            setCartItems([]);
                                        }
                                    }}
                                    className="isLink link pink"
                                >
                                    Logout
                                </div>
                            </Col>
                        </Row>
                    ) : (null)
                    }
                    <Row>
                        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                            <div className="dashboardMenu">
                                <ul>
                                    <li>
                                        <NavLink exact to={`${url}`} activeClassName="selected">
                                            <span className="icon">
                                                <BiBarChartAlt />
                                            </span>
                                            My Dashboard</NavLink >
                                    </li>
                                    <li>
                                        <NavLink to={`${url}/orders`} activeClassName="selected">
                                            <span className="icon">
                                                <BiListCheck />
                                            </span>
                                            My Orders
                                        </NavLink >
                                    </li>
                                    <li>
                                        <NavLink to={`${url}/shipping-address`} activeClassName="selected">
                                            <span className="icon">
                                                <BiLocationPlus />
                                            </span>
                                            My Shipping Address(es)</NavLink >
                                    </li>
                                    <li>
                                        <NavLink to={`${url}/messages`} activeClassName="selected">
                                            <span className="icon">
                                                <BiEnvelope />
                                            </span>
                                            Messages with JNooNz</NavLink >
                                    </li>
                                    <li>
                                        <NavLink to={`${url}/profile`} activeClassName="selected">
                                            <span className="icon">
                                                <BiUser />
                                            </span>
                                            My Profile
                                        </NavLink >
                                    </li>
                                    <li>
                                        <NavLink to={`${url}/change-password`} activeClassName="selected">
                                            <span className="icon">
                                                <BiLock />
                                            </span>
                                            Change Password</NavLink >
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                            <Switch >
                                <Route exact path={path} component={DashboardItems} />

                                <Route path={`${path}/orders/messages/:orderid/:id`} component={OrderMessagesThread} />
                                <Route path={`${path}/orders/messages/:id`} component={OrderMessages} />
                                <Route path={`${path}/orders/listing`} component={Orders} />
                                <Route path={`${path}/orders/:id`} component={OrderDetails} />
                                <Route path={`${path}/orders`} component={OrdersDashboard} />



                                <Route path={`${path}/shipping-address`} exact component={Address} />
                                <Route path={`${path}/shipping-address/:id`} component={UpdateShippingAddress} />

                                <Route path={`${path}/profile/update`} component={EditPersonal} />
                                <Route path={`${path}/profile/interests`} component={Interest} />
                                <Route path={`${path}/profile/alternate-email`} component={EditEmailID} />
                                <Route path={`${path}/profile`} component={ProfileDashboard} />
                                <Route path={`${path}/messages`} exact component={BuyerMessages} />
                                <Route path={`${path}/messages/:id`} component={BuyerMessageThread} />
                                <Route path={`${path}/change-password`} component={ChangePassword} />
                                <Route component={PageNotFound} />
                            </Switch>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default DashboardLanding