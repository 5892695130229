import React, { useContext, useEffect, useReducer, useState } from 'react'
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { AuthContext } from '../../contexts/AuthContext'
import { Card, ListGroup } from 'react-bootstrap'
import {getSentance} from '../../helpers/englishHelper'
import { SkeletonSinglelineItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent } from '../../components/Alert/Alert'
import { Time } from '../../components/TimeFormat/TimeFormat'
import { buyerLogAPI } from '../../apis/buyerAPIs'
const BuyersLog = ({ statsLoaded }) => {
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    //const [buyersStatsLoaded, setBuyersStatsLoaded] = useState(statsLoaded)
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);


    const loadContent = async () => {
        if (userAuthState.user && userAuthState.user.token) {
            itemDispatch({
                type: "FETCH_REQUEST"
            });

            // setTimeout(() => {
            //     itemDispatch({
            //         type: "FETCH_FAILURE"
            //     });
            // }, 3000);

            const apiResult = await buyerLogAPI(getBearerTokenConfig());
            if (apiResult.status) {
                itemDispatch({
                    type: "FETCH_SUCCESS",
                    payload: apiResult.data
                });
            } else {
                itemDispatch({
                    type: "FETCH_FAILURE"
                });
            }
        }
    }


    useEffect(() => {
        if (statsLoaded) {
            loadContent();
        }
    }, [statsLoaded]);

    return (
        <div className="buyersLogContainer">
            <Card border="secondaryGray">
                <Card.Header>Recent Activities</Card.Header>
                <Card.Body className="cardBody">
                    {itemState.isFetching ? (
                        <SkeletonSinglelineItem />
                    ) : itemState.hasError ? (
                        <ErrorComponent />
                    ) : (
                        <>
                            {(itemState.item && itemState.item.length) > 0 ? (
                                <ListGroup variant="flush" as="ul">
                                    {itemState.item.map((logItem, index) => (
                                        <ListGroup.Item as="li" key={index} variant="light">
                                            <div className="secondaryPink activityDate">
                                                <Time time={logItem.action_datetime} />
                                            </div>
                                            <div className="activitytext">
                                                <div className="text">
                                                    {console.log(logItem)}
                                                    {getSentance(logItem.action_type)}
                                                    {/* {logItem.action_type === "Login" &&
                                                        <span>You successfully logged in.</span>
                                                    }
                                                    {logItem.action_type === "Logout" &&
                                                        <span>You safely logged out.</span>
                                                    }
                                                   */}
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : (
                                <SkeletonSinglelineItem />
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </div>
    )
}
export default BuyersLog