import React, { useEffect, useReducer } from 'react'
import { Container, Col } from 'react-bootstrap'
import InnerPageHeader from '../components/InnerPageHeader/InnerPageHeader'
import { PagesReducer, pageInitialState } from "../reducers/PagesReducer"
import { fetchPageContentAPI } from '../apis/generalAPIs'
import { ContainerLoading } from '../components/Loading/Loading';
import { ErrorComponent } from '../components/Alert/Alert'

const StaticContentPage = (props) => {
    const [pageState, pageStateDispatch] = useReducer(PagesReducer, pageInitialState);
    const { match } = props;
    let page = match.url;


    const loadContent = async () => {
        if (page) {
            const apiResult = await fetchPageContentAPI(page.substring(1));
            if (apiResult.status) {
                pageStateDispatch({
                    type: "FETCH_PAGE_CONTENT_SUCCESS",
                    payload: apiResult.data
                });
            } else {
                pageStateDispatch({
                    type: "FETCH_PAGE_CONTENT_ERROR"
                });
            }
        }
    }

    useEffect(() => {
        loadContent();
    }, [page]);



    return (
        <div className="generalPages payments">
            <InnerPageHeader lastItemTitle={(pageState.content && pageState.content.heading) ? pageState.content.heading : page.substring(1).toUpperCase()} />
            <Container>
                <Col className="innerpageCard hasMarginTop hasShadow">
                    {pageState.isLoading && <ContainerLoading />}
                    {pageState.hasError
                        ? <ErrorComponent />
                        : <>
                            <h2 className="pageHeading lg">{(pageState.content && pageState.content.heading) ? pageState.content.heading : page.substring(1).toUpperCase()}</h2>
                            <Col className="p-0 mt-4">
                                <div className="richtext" dangerouslySetInnerHTML={{ __html: ((pageState.content && pageState.content.content) ? pageState.content.content : null) }}></div>
                            </Col>
                        </>
                    }
                </Col>
            </Container>
        </div>
    )
}
export default StaticContentPage