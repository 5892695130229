import React, { useContext, useState, useReducer, useEffect } from 'react'
import '../Messages.scss'
import { Container, Modal, ListGroup, Row, Button, Tab, Col, Nav } from 'react-bootstrap'
import MessagesItem from '../MessagesItem'
import { ContainerLoading } from '../../../components/Loading/Loading'
import SendOrderMessage from './SendOrderMessage'
import { ClosePopup } from '../../../components/CloseOverlayButton/CloseOverlay'
import { itemReducer, itemInitialState } from "../../../reducers/Reducers"
import axios from 'axios'
import { AuthContext } from '../../../contexts/AuthContext'
import { SkeletonEmailItem } from '../../../components/Skeleton/Skeleton';
import { ErrorComponent, ItemNotFoundDynamic } from '../../../components/Alert/Alert'
import { Link } from 'react-router-dom'
import { isTrue } from "../../../helpers/DOMHelpers"
import GoBack from '../../../components/GoBack/GoBack'
import { getOrderMessagesAPI } from '../../../apis/orderMessagesAPIs'


const OrderMessages = (props) => {
    const orderID = props.match.params.id;
    console.log(orderID);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const [showPopup, setShowPopup] = useState(false)
    const { getBearerTokenConfig } = useContext(AuthContext);

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [messages, setMessages] = useState([])


    const loadMessages = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const apiResult = await getOrderMessagesAPI(orderID, `page=${currentPage}`, getBearerTokenConfig());
        console.log(apiResult);
        if (apiResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: []
            });
            setMessages([...messages, ...apiResult.data.items]);
            setTotalPages(apiResult.data._meta.pageCount)
        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            });
        }
    }
    useEffect(() => {
        if (currentPage > 0) {
            loadMessages();
        }

        if (currentPage === 0) {
            setMessages([]);
        }
    }, [currentPage]);


    const loadMore = () => {
        setLoadMoreLoading(true)
        setTimeout(function () {
            setCurrentPage(currentPage + 1);
            setLoadMoreLoading(false)
        }, 1500)
    }
    return (
        <>
            <Container className="dashboardMessages">
                <GoBack history={props.history} />
                <Row className="align-items-center">
                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                        <div className="pageHeading">Order Messages</div>
                        <div className="caption2">Start your communication with JNooNz for your order: </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                        <div className="buttonContainer mb-4 textRight textAlignInheritMobile">
                            <Button size="sm"
                                variant="PinkButton"
                                className="lessCorners uppercase"
                                onClick={() =>
                                    setShowPopup(true)
                                }>
                                Send Message
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className="dashboardMessagesContainer">
                    <div className=" customTab customTabStyle2 mt-4">

                        {(itemState.isFetching && messages.length <= 0) ? (
                            <SkeletonEmailItem />
                        ) : itemState.hasError ? (
                            <ErrorComponent />
                        ) : (
                            <>
                                {(messages && messages.length > 0) ? (
                                    <>
                                        <ListGroup variant="flush" as="ul">
                                            {messages.map((message, index) => (
                                                <React.Fragment key={index}>

                                                    <>
                                                        <ListGroup.Item as="li" variant="transparent"
                                                            className={`pr-2 pl-2 messageItemHolder ${message.sender_type === 'Admin' ? "receivedMessage" :
                                                                [
                                                                    message.sender_type === 'Buyer' ? "sentMessage" : [
                                                                    ]]}`}
                                                        >
                                                            <Link to={`/member/orders/messages/${orderID}/${(message.last_message.reply_to) ? message.last_message.reply_to : message.last_message.id}`}
                                                                className={`${isTrue(message.is_read) ? 'read' : 'unread'}`}>
                                                                <MessagesItem key={message.id} message={message.last_message} />
                                                            </Link>
                                                        </ListGroup.Item>
                                                    </>

                                                </React.Fragment>
                                            ))}
                                        </ListGroup>
                                        {loadMoreLoading ? <SkeletonEmailItem /> : ''}
                                        {totalPages !== currentPage &&
                                            <div className="loadMoreButton">
                                                <Button
                                                    className="hasShadow"
                                                    variant="whiteButton"
                                                    size="sm"
                                                    onClick={() => {
                                                        loadMore()
                                                    }}
                                                >
                                                    load more
                                                </Button>
                                            </div>
                                        }
                                    </>
                                ) : (
                                    <ItemNotFoundDynamic
                                        className="itemNotFound textCenter mt-4"
                                        msgTitle="No communication found between you and admin"
                                    />
                                )}
                            </>
                        )}


                    </div>

                </div>
            </Container>

            <Modal
                show={showPopup}
                centered
                onHide={() =>
                    setShowPopup(false)
                }
                animation={false}
                className="authModal signinModal"
                size="md"
            >
                <Modal.Body>
                    <div className="closeButtonContainer blockButton">
                        <ClosePopup
                            setShowPopup={setShowPopup}
                        />
                    </div>

                    <div className="p-4">
                        <SendOrderMessage
                            orderID={orderID}
                            setCurrentPage={setCurrentPage}
                            setShowPopup={setShowPopup}
                        />
                    </div>


                </Modal.Body>
            </Modal>
        </>

    )
}

export default OrderMessages
