import React, { useState, useRef, useContext } from "react";
import * as DOMHelpers from "../../helpers/DOMHelpers";
import { Overlay, Popover } from "react-bootstrap";
import { IoMenu } from "react-icons/io5";
import { AiFillCaretDown } from "react-icons/ai";
import Sitemap from "../Sitemap/Sitemap";
import { BackdropContext } from "../../contexts/BackdropContext";
import { OverlayContext } from "../../contexts/OverlayContext";
const Menu = ({ props, hideOverlay, routes, match }) => {
  const { backdropState, backdropDispatch } = useContext(BackdropContext);
  const { overlayState, overlayDispatch } = useContext(OverlayContext);
  const [target, setTarget] = useState(null);
  const [sitemapExpanded, setSitemapExpanded] = useState(false);
  const ref = useRef(null);

  const handleOverlay = (event) => {
    setTarget(event.target);
    overlayDispatch({
      type: "OVERLAY_OPEN",
    });
    backdropDispatch({
      type: "FETCH_BACKDROPHORIZONTAL_OPEN",
    });
    DOMHelpers.fixedBody();
  };

  return (
    <>
      <div
        onClick={handleOverlay}
        ref={ref}
        // className={`${sitemapExpanded ? "inherit" : "relative"}`}
        className={`${backdropState.isShowing ? "disabledLink" : ""} || ${
          sitemapExpanded ? "inherit" : "relative"
        }`}
      >
        
          {/* <IoMenu
            title="Sitmap"
            className={"menuIcon " + ( overlayState.isShowing ? 'active': null)}
            />  */}
        <div className="menutextall">
        
            All
           
        
          <AiFillCaretDown className="dropdown-icon" />
        </div>
      </div>

      <Overlay
        show={overlayState.isShowing}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
        rootClose={true}
        onHide={() => {
          hideOverlay();
        }}
        transition={false}
      >
        <Popover
          className={`customPopoverTheme siteMap disabledElementChild ${
            sitemapExpanded ? "expanded" : ""
          }`}
        >
          <Popover.Content>
            <Sitemap
              hideOverlay={hideOverlay}
              sitemapExpanded={sitemapExpanded}
              setSitemapExpanded={setSitemapExpanded}
            />
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default Menu;
