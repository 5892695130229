import React, { useReducer, useContext, useState, useEffect } from 'react'
import { InputField } from "formik-stepper";
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { FullPageLoadingLight } from "../../components/Loading/Loading"
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import { PopupContext } from "../../contexts/PopupContext"
import { firstStepSignUpValidation, secondStepSignUpValidation, thirdStepSignUpValidation } from '../../components/FormPartials/Validation';
import { AuthContext } from "../../contexts/AuthContext"
import TelephoneInput from '../../components/FormPartials/TelephoneInput'
import GenderInput from '../../components/FormPartials/GenderInput'
import { checkEmailExist, registerMember } from '../../apis/buyerAPIs'
import { Formik, Form } from "formik";
import FormInput from '../../components/FormPartials/FormInput'

import { isValidEmail, getGuestSessionToken } from "../../helpers/CommonHelper"
import SuccessMsg from './../../components/FormPartials/SuccessMsg';
import { useHistory } from 'react-router-dom';

import CountriesInput from '../../components/FormPartials/CountriesInput';
const Signup = (props) => {
  let history = useHistory();
  const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
  const [currentStep, setCurrentStep] = useState(0)
  const [emailExistStatus, setEmailExistStatus] = useState(false)


  const { popupDispatch } = useContext(PopupContext);
  const { userAuthDispatch } = useContext(AuthContext);
  const initialValues = {
    type: "Registration",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    mobile: "",
    country_id: "",
    gender: "",
    guest_cart_session_token: getGuestSessionToken()
  }


  //console.log(history);











  const prevStep = async (props) => {
    await setCurrentStep((Math.max(currentStep - 1, 0)));
    props.validateForm();
    props.setTouched({});
  };

  const nextStep = async (props) => {
    console.log('next step function called on step ' + (currentStep + 1));
    props.setSubmitting(true);
    formDispatch({
      type: "FORM_SUBMISSION_START",
    });

    if (currentStep === 0) {
      const res = await props.submitForm();
      if (props.values.email && isValidEmail(props.values.email) && props.isValid) {


        //console.log('form is valid');
        const result = await checkEmailExist(props.values.email);
        setEmailExistStatus(result);

        if (result) {
          //console.log('Email exist')
          props.setSubmitting(false);
          formDispatch({
            type: "FORM_INITIAL_STAGE",
          });
          return false;
        }
        //console.log('Email not exist')
        setCurrentStep((Math.min(currentStep + 1, 2)));
        props.validateForm();
        props.setTouched({});

      }
    } else if (currentStep === 1) {
      const res = await props.submitForm();
      if (
        props.values.password
        && props.values.confirm_password
        && props.values.password === props.values.confirm_password
        && props.values.first_name
        && props.values.last_name
        && props.isValid
      ) {
        //console.log('form is valid');
        setCurrentStep((Math.min(currentStep + 1, 2)));
        props.validateForm();
        props.setTouched({});
      }
    }


    props.setSubmitting(false);
    formDispatch({
      type: "FORM_INITIAL_STAGE",
    });
  };




  const schemaArray = [firstStepSignUpValidation, secondStepSignUpValidation, thirdStepSignUpValidation];




  const step1 = (props) => {

    if (currentStep === 0) {
      return (
        <>

          <div>
            <FormInput
              controlId="email"
              label="Your Email Address"
              name="email"
              type="email"
              value={props.values.email}
              onChange={(e) => { props.handleChange(e); emailExistStatus === true  && setEmailExistStatus(false)}}
              onBlur={props.handleBlur}
            />
            {(!props.errors.email && emailExistStatus) &&
              <div className="errorMessage positionAbsolute" >
                <p>Email is already exist</p>
              </div>
            }
            <div className="w-100 d-flex justify-content-between">
              <button
                onClick={(e) => { nextStep(props); }}
                type="button"
                className="ml-auto btn btn-whiteButton tertiaryPink hasPadding hasShadow bold mt-2 btn-lg"
              >
                NEXT
              </button>
            </div>

          </div>

        </>
      );

    };
  }

  const step2 = (props) => {
    if (currentStep === 1) {
      return (
        <div>
          <FormInput
            controlId="password"
            label="Select Your Password"
            name="password"
            type="password"
            value={props.values.password}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="hidePlaceholder"
          />


          <FormInput
            controlId="confirm_password"
            label="Confirm Your Password"
            name="confirm_password"
            type="password"
            value={props.values.confirm_password}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="hidePlaceholder"
          />

          <FormInput
            controlId="first_name"
            label="Your First Name"
            name="first_name"
            type="text"
            value={props.values.first_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="hidePlaceholder"
          />

          <FormInput
            controlId="last_name"
            label="Your Last Name"
            name="last_name"
            type="text"
            value={props.values.last_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="hidePlaceholder"
          />


          {/* <button type="button" onClick={() => prevStep(props)}>Prev</button> &nbsp;
          <button type="button" onClick={(e) => { nextStep(props); }}>Next</button> */}


          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-whiteButton tertiaryPink hasPadding hasShadow bold mt-2 btn-lg"
              onClick={() => prevStep(props)}
            >
              PREVIOUS
            </button>
            <button
              type="button"
              className="ml-auto btn btn-whiteButton tertiaryPink hasPadding hasShadow bold mt-2 btn-lg"
              onClick={(e) => { nextStep(props); }}
            >
              NEXT
            </button>
          </div>

        </div>
      );
    }
  };

  const step3 = (props) => {
    if (currentStep === 2) {
      return (
        <>
          <div>
            <TelephoneInput

              label="Your Mobile Number"
              name="mobile"
              type="text"
              value={props.values.mobile}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              className="hidePlaceholder"
            />

            {/* <InputField

              name="country"
              label="Select Country of Residence"
              type="select"
              value={props.values.country}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              className="hidePlaceholder"
            >
              <option values="">Select Country</option>
              {countryOptions}
            </InputField> */}

            <CountriesInput
              listType='all'
              controlId="country_id"
              label="Select Country of Residence"
              name="country_id"
              as="select"
              value={props.values.country_id}
              onChange={(e) => {
                props.handleChange(e)
              }}
              onBlur={props.handleBlur}
              className="hidePlaceholder"
            />

            {/* <InputField

              name="gender"
              type="select"
              label="Select Your Gender"
              className="hidePlaceholder"
              value={props.values.gender}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            >
              <option values="">Select Gender</option>
              {genderOptions}
            </InputField> */}

            <GenderInput
              controlId="gender"
              label="Select Your Gender"
              name="gender"
              as="select"
              value={props.values.gender}
              onChange={(e) => {
                props.handleChange(e)
              }}
              onBlur={props.handleBlur}
              className="hidePlaceholder"
            />


            {/* <button type="button" onClick={() => prevStep(props)}>Prev</button> &nbsp;
            <button type="submit" >Submit</button> */}


            <div className="w-100 d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-whiteButton tertiaryPink hasPadding hasShadow bold mt-2 btn-lg"
                onClick={() => prevStep(props)}
              >
                PREVIOUS
              </button>
              <button
                type="submit"
                className="ml-auto btn btn-PurpleButton bold hasPadding hasShadow mt-2 btn-lg"
              >
                SIGN UP
              </button>
            </div>


          </div>
          {formState.hasSubmissionError && <FormSubmissionFailed />}
        </>
      );
    }
  };





  return (

    <div className="formContainer">
      {formState ? (
        <>
          {formState.isFormSubmitting && <FullPageLoadingLight />}

          {
            formState.isFormSubmitted
              ? <SuccessMsg
                msgTitle='Registration Completed Successfully'
                msgBody='Congratulations! Your account has been created.'
              />
              :


              <>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={schemaArray[currentStep]}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    console.log('in submit');
                    console.log(values);

                    setSubmitting(true);
                    formDispatch({
                      type: "FORM_SUBMISSION_START",
                    });

                    const submissionResult = await registerMember(values);
                    console.log(submissionResult);
                    if (submissionResult.status) {

                      userAuthDispatch({
                        type: "LOGIN",
                        payload: submissionResult
                      })

                      formDispatch({
                        type: 'FORM_SUBMISSION_SUCCESS',
                      });

                      setTimeout(() => {
                        formDispatch({
                          type: "FORM_INITIAL_STAGE",
                        });
                        popupDispatch({
                          type: "POPUP_SIGNIN_CLOSE"
                        })

                        if (history.location.pathname === '/cart') {
                          history.push("/checkout")
                        }


                        //DOMHelpers.relativeBody();
                        //toast.success(<ToastFormSubmission />)
                        setSubmitting(false); //// Important


                      }, 5000);
                    } else {
                      console.log('failed dispatch');
                      formDispatch({
                        type: "FORM_SUBMISSION_FAILED",
                      });
                      setTimeout(() => {
                        formDispatch({
                          type: "FORM_INITIAL_STAGE",
                        });
                      }, 5000);
                    }

                  }}
                >
                  {(props) => (
                    <>
                      <h4 className="signin_signupAreaHeading">Join JNooNZ</h4>
                      <Form>
                        {step1(props)}
                        {step2(props)}
                        {step3(props)}

                      </Form>
                    </>
                  )}
                </Formik>
                <div className="switchToSigninSignup mt-4">
                  Already A Member? &nbsp;
                  <span
                    onClick={() =>
                      popupDispatch({
                        type: "POPUP_SIGNIN_OPEN"
                      })
                    }
                    className="link green">Sign In
                  </span>
                </div>
              </>
          }
        </>
      ) : (
        <ErrorComponent />
      )}
    </div>


  );
}


export default Signup
