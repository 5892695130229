import axios from 'axios'

export const fetchWishlist = async (data, header) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/wishlist/get-items`, data, header)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const addWishlistItem = async (data, header) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/wishlist/add`, data, header)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const removeWishlistItem = async (data, header) => {

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/wishlist/remove`, data, header)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const moveWishlistItemToCart = async (data, header) => {

    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/wishlist/move-to-cart`, data, header)
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}
