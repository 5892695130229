import React, { useContext } from 'react'
import { Alert, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../contexts/AuthContext'
import { ErrorComponent } from './../../components/Alert/Alert';
const BankTransferDetails = ({ transferDetails }) => {
    console.log(transferDetails);
    const { userAuthState } = useContext(AuthContext)
    return (
        <div className="bankTransferDetails">

            {
                (transferDetails && transferDetails.accountTitle) ?
                    <>
                        <div className="sectionHeading">
                            If you wish to pay via bank account transfer, send your payment to the following Bank Account.
                        </div>
                        <div className="details">
                            <ul>
                                <li>Account Name:</li>
                                <li>{transferDetails.accountTitle}</li>


                                {
                                    transferDetails.bankName &&
                                    <>
                                        <li>Bank Name:</li>
                                        <li>{transferDetails.bankName}</li>
                                    </>
                                }


                                {
                                    transferDetails.accountNumber &&
                                    <>
                                        <li>Account Number:</li>
                                        <li>{transferDetails.accountNumber}</li>
                                    </>
                                }


                                {
                                    transferDetails.ibanNumber &&
                                    <>
                                        <li>IBAN:</li>
                                        <li>{transferDetails.ibanNumber}</li>
                                    </>
                                }


                                {
                                    transferDetails.swiftCode &&
                                    <>
                                        <li>Swift Code:</li>
                                        <li>{transferDetails.swiftCode}</li>
                                    </>
                                }



                            </ul>
                        </div>
                    </>
                    :
                    <ErrorComponent />
            }




            <Alert variant="purple" className="mt-4">
                {userAuthState.user ? (
                    <>
                        <p className="mb-0">
                            After your successful online bank transfer kindly upload the transfer slip to us from your dashboard.
                        </p>
                        {/* <p className="mt-2 mb-0">
                            <Link to="/dashboard/uploadTransferSlip" className="link white"> Upload slip now </Link>
                        </p> */}
                    </>
                ) : (
                    <>
                        <p className="mb-0">
                            After your successful online bank transfer kindly send transaction slip to our email.
                        </p>
                        <p className="mt-2 mb-0">
                            <Nav.Link
                                href="mailto:order@jnoonz.com?subject='Proff of payment'"
                                className="link white p-0">
                                order@jnoonz.com
                            </Nav.Link>
                        </p>
                    </>
                )}
            </Alert>
        </div>
    )
}

export default BankTransferDetails
