import React, { useReducer, useEffect, useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { FaFileInvoiceDollar } from "react-icons/fa";
import { itemReducer, itemInitialState } from "../../reducers/Reducers"
import { AuthContext } from '../../contexts/AuthContext'
import { orderStatsAPI } from '../../apis/orderAPIs'
import { SkeletonDashboardItem } from '../../components/Skeleton/Skeleton'
import ContinueShoppingButton from './../../components/ContinueShoppingButton/ContinueShoppingButton';
import { ErrorComponent } from './../../components/Alert/Alert';
const OrderDashboardStats = ({ setStatsLoaded }) => {
    let { url } = useRouteMatch();
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const { userAuthState, getBearerTokenConfig } = useContext(AuthContext);

    const loadContent = async () => {
        if (userAuthState.user && userAuthState.user.token) {
            itemDispatch({
                type: "FETCH_REQUEST"
            });

            const apiResult = await orderStatsAPI(getBearerTokenConfig());
            if (apiResult.status) {
                itemDispatch({
                    type: "FETCH_SUCCESS",
                    payload: apiResult.data
                });
                setStatsLoaded(true);
            } else {
                itemDispatch({
                    type: "FETCH_FAILURE"
                });
            }
        }
    }

    useEffect(() => {
        loadContent();
    }, []);


    return (
        <div className="mt-3">

            {itemState.isFetching ? (
                <SkeletonDashboardItem />
            ) : itemState.hasError ? (
                <ErrorComponent />
            ) : (
                <>
                    {(itemState.item && itemState.item.stats) ? (
                        <>
                            {
                                ((itemState.item.stats.totalOrders * 1) === 0)
                                    ?
                                    <ContinueShoppingButton
                                        msgTitle="You don't have any order(s) with JNooNz yet."
                                    />
                                    :
                                    <Row>
                                        <Col xl={6} lg={6} xs={12}>
                                            <div className="dashboardBox orderStatus all">
                                                <Card
                                                    border="secondaryPink"
                                                >
                                                    <Card.Body>
                                                        <Row className="align-items-center">
                                                            <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                <div className="icon">
                                                                    <FaFileInvoiceDollar />
                                                                </div>
                                                            </Col>
                                                            <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                <Card.Text className="mb-1">
                                                                    You have
                                                                </Card.Text>
                                                                <Card.Title className="mb-0">
                                                                    {itemState.item.stats.totalOrders} total order(s)
                                                                </Card.Title>
                                                                <Card.Link as={Link} to={`/member/orders/listing`}
                                                                    className="mt-3 link purple"
                                                                    style={{ display: "block" }}>
                                                                    View All Order(s)
                                                                </Card.Link>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>


                                        {((itemState.item.stats.pendingOrders * 1) > 0)
                                            &&
                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox orderStatus pending">
                                                    <Card
                                                        border="tertiaryPink"
                                                    >
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaFileInvoiceDollar />
                                                                    </div>
                                                                </Col>
                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-1">
                                                                        You have
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.pendingOrders} pending order with us
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`/member/orders/listing?order_status=Pending`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Pending Order(s)
                                                                    </Card.Link>

                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }



                                        {((itemState.item.stats.dispatchedOrders * 1) > 0)
                                            &&
                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox orderStatus dispatched">
                                                    <Card
                                                        border="primaryGreen"
                                                    >
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaFileInvoiceDollar />
                                                                    </div>
                                                                </Col>

                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-1">
                                                                        JNooNz dispatched
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.dispatchedOrders} order(s) to you so far
                                                                    </Card.Title>


                                                                    <Card.Link as={Link} to={`/member/orders/listing?order_status=Dispatch`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Dispatched Order(s)
                                                                    </Card.Link>

                                                                </Col>

                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }


                                        {((itemState.item.stats.deliveredOrders * 1) > 0)
                                            &&
                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox orderStatus delivered">
                                                    <Card
                                                        border="secondaryGreen"
                                                    >
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaFileInvoiceDollar />
                                                                    </div>
                                                                </Col>

                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-1">
                                                                        JNooNz delivered
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.deliveredOrders} order(s) to you so far
                                                                    </Card.Title>


                                                                    <Card.Link as={Link} to={`/member/orders/listing?order_status=Delivered`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Delivered Order(s)
                                                                    </Card.Link>

                                                                </Col>

                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }



                                        {((itemState.item.stats.canceledOrders * 1) > 0)
                                            &&

                                            <Col xl={6} lg={6} xs={12}>
                                                <div className="dashboardBox orderStatus canceled">
                                                    <Card
                                                        border="dangerColor"
                                                    >
                                                        <Card.Body>
                                                            <Row className="align-items-center">
                                                                <Col xl={3} lg={5} md={3} sm={6} xs={12}>
                                                                    <div className="icon">
                                                                        <FaFileInvoiceDollar />
                                                                    </div>
                                                                </Col>

                                                                <Col xl={9} lg={7} md={9} sm={6} xs={12} style={{ height: "100%" }}>
                                                                    <Card.Text className="mb-1">
                                                                        You cancled
                                                                    </Card.Text>
                                                                    <Card.Title className="mb-0">
                                                                        {itemState.item.stats.canceledOrders} order(s)
                                                                    </Card.Title>

                                                                    <Card.Link as={Link} to={`/member/orders/listing?order_status=Cancel`}
                                                                        className="mt-3 link purple"
                                                                        style={{ display: "block" }}>
                                                                        View Canceled Order(s)
                                                                    </Card.Link>

                                                                </Col>

                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }

                                    </Row>
                            }

                        </>
                    ) : (
                        <SkeletonDashboardItem />
                    )}
                </>
            )}
        </div>
    )
}

export default OrderDashboardStats
