import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap'
import { Field} from 'formik';

const CheckboxGroup = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  id,
  checked,
  ...props
}) => {
  return (
    <React.Fragment>

<Field name={name} validate={validate} {...props}>
  {({
  field,
  form: { touched, errors },
  meta,
  }) => (
    <>
    <Form.Check 
    name={name}
    ref={ref}
    as={as}
    id={id}
    type={type}
    label={label}
    onBlur={onBlur}
    value={value}
    onChange={onChange}
    options={options}
    errors={errors}
    required={required}
    disabled={disabled }
    readOnly={readOnly}
    placeholder={placeholder}
    checked={checked}
  />
  {/* <ErrorMessage component= "div" name={name}
    className="errorMessage" 
  /> */}
  </>
  )}

</Field>
    </React.Fragment>
  )
}

CheckboxGroup.defaultProps = {
  type: "checkbox",
  className: ""
}
CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf(['checkbox']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default CheckboxGroup;