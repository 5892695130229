import React,{createContext, useReducer} from 'react';
import { backdropReducer, backdropInitialState} from "../reducers/Reducers";
export const BackdropContext = createContext();
const BackdropContextProvider = (props)=>{
const [backdropState, backdropDispatch] = useReducer(backdropReducer, backdropInitialState)
    return(
        <BackdropContext.Provider value={{
            backdropState,
            backdropDispatch
        }}>
              {props.children}
        </BackdropContext.Provider>
    );
}
export default BackdropContextProvider;