import React, { useContext, useState, useEffect, useReducer } from "react";
import "./Orders.scss";
import {
  Container,
  Button,
  Modal,
  Badge,
  Alert,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CartContext } from "../../contexts/CartContext";
import { AuthContext } from "../../contexts/AuthContext";
import GoBack from "../../components/GoBack/GoBack";
import { MdCloudDownload } from "react-icons/md";
import goGreen from "../../assets/images/save-the-planet.svg";
import { Link, useRouteMatch } from "react-router-dom";
import { isTrue } from "../../helpers/CommonHelper";
import { itemReducer, itemInitialState } from "../../reducers/Reducers";
import { ErrorComponent } from "../../components/Alert/Alert";
import { Time } from "../../components/TimeFormat/TimeFormat";
import { ClosePopup } from "../../components/CloseOverlayButton/CloseOverlay";
import AddOrderReview from "./AddOrderReview";
import ProductFeedback from "./ProductFeedback";
import ProofOfPayment from "./ProofOfPayment";
//import { InvoicePDF } from './InvoicePDF'
import { getOrderDetailsAPI } from "../../apis/buyerOrdersAPIs";
import { ContainerLoading } from "../../components/Loading/Loading";
import { BsFillEnvelopeFill } from "react-icons/bs";
import {AiOutlineClose} from "react-icons/ai"
import {GoPackage} from "react-icons/go"
import { GiCheckMark } from "react-icons/gi";
import AppliedPackaging from "./AppliedPackaging";
import AppliedCustomization from "./AppliedCustomization";


const OrderDetails = (props) => {
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
  const { history } = props;
  const { match } = props;
  const orderID = match.params.id;
  //console.log(orderID);

  //console.log(path);
  const { cart } = useContext(CartContext);
  const { userAuthState } = useContext(AuthContext);
  const { getBearerTokenConfig } = useContext(AuthContext);
  const [showDismiss, setShowDismiss] = useState(true);
  const [productID, setProductID] = useState();
  const [itemID, setItemID] = useState();
  const [showAddReviewPopup, setShowAddReviewPopup] = useState(false);
  const [showAddFeedbackPopup, setShowAddFeedbackPopup] = useState(false);
  const [showPaymentProofPopup, setShowPaymentProofPopup] = useState(false);
  const [showPackagingPopup, setShowPackagingPopup] = useState(false);
  const [appliedPackaging, setAppliedPackaging] = useState(null);

  const [showCustomizationPopup, setShowCustomizationPopup] = useState(false);
  const [appliedCustomization, setAppliedCustomization] = useState(null);

  const paymentProofSubmitted = (item) => {
    // console.log("paymentProofSubmitted called ");
    itemDispatch({
      type: "FETCH_SUCCESS",
      payload: {
        ...itemState.item,
        proofOfPayment: item,
      },
    });
  };

  const reviewSubmittedForItem = (itemID) => {
    // console.log("reviewSubmittedForItem called with id " + itemID);
    itemDispatch({
      type: "FETCH_SUCCESS",
      payload: {
        ...itemState.item,
        ordered_products: itemState.item.ordered_products.filter((item) => {
          if (item.id === itemID) {
            item["review_submitted"] = 1;
          }
          return item;
        }),
      },
    });
  };

  const feedbackSubmittedForItem = (itemID) => {
    // console.log("feedbackSubmittedForItem called with id " + itemID);
    itemDispatch({
      type: "FETCH_SUCCESS",
      payload: {
        ...itemState.item,
        ordered_products: itemState.item.ordered_products.filter((item) => {
          if (item.id === itemID) {
            item["feedback_submitted"] = 1;
          }
          return item;
        }),
      },
    });
  };

  const loadContent = async () => {
    itemDispatch({
      type: "FETCH_REQUEST",
    });
    const apiResult = await getOrderDetailsAPI(orderID, getBearerTokenConfig());
    //console.log(apiResult);
    if (apiResult.status) {
      itemDispatch({
        type: "FETCH_SUCCESS",
        payload: apiResult.data,
      });
    } else {
      itemDispatch({
        type: "FETCH_FAILURE",
      });
    }
  };
  useEffect(() => {
    if (orderID) {
      loadContent();
    }
  }, [orderID]);
  return (
    <Container className="orderDetails">
      <GoBack history={history} />

      {itemState.isFetching ? (
        <ContainerLoading />
      ) : itemState.hasError ? (
        <ErrorComponent />
      ) : (
        <>
          {itemState.item.order && itemState.item.order.id > 0 && (
            <>
              
              {showDismiss && (
                <Alert
                  variant="success"
                  className="mb-4"
                  onClose={() => setShowDismiss(false)}
                  dismissible
                >
                  <div className="goGreenMsgContainer">
                    <div className="icon">
                      <img
                        className="icon"
                        src={goGreen}
                        alt="Go Green with JNooNz"
                        title="Go Green with JNooNz"
                      />
                    </div>
                    <div className="msg">
                      Go green with JNooNz, don’t print. Because it’s good for
                      the environment.
                    </div>
                  </div>
                </Alert>
              )}

              {/* content start */}
              <Row className="orderHeadRow">
                <Col xl={9} lg={12} xs={12} className="pageHeading">
                  Order Details
                </Col>
                <Col xl={3} lg={6} xs={12} className="actionButtons display-flex justify-content-end">
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip>Connect with JNooNz for this order</Tooltip>
                    }
                  >
                    <Link to={`/member/orders/messages/${orderID}`} className="d-flex justify-content-end" title="">
                      <Button
                        className="hasPadding hasShadow"
                        variant="PurpleButton"
                        size="sm"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Let us Know{""}
                        <BsFillEnvelopeFill
                          style={{ color: "white", marginLeft: "0.3rem" }}
                        />
                      </Button>
                    </Link>
                  </OverlayTrigger>

                  <ul>
                    <li>
                      {/* <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            Connect with JNooNz for this order
                          </Tooltip>
                        }
                      >
                        <Link to={`/member/orders/messages/${orderID}`} title="">
                          <BsFillEnvelopeFill />
                        </Link>
                      </OverlayTrigger> */}
                    </li>
                    {/* <li>
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip>
                                Download Receipt
                              </Tooltip>
                            }
                          >
                            <MdCloudDownload className="icon" />
                          </OverlayTrigger>
                        </li>

                        <li>

                          <MdCloudDownload className="icon" onClick={() => InvoicePDF()} />

                        </li> */}
                  </ul>
                </Col>
              </Row>

              <div className="ordersDetailsContainer mt-2">
                <Col className="p-0">
                  <Col className="p-0">
                    <Row>
                      <Col xl={4} lg={4} sm={12} xs={12} className="">
                        <div className="my-3">
                          Order Number:{" "}
                          <span className="tertiaryPink">
                            {itemState.item.order.order_id}
                          </span>
                        </div>
                        <div className="my-3">
                          Order Date:{" "}
                          <span className="tertiaryPink">
                            <Time time={itemState.item.order.created_at} />
                          </span>
                        </div>
                        <div className="my-3">
                          Order Status:{" "}
                          {itemState.item.order.order_status === 'Pending' ? <><Badge
                            pill
                            variant={`warning ${itemState.item.order.order_status}`}
                          >
                            {" "}
                            
                            {itemState.item.order.order_status}
                          </Badge></>:<>{itemState.item.order.order_status == 'Dispatch' ? <><Badge 
                            pill
                            variant={`secondary ${itemState.item.order.order_status}`}
                          >
                            {" "}
                         
                            {itemState.item.order.order_status}
                          </Badge></>:<>{itemState.item.order.order_status == 'Delivered' ? <><Badge 
                            pill
                            variant={`success ${itemState.item.order.order_status}`}
                          >
                            {" "}
                          
                            {itemState.item.order.order_status}
                          </Badge></>:<><Badge 
                            pill
                            variant={`danger ${itemState.item.order.order_status}`}
                          >
                            {" "}
                           
                            {itemState.item.order.order_status}
                          </Badge></>}</>}</>}
                          
                        </div>
                      </Col>

                      <Col xl={4} lg={4} sm={12} xs={12} className="">
                        <div className="my-3">
                          Sub Total:{" "}
                          <span className="tertiaryPink">
                            {itemState.item.order.currency.symbol}
                            {itemState.item.order.products_total_cost}
                          </span>
                        </div >

                        <div className="my-3">
                          Shipping Cost:{" "}
                          <span className="tertiaryPink">
                            {itemState.item.order.currency.symbol}
                            {itemState.item.order.shipping_cost}
                          </span>
                        </div>

                        <div className="my-3">
                          Total Amount:{" "}
                          <span className="tertiaryPink">
                            {itemState.item.order.currency.symbol}
                            {itemState.item.order.total_amount}
                          </span>
                        </div>
                      </Col>

                      <Col xl={4} lg={4} sm={12} xs={12} className="">
                       
                        <div className="my-3">
                          Payment Status:{" "}
                          {itemState.item.order.payment_status == "Pending" ? <><Badge 
                            pill
                            variant={`warning ${itemState.item.order.payment_status}`}
                          >
                            {" "}
                          
                            {itemState.item.order.payment_status}
                          </Badge></>:<><Badge 
                            pill
                            variant={`success ${itemState.item.order.payment_status}`}
                          >
                            {" "}
                           
                            {itemState.item.order.payment_status}
                          </Badge> </>}
                          
                        </div>
                        <div className="my-3">
                          Payment Method:{" "}
                          <span className="tertiaryPink">
                            {itemState.item.order.payment_method}
                          </span>
                        </div>

                        {(itemState.item.order.payment_method ===
                          "Bank Transfer" ||
                          itemState.item.order.payment_method ===
                            "Money Exchange Transfer") && (
                          <>
                            {!itemState.item.proofOfPayment ||
                            !itemState.item.proofOfPayment.id ? (
                              <div className="my-3 font-italic text-warning border border-warning" style={{cursor:'pointer',borderRadius:'25px',padding:'0.2rem 0.5rem',width:'fit-content'}}  onClick={() => {
                                setShowPaymentProofPopup(true);
                              }}>Upload Proof of Payment
                              
                              </div>
                            ) : (
                              <>
                                {itemState.item.proofOfPayment.status ===
                                  "Pending" && (
                                  <div className="text-secondary font-italic">
                                    <GiCheckMark
                                        style={{
                                         
                                          fontSize: "14px",
                                          marginRight: "0.2rem",
                                        }}
                                      />
                                     
                                    Proof of payment submitted
                                  </div>
                                )}
                                {itemState.item.proofOfPayment.status ===
                                  "Confirmed" && (
                                  <div className="text-success font-italic">
                                      <GiCheckMark
                                        style={{
                                         
                                          fontSize: "14px",
                                          marginRight: "0.2rem",
                                        }}
                                      />
                                    Proof of payment confirmed
                                  </div>
                                )}

                                {itemState.item.proofOfPayment.status ===
                                  "Rejected" && (
                                  <>
                                    <div className="text-danger font-italic">
                                    <AiOutlineClose  style={{ fontSize: "17px",fontStyle:"italic",marginRight: "0.1rem",}} />
                                      Proof of payment rejected
                                    </div>
                                    <div className="tertiaryGray">
                                      Admin Comments:{" "}
                                      <span className="tertiaryPink">
                                        {
                                          itemState.item.proofOfPayment
                                            .admin_comments
                                        }
                                      </span>
                                    </div>
                                    <div className="my-3 font-italic text-warning border border-warning"  style={{cursor:'pointer',borderRadius:'25px',padding:'0.2rem 0.5rem',width:'fit-content'}}  onClick={() => {
                                          setShowPaymentProofPopup(true);
                                        }}>
                                     
                                        Upload Another Proof of Payment
                                    
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <hr></hr>
                  <div className="orderItems mt-4">
                    <div className="pageHeading mb-2">Order List(s)</div>
                    {itemState.item.ordered_products && (
                      <>
                        {itemState.item.ordered_products.map((item, index) => (
                          <div key={index}className="hasBorderBottom">
                            <Row className="itemHolder horizontalItemHolder">
                              <Col xl={3} className="itemImageHolder">
                                <img
                                  className="itemImage"
                                  src={`${process.env.REACT_APP_BASE_URL}${item.product.image}`}
                                  alt={item.product.title}
                                  title={item.product.title}
                                />
                              </Col>
                              <Col xl={9} className="itemDetails">
                                <ul>
                                  <li className="itemCategory">
                                    {item.product.category.name === "" ? (
                                      <p>{item.product.type.name}</p>
                                    ) : (
                                      <>
                                        {item.product.subCategory.name ===
                                        "" ? (
                                          <p>{item.product.category.name}</p>
                                        ) : (
                                          <p>{item.product.subCategory.name}</p>
                                        )}
                                      </>
                                    )}
                                    <></>
                                  </li>
                                  <li className="itemTitle">
                                    {item.product.title}
                                  </li>
                                  <li>
                                    <div className="cartItemBottom">
                                      <ul>
                                        <li className="priceContainer">
                                          <div className="itemPrice isDiscountedprice">
                                            <div className="price fs-6">
                                              {
                                                itemState.item.order.currency
                                                  .symbol
                                              }
                                              {item.price}
                                            </div>
                                          </div>
                                        </li>
                                        <li></li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6} >
                                {" "}
                                {((item.customization &&
                                  item.customization.id > 0) ||
                                  (item.packaging &&
                                    item.packaging.id > 0)) && (
                                  <div className="actionButtons mt-2 d-flex align-items-center justify-content-start">
                                    <ul>
                                      {item.customization &&
                                        item.customization.id > 0 && (
                                          <li>
                                            <span
                                              className=" tertiaryPink"
                                              style={{ fontSize: "0.8rem",padding:'0.5rem' }}
                                              onClick={() => {
                                                setShowCustomizationPopup(true);
                                                setAppliedCustomization(
                                                  item.customization
                                                );
                                              }}
                                            >
                                              {/* <BsBrush style={{ fontSize: "0.85rem"}}/>  */}
                                              View Customization
                                            </span>
                                          </li>
                                        )}

                                      {item.packaging && item.packaging.id > 0 && (
                                        <li>
                                          <span
                                            className=" tertiaryPink"
                                            style={{ fontSize: "0.8rem" }}
                                            onClick={() => {
                                              setShowPackagingPopup(true);
                                              setAppliedPackaging(
                                                item.packaging
                                              );
                                            }}
                                          >
                                            {/* <GoPackage style={{ fontSize: "0.85rem"}}/>  */}
                                            View Packaging
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </Col>
                                            {itemState.item.order.order_status == 'Pending' ? <>
                              <Col xl={6} xs={12}>
                                <div className="actionButtons mt-2 d-flex align-items-center justify-content-start">
                                  <ul className="mr-1">
                                    {console.log(item)}
                                    {!isTrue(item.review_submitted) && (
                                      <li>
                                        <Button
                                          className="hasPadding hasShadow"
                                          variant="PurpleButton"
                                          size="sm"
                                          onClick={() => {
                                            setShowAddReviewPopup(true);
                                            setProductID(item.product.id);
                                            setItemID(item.id);
                                          }}
                                          title="Submit Review"
                                        >
                                          Submit Review
                                        </Button>
                                      </li>
                                    )}
                                    <li>
                                    {isTrue(item.review_submitted) && (
                                      <>
                                    <Button className="text-success font-italic hideOnMobile"
                                      style={{
                                        backgroundColor: "white",
                                        color: "green",
                                        border: "0",
                                        cursor:'text',
                                      }}
                                      disabled
                                    >
                                      <GiCheckMark
                                        style={{
                                          
                                          fontSize: "14px",
                                          marginRight: "0.2rem",
                                          cursor:'text',
                                        }}
                                      />
                                      Review submitted
                                    </Button>
                                    <Button className="text-success font-italic showOnMobile"
                                      style={{
                                        backgroundColor: "white",
                                        color: "green",
                                        border: "0",
                                        cursor:'text',
                                      }}
                                      disabled
                                    >
                                      <GiCheckMark
                                        style={{
                                          
                                          fontSize: "14px",
                                          marginRight: "0.2rem",
                                          cursor:'text',
                                        
                                        }}
                                      />
                                      Review 
                                    </Button>
                                   
                                  </>
                                  )}
                                    </li>

                                    {!isTrue(item.feedback_submitted) && (
                                      <li>
                                        <Button
                                          className="hasPadding hasShadow"
                                          variant="PurpleButton"
                                          size="sm"
                                          onClick={() => {
                                            setShowAddFeedbackPopup(true);
                                            setProductID(item.product.id);
                                            setItemID(item.id);
                                          }}
                                          title="Submit Feedback"
                                        >
                                          Submit Feedback
                                        </Button>
                                      </li>
                                    )}
                                    <li>
                                    {isTrue(item.feedback_submitted) && (
                                      <>
                                    <Button className="text-success font-italic hideOnMobile"
                                      style={{
                                        backgroundColor: "white",
                                        cursor:'text',
                                        border: "0",
                                      }}
                                      disabled
                                    >
                                      <GiCheckMark
                                        style={{
                                          cursor:'text',
                                          fontSize: "14px",
                                          marginRight: "0.2rem",
                                        }}
                                      />
                                      Feedback submitted
                                    </Button>
                                    <Button className="text-success font-italic showOnMobile"
                                    style={{
                                      backgroundColor: "white",
                                      cursor:'text',
                                      border: "0",
                                    }}
                                    disabled
                                  >
                                    <GiCheckMark
                                      style={{
                                        cursor:'text',
                                        fontSize: "14px",
                                        marginRight: "0.2rem",
                                      }}
                                    />
                                    Feedback 
                                  </Button>
                                  </>
                                  )}
                                      
                                    </li>
                                    
                                  </ul>
                                </div>
                          
                              </Col>
                              </>:<></>}
                            </Row>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </Col>
                <Col className="columnOne p-0">
                  <div className="cartOverlayFooter ">
                    <div className="mt-4 mb-4 hasBorderBottom ">
                      <span className="totalItemsinCart mr-2 fs-6">
                        {itemState.item.ordered_products && (
                          <>
                            <span className="totalItemsCount fs-6">
                              {itemState.item.ordered_products.length}
                            </span>{" "}
                            total item(s)
                          </>
                        )}
                      </span>
                      <span className="totalPriceOfCart fs-6">
                        <span className="totalPriceinCart fs-6 ">
                          {itemState.item.order.currency.symbol}
                          {itemState.item.order.total_amount}
                        </span>{" "}
                        total cost of the order
                      </span>
                    </div>
                  </div>
                </Col>

                <Col className="columnOne p-0">
                  <div className="cartOverlayFooter">
                    <div className="mt-4">
                      <span className=" mr-4 " style={{ fontSize: "0.9rem" }}>
                        Delivery Address:{" "}
                        <span className="tertiaryPink">
                          {itemState.item.address.address},{" "}
                          {itemState.item.address.city}
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
              {/* content ends */}

              <Modal 
                show={showAddReviewPopup}
                centered
                onHide={() => setShowAddReviewPopup(false)}
                animation={false}
                className=""
                size="md"
              >
                <Modal.Body>
                  <div className="closeButtonContainer floatButton">
                    <ClosePopup setShowPopup={setShowAddReviewPopup} />
                  </div>
                  <Col className="p-3">
                    <AddOrderReview
                      productID={productID}
                      itemID={itemID}
                      orderID={orderID}
                      setShowAddReviewPopup={setShowAddReviewPopup}
                      reviewSubmittedForItem={reviewSubmittedForItem}
                    />
                  </Col>
                </Modal.Body>
              </Modal>

              <Modal
                show={showAddFeedbackPopup}
                centered
                onHide={() => setShowAddFeedbackPopup(false)}
                animation={false}
                className=""
                size="md"
              >
                <Modal.Body>
                  <div className="closeButtonContainer floatButton">
                    <ClosePopup setShowPopup={setShowAddFeedbackPopup} />
                  </div>
                  <Col className="p-3">
                    <ProductFeedback
                      productID={productID}
                      itemID={itemID}
                      orderID={orderID}
                      setShowAddFeedbackPopup={setShowAddFeedbackPopup}
                      feedbackSubmittedForItem={feedbackSubmittedForItem}
                    />
                  </Col>
                </Modal.Body>
              </Modal>

              <Modal
                show={showPaymentProofPopup}
                centered
                onHide={() => setShowPaymentProofPopup(false)}
                animation={false}
                className=""
                size="md"
              >
                <Modal.Body>
                  <div className="closeButtonContainer floatButton">
                    <ClosePopup setShowPopup={setShowPaymentProofPopup} />
                  </div>
                  <Col className="p-3">
                    <ProofOfPayment
                      orderID={orderID}
                      setShowPaymentProofPopup={setShowPaymentProofPopup}
                      paymentProofSubmitted={paymentProofSubmitted}
                    />
                  </Col>
                </Modal.Body>
              </Modal>

              <Modal
                show={showPackagingPopup}
                centered
                onHide={() => setShowPackagingPopup(false)}
                animation={false}
                className=""
                size="md"
              >
                <Modal.Body>
                  {showPackagingPopup && (
                    <>
                      <div className="closeButtonContainer floatButton">
                        <ClosePopup setShowPopup={setShowPackagingPopup} />
                      </div>
                      <Col className="p-3">
                        <AppliedPackaging
                          currencySymbol={itemState.item.order.currency.symbol}
                          packaging={appliedPackaging}
                        />
                      </Col>
                    </>
                  )}
                </Modal.Body>
              </Modal>

              <Modal
                show={showCustomizationPopup}
                centered
                onHide={() => setShowCustomizationPopup(false)}
                animation={false}
                className=""
                size="md"
              >
                <Modal.Body>
                  {showCustomizationPopup && (
                    <>
                      <div className="closeButtonContainer floatButton">
                        <ClosePopup setShowPopup={setShowCustomizationPopup} />
                      </div>
                      <Col className="p-3">
                        <AppliedCustomization
                          currencySymbol={itemState.item.order.currency.symbol}
                          customization={appliedCustomization}
                        />
                      </Col>
                    </>
                  )}
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default OrderDetails;
