import React, { useReducer, useEffect, useContext } from 'react'
import './Sitemap.scss'
import { Row } from "react-bootstrap"
import { itemReducer, itemInitialState } from '../../reducers/Reducers'
import SitemapNormal from './SitemapNormal'
import SitemapExpanded from './SitemapExpanded'
import SitemapExpandedMobile from './SitemapExpandedMobile'
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa"
import { CloseOverlay } from '../CloseOverlayButton/CloseOverlay'
import { SkeletonQuestionItem } from '../../components/Skeleton/Skeleton'
import { ErrorComponent } from '../../components/Alert/Alert'
import { GlobalContext } from './../../contexts/GlobalContext';

const Sitemap = ({ hideOverlay, sitemapExpanded, setSitemapExpanded }) => {
  const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
  const { sitemap } = useContext(GlobalContext).globalData.data;

  useEffect(() => {
    itemDispatch({
      type: "FETCH_REQUEST"
    });
    if (sitemap && sitemap.length > 0) {
      setTimeout(() => {
        itemDispatch({
          type: "FETCH_SUCCESS",
          payload: sitemap
        });
      }, 100);
    } else {
      itemDispatch({
        type: "FETCH_FAILURE",
      });
    }
  }, [])


  return (
    <>
      <ul className="sitemapTopButtons">


        {(itemState.item && itemState.item.length > 0) &&
          <li>
            <div className="expandViewButton"
              onClick={() => {
                setSitemapExpanded(!sitemapExpanded)
              }}
            >
              <div className="btn btn-whiteButton hasShadow"
                style={{
                  padding: "0px 4px",
                  marginRight: 15
                }}>
                {sitemapExpanded ? (
                  <FaCompressAlt style={{
                    fontSize: 18
                  }} />
                ) : (
                  <FaExpandAlt style={{
                    fontSize: 18
                  }} />
                )}

              </div>
              {sitemapExpanded ? (
                <strong>collapse view </strong>
              ) : (
                <strong> expand view</strong>
              )
              }

            </div>
          </li>
        }
        <li>
          <div className="closeButtonContainer blockButton">
            <CloseOverlay />
          </div>
        </li>
      </ul>

      {itemState.isFetching ? (
        <SkeletonQuestionItem />
      ) : itemState.hasError ? (
        <ErrorComponent />
      ) : (


        <>
          <div className="hideOnMobileTablet">
            {!sitemapExpanded ? (
              <Row>
                <SitemapNormal
                  hideOverlay={hideOverlay}
                  itemState={itemState}
                />
              </Row>
            ) : (
              <div className="expandedSitemapContainer">
                <SitemapExpanded
                  hideOverlay={hideOverlay}
                  itemState={itemState}
                  
                />
                {console.log(itemState)}
              </div>
            )}
          </div>

          <div className="showOnMobileTablet">
            <div className="expandedSitemapContainer">
              <SitemapExpandedMobile
                hideOverlay={hideOverlay}
                itemState={itemState}
              />
            </div>
          </div>
        </>

      )}

    </>
  )

}

export default Sitemap