import React, {useEffect, useReducer} from 'react';
import "./SocialMediaSlider.scss";
import { FaTwitter } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {itemReducer, itemInitialState} from "../../reducers/Reducers";
import axios from "axios";
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([ Pagination, Autoplay ]);

const SocialMediaSlider = ()=> {
const sliderParams = {
    direction: "vertical",
    speed: 1000,
};
const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
useEffect(() => {
        let mounted = true;
        itemDispatch({
        type: "FETCH_REQUEST"
      });
      axios.get("https://fakestoreapi.com/products")
        .then(res => {
          console.log(res.data);
          if(mounted){
            itemDispatch({
            type: "FETCH_SUCCESS",
            payload: res.data
          });
        };
        })
        .catch(error => {
          console.log(error);
          itemDispatch({
            type: "FETCH_FAILURE"
          });
        });
        return () => mounted = false;
    }, []);
    return(
    <>
    <div className="socialMediaSliderContainer">
    <div className="heading">Live on Social Media</div>
    {itemState.item.length > 0 ? (
            <>
            <Swiper
            {...sliderParams}
            spaceBetween={10}
            autoplay = {{delay: 6000,}}
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
          {itemState.item.slice(0,3).map((socialmediapost, index) => (
              <SwiperSlide className="swiper-slide" key={index}>
                  <div className="socialMediaPostText">{socialmediapost.title}</div>
              </SwiperSlide>
          ))}
          </Swiper>
          <div className="socialMediaIcons">
              <ul>
                  <li>
                      <FaTwitter/>
                  </li>
              </ul>
          </div>
          </>
    ) : 
    (
      <SkeletonTheme color="#f5f5f5" highlightColor="#eaeaea">
          <Skeleton height={55}/>
          <Skeleton height={32} width={32} count={2}/>
      </SkeletonTheme>
    )}
    </div>
    </>
    )
};

export default SocialMediaSlider;