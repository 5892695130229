import React, { useReducer, useContext, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Formik, ErrorMessage } from 'formik'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, ItemNotFoundDynamic } from '../../components/Alert/Alert'
import { itemReducer, itemInitialState } from '../../reducers/Reducers'
import CheckboxGroup from '../../components/FormPartials/CheckboxGroup';

import { SkeletonCheckboxGroup } from '../../components/Skeleton/Skeleton';
import { getHearAboutUsOptionsAPI, updateHearAboutUsOptionsAPI } from '../../apis/buyerAPIs'
import { AuthContext } from '../../contexts/AuthContext'
import { FormSubmissionFailed } from './../../components/Alert/Alert';
import { isTrue } from "../../helpers/DOMHelpers"

const HowDidYouHear = (props) => {
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { getBearerTokenConfig } = useContext(AuthContext);

    const loadContent = async () => {
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        const apiResult = await getHearAboutUsOptionsAPI(getBearerTokenConfig());
        if (apiResult.status) {
            itemDispatch({
                type: "FETCH_SUCCESS",
                payload: apiResult.data.options
            });

        } else {
            itemDispatch({
                type: "FETCH_FAILURE"
            });
        }
    }

    useEffect(() => {
        loadContent()
    }, []);


    return (
        <div>
            <div className="pageHeading">How Did You Hear About Us?</div>
            <div className="formContainer mt-4">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle="Thank you for your feedback"
                                msgBody=""
                            />
                        ) : (
                            <>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{}}
                                    validate={(values, props) => {
                                        const errors = {};
                                        let filtered = itemState.item.filter(item => item.isChecked === true);
                                        if (filtered.length <= 0) {
                                            errors.checked = 'Select at-least one option';
                                        }
                                        return errors;
                                    }}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });


                                        const apiResult = await updateHearAboutUsOptionsAPI(itemState.item, getBearerTokenConfig());
                                        console.log(apiResult);
                                        if (apiResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED"
                                            });
                                        }

                                        setTimeout(() => {
                                            formDispatch({
                                                type: "FORM_INITIAL_STAGE",
                                            });
                                        }, 5000);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <>
                                            {itemState.isFetching ? (
                                                <SkeletonCheckboxGroup />
                                            ) : itemState.hasError ? (
                                                <ErrorComponent />
                                            ) : (
                                                <Form onSubmit={handleSubmit} className="mx-auto">
                                                    {formState.hasSubmissionError && <FormSubmissionFailed />}
                                                    <>
                                                        {(itemState.item && itemState.item.length) > 0 ? (
                                                            <>
                                                                <Row>
                                                                    {itemState.item.map((option, index) => {
                                                                        return (
                                                                            <Col xl={4} lg={4} md={4} sm={6} xs={12} key={option.id}>
                                                                                <div className="mb-3 hasCursor">
                                                                                    <CheckboxGroup
                                                                                        id={option.name}
                                                                                        name="checked"
                                                                                        type="checkbox"
                                                                                        label={option.name}
                                                                                        value={option.name}
                                                                                        onChange={(e) => {

                                                                                            // setCheckboxValue(!checkboxValue)

                                                                                            itemDispatch({
                                                                                                type: "FETCH_SUCCESS",
                                                                                                payload: itemState.item.map((optionCheck) => {
                                                                                                    //  console.log('Before', optionCheck);
                                                                                                    if (optionCheck.name === option.name) {
                                                                                                        optionCheck.isChecked = !optionCheck.isChecked;
                                                                                                    }
                                                                                                    // console.log('After', optionCheck);
                                                                                                    return optionCheck
                                                                                                })
                                                                                            });
                                                                                            handleChange(e)

                                                                                        }
                                                                                        }
                                                                                        onBlur={handleBlur}
                                                                                        checked={isTrue(option.isChecked)}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        )

                                                                    }
                                                                    )}
                                                                    <ErrorMessage component="div" name="checked" className="errorMessage" />
                                                                </Row>
                                                                <Button variant="PurpleButton"
                                                                    className="hasPadding uppercase hasShadow bold mt-3"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <ItemNotFoundDynamic
                                                                className="itemNotFound textCenter"
                                                                msgTitle="No options found due to a temporary issue"
                                                                msgText="Please refresh your browser or try after some time"
                                                            />
                                                        )}
                                                    </>

                                                </Form>
                                            )}
                                        </>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default HowDidYouHear
