import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik';
import { fetchAgeGroupAPI } from '../../apis/generalAPIs'
const AgeGroupInput = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  ...props
}) => {
  const [ageGroupList, setAgeGroupList] = useState([])


  const loadContent = async () => {
    const apiResult = await fetchAgeGroupAPI();
    //console.log(apiResult);
    if (apiResult.status) {
      setAgeGroupList(apiResult.data.groups)
    }
  }

  useEffect(() => {
    loadContent();
  }, [])

  const ageGroupOptions = ageGroupList.map((item) => {
    return (
      <option key={item.id} value={item.name}>
        {item.name}
      </option>
    )
  })

  return (
    <React.Fragment>

      <Field name={name} validate={validate}>
        {({
          field,
          form: { touched, errors },
          meta,
        }) => (
          <Form.Group md={md} controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              name={name}
              ref={ref}
              as={as}
              type={type}
              label={label}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              options={options}
              errors={errors}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              autoComplete="off"
            >
              <option values="">Select Your Gender</option>
              {ageGroupOptions}
            </Form.Control>
            <ErrorMessage component="div" name={name}
              className="errorMessage"
            />
          </Form.Group>
        )}

      </Field>
    </React.Fragment>
  )
}

AgeGroupInput.defaultProps = {
  type: "select",
  className: ""
}
AgeGroupInput.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf(['select']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default AgeGroupInput;