import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BuyersLog from './BuyersLog'
import DashboardStats from './DashboardStats'

const DashboardItems = (props) => {
    const [statsLoaded, setStatsLoaded] = useState(false)

    return (
        <Container>
            <DashboardStats setStatsLoaded={setStatsLoaded} />
            <BuyersLog statsLoaded={statsLoaded} />
        </Container>
    )
}
export default DashboardItems