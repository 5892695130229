import React, { createContext, useReducer } from 'react';
import { userAuthReducer } from "../reducers/Reducers";
export const AuthContext = createContext();

let user = localStorage.getItem('user');
// console.log('authenticated User');

if (user && user !== undefined && user !== 'undefined') {
  user = JSON.parse(user)
}

const userAuthInitialState = {
  user: user
};
const AuthContextProvider = (props) => {
  const [userAuthState, userAuthDispatch] = useReducer(userAuthReducer, userAuthInitialState);
  React.useEffect(() => {
    if (user && user.token) {
      userAuthDispatch({
        type: 'LOGIN',
        payload: {
          user: user
        }
      })
    }
  }, []);


  const getBearerTokenConfig = () => {
    return {
      headers: {
        Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
      },
    }
  }

  return (
    <AuthContext.Provider
      value={{
        userAuthState,
        getBearerTokenConfig,
        userAuthDispatch
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
export default AuthContextProvider;