import React from "react"
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Container, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import AppRoutes from './../../routes/AppRoutes';
const Breadcrumbs = ({ breadcrumbs, lastItemTitle, props }) => {

  return (
    <Container className="p-0">

      <Breadcrumb className="relative">
        {breadcrumbs.map(({
          match,
          breadcrumb
        }, index) => (
          <Breadcrumb.Item
            key={match.url}
            linkProps={{ to: ((breadcrumb.key === '/product') ? "/products" : match.url) }}
            linkAs={Link}
          >




            <>
              {
                ((index + 1 === breadcrumbs.length) && lastItemTitle)
                  ? <>{lastItemTitle}</>
                  : <>
                    {
                      (breadcrumb.key === '/product')
                        ? <>Products</>
                        : <>{breadcrumb}</>
                    }
                  </>
              }
            </>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Container>
  )
}

export default withBreadcrumbs(AppRoutes)(Breadcrumbs);
//export default withBreadcrumbs(routes)(Breadcrumbs);