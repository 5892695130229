import React, {useContext} from 'react'
import { Container } from 'react-bootstrap'
import {Link} from "react-router-dom"
import emptyCartIcon from '../../assets/images/empty-cart.svg'
import {BackdropContext} from "../../contexts/BackdropContext"
import {OverlayContext} from "../../contexts/OverlayContext"
import * as DOMHelpers  from "../../helpers/DOMHelpers"
const NoItemFoundInCart = () => {
const { backdropDispatch } = useContext(BackdropContext);
const {cartOverlayDispatch } = useContext(OverlayContext);
    return (
        <Container>
            <div className="noItemFoundInCart textCenter">
                <img src={emptyCartIcon} alt=""/>
                <h4 className="heading">Your JNooNz shopping cart looks empty</h4>
                <div className="text">What are you waiting for?</div>
                <Link to="/products" 
                    onClick={()=>{
                    cartOverlayDispatch({
                        type: "OVERLAY_CLOSE",
                    });
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    DOMHelpers.relativeBody();
                }}
                className="btn btn-PinkButton btn-lg uppercase hasPadding hasShadow">Start Shopping</Link>
            </div>
        </Container>
    )
}

export default NoItemFoundInCart