import React, { useState, useRef, useContext } from 'react';
import "./JnoonzSpecialZone.scss";
import { FaCertificate } from "react-icons/fa";
import { Popover, Overlay, Row, Col, Button, Badge } from "react-bootstrap";
import todaysMissionIcon from '../../assets/images/todays_mission_icon.svg';
import { FaShareAlt } from "react-icons/fa";
import SocialMediaSlider from '../SocialMediaSlider/SocialMediaSlider';
import { BackdropContext } from "../../contexts/BackdropContext";
import * as DOMHelpers from "../../helpers/DOMHelpers";
import { CloseOverlay } from '../CloseOverlayButton/CloseOverlay'
import { GlobalContext } from './../../contexts/GlobalContext';

const JnoonzSpecialZone = (props) => {
    const { getFrontendMessage } = useContext(GlobalContext);
    const specialZoneMessage = getFrontendMessage('General', 'Special Zone', 'Message', 'Text')


    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const handleOverlay = (event) => {
        setShow(!show);
        setTarget(event.target);
        backdropDispatch({
            type: "FETCH_BACKDROP_OPEN",
        });
        DOMHelpers.fixedBody();
    };
    return (
        <>
            <div
                className={`notificationButton topHeaderLink ${backdropState.isShowing ? "disabledLink" : ""}`}
                onClick={handleOverlay} ref={ref}>
                <div className="overlayItemIconHolder">
                    {/* {!show &&
                        <Badge
                            className="customBadge left animate__animated animate__wobble animate__delay-3s animate__infinite"
                            variant="secondaryPink">
                            1
                        </Badge>
                    } */}
                    {/* <FaCertificate className="topHeaderLinksIcons mr-2" /> */}
                </div>
                {/* <span className="uppercase optionalTextToShow">jnoonz</span>
                <span className="optionalTextToShow ml-1">Special Zone</span> */}
            </div>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
                rootClose={true}
                onHide={() => {
                    setShow(false);
                    backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                    });
                    DOMHelpers.relativeBody();
                }}
                transition={false}
            >
                <Popover className="customPopoverTheme specialZone disabledElementChild">
                    <Row>
                        <Col className="specialOffer">
                            <div className="closeButtonContainer floatButton variantGray">
                                <CloseOverlay />
                            </div>
                            <Popover.Content>
                                <Row>
                                    <Col lg={5} xs={12} sm={12} md={11}>
                                        <h3 className="title">Today's Mission</h3>
                                        <img src={todaysMissionIcon} alt="" className="todayMissionIcon" />
                                    </Col>
                                    <Col lg={7} xs={12} sm={12} className="rightCol">
                                        <p className="text fs-4"> This feature will be available soon.
                                        Check after some time.
                                            {/* {specialZoneMessage} */}
                                            </p>
                                       
                                    </Col>
                                </Row>
                                
                            </Popover.Content>
                        </Col>

                        {/* <Col className="socialMediaSlider">
                            <Popover.Content>
                                <SocialMediaSlider />
                            </Popover.Content>
                        </Col> */}

                    </Row>

                </Popover>
            </Overlay>
            {/* <Backdrop showBackdrop={showBackdrop}/> */}
        </>
    )
}

export default JnoonzSpecialZone;