import React, { useState, useEffect } from 'react'
import './QA.scss'
import axios from 'axios'
import { Tab, Tabs, Button } from "react-bootstrap"
import { SkeletonProductItem, SkeletonQuestionItem } from "../Skeleton/Skeleton"
import QAForm from './QAForm'
import { StartTime } from '../TimeFormat/TimeFormat'
import ProductReview from '../ProductReview/ProductReview'
import { fetchProductQuestionsAnswersAPI } from '../../apis/productAPIs'

const QA = ({ productid, itemReviews, ...props }) => {
    const [totalPages, setTotalPages] = useState(1);
    const [qa, setQA] = useState([])
    const [page, setPage] = useState(1)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)


    const loadQuestionAnswers = async () => {
        const apiResult = await fetchProductQuestionsAnswersAPI(`ProductQuestionsSearch[product_id]=${productid}&sort=-question_time&page=${page}`);
        if (apiResult.status) {
            setQA([...qa, ...apiResult.data.items]);
            setTotalPages(apiResult.data._meta.pageCount)
        }
    }

    useEffect(() => {
        // const productDetailsPromise = axios.get(`${process.env.REACT_APP_BASE_URL}api/product-questions?ProductQuestionsSearch[product_id]=${productid}&sort=-question_time&page=${page}`);
        // Promise.all([productDetailsPromise]).then((response) => {
        //     setQA([...qa, ...response[0].data.items]);
        //     setTotalPages(response[0].data._meta.totalCount)

        //     console.log(response);

        // });
        loadQuestionAnswers();
    }, [page]);

    const loadMore = () => {
        setLoadMoreLoading(true)
        setTimeout(function () {
            setPage(page + 1);
            setLoadMoreLoading(false)
        }, 1500)
    }

    return (
        <>
            <div className="qaContainer">

                <>


                    <div className="qaHolder customTab customTabThemeTwo">
                        <Tabs defaultActiveKey="qa" transition={false} variant="pills">

                            <Tab eventKey="qa" title="Q&amp;A" tabClassName="">
                                <div className="tabContent">
                                    <QAForm
                                        productid={productid}
                                    />
                                    {qa && qa.length > 0 ? (
                                        <>
                                            <ul className="mt-4">
                                                {qa.map((item, index) => {
                                                    return (
                                                        <li className="qaList" key={item.id}>
                                                            <div className="question">
                                                                <div><strong>Q: {item.question}</strong></div>
                                                                <div className="questionSender_questionTime">
                                                                    {item.questioner_name}
                                                                    &nbsp; - &nbsp;
                                                                    <StartTime time={item.question_time} />
                                                                </div>
                                                            </div>
                                                            <div className="answer">
                                                                <div>A: {item.answer}</div>
                                                                <div className="answerTime">
                                                                    <StartTime time={item.answer_time} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                                )}

                                            </ul>
                                            {loadMoreLoading ? <SkeletonQuestionItem /> : ''}
                                            {totalPages !== page &&
                                                <div className="loadMoreButton">
                                                    <Button
                                                        className="hasShadow"
                                                        variant="whiteButton"
                                                        size="sm"
                                                        onClick={() => {
                                                            loadMore()
                                                        }}
                                                    >
                                                        load more
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    ) : (null)}
                                </div>
                            </Tab>

                            <Tab eventKey="reviews" title="Review(s)" tabClassName="">
                                <div className="tabContent">
                                    <ProductReview

                                        productid={productid}
                                        itemReviews={itemReviews}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>

                </>

            </div>
        </>
    )
}

export default QA