import React, { useState, useContext } from 'react'
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { PopupContext } from "../../contexts/PopupContext"

import { WishlistContext } from "../../contexts/WishlistContext"

const AddToWishlist = ({ product_id, props }) => {
    const { addWishlistItem, getUserIDForWishlist, isItemInWishlist, removeWishlistItem } = useContext(WishlistContext);

    const { popupDispatch } = useContext(PopupContext);
    const [isHover, setIsHover] = useState(false);
    const [isActive, setIsActive] = useState(false);


    const isItemInWishlistFlag = isItemInWishlist(product_id);
    return (
        <>
            <div className="addToWishlistHolder cursor-pointer"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={async () => {
                    if (isItemInWishlistFlag) {
                        setIsActive(false)
                        await removeWishlistItem(product_id);
                    } else {
                        let userID = await getUserIDForWishlist();
                        if (userID) {
                            setIsActive(true)
                            await addWishlistItem(product_id)
                        } else {
                            popupDispatch({
                                type: "POPUP_WISHLISTEMAIL_OPEN",
                            })
                        }
                    }
                }}
            >
                {isHover || isActive || isItemInWishlistFlag ? (
                    <BsHeartFill title={isActive || isItemInWishlistFlag ? "Remove from Wishlist" : "Add to Wishlist"} />
                ) : (
                    <BsHeart title="Add to Wishlist" />
                )
                }
            </div>






        </>
    )
}
export default AddToWishlist;