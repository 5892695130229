import React, { useContext } from 'react'
import { isCustomizationApplied } from "../../helpers/CartHelper"
import Price from './../ProductItem/Price';
import { getSelectedCurrencySymbol, getPriceInSelectedCurrency } from "../../helpers/MoneyHelper"
import { GlobalContext } from './../../contexts/GlobalContext';

const CartItemPreview = ({ cart_item }) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;

    return (
        <>
            <div className="itemImageHolder">
                <img
                    className="itemImage"
                    src={`${process.env.REACT_APP_BASE_URL}${cart_item.product.image}`}
                    alt={cart_item.product.title}
                    title={cart_item.product.title}
                />
            </div>

            <div className="itemDetails">
                <ul>
                    <li className="itemCategory">
                        {cart_item.product.category.name === "" ? (
                            <p>{cart_item.product.type.name}</p>
                        ) : (
                            <>
                                {cart_item.product.subCategory.name === "" ? (
                                    <p>{cart_item.product.category.name}</p>
                                ) : (
                                    <p>{cart_item.product.subCategory.name}</p>
                                )}
                            </>
                        )}
                        <>
                        </>
                    </li>
                    <li className="itemTitle">
                        {cart_item.product.title}
                    </li>
                    <li>
                        <div className="cartItemBottom">
                            <ul>
                                <li className="priceContainer">
                                    <Price price={cart_item.product.price} oldPrice={cart_item.product.old_price} />
                                </li>
                            </ul>
                        </div>

                    </li>

                    <li>
                        {isCustomizationApplied(cart_item) &&
                            <div className="additionalCostinCartItem mt-6">
                                Customization Cost: <span className="additionalCost">{getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(cart_item.customization_price, defaultSelectedCurrency, currencies, true)}</span>
                            </div>
                        }
                    </li>

                    <li>
                        {cart_item.packaging_id &&
                            <div className="additionalCostinCartItem">
                                Pcakaging Cost: <span className="additionalCost">{getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(cart_item.packaging_price, defaultSelectedCurrency, currencies, true)}</span>
                            </div>
                        }
                    </li>
                </ul>
            </div>
        </>
    )
}

export default CartItemPreview;