import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import FilterByPrice from './FilterByPrice'
import TypeCategoryFilters from './TypeCategoryFilters'
import QuickFilters from './QuickFilters';
import FilterByAttributes from './FilterByAttributes';
import { removeItemFromArray } from '../../../helpers/CommonHelper'
import FilterByKeyword from './FilterByKeyword';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getPriceInUSD } from "../../../helpers/MoneyHelper"

const Filter = ({ productsCount, availableFilters, appliedFilters, setAppliedFilters }) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;
    const [prevSelectedCurrency, setPrevSelectedCurrency] = useState(defaultSelectedCurrency)
    //console.log(productsCount)


    let history = useHistory();
    const appliedAttributeFilters = (appliedFilters && appliedFilters['f'] !== undefined) ? JSON.parse(appliedFilters['f']) : undefined;

    const applyNewFilter = (key, value) => {
        let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));;

        if (key === 'type') {
            delete newAppliedFilters['q']
            delete newAppliedFilters['category']
            delete newAppliedFilters['subCategory']
        } else if (key === 'category') {
            delete newAppliedFilters['q']
            delete newAppliedFilters['subCategory']
        } else if (key === 'subCategory') {
            delete newAppliedFilters['q']
        }

        delete newAppliedFilters['f']



        newAppliedFilters[key] = value;

        if (
            (key === 'customizable' && value === false)
            || (key === 'hot' && value === false)
            || (key === 'newItem' && value === false)
            || (key === 'free_delivery' && value === false)
            || (key === 'stock' && value === false)
            || (key === 'q' && value === '')
        ) {
            delete newAppliedFilters[key]
        }

        let newURL = queryString.stringify(newAppliedFilters);
        history.push({ search: newURL });
        setAppliedFilters(newAppliedFilters);
    }

    const applyNewPriceFilter = (min, max) => {

        let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));


        let priceMinUSD = min;
        let priceMaxUSD = max;
        if (defaultSelectedCurrency !== 'USD') {
            priceMinUSD = getPriceInUSD(min, defaultSelectedCurrency, currencies, true);
            priceMaxUSD = getPriceInUSD(max, defaultSelectedCurrency, currencies, true);
        }

        newAppliedFilters['price-min'] = min;
        newAppliedFilters['price-max'] = max;

        newAppliedFilters['price-min-usd'] = priceMinUSD;
        newAppliedFilters['price-max-usd'] = priceMaxUSD;

        if (min === '' || min === 0) {
            delete newAppliedFilters['price-min']
            delete newAppliedFilters['price-min-usd']
        }

        if (max === '' || max === 0) {
            delete newAppliedFilters['price-max']
            delete newAppliedFilters['price-max-usd']
        }


        let newURL = queryString.stringify(newAppliedFilters);
        history.push({ search: newURL });
        setAppliedFilters(newAppliedFilters);
    }


    const applyNewAttributeFilter = (name, value, removeFlag) => {




        let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));
        let newAppliedAttributeFilters = (newAppliedFilters && newAppliedFilters['f'] !== undefined) ? JSON.parse(newAppliedFilters['f']) : undefined;

        if (removeFlag) {
            let appliedAttributesValues = newAppliedAttributeFilters[name]
            appliedAttributesValues = removeItemFromArray(value, appliedAttributesValues)

            if (appliedAttributesValues && appliedAttributesValues.length) {
                newAppliedAttributeFilters[name] = appliedAttributesValues
            } else {
                delete newAppliedAttributeFilters[name]
            }

        } else {
            if (newAppliedAttributeFilters && newAppliedAttributeFilters[name] && newAppliedAttributeFilters[name].length) {
                if (!newAppliedAttributeFilters[name].includes(value)) {
                    newAppliedAttributeFilters[name].push(value)
                }
            } else {
                newAppliedAttributeFilters = { ...newAppliedAttributeFilters, [name]: [value] }
            }
        }

        if (newAppliedAttributeFilters && Object.keys(newAppliedAttributeFilters).length) {
            newAppliedFilters['f'] = JSON.stringify(newAppliedAttributeFilters);
        } else {
            delete newAppliedFilters['f'];
        }

        let newURL = queryString.stringify(newAppliedFilters);
        history.push({ search: newURL });
        setAppliedFilters(newAppliedFilters);
    }

    useEffect(() => {
        //  console.log('currency change useEffect Called', defaultSelectedCurrency, prevSelectedCurrency );
        if (defaultSelectedCurrency !== prevSelectedCurrency) {
            //  console.log("Change in currency");
            applyNewPriceFilter(0, 0);
        }
    }, [defaultSelectedCurrency])


    return (
        <div className="filterContainer hasArrow ">
            <div className="filterHolder">

                <TypeCategoryFilters
                    availableFilters={availableFilters}
                    appliedFilters={appliedFilters}
                    applyNewFilter={applyNewFilter}
                />

                <QuickFilters
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    applyNewFilter={applyNewFilter}
                />
                <FilterByAttributes
                    availableFilters={availableFilters}
                    appliedAttributeFilters={appliedAttributeFilters}
                    applyNewAttributeFilter={applyNewAttributeFilter}
                />



                <FilterByPrice
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    applyNewPriceFilter={applyNewPriceFilter}
                />
                <FilterByKeyword
                    appliedFilters={appliedFilters}
                    applyNewFilter={applyNewFilter}
                />




            </div >
        </div>
    )
}

export default Filter
