import React, { useContext, useState, useRef, useEffect } from "react";
import "./UserOverlay.scss";
import { Popover, Overlay, Button, Nav } from "react-bootstrap";
import { BackdropContext } from "../../contexts/BackdropContext";
import { OverlayContext } from "../../contexts/OverlayContext";
import { PopupContext } from "../../contexts/PopupContext";
import { AuthContext } from "../../contexts/AuthContext";
import { IoCloseCircleSharp } from "react-icons/io5";
import { relativeBody } from "../../helpers/DOMHelpers";
import * as DOMHelpers from "../../helpers/DOMHelpers";
import { ImUser } from "react-icons/im";
import { Link, useHistory } from "react-router-dom";
import Logout from "../Logout/Logout";
const UserOverlay = ({ item }) => {
  const { backdropState, backdropDispatch } = useContext(BackdropContext);
  const { userOverlayState, userOverlayDispatch } = useContext(OverlayContext);
  const { userAuthState, userAuthDispatch } = useContext(AuthContext);
  const { popupDispatch } = useContext(PopupContext);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  let history = useHistory();
  let isLogin = userAuthState.user;
  useEffect(() => {
    const mobile = window.matchMedia("(max-width: 767px)");
    const tablet = window.matchMedia("(max-width: 960px)");
    if (!isLogin && mobile.matches) {
      setTimeout(() => {
        userOverlayDispatch({
          type: "OVERLAY_OPEN",
        });
        backdropDispatch({
          type: "FETCH_BACKDROPHORIZONTAL_OPEN",
        });
        DOMHelpers.fixedBody();
      }, 8000);

      setTimeout(() => {
        userOverlayDispatch({
          type: "OVERLAY_CLOSE",
        });
        backdropDispatch({
          type: "FETCH_BACKDROP_CLOSE",
        },10000);
      
        DOMHelpers.relativeBody();
      });
    }else if(!isLogin && tablet.matches){
      userOverlayDispatch({
        type: "OVERLAY_OPEN",
      });
      backdropDispatch({
        type: "FETCH_BACKDROPHORIZONTAL_OPEN",
      });
      DOMHelpers.fixedBody();
      setTimeout(() => {
        userOverlayDispatch({
          type: "OVERLAY_CLOSE",
        });
        backdropDispatch({
          type: "FETCH_BACKDROP_CLOSE",
        });
        DOMHelpers.relativeBody();
        
      }, 10000);
    } else if (!isLogin) {
      userOverlayDispatch({
        type: "OVERLAY_OPEN",
      });
      backdropDispatch({
        type: "FETCH_BACKDROPHORIZONTAL_OPEN",
      });
     
      setTimeout(() => {
        userOverlayDispatch({
          type: "OVERLAY_CLOSE",
        });
        backdropDispatch({
          type: "FETCH_BACKDROP_CLOSE",
        });
        DOMHelpers.relativeBody();
        
      }, 10000);
    } 
    else {
      userOverlayDispatch({
        type: "OVERLAY_CLOSE",
      });
      backdropDispatch({
        type: "FETCH_BACKDROP_CLOSE",
      });
      DOMHelpers.relativeBody();
      
    }
  }, [isLogin, userOverlayDispatch, backdropDispatch]);

  const handleCartOverlay = (event) => {
    setTarget(event.target);
    userOverlayDispatch({
      type: "OVERLAY_OPEN",
    });
    backdropDispatch({
      type: "FETCH_BACKDROPHORIZONTAL_OPEN",
    });
    DOMHelpers.fixedBody();
  };

  const openSignInPopup = () => {
    popupDispatch({
      type: "POPUP_SIGNIN_OPEN",
    });
    userOverlayDispatch({
      type: "OVERLAY_CLOSE",
    });
    backdropDispatch({
      type: "FETCH_BACKDROP_CLOSE",
    });
    DOMHelpers.relativeBody();
  };

  const hideCartOverlay = () => {
    userOverlayDispatch({
      type: "OVERLAY_CLOSE",
    });
    backdropDispatch({
      type: "FETCH_BACKDROP_CLOSE",
    });
    DOMHelpers.relativeBody();
  };

  return (
    <>
      <div
        className={`userIconOverlay ${
          userOverlayState.isShowing ? "disabledLink" : ""
        }`}
        onClick={handleCartOverlay}
        ref={ref}
      >
        <ImUser
          title="Your Membership Area"
          className={
            "userIcon " +
            (userOverlayState.isShowing || userAuthState.user ? "active" : null)
          }
        />
      </div>

      <Overlay
        show={userOverlayState.isShowing}
        target={target}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
        rootClose={true}
        onHide={() => {
          hideCartOverlay();
        }}
        transition={false}
      >
        <Popover className="customPopoverTheme whitePopover userOverlay disabledElementChild">
          <Popover.Content className="p-0 userOverlayContent">
            <ul>
              <li>
                {userAuthState.user && userAuthState.user.type === "Member" ? (
                  <div className="bold afterSigninUserArea">
                    Hello, <span>{userAuthState.user.first_name}</span>
                  </div>
                ) : (
                  <>
                   <div className='closeModalContainer'
                onClick={() => {
                    userOverlayDispatch({
                        type: "OVERLAY_CLOSE",
                      });
                      backdropDispatch({
                        type: "FETCH_BACKDROP_CLOSE",
                      });
                    relativeBody();
                }
                }
                >
                    <IoCloseCircleSharp className="closeModal" />
                </div>
                    <h5 className="overlayTitle">Get started now</h5>
                  </>
                )}
              </li>
              {(!userAuthState.user ||
                userAuthState.user.type !== "Member") && (
                <li>
                  <div className="flexContainer flexColumn">
                    <Button
                      variant="PinkButton"
                      size="sm"
                      className="hasShadow lessCorners"
                      onClick={() => {
                        popupDispatch({
                          type: "POPUP_SIGNIN_OPEN",
                        });
                        userOverlayDispatch({
                          type: "OVERLAY_CLOSE",
                        });
                        backdropDispatch({
                          type: "FETCH_BACKDROP_CLOSE",
                        });
                      }}
                    >
                      Sign in
                    </Button>
                    <div className="dividor columnView"> -- or -- </div>
                    <Button
                      variant="PurpleButton"
                      size="sm"
                      className="hasShadow lessCorners"
                      onClick={() => {
                        popupDispatch({
                          type: "POPUP_SIGNUP_OPEN",
                        });
                        userOverlayDispatch({
                          type: "OVERLAY_CLOSE",
                        });
                        backdropDispatch({
                          type: "FETCH_BACKDROP_CLOSE",
                        });
                      }}
                    >
                      New customer? Start here
                    </Button>
                  </div>
                </li>
              )}
              <li>
                <div className="userOverlayItemLinks">
                  <ul>
                    <li>
                      {userAuthState.user &&
                      userAuthState.user.type === "Member" ? (
                        <Link
                          to="/member"
                          onClick={() => {
                            hideCartOverlay();
                          }}
                        >
                          My Dashboard
                        </Link>
                      ) : (
                        <Nav.Link
                          className="p-0"
                          onClick={() => {
                            openSignInPopup();
                          }}
                        >
                          My Dashboard
                        </Nav.Link>
                      )}
                    </li>
                    <li>
                      {userAuthState.user &&
                      userAuthState.user.type === "Member" ? (
                        <Link
                          to="/cart"
                          onClick={() => {
                            hideCartOverlay();
                          }}
                        >
                          My Cart
                        </Link>
                      ) : (
                        <Nav.Link
                          className="p-0"
                          onClick={() => {
                            openSignInPopup();
                          }}
                        >
                          My Cart
                        </Nav.Link>
                      )}
                    </li>

                    <li>
                      {userAuthState.user &&
                      userAuthState.user.type === "Member" ? (
                        <Link
                          to="/wishlist"
                          onClick={() => {
                            hideCartOverlay();
                          }}
                        >
                          My Wishlist
                        </Link>
                      ) : (
                        <Nav.Link
                          className="p-0"
                          onClick={() => {
                            openSignInPopup();
                          }}
                        >
                          My Wishlist
                        </Nav.Link>
                      )}
                    </li>

                    <li>
                      {userAuthState.user &&
                      userAuthState.user.type === "Member" ? (
                        <Link
                          to="/member/orders"
                          onClick={() => {
                            hideCartOverlay();
                          }}
                        >
                          My Order(s)
                        </Link>
                      ) : (
                        <Nav.Link
                          className="p-0"
                          onClick={() => {
                            openSignInPopup();
                          }}
                        >
                          My Order(s)
                        </Nav.Link>
                      )}
                    </li>

                    {/* <li>
                                            {userAuthState.user ? (
                                                <Link to="/"
                                                    onClick={() => {
                                                        hideCartOverlay();
                                                    }}
                                                >
                                                    Need Support?
                                                </Link>
                                            ) : (
                                                <Nav.Link
                                                    className="p-0"
                                                    onClick={() => {
                                                        openSignInPopup();
                                                    }}
                                                >
                                                    Need Support?
                                                </Nav.Link>
                                            )}
                                        </li> */}

                    {userAuthState.user &&
                      userAuthState.user.type === "Member" && (
                        <>
                          <li>
                            <Logout
                              hideCartOverlay={hideCartOverlay}
                              userAuthState={userAuthState}
                              userAuthDispatch={userAuthDispatch}
                            />
                          </li>
                        </>
                      )}
                  </ul>
                </div>
              </li>
            </ul>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default UserOverlay;
