import React, { useState, useReducer, useContext } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../components/FormPartials/FormInput'
import FormTextArea200 from '../FormPartials/FormTextArea200'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import axios from 'axios'
import { qaValidation } from '../FormPartials/Validation'
import { GlobalContext } from './../../contexts/GlobalContext';
import { FormSubmissionFailed } from '../Alert/Alert'
import { submitProductQuestionAPI } from '../../apis/productAPIs'
import { AuthContext } from './../../contexts/AuthContext'

const QAForm = ({ productid, ...props }) => {
    const { getBearerTokenConfig } = useContext(AuthContext);
    const { getFrontendMessage } = useContext(GlobalContext);
    const [count, setCount] = useState(0);
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const heading = getFrontendMessage('Forms', 'Ask A Question Form', 'Top Heading', 'Text')
    const successMessages = getFrontendMessage('Forms', 'Ask A Question Form', 'Success Message', 'Paragraphs')
    return (
        <>
            <div className="formContainer">

                {formState && formState ? (
                    <>
                        {formState.isFormSubmitting && (
                            <ContainerLoading />
                        )}
                        {formState.isFormSubmitted ? (
                            <div>
                                <SuccessMsg
                                    msgTitle={successMessages && successMessages.length ? successMessages[0].text : ''}
                                    msgBody={successMessages && successMessages.length ? successMessages[1].text : ''}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="sectionHeading mb-3">
                                    <p>{heading}</p>

                                </div>
                                <Formik
                                    initialValues={{
                                        product_id: productid,
                                        questioner_name: "",
                                        questioner_email: "",
                                        question: ""
                                    }}
                                    validationSchema={qaValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });
                                        const submissionResult = await submitProductQuestionAPI(values, getBearerTokenConfig());
                                        console.log(submissionResult);
                                        if (submissionResult.status) {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_SUCCESS",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                resetForm();
                                                setSubmitting(false);
                                            }, 5000);

                                        } else {
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                        }
                                        // setTimeout(() => {
                                        //     formDispatch({
                                        //         type: "FORM_SUBMISSION_SUCCESS",
                                        //     });
                                        //     axios.post(`${process.env.REACT_APP_BASE_URL}api/product-questions/create`,
                                        //         values,
                                        //     )
                                        //         .then(res => {
                                        //             console.log(res);
                                        //             console.log(res.data);
                                        //             // alert(JSON.stringify(values, null, 2));
                                        //             resetForm();
                                        //             setSubmitting(false);
                                        //         })
                                        // }, 500);
                                        // console.log(values);

                                        // setTimeout(() => {
                                        //     formDispatch({
                                        //         type: "FORM_INITIAL_STAGE",
                                        //     });
                                        // }, 8000);
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            {
                                                formState.hasSubmissionError && (
                                                    <FormSubmissionFailed />
                                                )
                                            }

                                            <input name="product_id" type="hidden" value={values.product_id} />
                                            <Col className={`p-0 ${errors.questioner_name && touched.questioner_name ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="questioner_name"
                                                    label="Your Name"
                                                    name="questioner_name"
                                                    type="text"
                                                    value={values.questioner_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col className={`p-0 ${errors.questioner_email && touched.questioner_email ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="questioner_email"
                                                    label="Your Email Address"
                                                    name="questioner_email"
                                                    type="email"
                                                    value={values.questioner_email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col className={`p-0 ${errors.question && touched.question ? "errorParent" : ""}`}>
                                                <FormTextArea200
                                                    controlId="question"
                                                    label="Your Question"
                                                    name="question"
                                                    type="text"
                                                    as="textarea"
                                                    rows={3}
                                                    value={values.question}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        setCount(e.target.value.length);
                                                    }}
                                                    onBlur={handleBlur}
                                                    count={count}
                                                />
                                            </Col>

                                            <Button variant="whiteButton"
                                                className="hasPadding uppercase hasShadow bold"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}

                    </>
                ) : (
                    <h1>form submission error</h1>
                )}
            </div>
        </>
    )
}

export default QAForm
