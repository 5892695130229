import React, { useState } from 'react'
import { Form } from 'react-bootstrap'


const ProductSorting = ({ appliedSorting, setSorting }) => {
    // console.log(appliedSorting)

    const [currentSorting, setCurrentSorting] = useState(appliedSorting ? appliedSorting : 'sort by')


    return (
        <div>
            <div className="itemSortby">
                <Form.Control
                    as="select"
                    custom size="lg"
                    className="headerSearchSelectCustom"
                    onChange={(e) => setSorting(e.target.value)}
                    value={currentSorting}
                >
                    <option value="newest-to-oldest">Newest to Oldest</option>
                    <option value="oldest-to-newest" >Oldest to Newest</option>
                    <option value="high-to-low">Price: High to low</option>
                    <option value="low-to-high">Price: Low to high</option>
                </Form.Control>
            </div>
        </div>
    )
}

export default ProductSorting
