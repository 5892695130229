import axios from 'axios'



export const getOrderMessagesAPI = async (id, queryString, headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/order-messages/get-messages/${id}?${queryString}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}


export const sendOrderMessagesAPI = async (orderID, data, headers) => {
    if (!orderID || !data || !data.subject || !data.message || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/order-messages/send-message/${orderID}`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const getOrderMessageThreadAPI = async (orderID, messageID, queryString, headers) => {
    if (!orderID || !messageID || !headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/order-messages/get-thread/${orderID}/${messageID}?${queryString}`,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}

export const sendOrderMessagesReplyAPI = async (orderID, messageID, data, headers) => {
    if (!orderID || !messageID || !data || !data.subject || !data.message || !headers)
        return { "status": false };

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/order-messages/send-reply/${orderID}/${messageID}`,
            data,
            headers
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}