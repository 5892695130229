export const GlobalContextReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_CONTENT_SUCCESS":
            return {
                ...state,
                isFetching: false,
                isContentLoaded: true,
                data: action.payload
            };
        case "FETCH_CONTENT_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false
            };
        case "SET_SELECTED_CURRENCY":
            return {
                ...state,
                defaultSelectedCurrency: action.payload,
            };
        default:
            return state;
    }
};