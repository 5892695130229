import React from 'react'
import { IoCloseCircleSharp } from "react-icons/io5";

export const CloseOverlay = (props) => {
    return (
        <div className="closeButton"
            onClick={()=> {document.body.click()}}
            title="Close"
        >
        <IoCloseCircleSharp/>
        </div>
    )
}



export const ClosePopup = ({setShowPopup, props}) => {
    return (
        <div className="closeButton"
            onClick={()=> setShowPopup(false)}
            title="Close"
        >
        <IoCloseCircleSharp/>
        </div>
    )
}


export const CloseButton = ({props, onClick}) => {
    return (
        <div className="closeButton"
            onClick={onClick}
            title="Close"
        >
        <IoCloseCircleSharp/>
        </div>
    )
}
