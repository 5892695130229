import React from 'react'
import Moment from 'react-moment';
export const StartTime = ({time}) => {
    return (
        <Moment
        unix
        fromNow
        local
    >
         {time}
    </Moment>
    )
}

export const EndTime = ({time}) => {
    return (
        <Moment
        unix
        fromNow
        local
    >
         {time}
    </Moment>
    )
}


export const Time = ({time}) => {
    return (
        <Moment
        format="DD MMMM YYYY"
        unix
        local
    >
         {time}
    </Moment>
    )
}