import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import "./InnerPageHeader.scss";
const InnerPageHeader = ({ lastItemTitle }) => {
    return (
        <div className="innerPageHeader">
            <div className="headerPattern top"></div>
            <Breadcrumbs lastItemTitle={lastItemTitle} />
        </div>
    )
}

export default InnerPageHeader
