export const PagesReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_PAGE_CONTENT_SUCCESS":
            return {
                ...state,
                isLoading: false,
                content: action.payload.data,
            };
        case "FETCH_PAGE_CONTENT_ERROR":
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };

        default:
            return state;
    }
}

export const pageInitialState = {
    content: {},
    isLoading: true,
    hasError: false,
}