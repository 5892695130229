import React, { useEffect, useState, useReducer, useRef } from 'react'
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom";
import "./TopSellerzSlider.scss"
import { productReducer, productsInitialState } from "../../reducers/Reducers"
import ProductItem from "../ProductItem/ProductItem"
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper'
import { FaCaretRight, FaCaretLeft } from "react-icons/fa"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import { productSliderParams } from '../../helpers/DOMHelpers'
import { getTopSellingProductsAPI } from '../../apis/generalAPIs'




SwiperCore.use([Navigation, Autoplay, Scrollbar]);
const TopSellerzSlider = (props) => {
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [productState, productDispatch] = useReducer(productReducer, productsInitialState);
  const loadContent = async () => {
    const submissionResult = await getTopSellingProductsAPI();
    if (submissionResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: submissionResult.data.items
      });
    }
  }


  useEffect(() => {
    loadContent()
  }, []);

  return (
    <>
      {(productState.products && productState.products.length > 0) &&
        <Container className="productSlider topSellerzSlider sliderBottomSpacing">
          <Row>
            <Col className="productSliderHeadingContainer" xl={2} lg={3} md={3} sm={4} xs={12}>
              <div className="heading">
                Top Selling
              </div>
              <Link to="/products" className="btn btn-whiteButton hasShadow mt-2 btn-sm">view all</Link>
            </Col>
            <Col
              className={`productSliderContainer ${productState.hasError ? 'hasError' : ''}`}
              xl={10} lg={9} md={9} sm={8} xs={12}
            >
              <>
                <div
                  onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                  onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                  className="sliderWrapper"
                >
                  <Swiper
                    ref={swiperRef}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    {...productSliderParams}
                    onSwiper={(swiper) => {
                      setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        setIsFirstSlide(true);
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                      })
                    }}
                    onSlideChange={(swiper) => {
                      if (swiper.activeIndex === 0) {
                        setIsFirstSlide(true);
                      }
                      else if (swiper.isEnd) {
                        setIsLastSlide(true);
                      }
                      else {
                        setIsFirstSlide(false);
                        setIsLastSlide(false);
                      }
                    }}
                  >

                    {productState.products.map((item, index) => (
                      <SwiperSlide className="swiper-slide productSlideContent" key={index}>
                        <ProductItem key={item.id} item={item.product} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div ref={navigationPrevRef}
                    className={`customSliderNavigation prev ${isFirstSlide ? "hide" : ""}`}
                  >
                    <FaCaretRight className="customSliderPrevIcon" />
                  </div>
                  <div ref={navigationNextRef}
                    className={`customSliderNavigation next ${isLastSlide ? "hide" : ""}`}
                  >
                    <FaCaretLeft className="customSliderNextIcon" />
                  </div>


                </div>
              </>

            </Col>
          </Row>

        </Container>
      }
    </>
  )
}

export default TopSellerzSlider;