
import React from 'react'
import { Link } from 'react-router-dom'
export const ToastCartItemAdded = ({ closeToast, toastProps }) => {
  return (
    <div>
      <div>Item Added to Cart </div>
      <div className="mt-2">
        <Link to="/cart" className="link white">View Cart</Link>
      </div>
    </div>
  )
}

export const ToastWishlistItemAdded = ({ closeToast, toastProps }) => {
  return (
    <div>
      <div>Item Added to Wishlist </div>
      <div className="mt-2">
        <Link to="/wishlist" className="link white">View Wishlist</Link>
      </div>
    </div>
  )
}


