import React from 'react'
import { Container, Col } from 'react-bootstrap'
import InnerPageHeader from "../../components/InnerPageHeader/InnerPageHeader"
import { Link } from 'react-router-dom'
const ConfirmOrder = () => {
    return (
        <>
            <InnerPageHeader />
            <Container>
                <Col className="confirmOrder innerpageCard hasMarginTop hasShadow">
                    <div className="confirmOrderMsg">
                        <div className="successIcon center mb4">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none"
                                    stroke="#73AF55" strokeWidth="6"
                                    strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className="path check"
                                    fill="none" stroke="#73AF55"
                                    strokeWidth="6" strokeLinecap="round"
                                    strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </div>
                        <div className="heading">Thank You.</div>
                        <div className="msgBody">
                            <p>
                                Your order has been received and will be processed soon.
                            </p>
                            <p>
                                Your transaction number is: <strong className="secondaryPink">147963812</strong>
                            </p>
                        </div>
                        <Link to="/products" className="btn btn-PinkButton btn-lg hasPadding hasShadow uppercase bold mt-4">CONTINUE SHOPPING</Link>
                    </div>


                    <div className="additionalMsgAfterSubmission">
                        <div>
                            <p>We have sent an invoice with your order's details on your registered email address.</p>
                            <p>If you do not receive the message within a few minutes of your order, please check your Bulk Mail / Spam / Junk folders just in case the email got delivered there instead of your inbox. </p>
                            <p>To avoid future emails delivery issues we would recommend you to <strong> whitelist the domain : jnoonz.com </strong></p>
                        </div>
                        <div>
                            if you have any query, Kindly <Link to="/contact" className="link pink">Contact us </Link>
                        </div>
                    </div>

                </Col>
            </Container>
        </>
    )
}

export default ConfirmOrder
