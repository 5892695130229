import React, { useEffect, useContext, useState } from 'react'
import { Form, Col, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import BankTransferDetails from './BankTransferDetails'
import ExchangeTransferDetails from './ExchangeTransferDetails'
import { ContainerLoading } from "../../components/Loading/Loading"
import { FormSubmissionFailed } from '../../components/Alert/Alert'
import { CartContext } from '../../contexts/CartContext'
import { fetchMoneyTransferDetailAPI } from '../../apis/generalAPIs'

const PaymentOptions = ({ paymentMethodMsg }) => {
    const { cart, addPaymentMethodToCart } = useContext(CartContext);
    const [loading, setLoading] = useState(true)
    const [transferDetails, setTransferDetails] = useState(null)

    //const [paymentMethodValue, setPaymentMethodValue] = useState(((cart && cart.cartItems.paymentMethod) ? cart.cartItems.paymentMethod : ''));

    const handleOptionChange = async (changeEvent) => {
        console.log('payment method changed');
        await addPaymentMethodToCart(changeEvent.target.value);
    }


    const loadBankDetails = async () => {
        const apiResult = await fetchMoneyTransferDetailAPI();
        console.log(apiResult);
        if (apiResult.status) {
            setTransferDetails(apiResult.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadBankDetails()
    }, [])

    return (
        <div className="paymentMethods">
            <div className="selectPaymentOptions">
                <div className="pageHeading">
                    {paymentMethodMsg.heading}
                </div>

                <div className="formContainer">


                    {(loading || cart.isUpdating) ? <ContainerLoading />
                        :
                        <>
                            <div className="customHeadingForForm">
                                <p>
                                    {paymentMethodMsg.subHeading}
                                </p>
                            </div>
                            {(cart && cart.hasError) && <FormSubmissionFailed />}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    paymentMethod: '',
                                }}
                                validationSchema=""
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    return;
                                }}
                            >
                                {({ values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,

                                    isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} className="mx-auto">
                                        <Col className={`p-0 ${errors.firstName && touched.firstName ? "errorParent" : ""}`}>
                                            {/* {['Cash On Delivery', 'Bank Transfer', 'Money Exchange Transfer'].map((type) => (
                    <div key={`-${type}`} className="mb-3">
                    
                    </div>
                    ))} */}
                                            {/* <Form.Check
                                                inline
                                                name="paymentMethod"
                                                label="Bank Transfer"
                                                type="radio"
                                                id="Bank Transfer"
                                                value="Bank Transfer"
                                                checked={cart && cart.cartItems.paymentMethod === 'Bank Transfer'}
                                                onChange={handleOptionChange}

                                            /> */}
                                            <Form.Check
                                                inline
                                                className="hasCursor"
                                                name="paymentMethod"
                                                label="Cash On Delivery"
                                                type="radio"
                                                id="Cash On Delivery"
                                                value="Cash On Delivery"
                                                checked={cart && cart.cartItems.paymentMethod === 'Cash On Delivery'}
                                                onChange={handleOptionChange}

                                            />
                                            <Form.Check
                                                inline
                                                name="paymentMethod"
                                                label="Money Exchange Transfer"
                                                type="radio"
                                                id="Money Exchange Transfer"
                                                value="Money Exchange Transfer"
                                                checked={cart && cart.cartItems.paymentMethod === 'Money Exchange Transfer'}
                                                onChange={handleOptionChange}

                                            />
                                        </Col>



                                        <Col className="paymentMethodSelected">
                                            {cart && cart.cartItems.paymentMethod === 'Cash On Delivery' &&
                                                <>
                                                    <Alert
                                                        variant="tertiaryPink"
                                                        className="selectedOptionHolder">Your selection of payment method is: <span className="selectedOption">{cart.cartItems.paymentMethod}</span></Alert>
                                                </>
                                            }
                                            {cart && cart.cartItems.paymentMethod === 'Bank Transfer' &&
                                                <>
                                                    <Alert
                                                        variant="tertiaryPink"
                                                        className="selectedOptionHolder">Your selection of payment method is: <span className="selectedOption">{cart.cartItems.paymentMethod}</span>
                                                    </Alert>
                                                    <BankTransferDetails transferDetails={(transferDetails && transferDetails.bank) ? transferDetails.bank : null} />
                                                </>
                                            }
                                            {cart && cart.cartItems.paymentMethod === 'Money Exchange Transfer' &&
                                                <>
                                                    <Alert
                                                        variant="tertiaryPink"
                                                        className="selectedOptionHolder">Your selection of payment method is: <span className="selectedOption">{cart.cartItems.paymentMethod}</span>
                                                    </Alert>
                                                    <ExchangeTransferDetails transferDetails={(transferDetails && transferDetails.personal) ? transferDetails.personal : null} />
                                                </>
                                            }
                                        </Col>

                                        {/* <Button variant="PurpleButton"
                    className="hasPadding uppercase hasShadow bold"
                    type="submit"
                    disabled={isSubmitting}
                >
                    Submit
                </Button> */}
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }



                </div>


                {/* <div className="itemDetailsTabs customTab mt-4">
                <Tabs defaultActiveKey="cashOnDelivery" transition={false} variant="pills"
                    onSelect={paymentMethodSelection()}
                >
                <Tab eventKey="cashOnDelivery" title="Cash On Delivery" tabClassName="">
                    
                </Tab>
                <Tab eventKey="bankTransfer" title="Bank Transfer" tabClassName="">
                    <BankTransferDetails/>
                </Tab>
                <Tab eventKey="moneyExchangeTransfer" title=" Money Exchange Transfer" tabClassName="">
                    <ExchangeTransferDetails/>
                </Tab>
                </Tabs>
                </div> */}
            </div>
        </div>
    )
}

export default PaymentOptions
