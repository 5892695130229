import React, {useContext} from 'react';
import {BackdropContext} from "../../contexts/BackdropContext";
import "./Backdrop.scss";
export const Backdrop = (props)=>{
const { backdropState } = useContext(BackdropContext);
    return(
        <>
            {backdropState.isShowing && (
                <div className="customBackdrop"></div>
            )}
            
        </>
    )
}

export const BackdropHorizontal = (props)=>{
    const { backdropState } = useContext(BackdropContext);
        return(
            <>
                {backdropState.isShowingHorizontal && (
                    <div className="customBackdrop horizontal"></div>
                )}
                
            </>
        )
    }


export const BackdropSolid = (props)=>{
    const { backdropState } = useContext(BackdropContext);
        return(
            <>
                {backdropState.isShowingSolid && (
                    <div className="customBackdrop solid"></div>
                )}
                
            </>
        )
    }