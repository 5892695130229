import React, { useState, useReducer, useContext, useEffect } from 'react'
import axios from 'axios'
import { Container, Col, Row, Form, Button } from 'react-bootstrap'
import { ContainerLoading } from '../../Loading/Loading'
import { itemReducer, itemInitialState } from "../../../reducers/Reducers"
import { formSubmissionReducer, formInitialState } from '../../../reducers/Reducers'
import { AuthContext } from "../../../contexts/AuthContext"
import { CartContext } from "../../../contexts/CartContext"
import SuccessMsg from '../../FormPartials/SuccessMsg'
import { ErrorComponent } from '../../Alert/Alert'
import { Formik } from 'formik'
import { itemPackingValidation } from '../../FormPartials/Validation'
import MendatoryFieldMsg from '../../FormPartials/MendatoryFieldMsg'
const PackagingScreen = ({ cart_item, ...props }) => {
    const [showPreview, setShowPreview] = useState(true)

    const { match } = props;
    const catItemID = parseInt(match.params.id);
    console.log(catItemID);

    const { userAuthState } = useContext(AuthContext);
    const { cart, cartDispatch } = useContext(CartContext);
    const guest_session_token = JSON.parse(localStorage.getItem('guest_session_token'));
    const [packaging_id, setpackaging_id] = useState(null)


    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)

    const sendData = async (e) => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}api/cart/add-packaging`,
            {
                guest_session_token: guest_session_token,
                cart_item_id: catItemID,
                packaging_id: packaging_id
            },
            {
                headers: {
                    Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
                },
            },
        )
            .then(response => {
                cartDispatch({
                    type: "FETCH_CARTITEM_SUCCESS",
                    payload: response.data
                })
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleGetID = (event) => {
        const index = event.target.selectedIndex;
        const optionElement = event.target.childNodes[index];
        const optionElementId = optionElement.getAttribute('id');
        setpackaging_id(optionElementId)
        console.log(optionElementId);
    }

    const getPackingOptionData = async (e) => {
        let mounted = true;
        itemDispatch({
            type: "FETCH_REQUEST"
        });
        await axios.get(`${process.env.REACT_APP_BASE_URL}api/products/packaging`,
            {
                headers: {
                    Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
                }
            }
        )
            .then(res => {
                console.log(res.data)
                if (mounted) {
                    itemDispatch({
                        type: "FETCH_SUCCESS",
                        payload: res.data
                    })
                }
            })
            .catch(error => {
                itemDispatch({
                    type: "FETCH_FAILURE"
                })
            })
        return () => mounted = false;
    }

    useEffect(() => {
        getPackingOptionData();
    }, [])

    const options = itemState.item.map((optionitem) => {
        return (
            <option key={optionitem.title} value={optionitem.id} id={optionitem.id}>
                {optionitem.title}
            </option>
        )
    })

    return (
        <>

            <p>cart item id: {catItemID} </p>

            <Container>

                <div className="customizationFormContainer">
                    <div className="pageHeading">
                        <p>Select packing option for your item.</p>
                    </div>

                    <div className="placeOrderitem customTab horizontal">

                        <Row className="mt-30">
                            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                                {catItemID && cart.cartItems && cart.cartItems.items &&
                                    <>
                                        {cart.cartItems.items.map((cart_item, index) =>
                                            <>
                                                {catItemID === cart_item.id &&


                                                    <div key={index} className="itemHolder noBorder noHover p-0">
                                                        <div className="itemImageHolder autoHeight">
                                                            <img
                                                                className="itemImage"
                                                                src={`${process.env.REACT_APP_BASE_URL}${cart_item.product.image}`}
                                                                alt={cart_item.product.title}
                                                                title={cart_item.product.title}
                                                            />
                                                        </div>

                                                        <div className="itemDetails mt-3">
                                                            <ul>
                                                                <li>{cart_item.id}</li>
                                                                <li className="itemCategory">
                                                                    {cart_item.product.category.name === "" ? (
                                                                        <p>{cart_item.product.type.name}</p>
                                                                    ) : (
                                                                        <>
                                                                            {cart_item.product.subCategory.name === "" ? (
                                                                                <p>{cart_item.product.category.name}</p>
                                                                            ) : (
                                                                                <p>{cart_item.product.subCategory.name}</p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <>
                                                                    </>
                                                                </li>
                                                                <li className="itemTitle">
                                                                    {cart_item.product.title}
                                                                </li>
                                                                <li>
                                                                    <div className="cartItemBottom">
                                                                        <ul>
                                                                            <li className="priceContainer">
                                                                                <div className="itemPrice isDiscountedprice">
                                                                                    <div className="price" >
                                                                                        ${cart_item.product.price}
                                                                                    </div>
                                                                                    {(cart_item.product.old_price && cart_item.product.old_price > cart_item.product.price) && (
                                                                                        <div className="oldPrice"><del>${cart_item.product.old_price}</del></div>
                                                                                    )}
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                {(cart_item && cart_item.package_price) && (
                                                                    <li>
                                                                        <div className="additionalCostinCartItem">Packaging Cost: <span className="additionalCost">${cart_item.package_price}</span></div>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>



                                                }
                                            </>
                                        )}
                                    </>
                                }
                            </Col>

                            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                <div className="formContainer">
                                    {formState && formState ? (
                                        <>
                                            {formState.isFormSubmitting && (
                                                <ContainerLoading />
                                            )}
                                            {formState.isFormSubmitted ? (
                                                <>
                                                    <SuccessMsg
                                                        msgTitle="Your selected packing option is added"
                                                        msgBody=""
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={{
                                                            packingOption: ""
                                                        }}
                                                        validationSchema={itemPackingValidation}
                                                        validateOnChange={true}
                                                        validateOnBlur={false}
                                                        onSubmit={(values, { setSubmitting, resetForm, event }) => {
                                                            setSubmitting(true);
                                                            formDispatch({
                                                                type: "FORM_SUBMISSION_START",
                                                            });
                                                            setTimeout(() => {
                                                                formDispatch({
                                                                    type: "FORM_SUBMISSION_SUCCESS",
                                                                });
                                                                sendData();
                                                                setShowPreview(false)
                                                            }, 500);
                                                            console.log(JSON.stringify(values))
                                                            setTimeout(() => {
                                                                formDispatch({
                                                                    type: "FORM_INITIAL_STAGE",
                                                                });
                                                                // getCartData()
                                                            }, 5000);
                                                        }}
                                                    >
                                                        {({ values,
                                                            errors,
                                                            touched,
                                                            handleChange,
                                                            handleBlur,
                                                            handleSubmit,
                                                            isSubmitting,
                                                            setFieldValue,
                                                            setFieldTouched,
                                                            error,
                                                            form,
                                                            field
                                                        }) => (
                                                            <Form onSubmit={handleSubmit} className="mx-auto">
                                                                <Col
                                                                    className={`mendatory ${errors.packingOption && touched.packingOption ? "errorParent" : ""}`}>
                                                                    <Form.Group controlId="packingOption">
                                                                        <Form.Label>Select Packing Option</Form.Label>
                                                                        <Form.Control as="select"
                                                                            name="packingOption"
                                                                            onChange={(event) => {
                                                                                handleChange(event)
                                                                                handleGetID(event)
                                                                            }
                                                                            }
                                                                            value={values.packingOption}
                                                                            onBlur={handleBlur}
                                                                        >
                                                                            <option value="">Select Packing Option</option>
                                                                            {options}
                                                                        </Form.Control>
                                                                        {errors.packingOption && <div className="errorMessage">{errors.packingOption}</div>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Form.Row className="mt-40">
                                                                    <Col xl={3} lg={3}>
                                                                        <Button variant="whiteButton"
                                                                            className="hasPadding uppercase hasShadow bold lessCorners"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                cartDispatch({
                                                                                    type: "PACKING_STEP_ONE"
                                                                                });
                                                                            }
                                                                            }
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                    </Col>
                                                                    <Col xl={9} lg={9}>
                                                                        <Button variant="PurpleButton"
                                                                            className="hasPadding uppercase hasShadow bold lessCorners"
                                                                            type="submit"
                                                                            size="sm"
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            Add my selected packing
                                                                        </Button>
                                                                    </Col>
                                                                </Form.Row>
                                                                <Col className="p-0 mt-3">
                                                                    <MendatoryFieldMsg />
                                                                </Col>

                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </>

                                            )}
                                        </>
                                    ) : (
                                        <ErrorComponent />
                                    )}
                                </div>
                            </Col>

                            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                                <div className="packingDetailsOnItem">


                                    {showPreview ? (
                                        <>
                                            <div className="pageHeading mb-4">
                                                You have selected below packing option
                                            </div>
                                            <div className="packingContainer">
                                                <div className="packingDetails">
                                                    <ul>
                                                        <li>
                                                            {/* ID: {cart_item.package_id}
                                            {cart_item.title} */}
                                                            {itemState.item.map((optionItem, index) =>
                                                                <Row>
                                                                    {optionItem.id === 2 &&
                                                                        <>
                                                                            <Col xl={4} lg={4} md={4} sm={4} xs={12} className="pr-0">
                                                                                <div className="packingImage">
                                                                                    <img
                                                                                        className="itemImage"
                                                                                        src={`${process.env.REACT_APP_BASE_URL}${optionItem.image}`}
                                                                                        alt={optionItem.title}
                                                                                        title={optionItem.title}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                                                                <div className="selectedOption">{optionItem.title}</div>
                                                                            </Col>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    )
                                        :
                                        (<p>Please select pacaking first</p>)
                                    }
                                    <div className="packingTotalCost hasBorderTop hasMediumTopPadding">
                                        Packing price: <span className="totalPrice">
                                            {cart_item && cart_item.package_price ? (
                                                <>
                                                    {cart_item.package_price}
                                                </>
                                            )
                                                :
                                                (
                                                    <>
                                                        <span>$0</span>
                                                    </>
                                                )
                                            }
                                        </span>
                                        <div className="caption">Your packing price will be included in your total item cost.</div>
                                    </div>
                                    <div className="mt-3 textRight">
                                        <Button className="hasShadow hasPadding lessCorners" variant="PinkButton" size="sm"
                                            onClick={() => {
                                                // handleClose()
                                            }
                                            }
                                        >
                                            Accept and Continue
                                        </Button>
                                    </div>


                                </div>
                            </Col>

                        </Row>



                    </div>

                </div>

            </Container>
        </>
    )
}

export default PackagingScreen
