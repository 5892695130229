import React, { useContext, useReducer, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { isloggedInAPI } from '../apis/buyerAPIs'
import { isLoggedInReducer, isLoggedInInitialState } from "../reducers/IsLoggedInReducer"
const PrivateRoutes = ({ component: Component, breadcrumb: Breadcrumb, ...rest }) => {
    const { userAuthState, userAuthDispatch, getBearerTokenConfig } = useContext(AuthContext);
    const [isLoggedInState, isLoggedInDispatch] = useReducer(isLoggedInReducer, isLoggedInInitialState)

    const checkUserIsLoggedIn = async () => {
        if (userAuthState && userAuthState.user && userAuthState.user.token) {
            const apiResult = await isloggedInAPI(getBearerTokenConfig());

            if (apiResult.status && apiResult.data.is_logged_in) {
                isLoggedInDispatch({
                    type: "LOGGED_IN_VERIFICATION_SUCCESS",
                    payload: apiResult.data
                });
            } else {
                userAuthDispatch({ type: "LOGOUT" })
                isLoggedInDispatch({
                    type: "LOGGED_IN_VERIFICATION_FAILED",
                });
            }
        } else {
            userAuthDispatch({ type: "LOGOUT" })
            isLoggedInDispatch({
                type: "LOGGED_IN_VERIFICATION_FAILED",
            });
        }
    }

    useEffect(() => {
        checkUserIsLoggedIn()
    }, [])



    return (
        <>

            {(isLoggedInState && !isLoggedInState.isChecking) &&

                <Route
                    {...rest}
                    render={(props) => (
                        (isLoggedInState.isLoggedIn) ?
                            <Component {...props} />
                            :
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                    )
                    }
                />
            }


        </>
    )
}

export default PrivateRoutes