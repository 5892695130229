import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import SwiperCore, { Navigation, Scrollbar, Autoplay } from 'swiper'
import { FaCaretRight, FaCaretLeft } from "react-icons/fa"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import ScrollArea from 'react-scrollbar'
SwiperCore.use([Navigation, Autoplay, Scrollbar]);

const SitemapExpended = ({ props, hideOverlay, itemState }) => {
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const sliderParams = {
    speed: 500,
  };
  return (
    <>
      {itemState && (
        <Fragment>
           <ScrollArea
                speed={0.8}
                className="scrollContent w-100"
                contentClassName="content "
                horizontal={false}
            >
          <Swiper
            {...sliderParams}
            autoplay={false}
            slidesPerView={6}
            slidesPerGroup={6}
            spaceBetween={10}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                if (swiper.params && swiper.params.navigation) {
                  // Override prevEl & nextEl now that refs are defined
                  swiper.params.navigation.prevEl = navigationPrevRef.current
                  swiper.params.navigation.nextEl = navigationNextRef.current
                  setIsFirstSlide(true);
                  // Re-init navigation
                  swiper.navigation.destroy()
                  swiper.navigation.init()
                  swiper.navigation.update()
                }

              })
            }}
            scrollbar={false}
            onSlideChange={(swiper) => { console.log(swiper.activeIndex); }}
            onReachBeginning={() => {
              console.log('slide reach on start')
              setIsFirstSlide(true);
            }}
            onReachEnd={() => {
              console.log('slide reach on end')
              setIsLastSlide(true);
            }}
            style={{ height: "100%" }}
          >
            
            {itemState.item.map((type, index) => (
             
              <SwiperSlide
                className={`swiper-slide sitemapExpendedHolder ${type.name === "JNnooNz Exclusive " ? 'isJnoonzExclusive' : ''}`}
                style={{ height: "auto" }} key={type.id}>
                <div key={type.id}>
                  <div className="typesName">
                    {type.name}
                   
                  </div>
                  <div className="typesImage expanded ">
                    <img
                      className="itemImage"
                      src={`${process.env.REACT_APP_BASE_URL}${type.image}`}
                      alt={type.name}
                      title={type.name}
                    />
                  </div>
                  <>
                    {type.child.map((category, index) => (
                      <div className="categoryContainer" key={category.id} >
                        <div className="categoryName">
                          {category.name}
                        </div>
                        <>
                          {(category.child && category.child.length) ? (

                            <div className="subCategoryContainer">
                              {category.child.map((subCategory, scindex) => (
                                <Link to={`/products?type=${type.slug}&category=${category.slug}&subCategory=${subCategory.slug}`}
                                  key={subCategory.id}
                                  className="colLastLinks"
                                  onClick={() => {
                                    hideOverlay()
                                  }}
                                >{subCategory.name}</Link>
                              ))}
                            </div>
                          ) : (null)}
                        </>
                      </div>

                    ))}
                  </>

                </div>
              </SwiperSlide>
            ))}

          </Swiper>
          <div ref={navigationPrevRef}
            className={`customSliderNavigation prev ${!isFirstSlide ? "a" : ""}`}
          >
            <FaCaretRight className="customSliderPrevIcon" />
          </div>
          <div ref={navigationNextRef}
            className={`customSliderNavigation next ${!isLastSlide ? "b" : ""}`}
          >
            <FaCaretLeft className="customSliderNextIcon" />
          </div>

          </ScrollArea>

        </Fragment>
      )}


    </>
  )
}
export default SitemapExpended