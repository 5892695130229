import React, { useEffect, useState } from 'react'
import './ProductReview.scss'
import { Time } from '../TimeFormat/TimeFormat'
import ProductRating from '../ProductRating/ProductRating'
import { ItemNotFoundDynamic } from '../Alert/Alert'
import { SkeletonQuestionItem } from '../Skeleton/Skeleton'
import { Button } from 'react-bootstrap'
import { fetchProductReviewsAPI } from '../../apis/productAPIs'
const ProductReview = ({ productid, itemReviews, props }) => {

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [qa, setQA] = useState([])


    const loadReviews = async () => {
        const apiResult = await fetchProductReviewsAPI(`ProductReviewsSearch[product_id]=${productid}&sort=-question_time&page=${page}`);
        if (apiResult.status) {
            setTotalPages(apiResult.data._meta.pageCount)
            setQA([...qa, ...apiResult.data.items]);
        }
    }
    useEffect(() => {
        loadReviews();
    }, [page]);

    const loadMore = () => {
        setLoadMoreLoading(true)
        setTimeout(function () {
            setPage(page + 1);
            setLoadMoreLoading(false)
        }, 1500)
    }

    return (
        <div className="productReview">
            <>
                {qa && qa.length > 0 ? (
                    <>
                        <div className="pageHeading">
                            Customer Reviews
                        </div>
                        <div className="productReviewTotalContainer">
                            <ul>
                                <li>
                                    <div className="productRating sm" title={`${itemReviews.rating} out of 5 stars`}>
                                        <ProductRating
                                            initialRating={itemReviews.rating}
                                            readonly={true}
                                        />
                                        <span className="pl-2">{itemReviews.rating} out of 5</span>
                                    </div>
                                </li>
                                <li>|</li>
                                <li>
                                    <div>
                                        {itemReviews.count} customer review(s)
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <ul className="review_rating_list mt-4">
                            {qa.map((item, index) =>
                                <li key={item.id}>
                                    <div className="productRating sm mb-2"
                                        title={`${item.rating} out of 5 stars`}
                                    >
                                        {console.log(item.rating)}
                                        <ProductRating
                                            initialRating={item.rating}
                                            readonly={true}
                                        />
                                    </div>
                                    <div className="review">{item.comments}</div>
                                    <div className="reviewSender_date">
                                        By: {item.buyer.name}
                                        &nbsp; - &nbsp;
                                        <Time time={item.created_at} />
                                    </div>
                                </li>
                            )}
                        </ul>
                        {loadMoreLoading ? <SkeletonQuestionItem /> : ''}
                        {totalPages !== page &&
                            <div className="loadMoreButton">
                                <Button
                                    className="hasShadow"
                                    variant="whiteButton"
                                    size="sm"
                                    onClick={() => {
                                        loadMore()
                                    }}
                                >
                                    load more
                                </Button>
                            </div>
                        }
                    </>
                ) : (
                    <p>
                        <ItemNotFoundDynamic
                            msgTitle="Review(s) or rating(s) are not available for this item."
                        /></p>
                )}

            </>






        </div>
    )
}

export default ProductReview